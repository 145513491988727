export const allRecipientTypesOptions = [
  'Email',
  'SMS',
  'Text-To-Speech (cell)',
  'Text-To-Speech (home)',
];

export const allRecipientTypes = [
  { label: 'Email', icon: 'email' },
  { label: 'SMS', icon: 'sms' },
  { label: 'Text-To-Speech (cell)', icon: 'phone' },
  { label: 'Text-To-Speech (home)', icon: 'home' },
] as const;
