import dayjs from 'dayjs';

interface GenerateInferredNameInput {
  origin_name: string;
  destination_name: string;
  departure_window_start: string;
  departure_window_end: string;
}

export const generateInferredName = ({
  origin_name,
  destination_name,
  departure_window_start,
  departure_window_end,
}: GenerateInferredNameInput) => {
  const departureWindowStart = dayjs(departure_window_start).format(
    'YYYY-MM-DD HH:mm:ss'
  );
  const departureWindowEnd = dayjs(departure_window_end).format(
    'YYYY-MM-DD HH:mm:ss'
  );

  return `${origin_name} to ${destination_name} from ${departureWindowStart} to ${departureWindowEnd}`;
};
