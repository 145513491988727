import { useState } from 'react';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { MdLocationOn } from 'react-icons/md';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';

export const OriginPage: React.FC<any> = ({
  arr,
  origin,
  setOrigin,
  setSelOriAirports,
  setActiveStep,
  flyTo,
  layerState,
}) => {
  const [search, setSearch] = useState('');
  return (
    <>
      <label className="form-label">Select an Origin AOR</label>
      <StylishNewButton
        className="button--primary float-end button--sml"
        onClick={() => layerState.setShowAORsOnMap(!layerState.showAORsOnMap)}
      >
        {layerState.showAORsOnMap ? 'Hide AORs' : 'View AORs'}
      </StylishNewButton>
      <StylishNewSearchBar
        value={search}
        onChangeSearchTerm={(e: any) => {
          setSearch(e);
        }}
        placeholder="Search for AOR"
      />
      <div className="list-border">
        {arr
          .filter((x: any) =>
            search !== ''
              ? x.label.toLowerCase().includes(search.toLowerCase())
              : x
          )
          .map((x: any) => {
            return (
              <div
                key={x.id}
                className="list-item"
                onClick={() => {
                  setOrigin({ value: x.value, label: x.label });
                  setSelOriAirports([]);
                }}
              >
                <span className="item-text form-label">
                  <input
                    type="radio"
                    className="neo-radio form-check-input me-3"
                    value={x.label}
                    checked={origin?.label === x?.label}
                    readOnly
                  />
                  {x.label}
                </span>
                <StylishNewButton
                  className="btn button--link float-end"
                  onClick={(e) => {
                    flyTo(x.value);
                    e.stopPropagation();
                  }}
                >
                  <MdLocationOn />
                </StylishNewButton>
              </div>
            );
          })}
      </div>
      <StylishNewButton
        className="button--sml button--primary float-end"
        disabled={!origin}
        onClick={() => {
          setActiveStep((s: number) => s + 1);
        }}
      >
        Next
      </StylishNewButton>
    </>
  );
};
