import React from 'react';

export default function CommonLayerPopup(props) {
  const { 
    children
  } = props;

  return (
    <div>
      {children}
    </div>
  );
}
