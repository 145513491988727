import React from 'react';
import IconFile from 'images/icons/icon__file.svg';
import IconUpload from 'images/icons/icon__upload.svg';
import IconClose from 'images/icons/icon__times.svg';

type ReportAttachmentsSectionProps = {
  incidentAfterActionReport: string[];
  exerciseAfterActionReport: string[];
  uploadFile: (x: File, y: string) => void;
  deleteFile: (x: number, y: string, z: string) => void;
  fileLoading: boolean;
};
export const ReportAttachmentsSection: React.FC<
  ReportAttachmentsSectionProps
> = ({
  incidentAfterActionReport,
  exerciseAfterActionReport,
  uploadFile,
  deleteFile,
  fileLoading,
}) => {
  return (
    <>
      <h4>Attachments</h4>
      {!!incidentAfterActionReport && incidentAfterActionReport.length > 0 && (
        <div className="col-md-12 mb-2">
          <label className="form-label m-0">
            Incident After Action Report (File Upload)
          </label>
          <div className="row">
            {incidentAfterActionReport.map((file, index: number) => {
              return (
                <div key={file} className="col-md-3 col-xl-2 my-2">
                  <div className="cards cards__uploaded-file">
                    <span
                      className="remove"
                      onClick={() =>
                        deleteFile(
                          index,
                          'IncidentAfterActionReport',
                          file.trim()
                        )
                      }
                    >
                      <img src={IconClose} alt="" />
                    </span>
                    <span className="icon">
                      <img src={IconFile} alt="" />
                    </span>
                    <span className="title">
                      {file.trim().split('/').pop()}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div className="col-md-12 mb-3">
        <label className="form-label">
          Incident After Action Report (File Upload)
        </label>
        <div className="form-upload">
          <div>
            {fileLoading ? (
              <div>Uploading...</div>
            ) : (
              <>
                <span className="d-flex justify-content-center mb-2">
                  <img src={IconUpload} alt="" />
                </span>
                <span className="d-block">
                  Drop Your Files Here or Click to Upload
                </span>
              </>
            )}
          </div>
          <input
            type="file"
            onInput={(e: any) =>
              uploadFile(e.target.files[0], 'IncidentAfterActionReport')
            }
          />
        </div>
      </div>
      {!!exerciseAfterActionReport && exerciseAfterActionReport.length > 0 && (
        <div className="col-md-12 mb-2">
          <label className="form-label m-0">
            Exercise After Action Report (File Upload)
          </label>
          <div className="row">
            {exerciseAfterActionReport.map((file, index: number) => {
              return (
                <div key={file} className="col-md-3 col-xl-2 my-2">
                  <div className="cards cards__uploaded-file">
                    <span
                      className="remove"
                      onClick={() =>
                        deleteFile(
                          index,
                          'ExerciseAfterActionReport',
                          file.trim()
                        )
                      }
                    >
                      <img src={IconClose} alt="" />
                    </span>
                    <span className="icon">
                      <img src={IconFile} alt="" />
                    </span>
                    <span className="title">
                      {file.trim().split('/').pop()}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div className="col-md-12 mb-3">
        <label className="form-label">
          Exercise After Action Report (File Upload)
        </label>
        <div className="form-upload">
          <div>
            {fileLoading ? (
              <div>Uploading...</div>
            ) : (
              <>
                <span className="d-flex justify-content-center mb-2">
                  <img src={IconUpload} alt="" />
                </span>
                <span className="d-block">
                  Drop Your Files Here or Click to Upload
                </span>
              </>
            )}
          </div>
          <input
            type="file"
            onInput={(e: any) =>
              uploadFile(e.target.files[0], 'ExerciseAfterActionReport')
            }
          />
        </div>
      </div>
    </>
  );
};

export default ReportAttachmentsSection;
