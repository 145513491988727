import React, { useEffect, useState } from 'react';
import { StylishSwitcher } from 'components/DesignSystems/New/StylishSwitcher';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import { StylishNewCheckbox } from 'components/DesignSystems/New/StylishNewCheckbox';
import ExpandRightArrow from '../../../DesignSystems/buttons/ExpandRightArrow';
import ExpandDownArrow from '../../../DesignSystems/buttons/ExpandDownArrow';
import FilterListIcon from '@mui/icons-material/FilterList';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Dropdown from 'react-bootstrap/Dropdown';
import {
  useAirportsData,
  useSeaportsData,
} from '../../mapHooks/useTransportation';

export const OptionsPage: React.FC<any> = ({
  airportsActive,
  setAirportsActive,
  seaportsActive,
  setSeaportsActive,
  setActiveStep,
  selOriAirports,
  setSelOriAirports,
  selDestAirports,
  setSelDestAirports,
  selOriSeaports,
  setSelOriSeaports,
  selDestSeaports,
  setSelDestSeaports,
  origin,
  destination,
}) => {
  const [originSearch, setOriginSearch] = useState('');
  const [destinationSearch, setDestinationSearch] = useState('');
  const [originSearchSea, setOriginSearchSea] = useState('');
  const [destinationSearchSea, setDestinationSearchSea] = useState('');
  const [tempOriginAirports, setTempOriginAirports] = useState<string[]>([]);
  const [tempDestinationAirports, setTempDestinationAirports] = useState<
    string[]
  >([]);
  const [tempOriginSeaports, setTempOriginSeaports] = useState<string[]>([]);
  const [tempDestinationSeaports, setTempDestinationSeaports] = useState<
    string[]
  >([]);
  const [show, setShow] = useState(true);
  const [showSea, setShowSea] = useState(true);
  const [oriInt, setOriInt] = useState(false);
  const [destInt, setDestInt] = useState(false);
  const [oriSeaInt, setOriSeaInt] = useState(false);
  const [destSeaInt, setDestSeaInt] = useState(false);

  const { data: originAirports = [] } = useAirportsData('', 300, 1, [
    origin?.value,
  ]);

  const { data: destinationAirports = [] } = useAirportsData('', 300, 1, [
    destination?.value,
  ]);

  const { data: originSeaports = [] } = useSeaportsData('', 300, 1, [
    origin?.value,
  ]);

  const { data: destinationSeaports = [] } = useSeaportsData('', 300, 1, [
    destination?.value,
  ]);

  useEffect(() => {
    setTempOriginAirports(selOriAirports);
    setTempDestinationAirports(selDestAirports);
    setTempOriginSeaports(selOriSeaports);
    setTempDestinationSeaports(selDestSeaports);
  }, [selOriAirports, selDestAirports, selOriSeaports, selDestAirports]);

  const handleCheckboxChange = (
    setFunc: (arr: any) => void,
    isChecked: boolean,
    iata_code: string
  ) => {
    setFunc((prev: any[]) => {
      if (isChecked) {
        return [...prev, iata_code];
      } else {
        return prev.filter((code: string) => code !== iata_code);
      }
    });
  };

  const handleAll = (
    temp: Port[],
    origin: Port[],
    setFunc: (arr: (string | undefined)[]) => void
  ) => {
    if (temp.length === origin.length) {
      setFunc([]);
    } else {
      setFunc(origin.map((node: Port) => node.iata_code || node.name));
    }
  };

  return (
    <>
      <div className="d-flex bg-gray-900--light-5 p-3 rounded mb-3 form-label">
        <span>
          {origin.label}: {originAirports.length} airports, {destination.label}:{' '}
          {destinationAirports.length} airports
        </span>
        <StylishSwitcher
          className={'ms-auto'}
          checked={airportsActive}
          onChange={() => {
            setAirportsActive(!airportsActive);
            setShow(!airportsActive);
          }}
          value={airportsActive}
        />
        <span className="ms-3" onClick={() => setShow((show) => !show)}>
          {show ? <ExpandDownArrow /> : <ExpandRightArrow />}
        </span>
      </div>
      <div className="d-flex form-label">
        {airportsActive && show && (
          <>
            <PortListComponent
              portTitle={'Origin Airports'}
              portSearch={originSearch}
              setPortSearch={setOriginSearch}
              tempPort={tempOriginAirports}
              portData={originAirports}
              setTempPort={setTempOriginAirports}
              portIntl={oriInt}
              setPortIntl={setOriInt}
              handleAll={handleAll}
              handleCheckboxChange={handleCheckboxChange}
            />
            <PortListComponent
              portTitle={'Destination Airports'}
              portSearch={destinationSearch}
              setPortSearch={setDestinationSearch}
              tempPort={tempDestinationAirports}
              portData={destinationAirports}
              setTempPort={setTempDestinationAirports}
              portIntl={destInt}
              setPortIntl={setDestInt}
              handleAll={handleAll}
              handleCheckboxChange={handleCheckboxChange}
            />
          </>
        )}
      </div>
      <div className="d-flex bg-gray-900--light-5 p-3 rounded mb-3 form-label">
        <span>
          {origin.label}: {originSeaports.length} seaports, {destination.label}:{' '}
          {destinationSeaports.length} seaports
        </span>
        <StylishSwitcher
          className={'ms-auto'}
          checked={seaportsActive}
          onChange={() => {
            setSeaportsActive(!seaportsActive);
            setShowSea(!seaportsActive);
          }}
          value={seaportsActive}
        />
        <span
          className="ms-3"
          onClick={() => setShowSea((showSea) => !showSea)}
        >
          {showSea ? <ExpandDownArrow /> : <ExpandRightArrow />}
        </span>
      </div>
      <div className="d-flex form-label">
        {seaportsActive && showSea && (
          <>
            <PortListComponent
              portTitle={'Origin Seaports'}
              portSearch={originSearchSea}
              setPortSearch={setOriginSearchSea}
              tempPort={tempOriginSeaports}
              portData={originSeaports}
              setTempPort={setTempOriginSeaports}
              portIntl={oriSeaInt}
              setPortIntl={setOriSeaInt}
              handleAll={handleAll}
              handleCheckboxChange={handleCheckboxChange}
            />
            <PortListComponent
              portTitle={'Destination Seaports'}
              portSearch={destinationSearchSea}
              setPortSearch={setDestinationSearchSea}
              tempPort={tempDestinationSeaports}
              portData={destinationSeaports}
              setTempPort={setTempDestinationSeaports}
              portIntl={destSeaInt}
              setPortIntl={setDestSeaInt}
              handleAll={handleAll}
              handleCheckboxChange={handleCheckboxChange}
            />
          </>
        )}
      </div>
      <StylishNewButton
        className="button--sml button--secondary"
        onClick={() => {
          setSelOriAirports(tempOriginAirports);
          setSelDestAirports(tempDestinationAirports);
          setSelOriSeaports(tempOriginSeaports);
          setSelDestSeaports(tempDestinationSeaports);
          setActiveStep((s: number) => s - 1);
        }}
      >
        Previous
      </StylishNewButton>
      <StylishNewButton
        className="button--sml button--primary float-end"
        disabled={
          tempOriginAirports.length === 0 &&
          tempDestinationAirports.length === 0 &&
          tempOriginSeaports.length === 0 &&
          tempDestinationSeaports.length === 0
        }
        onClick={() => {
          setSelOriAirports(tempOriginAirports);
          setSelDestAirports(tempDestinationAirports);
          setSelOriSeaports(tempOriginSeaports);
          setSelDestSeaports(tempDestinationSeaports);
          setActiveStep((s: number) => s + 1);
        }}
      >
        Next
      </StylishNewButton>
    </>
  );
};

type Port = {
  geojson?: string;
  iata_code?: string;
  icao_code?: string;
  lat?: number;
  lon?: number;
  name?: string;
  node_type?: string;
  status?: string;
  timezone?: string;
  is_international?: boolean;
};
type PortList = {
  portTitle: string;
  portSearch: string;
  setPortSearch: React.Dispatch<React.SetStateAction<string>>;
  tempPort: string[];
  portData: Port[];
  setTempPort: React.Dispatch<React.SetStateAction<any>>;
  portIntl: boolean;
  setPortIntl: React.Dispatch<React.SetStateAction<boolean>>;
  handleAll: (x: any, y: any, z: any) => void;
  handleCheckboxChange: (
    x: React.Dispatch<React.SetStateAction<string>>,
    y: boolean,
    z: string
  ) => void;
};

const PortListComponent: React.FC<PortList> = ({
  portTitle,
  portSearch,
  setPortSearch,
  tempPort,
  portData,
  setTempPort,
  portIntl,
  setPortIntl,
  handleAll,
  handleCheckboxChange,
}) => {
  return (
    <div className="port-list">
      {portTitle}:
      <StylishNewSearchBar
        className="short-search"
        value={portSearch}
        onChangeSearchTerm={(e: any) => {
          setPortSearch(e);
        }}
        placeholder="Search"
      />
      <br />
      <div className="d-flex justify-content-between mb-1">
        <StylishNewCheckbox
          label={
            tempPort?.length === portData?.length
              ? 'Deselect All'
              : 'Select All'
          }
          checked={tempPort?.length === portData?.length}
          onClick={() => handleAll(tempPort, portData, setTempPort)}
        />
        <span className="float-end">
          <Dropdown>
            <Dropdown.Toggle className="button--icon neo-filter-button">
              <FilterListIcon />
            </Dropdown.Toggle>
            <Dropdown.Menu align="end">
              <Dropdown.Item
                className={
                  'form-label neo-dropdown-item' + (portIntl ? ' selected' : '')
                }
                onClick={() => setPortIntl(!portIntl)}
              >
                INTL. Only
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </span>
      </div>
      <div className="list-border small form-label">
        {portData
          .filter((x: Port) =>
            portIntl
              ? x?.is_international ||
                x?.name?.toLowerCase().includes('international')
              : x
          )
          .filter((x: Port) =>
            portSearch !== ''
              ? x?.name?.toLowerCase().includes(portSearch.toLowerCase()) ||
                x?.iata_code?.toLowerCase().includes(portSearch.toLowerCase())
              : x
          )
          .map((node: Port, idx: number) => {
            return (
              <>
                <div
                  key={idx}
                  className={
                    'airport-item form-label ' +
                    (tempPort.includes(node?.iata_code || node?.name || '')
                      ? 'selected-item'
                      : '')
                  }
                  onClick={() =>
                    handleCheckboxChange(
                      setTempPort,
                      !tempPort.includes(node?.iata_code || node.name || ''),
                      node?.iata_code || node?.name || ''
                    )
                  }
                >
                  <span>{node.name}</span>
                  {node.iata_code && (
                    <>
                      <br />
                      <span>{node.iata_code}</span>
                    </>
                  )}
                  {tempPort.includes(node?.iata_code || node?.name || '') && (
                    <CheckCircleIcon className="float-end bg-gray-200" />
                  )}
                  <br />
                </div>
                <br />
              </>
            );
          })}
      </div>
    </div>
  );
};
