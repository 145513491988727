import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import axios from 'axios';
import config from 'constants/apiConfig';
import {
  selectGroupGuid,
  selectUserGuid,
} from '../../../slices/commonSelectors';

export const keys = {
  base: 'disasterChatsV2',
  sitreps: 'sitrepsV2',
};

// Hook to fetch sitreps (list of sitreps)
export const useSitrepsV2 = () => {
  const group_guid = useSelector(selectGroupGuid);

  return useQuery({
    queryKey: [keys.sitreps, group_guid],
    queryFn: async () => {
      const response = await axios.get(`${config.apiGateway.sitrepsV2}/`, {
        params: { group_guid },
      });
      return response.data;
    },
    staleTime: 0,
  });
};

export const useSitrepV2 = (sitrepId: UUID) => {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: [keys.sitreps, sitrepId],
    queryFn: async () => {
      const response = await axios.get(
        `${config.apiGateway.sitrepsV2}/pollSitrep`,
        {
          params: { sitrep_id: sitrepId },
        }
      );
      const sitrep = response.data;
      // Check if sitrep status is 'Error', and throw an error if so
      if (sitrep.status && sitrep.status.toLowerCase() === 'error') {
        throw new Error('Sitrep processing failed with status "Error"');
      }
      return sitrep;
    },
    enabled: Boolean(sitrepId), // Only run the query if sitrepId is truthy
    refetchInterval: (data: any) => {
      // Access the query state via queryClient
      const queryState = queryClient.getQueryState([keys.sitreps, sitrepId]);

      // Stop polling if the query has encountered an error
      if (queryState?.status === 'error') {
        return false;
      }

      // If data is undefined or status is undefined, continue polling
      if (!data?.state?.data?.status) {
        return 500; // Continue polling every 500ms
      }

      // I don't fully understand why this is needed, but sometimes there are cases where the sitrep object hasn't loaded, but the query was valid or invalid.
      const status =
        data?.status?.toLowerCase() || data?.state?.data?.status?.toLowerCase();

      // Stop polling if status is 'summarized' or 'error'
      if (status === 'summarized' || status === 'error') {
        return false;
      }
      // Continue polling
      return 500;
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: true, // Allow refetching on reconnect in case of network issues
    refetchOnMount: false,
    retry: false, // Do not retry the query if it fails
  });
};

type InitSitRepMutationInputs = {
  sitrep_template_id: UUID;
  sitrep_source: string;
  sitrep_source_id: UUID;
  name: string;
  description: string;
  selectedDatetime: string;
  aor_id: UUID;
};

export const useInitSitrepV2 = () => {
  const queryClient = useQueryClient();
  const user_guid = useSelector(selectUserGuid);
  const group_guid = useSelector(selectGroupGuid);

  return useMutation<any, any, InitSitRepMutationInputs>({
    mutationFn: async ({
      sitrep_template_id,
      sitrep_source,
      sitrep_source_id,
      name,
      description,
      selectedDatetime,
      aor_id,
    }) => {
      const response = await axios.post(
        `${config.apiGateway.sitrepsV2}/initSitrep`,
        {
          sitrep_template_id,
          sitrep_source,
          sitrep_source_id,
          name,
          description,
          user_guid,
          group_guid,
          selectedDatetime,
          aor_id,
        }
      );

      if (response.data && response.data.sitrepId) {
        return response.data.sitrepId;
      } else {
        throw new Error('Invalid response from server: sitrepId not found.');
      }
    },
    onSuccess: (sitrepId) => {
      queryClient.invalidateQueries({ queryKey: [keys.sitreps] });
    },
  });
};

type UpdateSitrepMutationInputs = {
  name?: string;
  description?: string;
  sitrep_id: UUID;
  archived?: boolean;
  aor_id?: UUID;
};

export const useUpdateSitrepV2 = () => {
  const queryClient = useQueryClient();

  return useMutation<any, any, UpdateSitrepMutationInputs>({
    mutationFn: async ({ name, description, sitrep_id, archived, aor_id }) => {
      const payload: {
        name?: string;
        description?: string;
        id: UUID;
        archived?: boolean;
        aor_id?: UUID;
      } = { id: sitrep_id };
      if (name !== undefined) payload.name = name;
      if (description !== undefined) payload.description = description;
      if (archived !== undefined) payload.archived = archived;
      if (aor_id !== undefined) payload.aor_id = aor_id;

      const response = await axios.put(
        `${config.apiGateway.sitrepsV2}/`,
        payload
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [keys.sitreps] });
    },
  });
};

type RefeshSitrepMutationInputs = {
  id: UUID;
  selectedDatetime: string;
  sections?: any[];
  hardRefresh: boolean;
};

export const useRefreshSitrepV2 = () => {
  const user_guid = useSelector(selectUserGuid);
  const group_guid = useSelector(selectGroupGuid);
  const queryClient = useQueryClient();

  return useMutation<any, any, RefeshSitrepMutationInputs>({
    mutationFn: async ({
      id,
      selectedDatetime,
      sections = [],
      hardRefresh,
    }) => {
      const response = await axios.post(
        `${config.apiGateway.sitrepsV2}/refreshSitrep`,
        {
          sitrepId: id,
          user_guid,
          group_guid,
          selectedDatetime,
          sections,
          hardRefresh,
        }
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [keys.sitreps] });
    },
  });
};

type UpdateSitrepSectionMutationInputs = {
  sitrepId: UUID;
  sectionId: UUID;
  updatedFields: Record<string, any>;
};

export const useUpdateSitrepSectionV2 = () => {
  const queryClient = useQueryClient();

  return useMutation<any, any, UpdateSitrepSectionMutationInputs>({
    mutationFn: async ({ sitrepId, sectionId, updatedFields }) => {
      const response = await axios.post(
        `${config.apiGateway.sitrepsV2}/updateSitrepSection`,
        {
          sitrepId,
          sectionId,
          ...updatedFields,
        }
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [keys.sitreps] });
    },
  });
};

type AddSitrepSectionMutationInputs = {
  sitrepId: UUID;
  sectionTemplateId: UUID;
  selectedDatetime: string;
  sitrep_source: string;
  sitrep_source_id: UUID;
  order: number;
  aor_id: UUID;
};

export const useAddSitrepSectionV2 = () => {
  const user_guid = useSelector(selectUserGuid);
  const group_guid = useSelector(selectGroupGuid);
  const queryClient = useQueryClient();

  return useMutation<any, any, AddSitrepSectionMutationInputs>({
    mutationFn: async ({
      sitrepId,
      sectionTemplateId,
      selectedDatetime,
      sitrep_source,
      sitrep_source_id,
      order,
      aor_id,
    }) => {
      const response = await axios.post(
        `${config.apiGateway.sitrepsV2}/addSitrepSection`,
        {
          sitrepId,
          sectionTemplateId,
          user_guid,
          group_guid,
          selectedDatetime,
          sitrep_source,
          sitrep_source_id,
          order,
          aor_id,
        }
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [keys.sitreps] });
    },
  });
};

type RemoveSitrepSectionMutationInputs = {
  sitrepId: UUID;
  sectionId: UUID;
};

export const useRemoveSitrepSectionV2 = () => {
  const queryClient = useQueryClient();

  return useMutation<any, any, RemoveSitrepSectionMutationInputs>({
    mutationFn: async ({ sitrepId, sectionId }) => {
      const response = await axios.post(
        `${config.apiGateway.sitrepsV2}/removeSitrepSection`,
        {
          sitrepId,
          sectionId,
        }
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [keys.sitreps] });
    },
  });
};

type MoveSitrepSectionMutationInputs = {
  sitrepId: UUID;
  sectionId: UUID;
  direction: 'up' | 'down';
};

export const useMoveSitrepSectionV2 = () => {
  const queryClient = useQueryClient();

  return useMutation<any, any, MoveSitrepSectionMutationInputs>({
    mutationFn: async ({ sitrepId, sectionId, direction }) => {
      const response = await axios.post(
        `${config.apiGateway.sitrepsV2}/moveSection`,
        {
          sitrepId,
          sectionId,
          direction,
        }
      );
      return response.data;
    },
    onSuccess: (_, { sitrepId }) => {
      queryClient.invalidateQueries({ queryKey: [keys.sitreps, sitrepId] });
    },
  });
};
