import React, { createContext, useContext, useEffect, useState } from 'react';
import UpgradeSubscriptionModal from './UpgradeSubscriptionModal';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

const SidebarNavContext = createContext<{
  close: () => void;
  isOpen: boolean;
  toggle: () => void;
  handleShowUpgradeModal: () => void;
}>({
  close: noop,
  isOpen: false,
  toggle: noop,
  handleShowUpgradeModal: noop,
});

const SidebarNavProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [sideNavOpen, setSideNavOpen] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const close = () => {
    setSideNavOpen(false);
  };

  const toggle = () => {
    setSideNavOpen(!sideNavOpen);
  };

  const handleShowUpgradeModal = () => {
    setShowUpgradeModal(true);
  };

  const handleCloseUpgradeModal = () => {
    setShowUpgradeModal(false);
  };

  useEffect(() => {
    if (showUpgradeModal) setSideNavOpen(false);
  }, [showUpgradeModal]);

  return (
    <>
      <SidebarNavContext.Provider
        value={{
          close,
          isOpen: sideNavOpen,
          toggle,
          handleShowUpgradeModal,
        }}
      >
        {children}
      </SidebarNavContext.Provider>
      {showUpgradeModal && (
        <UpgradeSubscriptionModal onClose={handleCloseUpgradeModal} />
      )}
    </>
  );
};

export default SidebarNavProvider;
export const useSidebarNavContext = () => useContext(SidebarNavContext);
