import React, { useEffect, useRef, useState } from 'react';
import { StylishNewTextEditor } from './StylishNewTextEditor';
import { useController } from 'react-hook-form';

export default function TinyMCEWithFormController({
  className,
  name,
  defaultValue = '',
  control,
  placeholder,
  readOnly,
  rules = {},
}) {
  const { field, fieldState, formState } = useController({
    name,
    control,
    defaultValue,
    rules,
    shouldUnregister: true,
  });

  const [submitCount, setSubmitCount] = useState(formState.submitCount);
  const editorRef = useRef(null);
  useEffect(() => {
    // awful way listen for reset()
    // NOTE: this only works is submitCount goes up (happens when an async operation occurs)
    if (!fieldState.isDirty && formState.submitCount !== submitCount) {
      setSubmitCount(formState.submitCount);
      editorRef.current.resetContent(defaultValue);
    }
  }, [fieldState.isDirty]);
  return (
    <StylishNewTextEditor
      className={className}
      initialValue={defaultValue}
      placeholder={placeholder}
      readOnly={readOnly}
      onChange={(value) => {
        field.onChange(value);
      }}
      editorRef={editorRef}
    />
  );
}
