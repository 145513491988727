import DrawerWrapper, { DrawerFooter } from '../../IAP/DrawerWrapper';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Offcanvas, Row } from 'react-bootstrap';
import { useCreateNotificationJob } from './notificationJobHooks';
import { PratusStepper } from '../../DesignSystems/PratusStepper/PratusStepper';
import { SelectRecipientsStep } from './SelectRecipientsStep';
import { SelectTypeAndContentStep } from './SelectTypeAndContentStep';
import { SelectAlertTypeStep } from './SelectAlertTypeStep';
import generateNotificationObject from '../generateNotificationEntity';
import {
  selectGroupGuid,
  useAppSelector,
} from '../../../slices/commonSelectors';
import { PreviewAndSendStep } from './PreviewAndSendStep';

type AddNotificationJobDrawerProps = {
  toggle: () => void;
};

export type ContactMethod =
  | 'Email'
  | 'SMS'
  | 'Text-To-Speech (cell)'
  | 'Text-To-Speech (home)';

// TODO: How do we keep track of form fields from the different steps?
export type FormFields = {
  // step 0
  short_description: string;
  jobType: 'riskJob';
  threshold: string; // convert to number
  pattern: string; // cron pattern
  aor: UUID;
  // step 1
  template: UUID;
  contact_method: ContactMethod[];
  subject?: string;
  email_message?: string;
  sms_message?: string;
  tts_message?: string;
  // step 2
  searchTerm?: string; // maybe not correct in the form. searches users.
  email: string;
  sms: string;
};

const AddNotificationJobDrawer: React.FC<AddNotificationJobDrawerProps> = ({
  toggle,
}) => {
  const [activeStep, setActiveStep] = useState(0);

  const groupGuid = useAppSelector(selectGroupGuid);
  const templates = useAppSelector((s) => s.app.notifications);

  const form = useForm<FormFields>({
    defaultValues: {
      contact_method: [],
      jobType: 'riskJob',
      threshold: '12',
      pattern: '*/5 * * * *',
    },
  });
  const {
    handleSubmit,
    formState: { errors },
  } = form;

  const mutation = useCreateNotificationJob();

  const createJob = async (formData: FormFields) => {
    const description =
      formData.short_description ?? getShortDescription(formData);

    let notification;
    if (formData.template) {
      notification = templates.find((t: any) => t.notification_id === formData.template);
    } else {
      notification = generateNotificationObject(
        formData.subject ?? 'No Subject',
        formData.email_message ?? 'No Message',
        groupGuid,
        formData.contact_method,
        [formData.email],
        [formData.sms],
        formData.sms_message,
        [formData.sms], // reusing the number for tts
        [formData.sms], // reusing the number for tts
        formData.tts_message,
        undefined,
        undefined,
        undefined,
        undefined,
        `Wind Risk Alert: ${formData.threshold} mph (${formData.short_description})`
      );
    }

    await mutation.mutateAsync({
      short_description: description,
      group_guid: groupGuid,
      job_type: formData.jobType,
      job_options: {
        wind_location: formData.aor,
        threshold: parseInt(formData.threshold),
      },
      repeat_options: {
        pattern: formData.pattern,
      },
      notification: notification,
    });
    toggle();
  };

  return (
    <DrawerWrapper toggle={toggle} title="Add Alert">
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(createJob)}
          className="d-flex flex-column justify-content-between flex-grow-1"
        >
          <Offcanvas.Body className={'p-4'}>
            <Row className={'py-4'}>
              <PratusStepper
                steps={[
                  { id: 0, label: 'Define Alert' },
                  { id: 1, label: 'Define Content' },
                  { id: 2, label: 'Select Recipients' },
                  { id: 3, label: 'Preview & Send' },
                ]}
                activeStep={activeStep}
              />
            </Row>
            {getStep(activeStep)}
          </Offcanvas.Body>
          <DrawerFooter>
            {activeStep > 0 && (
              <button
                type="button"
                className={'button button--secondary me-2'}
                onClick={() => setActiveStep(activeStep - 1)}
                /**
                 * TODO: need a way to prevent nav if current page has errors
                 */
              >
                Previous
              </button>
            )}
            {activeStep === 3 && (
              <button
                className={'button button--primary'}
                type="submit"
                disabled={Object.keys(errors).length > 0}
              >
                Save Job
              </button>
            )}{' '}
            {activeStep < 3 && (
              <button
                className={'button button--primary'}
                type="button"
                onClick={() => setActiveStep(activeStep + 1)}
              >
                Next
              </button>
            )}
          </DrawerFooter>
        </form>
      </FormProvider>
    </DrawerWrapper>
  );
};

export default AddNotificationJobDrawer;

const getStep = (activeStep: number) => {
  switch (activeStep) {
    case 0:
      return <SelectAlertTypeStep />;
    case 1:
      return <SelectTypeAndContentStep />;
    case 2:
      return <SelectRecipientsStep />;
    case 3:
      return <PreviewAndSendStep />;
    default:
      return null;
  }
};

function getShortDescription(formData: FormFields) {
  return `Wind Risk Alert: ${formData.threshold} mph`;
}
