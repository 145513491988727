// mapMenu/MapExternalMaps.js
import React from 'react';
import ExternalMapManager from 'components/DisasterGPT/ExternalMapManager';

const MapExternalMaps = ({ mapSettings, handleMapSettings, onClose }) => {
  const handleSelectMap = (map) => {
    // If clicking the already active map, deselect it
    if (mapSettings.currentExternalMap?.url === map?.url) {
      handleMapSettings({
        ...mapSettings,
        currentExternalMap: null,
        showExternalMap: false
      });
    } else {
      // Otherwise, select the new map
      handleMapSettings({
        ...mapSettings,
        currentExternalMap: map,
        showExternalMap: true
      });
    }
  };

  return (
    <ExternalMapManager 
      onSelectMap={handleSelectMap}
      onClose={onClose}
      currentMap={mapSettings.currentExternalMap}
    />
  );
};

export default MapExternalMaps;