import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import StylishNewTableAction from 'components/DesignSystems/New/StylishNewTableAction';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { getAllWorkAssignments } from 'actions/IAPForm204Actions';
import AddUpdateWorkAssignment from './WorkAssignment/AddUpdateWorkAssignment';
import {
  getAllObjectiveToIncident,
  getAllResourcesToIncident,
} from 'actions/incidentActions';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { deleteAction } from 'actions/IAPForm201Actions';
import AddUpdateBranchModal from './Branch/AddUpdateBranchModal';
import PageTitle from 'components/headers/PageTitle';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export const AssignmentList = () => {
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();
  const [allWorkAssignments, setAllWorkAssignments] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const [deleteConfirmationData, setDeleteConfirmationData] = useState(null);
  const [updateAssignmentData, setUpdateAssignmentData] = useState();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [workAssignmentModalType, setWorkAssignmentModalType] = useState('New');
  const [showNewBranchModal, setShowNewBranchModal] = useState(false);

  const incidentObjectives = useSelector(
    (state) => state.app.incidentObjectives
  );
  const reduxIncidentResources = useSelector(
    (state) => state.app.incidentResources
  );
  const [
    isWorkAssignmentsDialogOpen,
    setIsWorkAssignmentsDialogOpen,
  ] = useState(false);
  const reduxCurrentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });
  const reduxAllWorkAssignments = useSelector(
    (state) => state.app.allWorkAssignments
  );
  const onChangeSearchTerm = (termSearched) => {
    setSearchTerm(termSearched);
    const filteredAssignments = reduxAllWorkAssignments.filter((assignment) => {
      return (
        assignment.special_equipment
          .toLowerCase()
          .includes(termSearched.toLowerCase()) ||
        assignment.description
          .toLowerCase()
          .includes(termSearched.toLowerCase()) ||
        assignment.status.toLowerCase().includes(termSearched.toLowerCase()) ||
        assignment.overhead.toLowerCase().includes(termSearched.toLowerCase())
      );
    });
    setAllWorkAssignments(filteredAssignments);
  };
  useEffect(() => {
    if (!!reduxCurrentIncident) {
      if (!!reduxCurrentIncident.current_period) {
        reduxDispatch(getAllWorkAssignments());
      }
      reduxDispatch(getAllResourcesToIncident());
      reduxDispatch(getAllObjectiveToIncident());
    }
  }, [reduxCurrentIncident]);
  useEffect(() => {
    if (!!reduxAllWorkAssignments) {
      setAllWorkAssignments(reduxAllWorkAssignments);
    } else {
      setAllWorkAssignments([]);
    }
  }, [reduxAllWorkAssignments]);

  const onDeleteAction = (row) => {
    setDeleteConfirmationData(row);
    setShowConfirmDialog(true);
  };

  const onConfirmedDeleteActionData = () => {
    reduxDispatch(
      deleteAction(deleteConfirmationData.action_id, 'Work Assignment')
    );
    setDeleteConfirmationData(null);
    setShowConfirmDialog(false);
  };

  const onEditAssigmnent = (row) => {
    setUpdateAssignmentData(row);
    setWorkAssignmentModalType('Edit');
    setIsWorkAssignmentsDialogOpen(true);
  };
  const columns = [
    {
      dataField: 'description',
      text: 'Description',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Description' },
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      attrs: { title: 'Status' },
    },
    {
      dataField: 'branch_name',
      text: 'Branch',
      sort: true,
      attrs: { title: 'Branch' },
    },
    {
      dataField: 'division_name',
      text: 'Division',
      sort: true,
      attrs: { title: 'Division' },
    },
    {
      dataField: 'overhead',
      text: 'Overhead',
      sort: true,
      attrs: { title: 'Overhead' },
    },
    {
      dataField: 'special_equipment',
      text: 'Special Equipment',
      sort: true,
      attrs: { title: 'Special Equipment' },
    },
    {
      text: 'Action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => (
        <StylishNewTableAction
          name="Assignment"
          cell={cell}
          row={row}
          rowIndex={rowIndex}
          onDelete={() => onDeleteAction(row)}
          onEdit={() => onEditAssigmnent(row)}
        />
      ),
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
      attrs: { title: 'Action' },
    },
  ];

  const expandRowWorkAssignment = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <div className="mb-3">
            <h6 className="mb-1">Description</h6>
            <p className="m-0">{row.description}</p>
          </div>
          <div className="mb-3">
            <h6 className="mb-1">Overhead</h6>
            <p className="m-0">{row.overhead}</p>
          </div>
          <div className="mb-3">
            <h6 className="mb-1">Special Equipment</h6>
            <p className="m-0">{row.special_equipment}</p>
          </div>
          {row.objectives.every((objective) => !!objective.description) && (
            <>
              {row.objectives && row.objectives.length > 0 && (
                <div className="mb-3">
                  <h6 className="mb-1">
                    Objective(s) attached to this assignment:
                  </h6>
                  <ul className="list">
                    {row.objectives.map((objective) => {
                      return (
                        <li key={objective.id}>{objective.description}</li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </>
          )}
          {row.resources.every(
            (resource) => !!resource.type && !!resource.name
          ) && (
            <>
              {row.resources && row.resources.length > 0 && (
                <div className="mb-3">
                  <h6 className="mb-1">
                    Resource(s) attached to this assignment and Number of
                    required resource:
                  </h6>
                  <ul className="list">
                    {row.resources.map((resource) => {
                      return (
                        <li key={resource.id}>
                          {resource.type} ({resource.name}) ,{' '}
                          {resource.required_amount}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </>
          )}
          <div className="button-group mt-4">
            <StylishNewButton
              type="button"
              className="button--primary"
              onClick={() => onEditAssigmnent(row)}
            >
              Edit
            </StylishNewButton>
            <StylishNewButton
              type="button"
              className="button--tertiary button--reverse"
              onClick={() => onDeleteAction(row)}
            >
              Delete
            </StylishNewButton>
          </div>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  return (
    <>
      <PageTitle title="Assignment List" parents={['Incident Management']} />

      <div className="d-md-flex flex-wrap align-items-center mb-4">
        <StylishNewButton
          className="button--primary w-100 w-md-auto"
          onClick={() => {
            setWorkAssignmentModalType('New');
            setUpdateAssignmentData(null);
            setIsWorkAssignmentsDialogOpen(true);
          }}
        >
          Create Assignment
        </StylishNewButton>
        <div className="button-group toggle ms-auto mt-3 mt-md-0">
          {/* <NavLink
            className="button--icon lrg flex-grow-1"
            to={'/incident_management/assignments_list'}
          >
            <i className="fa fa-bars" aria-hidden="true"></i>
          </NavLink>
          <NavLink
            className="button--icon lrg flex-grow-1"
            to={'/incident_management/assignments_board'}
          >
            <i className="fa fa-th-large" aria-hidden="true"></i>
          </NavLink>
          <NavLink
            className="button--icon lrg flex-grow-1"
            to={'/incident_management/assignments_calendar'}
          >
            <i className="fa fa-calendar" aria-hidden="true"></i>
          </NavLink> */}
        </div>
        <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0 mt-3 mt-lg-0">
          <StylishNewSearchBar
            className="ms-lg-3 me-3 me-md-0"
            value={searchTerm}
            onChangeSearchTerm={onChangeSearchTerm}
          />
          <StylishNewButton
            customButton
            className="button--icon ms-auto ms-lg-3 flex-shrink-0"
          >
            <SharedIcon iconName="question_mark" />
          </StylishNewButton>
          <Dropdown className="flex-shrink-0 ms-3">
            <Dropdown.Toggle className="button--icon">
              <SharedIcon iconName="more_vert" bold />
            </Dropdown.Toggle>
            <Dropdown.Menu align="end">
              {/* temporarly hiding this until we can select branches for the work assignments */}
              {/* <Dropdown.Item onClick={() => setShowNewBranchModal(true)}> */}
              {/*   <img src={IconAdd} alt="" /> */}
              {/*   New Branch */}
              {/* </Dropdown.Item> */}
              <Dropdown.Item
              // onClick={() => history.push('/incident_management/ICS_204-CG')}
              >
                {/* <img src={IconView} alt="" />
                Printable Assignments List (204) */}
                <Link
                  to={'/incident_management/ICS_204-CG'}
                  className="nav-link d-flex align-items-center"
                >
                  <SharedIcon iconName="visibility" />
                  Printable Assignments List (204)
                </Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="form-block">
        <StylishNewTable
          keyField={'action_id'}
          columns={columns}
          rows={allWorkAssignments}
          expandRow={expandRowWorkAssignment}
        />
      </div>
      {isWorkAssignmentsDialogOpen && (
        <AddUpdateWorkAssignment
          incidentObjectives={incidentObjectives}
          incidentResources={reduxIncidentResources}
          workAssignmentModalType={workAssignmentModalType}
          updateActionData={updateAssignmentData}
          show={isWorkAssignmentsDialogOpen}
          onClose={() => setIsWorkAssignmentsDialogOpen(false)}
        />
      )}
      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Delete Confirmation'}
          dialogContent={'Are you sure you want to delete this assignment?'}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={() => onConfirmedDeleteActionData()}
        />
      )}
      {showNewBranchModal && (
        <AddUpdateBranchModal
          show={showNewBranchModal}
          onClose={() => setShowNewBranchModal(false)}
        />
      )}
    </>
  );
};
