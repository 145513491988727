import React, { useEffect, useState } from 'react';
import * as turf from '@turf/turf';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishSwitcher } from 'components/DesignSystems/New/StylishSwitcher';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { useAors } from 'components/Notifications/jobs/notificationJobHooks';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput.js';
import ReactPaginate from 'react-paginate';
import IconClose from '../../../assets/images/icon__times.svg';
import IconArrowRight from '../../../assets/images/icon__arrow--right.svg';
import IconArrowLeft from '../../../assets/images/icon__arrow--left.svg';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../constants/apiConfig';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useAirportsData, useAirportsDataCount, useAssetsData, useSeaportsData,
  useSeaportsDataCount,} from '../mapHooks/useTransportation';
import { transportationAirAssetsEffect, transportationAirportsEffect,
  transportationSeaportsEffect,
} from '../mapEffects/transportationEffect';
import mapboxgl from 'mapbox-gl';
import { MdLocationOn } from 'react-icons/md';
import moment from 'moment';
import { PratusStepper } from 'components/DesignSystems/PratusStepper/PratusStepper';
import { OriginPage } from './MapNeoPages/OriginPage';
import { OptionsPage } from './MapNeoPages/OptionsPage';
import { DestinationPage } from './MapNeoPages/DestinationPage';
import { FinalPage } from './MapNeoPages/FinalPage';
import { AnalysisView, ResultsPage } from './MapNeoPages/ResultsPage';
import {
  useCapacityAnalysisList,
} from 'hooks/useCapacity';
import { selectGroup, useAppSelector } from 'slices/commonSelectors';
import TransportationDetails from '../mapComponents/TransportationDetails';
import { generateInferredName } from '../utils/generateInferredName';

const pageLimit = 50;
const MapNEO: React.FC<{
  map: any;
  mapHasLoaded: any;
  mapSettings: any;
  apiPrefix: any;
  setLayerClicked: any;
  airportsActive: boolean;
  setAirportsActive: any;
  seaportsActive: boolean;
  setSeaportsActive: any;
  selectedNode: any;
  setSelectedNode: any;
  onClose: any;
  layerState: any;
}> = (props) => {
  const {
    map,
    mapHasLoaded,
    mapSettings,
    apiPrefix,
    setLayerClicked,
    airportsActive,
    setAirportsActive,
    seaportsActive,
    setSeaportsActive,
    selectedNode,
    setSelectedNode,
    onClose,
    layerState,
  } = props;

  const selectedDatetime = mapSettings?.selectedDatetime;
  const reduxCurrentlySelectedGroup = useSelector((state: any) => {
    return state.app.currentlySelectedGroup;
  });
  const group_guid = useAppSelector(selectGroup);

  const [showSidebarSecondary, setShowSidebarSecondary] = useState(false);
  const [sidebarSecondaryActiveItem, setSidebarSecondaryActiveItem] = useState<
    string | null
  >(null);
  const [origin, setOrigin] = useState({ value: '', label: '' });
  const [destination, setDestination] = useState({ value: '', label: '' });
  const [arr, setArr] = useState<{ value: string; label: string }[]>([]);
  const [airportSearchInput, setAirportSearchInput] = useState('');
  const [seaportSearchInput, setSeaportSearchInput] = useState('');
  const [currentAirportPage, setCurrentAirportPage] = useState(1);
  const [currentSeaportPage, setCurrentSeaportPage] = useState(1);
  const [selOriAirports, setSelOriAirports] = useState<any>([]);
  const [selDestAirports, setSelDestAirports] = useState<any>([]);
  const [selOriSeaports, setSelOriSeaports] = useState<any>([]);
  const [selDestSeaports, setSelDestSeaports] = useState<any>([]);
  const [existingAnalysis, setExistingAnalysis] = useState<{ label: string; value: any } | null>(null);
  const [analysisOptions, setAnalysisOptions] = useState<{ value: any; label: string }[]>([]);
  const [activeStep, setActiveStep] = useState(1);
  const [result, setResult] = useState(null);
  const [assetsViewActive, setAssetsViewActive] = useState(false);
  const aors = useAors();
  const capacityAnalysisList = useCapacityAnalysisList(group_guid.group_guid);

  useEffect(() => {
    if (capacityAnalysisList?.data) {
      const temp = capacityAnalysisList.data.map((item: any) => ({
        value: item.analysis_output,
        label: generateInferredName(item),
      }));
      setAnalysisOptions(temp);
    }
  }, [capacityAnalysisList.data]);

  const {
    data: airportNodes = [],
    isLoading: isLoadingAirportNodes,
  } = useAirportsData(airportSearchInput, pageLimit, currentAirportPage);
  const {
    data: airportNodesCount,
    isLoading: isLoadingAirportNodesCount,
  } = useAirportsDataCount(airportSearchInput);
  const {
    data: seaportNodes = [],
    isLoading: isLoadingSeaportNodes,
  } = useSeaportsData(seaportSearchInput, pageLimit, currentSeaportPage);
  const {
    data: seaportNodesCount,
    isLoading: isLoadingSeaportNodesCount,
  } = useSeaportsDataCount(seaportSearchInput);
  const {
    data: airAssetsNodes = [],
    isLoading: isLoadingAirAssetsNodes
  } = useAssetsData(selectedNode?.icao_code);

  const steps = [
    { id: 1, label: '' },
    { id: 2, label: '' },
    { id: 3, label: '' },
    { id: 4, label: '' },
  ];
  useEffect(() => {
    if (aors?.data) {
      const temp = aors.data.map((aor) => ({
        value: aor.id,
        label: aor.name,
      }));
      setArr(temp);
    }
  }, [aors.data]);

  const aorIds = [ origin.value, destination.value ];

  useEffect(() => {
    transportationAirportsEffect(
      map,
      apiPrefix,
      airportsActive,
      setLayerClicked,
      airportNodes,
      aorIds
    );
  }, [airportNodes, airportsActive]);
  
  useEffect(() => {
    transportationSeaportsEffect(
      map,
      apiPrefix,
      seaportsActive,
      setLayerClicked,
      seaportNodes,
      aorIds
    );
  }, [seaportNodes, seaportsActive]);

  useEffect(() => {
    transportationAirAssetsEffect(
      map,
      apiPrefix,
      airportsActive,
      setLayerClicked,
      airAssetsNodes
    )
  }, [ airAssetsNodes, airportsActive ]);

  useEffect(() => {
    if (selectedNode) {
      setShowSidebarSecondary(true);
      setSidebarSecondaryActiveItem('Selected Node');
    }
  }, [selectedNode]);

  const initialCodes = useMutation({
    mutationKey: ['initialCodes'],
    mutationFn: async () => {
      await axios.post(config.apiGateway.initialCodes);
      toast.success('Initial IATA Codes imported successfully');
    },
    onError: (error: any) => {
      toast.error('Failed to import initial IATA Codes ' + error?.message);
    },
  });

  const importAirports = useMutation({
    mutationKey: ['importAirports'],
    mutationFn: async () => {
      await axios.post(config.apiGateway.airportCodes);
      toast.success('Airport data imported successfully');
    },
    onError: (error: any) => {
      toast.error('Failed to import airport data ' + error?.message);
    },
  });

  const flyToGeolocation = (input: any) => {
    const item = aors?.data?.find((aor) => aor.id === input);
    if (item?.geolocation?.geojson) {
      const box = turf.bbox(
        item?.geolocation?.geojson?.data?.features[0]?.geometry
      );
      map.current.fitBounds(box, { padding: 50, maxZoom: 15 });
    }
  };

  const addToGeolocation = (input: UUID, origin: UUID) => {
    const item = aors?.data?.find((aor) => aor.id === input);
    const item2 = aors?.data?.find((aor) => aor.id === origin);
    if (item?.geolocation?.geojson && item2?.geolocation?.geojson) {
      const bbox1 = turf.bbox(
        item?.geolocation?.geojson?.data?.features[0]?.geometry
      );
      const bbox2 = turf.bbox(
        item2?.geolocation?.geojson?.data?.features[0]?.geometry
      );
      const combinedBbox = [
        Math.min(bbox1[0], bbox2[0]), // minX
        Math.min(bbox1[1], bbox2[1]), // minY
        Math.max(bbox1[2], bbox2[2]), // maxX
        Math.max(bbox1[3], bbox2[3]), // maxY
      ];

      map.current.fitBounds(combinedBbox, { padding: 50, maxZoom: 15 });
    }
  };
  const sidebarSecondaryHandler = (show: any, key: string) => {
    setShowSidebarSecondary(show);
    setSidebarSecondaryActiveItem(key);
  };

  const handleClickNode = (node: any) => {
    setShowSidebarSecondary(true);
    setSidebarSecondaryActiveItem('Selected Node');
    setSelectedNode(node);
  };

  return (
    <div className="sidebar-content-wide">
      <div className="sidebar-title">
        {showSidebarSecondary && (
          <StylishNewButton
            customButton
            className={'button--icon'}
            onClick={() => {
              setShowSidebarSecondary(false);
              setSidebarSecondaryActiveItem(null);
            }}
          >
            <img src={IconArrowLeft} alt="" />
          </StylishNewButton>
        )}
        {sidebarSecondaryActiveItem === 'Airports Data' ? (
          <h4 className="m-0">Airports Data</h4>
        ) : sidebarSecondaryActiveItem === 'Seaports Data' ? (
          <h4 className="m-0">Seaports Data</h4>
        ) : (
          <h4 className="m-0">NEO Analysis</h4>
        )}
        <StylishNewButton
          customButton
          className={'button--icon'}
          onClick={onClose}
        >
          <img src={IconClose} alt="" />
        </StylishNewButton>
      </div>
      <div className="sidebar-inner">
        {!showSidebarSecondary && (
          <>
            View Existing Analysis
            <StylishNewSelect
              options={analysisOptions}
              value={existingAnalysis}
              onChange={(e: {label: UUID, value: any}) => {
                setExistingAnalysis(e);
              }}
              isClearable={true}
              placeholder="Select Analysis"
            />
            <br />
            <StylishNewButton
              onClick={() => setShowSidebarSecondary((x: any) => !x)}
              className="button--sml button--secondary"
            >
              Create New Analysis
            </StylishNewButton>
            <hr />
            {existingAnalysis && <AnalysisView result={existingAnalysis.value} />}
          </>
        )}
        {showSidebarSecondary && (
          <>
            <div>
              <div className="m-3">
                <PratusStepper steps={steps} activeStep={activeStep} />
              </div>
            </div>
            {activeStep === 1 && (
              <OriginPage
                arr={arr}
                origin={origin}
                setOrigin={setOrigin}
                setActiveStep={setActiveStep}
                setSelOriAirports={setSelOriAirports}
                flyTo={flyToGeolocation}
                layerState={layerState}
              />
            )}
            {activeStep === 2 && (
              <DestinationPage
                arr={arr}
                destination={destination}
                setDestination={setDestination}
                setActiveStep={setActiveStep}
                setSelDestAirports={setSelDestAirports}
                origin={origin}
                flyTo={flyToGeolocation}
                flyTo2={addToGeolocation}
              />
            )}
            {activeStep === 3 && (
              <OptionsPage
                airportsActive={airportsActive}
                setAirportsActive={setAirportsActive}
                seaportsActive={seaportsActive}
                setSeaportsActive={setSeaportsActive}
                setActiveStep={setActiveStep}
                selOriAirports={selOriAirports}
                setSelOriAirports={setSelOriAirports}
                selDestAirports={selDestAirports}
                setSelDestAirports={setSelDestAirports}
                selOriSeaports={selOriSeaports}
                setSelOriSeaports={setSelOriSeaports}
                selDestSeaports={selDestSeaports}
                setSelDestSeaports={setSelDestSeaports}
                origin={origin}
                destination={destination}
              />
            )}
            {activeStep === 4 && (
              <FinalPage
                setActiveStep={setActiveStep}
                origin={origin}
                destination={destination}
                selOriAirports={selOriAirports}
                selDestAirports={selDestAirports}
                selOriSeaports={selOriSeaports}
                selDestSeaports={selDestSeaports}
                setResult={setResult}
              />
            )}
            {activeStep === 5 && (
              <ResultsPage
                setActiveStep={setActiveStep}
                origin={origin}
                destination={destination}
                result={result}
              />
            )}
          </>
        )}
        {/* TODO: the commented code is better suited to the Geolocations UI. Clicking on an airport in the NEO should show the popup, but should not
                  result in a sidebar change.
        */}
        {/* {!showSidebarSecondary ? (
          <>
            <br />
            <StylishNewButton
              className="button--secondary ms-2"
              onClick={() => initialCodes.mutate()}
            >
              Import initial IATA Codes
            </StylishNewButton>
            <StylishNewButton
              className="button--secondary ms-2"
              onClick={() => importAirports.mutate()}
            >
              Update airport data
            </StylishNewButton>
          </>
        ) : (
          <>
            {sidebarSecondaryActiveItem === 'Airports Data' ? (
              <>
                <div className="d-flex">
                  <StylishNewInput
                    placeholder="Search Airport..."
                    value={airportSearchInput}
                    onChange={(e) => setAirportSearchInput(e.target.value)}
                    className={'mb-3'}
                  />
                </div>
                <div className="scroll scroll-panel">
                  {airportNodes?.length > 0 ? (
                    airportNodes.map((airport: any, index: number) => {
                      return (
                        <div
                          className="d-flex-column task-map-card cursor-pointer"
                          key={'airportlist-card' + index}
                          onClick={() => handleClickNode(airport)}
                        >
                          <h5 className="mb-1 mt-1">{airport?.name}</h5>
                          <div className="d-flex justify-content-between">
                            <div className="">Type: {airport?.node_type}</div>
                            <div className="">Status: {airport?.status}</div>
                            <div className="">
                              IATA: {airport?.iata_code}, ICAO:{' '}
                              {airport?.icao_code}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p className="m-0">No Airports Found</p>
                  )}
                </div>
                <div className="d-flex justify-content-center justify-content-md-end mt-3">
                  <ReactPaginate
                    className="pagination"
                    breakLabel="..."
                    nextLabel="Next"
                    onPageChange={({ selected }) =>
                      setCurrentAirportPage(selected + 1)
                    }
                    pageRangeDisplayed={3}
                    pageCount={Math.ceil(airportNodesCount?.count / pageLimit)}
                    previousLabel="Prev"
                    forcePage={currentAirportPage - 1}
                    renderOnZeroPageCount={null}
                  />
                </div>
              </>
            ) : null}

            {sidebarSecondaryActiveItem === 'Seaports Data' ? (
              <>
                <div className="d-flex">
                  <StylishNewInput
                    placeholder="Search Seaport..."
                    value={seaportSearchInput}
                    onChange={(e) => setSeaportSearchInput(e.target.value)}
                    className={'mb-3'}
                  />
                </div>
                <div className="scroll scroll-panel">
                  {seaportNodes?.length > 0 ? (
                    seaportNodes.map((seaport: any, index: number) => {
                      return (
                        <div
                          className="d-flex-column task-map-card cursor-pointer"
                          key={'airportlist-card' + index}
                          onClick={() => handleClickNode(seaport)}
                        >
                          <h5 className="mb-1 mt-1">{seaport?.name}</h5>
                          <div className="d-flex justify-content-between">
                            <div className="">Type: {seaport?.node_type}</div>
                            <div className="">Status: {seaport?.status}</div>
                            <div className="">
                              IATA: {seaport?.iata_code}, ICAO:{' '}
                              {seaport?.icao_code}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p className="m-0">No Seaports Found</p>
                  )}
                </div>
                <div className="d-flex justify-content-center justify-content-md-end mt-3">
                  <ReactPaginate
                    className="pagination"
                    breakLabel="..."
                    nextLabel="Next"
                    onPageChange={({ selected }) =>
                      setCurrentSeaportPage(selected + 1)
                    }
                    pageRangeDisplayed={3}
                    pageCount={Math.ceil(seaportNodesCount?.count / pageLimit)}
                    previousLabel="Prev"
                    forcePage={currentSeaportPage - 1}
                    renderOnZeroPageCount={null}
                  />
                </div>
              </>
            ) : null}

            {sidebarSecondaryActiveItem === 'Selected Node' ? (
              <div className="tab-wrapper map-tab-container">
                <div
                  className="anchor anchor--white cursor-pointer d-flex align-items-center bg-gray-900 p-3 rounded mb-3"
                  onClick={() => {flyToGeolocation(selectedNode)}}
                >
                  <MdLocationOn className="img-h-20 me-3" />
                  {!!selectedNode?.geojson ? (
                    <>
                      Lon: {selectedNode?.lng}, Lat: {selectedNode?.lat}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <TransportationDetails
                  selectedNode={selectedNode}
                />
              </div>
            ) : null}
          </>
        )} */}
      </div>
    </div>
  );
};

export default MapNEO;
