// hooks/useExternalMaps.js

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from 'constants/apiConfig';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
import { useSelector } from 'react-redux';
import { selectGroupGuid } from 'slices/commonSelectors';

export const keys = {
  externalMaps: 'externalMaps',
};

// Fetch external maps
export const useExternalMaps = () => {
  const group_guid = useSelector(selectGroupGuid);

  return useQuery({
    queryKey: [keys.externalMaps, group_guid],
    queryFn: async () => {
      const response = await axios.get(
        `${config.apiGateway.externalMaps}/`,
        {
          params: { group_guid },
        }
      );
      return response.data;
    },
    onError: (error) => {
      console.error('Error fetching external maps:', error);
      toast.error('Failed to fetch external maps.', {
        ...toastConfig,
        autoClose: false,
      });
    },
  });
};

// Create an external map
export const useCreateExternalMap = () => {
  const queryClient = useQueryClient();
  const group_guid = useSelector(selectGroupGuid);

  return useMutation({
    mutationFn: async (data) => {
      const response = await axios.post(
        `${config.apiGateway.externalMaps}/`,
        {
          ...data,
          group_guid,
        }
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([keys.externalMaps]);
    },
    onError: (error) => {
      console.error('Error creating external map:', error);
      toast.error('Failed to create external map.', toastConfig);
    },
  });
};

// Update an external map
export const useUpdateExternalMap = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => {
      const { id, ...rest } = data;
      const response = await axios.put(
        `${config.apiGateway.externalMaps}/${id}`,
        rest
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([keys.externalMaps]);
    },
    onError: (error) => {
      console.error('Error updating external map:', error);
      toast.error('Failed to update external map.', toastConfig);
    },
  });
};

// Archive an external map
export const useDeleteExternalMap = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id) => {
      const response = await axios.delete(
        `${config.apiGateway.externalMaps}/${id}`
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([keys.externalMaps]);
    },
    onError: (error) => {
      console.error('Error archiving external map:', error);
      toast.error('Failed to archive external map.', toastConfig);
    },
  });
};