export function drawToggle(toggle) {
  const controls = document.getElementsByClassName(
    'mapbox-gl-draw_ctrl-draw-btn'
  );

  if (toggle) {
    controls[0].style.display = 'block';
    controls[1].style.display = 'block';
    controls[2].style.display = 'block';
  } else {
    controls[0].style.display = 'none';
    controls[1].style.display = 'none';
    controls[2].style.display = 'none';
  }
  return;
}
