import { useSelector } from 'react-redux';

export function useSelfHostedKnowledgeBase() {

  const featureFlags = useSelector((state) => {
    return state.app.featureFlags;
  });


  return !!featureFlags.includes("SELF_HOST_KNOWLEDGE_BASE") && "/knowledge_base"
}

export const EventCreateOrEdit = {
  title: 'Plan Event',
  content: `
            <p>Events allow users to develop scenarios within exercises. Events have a unique Master Scenario Events List of injects that drive the event forward to support the participant’s training objectives.</p>
            <p>Give the event a unique name and color to easily distinguish specific events, select the Overall Method from the drop-down, add the Start Date and End Date, and type in additional details in the Scenario and Remarks boxes.</p>
            `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpActivity = {
  title: 'Activity Home',
  content: `
        <p>View MSEL inject activity in chronological order from members of your workspace. Click the name of the MSEL inject to view more details.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

//miscellaneous use

export const MiscellaneousHelpLink = {
  link: 'https://pratus.disastertech.com/knowledge',
};

// Settings Module

export const HelpUserSettings = {
  title: 'User Settings',
  content: `
        <p>Change your Name by filling in where it says Name and then click Save. </p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};
export const HelpMembers = {
  title: 'User Roles',
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpWorkspaceMembers = {
  title: 'Workspace Members',
  content: `
        <p>Anyone can invite a new or current user to the workspace by typing in their email address. Admins may select a role for the new user by clicking the Select Role for User drop-down.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpTimeZone = {
  title: 'Time Zone',
  content: `
        <p>Set a unified time zone for your workspace by selecting the Set Time Zone button. When a time zone is set,
        all users within a workspace view a unified time zone. If a time zone is not chosen, or is reset to local time, each user will see the time zone set from their device, which may be different across users.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpApplicationSettings = {
  title: 'Application Settings',
  content: `
        <p>Customize which applications appear the home page and application launcher by setting each as active or inactive. Making an application inactive will not affect any data within that application.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpPlanJmets = {
  title: 'Plan JMETs',
  content: `
        <p>JMETS are included in the app and are used in the Training Objectives and Accredited Tasks section of Plan. Create a JMET in the workspace by clicking the button at the top of the screen. Then fill in the details and select Save. Edit or Delete a JMET by select the more options icon on the right-hand side of the JMET. Select the Level of War, Task Number, or Task Title to change the viewing order of the JMETs.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpPlanOrganizations = {
  title: 'Plan Organizations',
  content: `
        <p>Organizations represent the sponsors/owners of an exercise. Users can create, edit, or delete an Organization in Settings. Organizations are selected in the Exercise Details section of EMW. Create an Organization by clicking the button "Add Organization". There are two levels you can fill, "Name" and "Group" in the case that there are multiple sub-organizations. Click Save. Edit or Delete an Organization by selecting the three-dot icon on the right-hand side of the desired entry.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpPlanPlatforms = {
  title: 'Plan Platforms',
  content: `
        <p>Platforms are included in the app and are used when choosing a Participant Platform in Plan. Users can create, edit, or delete them on the Settings page. Fill in the Title and Type, then click Save. Edit or Delete Platforms by selecting the more options icon on the right-hand side of the Platform.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpParticipantTypes = {
  title: 'Participant Types',
  content: `
        <p>The Essential Support Function or type of organization of the participant. The selectable values are customizable to meet the exercise needs, and used as a filter for the Feedback and Reports sections of the application. Add, edit, or delete an ESF/Organization Type by selecting the Add ESF/Organization Type button; click on the three dots under the Action column to edit or delete an entry.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpServiceDepartment = {
  title: 'Service/Department',
  content: `
        <p>Describes a structured level of the participating organization. Add, edit, or delete as required to meet the specific criteria of your exercise. Add an entry by selecting the Add City/Town/Service/Department button; click on the three dots under the Action column to edit or delete an entry.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpServiceDepartmentTypes = {
  title: 'Service/Department Types',
  content: `
        <p>Describes a type of level of the participating organization. Add, edit, or delete as required to meet the specific criteria of your exercise. Add an entry by selecting the Add Jurisdiction/Service/Department button; click on the three dots under the Action column to edit or delete an entry.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpCommandAgency = {
  title: 'Command/Agency',
  content: `
        <p>Describes a structured level of your organization and is customizable to meet the specific criteria of your exercise. Add by selecting the State/County/Chapter/Command/Agency button; click on the three dots under the Action column to edit or delete an entry.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpPlanJointStaffTrainingPriorities = {
  title: 'Strategic Priorities',
  content: `
        <p>Users can define desired Strategic Priorities to align participant objectives. Create a Strategic Priority by selecting the button "Add Strategic Priority". Add the Title and Description, then click Save. Edit or Delete a Strategic Priority by selecting the three-dot icon on the right-hand side of each.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpInjectMethod = {
  title: 'Inject Method',
  content: `
        <p>Inject Methods are used when creating a MSEL Inject. Inject Methods are preset but can be modified to include additional methods by selecting Create Method. Easily identify the Inject Method by adding a unique name, icon, and color. Delete or Edit an Inject Method by selecting the more option icon.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpInjectOwner = {
  title: 'Inject Owner',
  content: `
        <p>Inject Owners are used in the first step when creating a MSEL Inject. Inject Owners are preset but can be modified to include additional owners by selecting Create Owner. Delete or Edit an Inject Owner by selecting the more options icon.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpExcerciseType = {
  title: 'Excercise Type',
  content: `
        <p>Defines the type of exercise being conducted. Add a new entry by selecting on “Add Exercise Type”, input a name, and click “Save Changes”. Click on the three dots under Action to edit the name or delete the entry.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpFromTo = {
  title: 'From To',
  content: `
        <p>Defines the notional entity the MSEL Inject is being sent from and the entity the inject trigger is being sent to. Add a new entry by selecting “Add From To”, input a name, and click “Save Changes”. Click on the three dots under Action to edit the name or delete the entry.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpTechnicalDiscipline = {
  title: 'Technical Discipline',
  content: `
        <p>Click the "more help" button for further information.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpMissionTypes = {
  title: 'Mission Type',
  content: `
        <p>Click the "more help" button for further information.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpMissionProfile = {
  title: 'Mission Profile',
  content: `
        <p>Click the "more help" button for further information.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};
export const HelpMissionLocation = {
  title: 'Mission Location',
  content: `
        <p>Click the "more help" button for further information.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpPosition = {
  title: 'Position',
  content: `
        <p>Defines the role a member is fulfilling within each participating organization. Select “Add Position”, input a name, and click “Save Changes”. Click on the three dots under Action to edit the name or delete the entry.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};
export const HelpMetric = {
  title: 'Metric',
  content: `
        <p>Add supporting data to help provide depth to the accomplishment of a participant objective, Select “Add Metric”, input a name, and click “Save Changes”. Click on the three dots under Action to edit the name or delete the entry.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpPrimarySource = {
  title: 'Primary Source',
  content: `
        <p>Define the primary source of funding for the exercise budget. Select “Add Primary Source”, input a name, and click “Save Changes”. Click on the three dots under Action to edit the name or delete the entry.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};
export const HelpSubSource = {
  title: 'Sub Source',
  content: `
        <p>Define a sub-source of funding for the exercise budget (as necessary). Select “Add Sub Source”, input a name, and click “Save Changes”. Click on the three dots under Action to edit the name or delete the entry.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpSupprt = {
  title: 'Support',
  content: '<p>Click the "more help" button for further information.</p>',
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpPlanStatusLabels = {
  title: 'Plan Status Labels',
  content: `
        <p>Define the statuses of MSEL injects with Status Labels . Edit the title or color by click on the three dots under the Action column of each label.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpPlanStatusBoards = {
  title: 'Plan Status Boards',
  content: `
        <p>Define the statuses of MSEL injects with Status Labels . Edit the title and/or select the desired labels for each board by clicking on the three dots under the Action column to edit or delete an entry.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpPlanAssessmentReasons = {
  title: 'Assessment Reasons',
  content: `
        <p>Reasons explain the ineffectiveness of completing objectives in Assessment and Feedback forms. Create a new entry by selecting the “Add Assessment Reason” button. Input the Title and click Save Changes.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpTrainingPriority = {
  title: 'Objective Priority',
  content: `
        <p>Objective Priorities are used when creating or editing an objective. Priorities can be added by selecting Create Objective Priority. Click on the three dot menu under “Action” to edit the name or color, or to delete it.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

// Plan Module

export const HelpPreparePlan = {
  title: 'EMW Plan',
  content: `
        <p>This dashboard presents the Exercise Details, MSEL List, and Calendar showing the dates and times of the scenario-based Events . These displays allow users to have quick visualization and access to critical sections for planning an exercise.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpPrepareDashboard = {
  title: 'EMW Dashboard',
  content: `
        <p>The EMW dashboard presents the Exercise Details, MSEL Inject graph, and Calendar showing the dates and times of MSEL Injects. These displays allow users to quickly view critical and access sections for planning an exercise.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpExerciseMain = {
  title: 'Exercise Details',
  content: `
        <p>Create the training environment for your training exercise by determining the focus of the 
        exercise and training objectives. In this section, add the exercise details, plan meetings,
        funding sources, objectives, and tasks and priorities. Select Edit Guidance to begin creating exercise details.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpExerciseDetails = {
  title: 'Exercise Details',
  content: `
        <p>Under Commander's Guidance, state the strategic objective.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpPlanningMeetings = {
  title: 'Planning Meetings',
  content: `
        <p>Schedule your Planning Meetings to support the development of your exercise.</p>
        <p>If following the Joint Exercise Life Cycle, you may enter those events here or name your own planning meetings.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpFundingSources = {
  title: 'Funding Sources',
  content: `
        <p>Select Funding Sources, Sub-sources, and Amounts here. The Primary Source is the main source of funding received for the exercise.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpExerciseObjectives = {
  title: 'Exercise Objectives',
  content: `
        <p>Create SMART Exercise Objectives that are specific, measurable, achievable, relevant and time-bound. Only the Title has to be filled out to move to the next step. Planners may return to the previous step and edit as needed.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpTasksPriorities = {
  title: 'Tasks & Priorities',
  content: `
        <p>Select Add Acredited Task. Choose Accredited Task and hit Save or Cancel.</p>
        <p>Choose the Add Command Priority button. Fill in the Title and Description. Once completed, select Save or Cancel.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpExerciseDetailsReview = {
  title: 'Review',
  content: `
        <p>Review your exercise details and complete any remaining sections.</p>
        <p>Sections in red have an error and need review.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpParticipants = {
  title: 'Participants',
  content: `
        <p>Add participants to your training scenario and exercises. These details are used for future objectives and to create a participant training group. The Participants section contains the list of participants, participant platforms, participant planners, contacts, training objectives, and funding sources. Select Create Participant, add the name and press Save. Then, follow the numbered stepper.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpParticipantDetails = {
  title: 'Participant Details',
  content: `
        <p>Input details regarding the participant. For each participant, enter the Name, Service or Department, Command or Agency, Participant Type, Home Station, Select an Icon, and Total Training Audience.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpParticipantPlatforms = {
  title: 'Participant Platforms',
  content: `
        <p>Add all platforms for the participant. Platforms can be modified in the Plan section of Settings. Select the platform from the fixed drop-down and add the Bed-down location. Then click Save to continue or Cancel to return to the previous page.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpParticipantPlanners = {
  title: 'Participant Planners',
  content: `
        <p>Select the email of the user that is planning for this participant. Selecting the email adds the user to the participant platform. To designate a primary point of contact, select the Primary Contact checkbox for that user. Only users who are added to this exercise can be selected. If a user does not appear on the list, ensure they have an account and have been given access to the exercise workspace.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpParticipantFundingSources = {
  title: 'Funding Sources',
  content: `
        <p>Add the sources and funding amount for the participant. This should align with the funding sources for this exercise.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpParticipantReview = {
  title: 'Review',
  content: `
        <p>Review the participant details for accuracy. Return to any section to edit by selecting the numbered step that correlates with the area you need to modify.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpParticipantDetailsandTrainingObjectives = {
  title: 'Participant Details and Training Objectives',
  content: `
        <p>This page shows details regarding the participant and their associated training objectives. On the Details tab, select the more options icon to edit or delete the participant. Click the Training Objectives tab to view and create training objectives for the selected participant.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpTrainingObjectives = {
  title: 'Training Objectives',
  content: `
        <p>Create Training Objectives for participants in the training exercise. Begin adding Training Objectives by selecting Create Training Objective. Once completed,
        Training Objectives appear in this list. Select the arrow associated with the Training Objective to view additional details.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpTrainingObjectiveDetails = {
  title: 'Training Objective Details',
  content: `
        <p>Select the participant platform for the Training Objective and fill in the details as desired. Most details are predetermined by the participant previously chosen.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpTrainingObjectiveMeasures = {
  title: 'Training Objective Measures',
  content: `
        <p>Describe the task, conditions, and standards for the training objective. These details are used when evaluating and providing feedback. To see examples, click the More Help button.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpTrainingObjectiveAssociations = {
  title: 'Training Objective Associations',
  content: `
        <p>Add further data that the Training Objective supports. View Exercise Objective details at anytime by clicking on View Exercise Details. The dropdowns of the Training Objective Associations have predetermined options. Add all necessary details on the Training Objective Measures page.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpTrainingObjectiveReview = {
  title: 'Training Objective Review',
  content: `
        <p>Review the Training Objective information. To return or edit any section, click the edit button next to that section, or the numbered step along the top of the page.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpEvents = {
  title: 'Events',
  content: `
        <p>Events, which have a unique Master Scenario Events List (MSEL) of injects, allow users to develop scenarios within exercises. MSELs drive the event forward to support the training objectives that are associated with participants. Begin creating an event by selecting Create Event and completing the missing information.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpPlanEvent = {
  title: 'Plan Event',
  content: `
        <p>Events allow users to develop scenarios within exercises. Events have a unique Master Scenario Events List of injects that drive the event forward to support the participant’s training objectives.</p>
        <p>Give the event a unique name and color to easily distinguish specific events, select the Overall Method from the drop-down, add the Start Date and End Date, and type in additional details in the Scenario and Remarks boxes.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpEventDetails = {
  title: 'Event Details',
  content: `
        <p>Manage the event and associated MSEL Injects. Select Add MSEL Inject or Edit Event to add important details to the event.
         Access the tools to Edit Inject, Remove From Event, or Delete Inject by clicking the more options icon, which is located on the right-hand side of the MSEL Inject.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpPlanInjects = {
  title: 'Plan Injects',
  content: `
        <p>Monitor the status of MSEL Injects. Status Labels, chosen in the Status Boards section of Settings, simplify the process of tracking MSEL Statuses. Select Create MSEL Inject to add a new MSEL Inject or click the drop-down to Manage Boards. Filter MSEL Injects from the Events drop-down.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpPlanInjectDetail = {
  title: 'Plan Inject Detail',
  content: `
        <p>To create a MSEL Inject, the user must enter a title; however, all other information can be completed as needed. The Plan Label designates the planning status of each inject.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpPlanInjectDescriptionMeasures = {
  title: 'Plan Inject Description & Measures',
  content: `
        <p>The MSEL Inject's Description is the trigger, which is information that drives an Anticipated Response from the training audience. The trigger can be sent via email, phone call, white card, media, etc. To support the inject, users can upload a file, or copy and paste the permalink of an associated EMW Media post.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpMSELInjectLocation = {
  title: 'MSEL Inject Location',
  content: `
        <p>Set the location for your MSEL Inject anywhere on the map by clicking the location icon. Below the name of the location, the latitude and longitude will be listed. View the map as a traditional Map or Satellite. Select OK to save the location of the MSEL Inject or Cancel to return to the previous page.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpObjectivesMethods = {
  title: 'Objectives & Methods',
  content: `
        <p>Assign a training objective to the MSEL inject from the Available Training Objectives table. Then select the Training Method for each of the assigned training objectives.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpMSELInjectReview = {
  title: 'MSEL Inject Review',
  content: `
        <p>Review the content of any inject. Update a section by clicking the stepper that you wish to edit. Upon creation, the inject will be assigned a unique identification number.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpViewInject = {
  title: 'View Inject',
  content: `
        <p>View Inject Details, Status, and Activity Log. The Activity Log shows the workspace user and the time that they edited that inject. Click the more options icon to edit the MSEL Inject.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpMselCalendar = {
  title: 'MSEL Calendar',
  content: `
        <p>Monitor the status of the MSEL Injects. Status Labels, chosen in the Status Boards section of Settings, simplify the process of tracking MSEL Statuses. Select Create MSEL Inject to add a new MSEL Inject.</p>
        `,
  link:
    'https://pratus.disastertech.com/knowledge',
};

export const HelpTrainDashboard = {
  title: 'Manage Dashboard',
  content: `
        <p>The Manage dashboard presents the MSEL Inject Status, Calendar showing dates and times of MSEL Injects, and Participant Objectives that were Requested vs Planned, Planned vs Assessed, and completion status. These displays allow users to quickly view and access critical sections for managing an exercise.</p>
        `,
  link:
    'https://pratus.disastertech.com/knowledge',
};

export const HelpMSELTimeline = {
  title: 'MSEL Timeline',
  content: `
        <p>View the progress of a MSEL Inject within a specific Scenario. Begin by selecting the All Events drop-down. A complete MSEL is green, which means that the MSEL was injected and completed. A yellow status means that the MSEL is incomplete. Update a MSEL inject by first selecting the name of the MSEL. Once the window populates, update the status, edit the inject, or view and edit Training Objectives here.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpAssessOverview = {
  title: 'Overview',
  content: `
        <p>This page displays a list of all Participant Objectives that have been aligned to a MSEL Inject. Once the MSEL Inject is marked "Complete or Incomplete", users will need to create and submit the respective forms - Assessment (by Observer/Evaluators) and/or Feedback (by Participants). Feedbacks are "Self-Assessments". Press the arrow associated with the Participant to view details and the related Assessment and Feedback Lists. Select Create Assessment or Create Feedback (Self-Assessment) for a participant objective. Each participant objective displays the number of required runs, the number of MSEL injects aligned to, and the number of assessment and feedback forms submitted. If submitting both, Assessment and Feedback counts should be the same, and should match the number for the "Inject Count".</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpAssessList = {
  title: 'Assess List',
  content: `
        <p>This page lists assessments of objectives and shows the status of each. Select Create Assessment to begin filling out the form. Users can also edit or delete forms by selecting the three-dot icon next to each entry.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const HelpFeedback = {
  title: 'Feedback',
  content: `
        <p>This page lists feedbacks (self-assessments) of objectives and shows the status of each. Select Create Feedback to begin filling out the form. Users can also edit or delete forms by selecting the three-dot icon next to each entry.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const CustomReports = {
  title: 'Custom Reports',
  content: `
        <p>Custom reports provide comprehensive details regarding your training exercise. Select the Create Report button, fill in the missing details, and click the Save button to generate your custom report. Double click the row or click the more options icon, which is located on the right-hand side of the page, to view the report.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const PlanAnalytics = {
  title: 'Analytics',
  content: `
        <p>Select the Source type of the data you would like to view. Every plot has a navigation menu that is only visible when the mouse is hovering over that plot. Some plots have drop-downs associated with them. Select the drop-down to view custom plots. Plots are unique to the workspace and report on whether or not a training objective was completed, the effectiveness of the training, funding allocation, etc.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const PlanningMeetings = {
  title: 'Planning Meetings',
  content: `
        <p>Schedule your Planning Meetings to support the development of your exercise.
        If following the Joint Exercise Life Cycle, you may enter those events here or name your own planning meetings.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const FundingSources = {
  title: 'Funding Sources',
  content: `
        <p>Select Funding Sources, Sub-sources, and Amounts here. The Primary Source is the main source of funding received for the exercise.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const ExerciseObjectives = {
  title: 'Exercise Objectives',
  content: `
        <p>Create SMART Exercise Objectives that are specific, measurable, achievable, relevant and time-bound. Only the Title has to be filled out to move to the next step. Planners may return to the previous step and edit as needed.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const TasksPriorities = {
  title: 'Tasks & Priorities',
  content: `
        <p>Select Add Acredited Task. Choose Accredited Task and hit Save or Cancel.</p>
        <p>Choose the Add Command Priority button. Fill in the Title and Description. Once completed, select Save or Cancel.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const Review = {
  title: 'Review',
  content: `
        <p>Review your exercise details and complete any remaining sections.</p>
        <p>Sections in red have an error and need review.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};
//Developer Module

export const HelpPrepareDeveloper = {
  title: ' Developer',
  content: `
        <p>
        The DEVELOPER application provides the super-user the ability to create/manage workspaces and invite/manage all users         
         </p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

//Media Module

export const PrepareMedia = {
  title: 'EMW Media',
  content: `
        <p>EMW Media is a platform in which procedures and tools for collecting and analyzing publicly available information (PAI) can be practiced and refined. Online exercise activities remain strictly within the exercise environment and personally identifiable information (PII) is replaced with artificial information, simulating a realistic environment. Enhance a training scenario by creating realistic posts, profiles, personas, networks, and sites. Begin constructing a simulated online media environment by choosing the Personas tab.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const PostsHelp = {
  title: 'Posts',
  content: `
        <p>Create customized posts using text, images, time, date, and location. These customization features help the user create more realistic posts (injects) for the training audience. To begin, select the Create Post button.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const ProfilesHelp = {
  title: 'Profiles',
  content: `
        <p>The Profiles tab enables the user to build, design, and edit profiles. Select which service the profile links to and customize profiles by creating an original name, username, profile description, avatar, and banner.</p>
        <p>Use the search bar to find a specific profile to modify. Organize the order of the profiles by selecting the drop-down at the top of the Profiles page.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const ViewProfileHelp = {
  title: 'View Profile',
  content: `
        <p>View All, Published, Scheduled, or Drafted posts on the selected profile's page.</p>  
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const EditProfilesHelp = {
  title: 'Edit Profiles',
  content: `
        <p>Modify the Name, Description, Data, Avatar, and Banner for this Profile. Users are unable to alter the Site and Username.</p>  
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

//Network Module

export const NetworkHelp = {
  title: 'Networks',
  content: `
        <p>Adding a network allows the user to create a network of linked personas. Each network serves as a hub for the personas in the training scenario. To begin, select Create Network.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const EditNetworkHelp = {
  title: 'Edit Network',
  content: `
        <p>Type in the Name of the Network, create a detailed Description, and add an Avatar to the Network. Click Save to save the network details. Add personas to a Network by selecting the Add Persona button. Easily create a quick persona or choose from your preexisting list of personas.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const NetworkView = {
  title: 'Network view',
  content: `
        <p>Add personas to a network by selecting the Add Persona button. From this section, easily create a quick persona or choose from your preexisting list of personas. Select the more options icon beside a persona to edit or remove them. Click the more options icon next to the name of the network to edit the network (i.e. edit the name, description, and avatar) or delete the network.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const NoiseHelp = {
  title: 'Noise',
  content: `
        <p>The Noise tab supports a scraping feature to pull publicly available information (PAI) content from the Internet for injection as haystack/noise.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const PersonasHelp = {
  title: 'Personas',
  content: `
        <p>Create and customize personas for a network. By using this feature, the user can link personas together to establish a criminal organization or terrorist group. Select Create Persona to begin.</p>
        <p>Use Default Attributes by filling in attributes to create more complex personas. Use Blank Persona by filling in a name and choosing media for the persona. Add any attribute by choosing Add Attribute. Quick Create Persona by filling in the name of the persona.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const ViewPersonaHelp = {
  title: 'View Persona',
  content: `
        <p>View, edit, and remove all profiles from a persona at any time.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const Site = {
  title: 'Sites',
  content: `
        <p>There are 5 preset Sites. Establish services (e.g. social media platforms, blogs, news, or video sites) by selecting Create Site. Enter the Name, select Site Type, Icon, and Icon Color to make your site distinguishable.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const MediaSiteView = {
  title: 'Media Site View',
  content: `
        <p>This page shows posts from a site. Clicking a post will expand the content into a full page. Selecting the back button will return you to the previous screen.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const Calendar = {
  title: 'Calendar',
  content: `
        <p>View created posts from the past, present, or future by selecting the desired month. Click on any posts you would like to view on the calendar. The user may see their posts in a day, week, month, and list view.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const MediaAnalytics = {
  title: 'Analytics',
  content: `
        <p>View the total views and total unique visitors for each post.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const MediaNoise = {
  title: 'Noise',
  content: `
        <p>The Noise tab supports a scraping feature to pull publicly available information (PAI) content from the Internet for injection as haystack/noise.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

// Notebook

export const Notebook = {
  title: 'Notebook',
  content: `
        <p>Create collaborative notebooks to organize your workspace content. Give the notebook a title, color, and icon as unique identifiers. Select Create Notebook to start customizing a unique, collaborative Notebook. Stay organized by creating folders and uploading files to support the exercise.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

export const FoldersandFileUploads = {
  title: 'Folders and File Uploads',
  content: `
        <p>Select the "Create" button to create a folder. Use the Files tab to upload files in the main section or in a folder. Files include documents, presentations, spreadsheets, images, and videos.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

// Web

export const Search = {
  title: 'Search',
  content: `
        <p>Type in keywords to search for posts. Once a search is made and a new page populates, refine search results by selecting a category.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

// Observe

export const Home = {
  title: 'Home',
  content: `
        <p>View observation posts in chronological order from members of your workspace.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};

// Map

export const PREPAREMap = {
  title: 'Home',
  content: `
        <p>EMW Map is a unique platform used to create a realistic training experience by displaying data into a map view. Users can click Create Map and design the training environment, enhancing preparedness for training events and, ultimately, mission readiness. View addresses or significant landmarks in Globe to better understand complex environments world-wide. Under Media Posts users can track the locations of noise and injects from PREPARE Media.</p>
        `,
  link: 'https://pratus.disastertech.com/knowledge',
};
