import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import geolocationsEffect from '../mapEffects/geolocationsEffect';


export const useGeolocationLayers = (map, mapHasLoaded, apiPrefix, mapSettings, setLayerClicked) => {
  const [showAORsOnMap, setShowAORsOnMap] = useState(false);
  const [showResourceSitesOnMap, setShowResourceSitesOnMap] = useState(false);
  const [showResourceSetsOnMap, setShowResourceSetsOnMap] = useState(false);
  const [showWorkAssignmentsOnMap, setShowWorkAssignmentsOnMap] = useState(false);
  const [showPlanEventsOnMap, setShowPlanEventsOnMap] = useState(false);
  const [showAirportsOnMap, setShowAirportsOnMap] = useState(false);
  const [showSeaportsOnMap, setShowSeaportsOnMap] = useState(false);

  const reduxCurrentlySelectedGroup = useSelector((state) => state.app.currentlySelectedGroup);
  const reduxCurrentIncident = useSelector((state) => state.app.currentIncident);

  useEffect(() => {
    if (mapHasLoaded && reduxCurrentlySelectedGroup) {
      geolocationsEffect({
        map,
        mapHasLoaded,
        apiPrefix,
        reduxCurrentlySelectedGroup,
        reduxCurrentIncident,
        showAORsOnMap,
        showPlanEventsOnMap,
        showResourceSitesOnMap,
        showResourceSetsOnMap,
        showWorkAssignmentsOnMap,
        showAirportsOnMap,
        showSeaportsOnMap,
        setLayerClicked
      });
    }
  }, [
    mapHasLoaded,
    reduxCurrentlySelectedGroup?.group_guid,
    reduxCurrentIncident?.id,
    showAORsOnMap,
    showPlanEventsOnMap,
    showResourceSitesOnMap,
    showResourceSetsOnMap,
    showWorkAssignmentsOnMap,
    showAirportsOnMap,
    showSeaportsOnMap
  ]);

  return {
    showAORsOnMap,
    setShowAORsOnMap,
    showResourceSitesOnMap,
    setShowResourceSitesOnMap,
    showResourceSetsOnMap,
    setShowResourceSetsOnMap,
    showWorkAssignmentsOnMap,
    setShowWorkAssignmentsOnMap,
    showPlanEventsOnMap,
    setShowPlanEventsOnMap,
    showAirportsOnMap,
    setShowAirportsOnMap,
    showSeaportsOnMap,
    setShowSeaportsOnMap,
  };
};
