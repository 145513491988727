type getPhoneNumberCountProps = {
  disabledGuids: UUID[] | NonMember[];
  nonMembers: NonMember[];
  groupMembersToNotify: UUID[];
  reduxRostersForGroups: RosterItem[];
  selectedDistributionLists: UUID[];
  distributionLists: Team[];
};

function getNumberFromId(
  user_guid: UUID,
  array: UUID[],
  groupRoster: RosterItem[]
) {
  const found = groupRoster.find((person) => person.user_guid === user_guid);
  if (found?.profile_settings?.mobile_phone_number) {
    array.push(found?.profile_settings?.mobile_phone_number);
  }
  if (found?.profile_settings?.home_phone_number) {
    array.push(found?.profile_settings?.home_phone_number);
  }
}

export default function getNotificationNumbers(
  props: getPhoneNumberCountProps
) {
  const {
    disabledGuids,
    nonMembers,
    groupMembersToNotify,
    reduxRostersForGroups,
    selectedDistributionLists,
    distributionLists,
  } = props;

  const flatDisabledGuids = disabledGuids.map((guid) => {
    return typeof guid === 'string' ? guid : guid.id;
  });

  const nonMemberNumbers = nonMembers
    .filter(
      (ele) =>
        ['SMSRecipients', 'TTSHomeRecipients', 'TTSCellRecipients'].includes(
          ele.type
        ) && !flatDisabledGuids.includes(ele.id)
    )
    .map((ele) => ele.content);

  const memberNumbers = groupMembersToNotify
    .filter((member) => !flatDisabledGuids.includes(member))
    .reduce((acc: UUID[], curr) => {
      getNumberFromId(curr, acc, reduxRostersForGroups);
      return acc;
    }, []);

  const selectedListsInfo = selectedDistributionLists.map((listId) => {
    const item = distributionLists.find((list) => list.id === listId);
    if (!item) {
      return null;
    }
    return item;
  });

  const distributionMembers = selectedListsInfo.reduce((acc: UUID[], team) => {
    team?.members
      .filter((member) => !flatDisabledGuids.includes(member))
      .forEach((curr) => {
        getNumberFromId(curr, acc, reduxRostersForGroups);
      });
    team?.nonMembers
      .filter((member) => !flatDisabledGuids.includes(member.non_member_guid))
      .forEach((curr) => {
        acc.push(curr.content);
      });
    return acc;
  }, []);

  const finalNumbers = distributionMembers.concat(
    memberNumbers,
    nonMemberNumbers
  );

  const tempArr = finalNumbers
    .map((num) => num.replace(/[^0-9]/g, ''))
    .filter((ele) => ele);
  return new Set(tempArr).size;
}
