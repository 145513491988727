import getQueryParams from 'utils/getQueryParams';

import { AnomalySetup } from 'components/AI2FP/constants/Anomalies';

import {
  FETCH_ADLS_REQUEST,
  FETCH_ADLS_SUCCESS,
  FETCH_ADLS_ERROR,
  UPSERT_ADL_REQUEST,
  UPSERT_ADL_SUCCESS,
  UPSERT_ADL_ERROR,
  DELETE_ADL_REQUEST,
  DELETE_ADL_SUCCESS,
  DELETE_ADL_ERROR,
  ASSIGN_ADL_REQUEST,
  ASSIGN_ADL_SUCCESS,
  ASSIGN_ADL_ERROR,
  UNASSIGN_ADL_REQUEST,
  UNASSIGN_ADL_SUCCESS,
  UNASSIGN_ADL_ERROR,
  FETCH_HVAS_REQUEST,
  FETCH_HVAS_SUCCESS,
  FETCH_HVAS_ERROR,
  FETCH_HVA_DATA_REQUEST,
  FETCH_HVA_DATA_SUCCESS,
  FETCH_HVA_DATA_ERROR,
  UPSERT_HVA_REQUEST,
  UPSERT_HVA_SUCCESS,
  UPSERT_HVA_ERROR,
  DELETE_HVA_REQUEST,
  DELETE_HVA_SUCCESS,
  DELETE_HVA_ERROR,
  FETCH_HVA_TEMPLATE_CATEGORIES_REQUEST,
  FETCH_HVA_TEMPLATE_CATEGORIES_SUCCESS,
  FETCH_HVA_TEMPLATE_CATEGORIES_ERROR,
  UPSERT_HVA_TEMPLATE_CATEGORY_REQUEST,
  UPSERT_HVA_TEMPLATE_CATEGORY_SUCCESS,
  UPSERT_HVA_TEMPLATE_CATEGORY_ERROR,
  DELETE_HVA_TEMPLATE_CATEGORY_REQUEST,
  DELETE_HVA_TEMPLATE_CATEGORY_SUCCESS,
  DELETE_HVA_TEMPLATE_CATEGORY_ERROR,
  FETCH_HVA_TEMPLATE_RISKS_REQUEST,
  FETCH_HVA_TEMPLATE_RISKS_SUCCESS,
  FETCH_HVA_TEMPLATE_RISKS_ERROR,
  UPSERT_HVA_TEMPLATE_RISK_REQUEST,
  UPSERT_HVA_TEMPLATE_RISK_SUCCESS,
  UPSERT_HVA_TEMPLATE_RISK_ERROR,
  DELETE_HVA_TEMPLATE_RISK_REQUEST,
  DELETE_HVA_TEMPLATE_RISK_SUCCESS,
  DELETE_HVA_TEMPLATE_RISK_ERROR,
  FETCH_HVA_TEMPLATE_COLUMNS_REQUEST,
  FETCH_HVA_TEMPLATE_COLUMNS_SUCCESS,
  FETCH_HVA_TEMPLATE_COLUMNS_ERROR,
  CLONE_HVA_REQUEST,
  CLONE_HVA_SUCCESS,
  CLONE_HVA_ERROR,
  FETCH_ALERTS_REQUEST,
  FETCH_ALERTS_SUCCESS,
  FETCH_ALERTS_ERROR,
  UPDATE_ALERT_REQUEST,
  UPDATE_ALERT_SUCCESS,
  UPDATE_ALERT_ERROR,
  FETCH_GEOLOCATION_RISK_ORG_REQUEST,
  FETCH_GEOLOCATION_RISK_ORG_SUCCESS,
  FETCH_GEOLOCATION_RISK_ORG_ERROR,
  FETCH_GEOLOCATION_RISK_ORG_RESULT_REQUEST,
  FETCH_GEOLOCATION_RISK_ORG_RESULT_SUCCESS,
  FETCH_GEOLOCATION_RISK_ORG_RESULT_ERROR,
} from 'constants/alert-action-types';

import {
  FETCH_AI2FP_DATA_REQUEST,
  FETCH_AI2FP_DATA_SUCCESS,
  FETCH_AI2FP_DATA_ERROR,
  FETCH_CORE_COMPONENTS_REQUEST,
  FETCH_CORE_COMPONENTS_SUCCESS,
  FETCH_CORE_COMPONENTS_ERROR,
  UPSERT_CORE_COMPONENT_REQUEST,
  UPSERT_CORE_COMPONENT_SUCCESS,
  UPSERT_CORE_COMPONENT_ERROR,
  LINK_CORE_COMPONENTS_REQUEST,
  LINK_CORE_COMPONENTS_SUCCESS,
  LINK_CORE_COMPONENTS_ERROR,
  UNLINK_CORE_COMPONENTS_REQUEST,
  UNLINK_CORE_COMPONENTS_SUCCESS,
  UNLINK_CORE_COMPONENTS_ERROR,
  DELETE_CORE_COMPONENT_REQUEST,
  DELETE_CORE_COMPONENT_SUCCESS,
  DELETE_CORE_COMPONENT_ERROR,
  UPDATE_CURRENT_AOR_REQUEST,
  UPDATE_CURRENT_AOR_SUCCESS,
  UPDATE_CURRENT_AOR_ERROR,
  FETCH_THRESHOLDS_FOR_AOR_REQUEST,
  FETCH_THRESHOLDS_FOR_AOR_SUCCESS,
  FETCH_THRESHOLDS_FOR_AOR_ERROR,
  UPSERT_THRESHOLD_REQUEST,
  UPSERT_THRESHOLD_SUCCESS,
  UPSERT_THRESHOLD_ERROR,
  UPDATE_CURRENT_ANOMALY_REQUEST,
  UPDATE_CURRENT_ANOMALY_SUCCESS,
  UPDATE_CURRENT_ANOMALY_ERROR,
  FETCH_ANOMALIES_REQUEST,
  FETCH_ANOMALIES_SUCCESS,
  FETCH_ANOMALIES_ERROR,
  ARCHIVE_ANOMALY_REQUEST,
  ARCHIVE_ANOMALY_SUCCESS,
  ARCHIVE_ANOMALY_ERROR,
  ANOMALY_TO_INCIDENT_REQUEST,
  ANOMALY_TO_INCIDENT_SUCCESS,
  ANOMALY_TO_INCIDENT_ERROR,
} from 'constants/ai2fp-action-types';

import {
  LOAD_DATASET_REQUEST,
  LOAD_DATASET_SUCCESS,
  LOAD_DATASET_ERROR,
  FETCH_ODDS_METADATASETS_REQUEST,
  FETCH_ODDS_METADATASETS_SUCCESS,
  FETCH_ODDS_METADATASETS_ERROR,
  FETCH_ODDS_DATASET_REQUEST,
  FETCH_ODDS_DATASET_SUCCESS,
  FETCH_ODDS_DATASET_ERROR,
  FETCH_ODDS_DATASET_FOR_DASHBOARD_REQUEST,
  FETCH_ODDS_DATASET_FOR_DASHBOARD_SUCCESS,
  FETCH_ODDS_DATASET_FOR_DASHBOARD_ERROR,
} from 'constants/odds-action-types';

import {
  UPDATE_CURRENT_INCIDENT_REQUEST,
  UPDATE_CURRENT_INCIDENT_SUCCESS,
  UPDATE_CURRENT_INCIDENT_ERROR,
  CREATE_WIDGET_REQUEST,
  CREATE_WIDGET_SUCCESS,
  CREATE_WIDGET_ERROR,
  CREATE_INCIDENT_REQUEST,
  CREATE_INCIDENT_SUCCESS,
  CREATE_INCIDENT_ERROR,
  UPDATE_INCIDENT_REQUEST,
  UPDATE_INCIDENT_SUCCESS,
  UPDATE_INCIDENT_ERROR,
  CREATE_DASHBOARD_REQUEST,
  CREATE_DASHBOARD_SUCCESS,
  CREATE_DASHBOARD_ERROR,
  UPDATE_DASHBOARD_REQUEST,
  UPDATE_DASHBOARD_SUCCESS,
  UPDATE_DASHBOARD_ERROR,
  FETCH_DASHBOARDS_REQUEST,
  FETCH_DASHBOARDS_SUCCESS,
  FETCH_DASHBOARDS_ERROR,
  TOGGLE_WIDGET_REQUEST,
  TOGGLE_WIDGET_SUCCESS,
  TOGGLE_WIDGET_ERROR,
  UPDATE_WIDGET_REQUEST,
  UPDATE_WIDGET_SUCCESS,
  UPDATE_WIDGET_ERROR,
  REMOVE_WIDGET_REQUEST,
  REMOVE_WIDGET_SUCCESS,
  REMOVE_WIDGET_ERROR,
  FETCH_DASHBOARD_DATA_REQUEST,
  FETCH_DASHBOARD_DATA_SUCCESS,
  FETCH_DASHBOARD_DATA_ERROR,
  UPDATE_CURRENT_DASHBOARD_REQUEST,
  UPDATE_CURRENT_DASHBOARD_SUCCESS,
  UPDATE_CURRENT_DASHBOARD_ERROR,
  UPDATE_MAP_WIDGET_ALREADY_RELOADED_REQUEST,
  UPDATE_MAP_WIDGET_ALREADY_RELOADED_SUCCESS,
  UPDATE_MAP_WIDGET_ALREADY_RELOADED_ERROR,
  UPDATE_INCIDENT_GROUP_REQUEST,
  UPDATE_INCIDENT_GROUP_SUCCESS,
  UPDATE_INCIDENT_GROUP_ERROR,
  FETCH_INCIDENT_ASSETS_REQUEST,
  FETCH_INCIDENT_ASSETS_SUCCESS,
  FETCH_INCIDENT_ASSETS_ERROR,
  FETCH_INCIDENT_ASSET_LOGS_REQUEST,
  FETCH_INCIDENT_ASSET_LOGS_SUCCESS,
  FETCH_INCIDENT_ASSET_LOGS_ERROR,
  CREATE_INCIDENT_ASSET_REQUEST,
  CREATE_INCIDENT_ASSET_SUCCESS,
  CREATE_INCIDENT_ASSET_ERROR,
  UPDATE_INCIDENT_ASSET_REQUEST,
  UPDATE_INCIDENT_ASSET_SUCCESS,
  UPDATE_INCIDENT_ASSET_ERROR,
  FETCH_ROLES_BY_INCIDENT_REQUEST,
  FETCH_ROLES_BY_INCIDENT_SUCCESS,
  FETCH_ROLES_BY_INCIDENT_ERROR,
  EXPORT_MARKDOWN_TO_DOCUMENT_REQUEST,
  EXPORT_MARKDOWN_TO_DOCUMENT_SUCCESS,
  EXPORT_MARKDOWN_TO_DOCUMENT_ERROR,
  FETCH_DATASET_METADATA_FOR_WIDGET_REQUEST,
  FETCH_DATASET_METADATA_FOR_WIDGET_SUCCESS,
  FETCH_DATASET_METADATA_FOR_WIDGET_ERROR,
  UPDATE_CURRENTLY_SELECTED_GROUP_REQUEST,
  UPDATE_CURRENTLY_SELECTED_GROUP_SUCCESS,
  UPDATE_CURRENTLY_SELECTED_GROUP_ERROR,
  CLEAR_DATASET_METADATA_FOR_WIDGET_REQUEST,
  CLEAR_DATASET_METADATA_FOR_WIDGET_SUCCESS,
  CLEAR_DATASET_METADATA_FOR_WIDGET_ERROR,
  FETCH_DATASET_METADATA_REQUEST,
  FETCH_DATASET_METADATA_SUCCESS,
  FETCH_DATASET_METADATA_ERROR,
  CLEAR_DATASET_METADATA_REQUEST,
  CLEAR_DATASET_METADATA_SUCCESS,
  CLEAR_DATASET_METADATA_ERROR,
  SELECT_OPERATIONAL_PERIOD_REQUEST,
  SELECT_OPERATIONAL_PERIOD_SUCCESS,
  SELECT_OPERATIONAL_PERIOD_ERROR,
  SET_CLONE_DEFAULTS_REQUEST,
  SET_CLONE_DEFAULTS_SUCCESS,
  SET_CLONE_DEFAULTS_ERROR,
  FETCH_CLONE_DEFAULTS_REQUEST,
  FETCH_CLONE_DEFAULTS_SUCCESS,
  FETCH_CLONE_DEFAULTS_ERROR,
  FETCH_DICE_LOG_REQUEST,
  FETCH_DICE_LOG_SUCCESS,
  FETCH_DICE_LOG_ERROR,
  DELETE_LAYOUT_REQUEST,
  DELETE_LAYOUT_SUCCESS,
  DELETE_LAYOUT_ERROR,
  FETCH_DATASET_FOR_TABLE_REQUEST,
  FETCH_DATASET_FOR_TABLE_SUCCESS,
  FETCH_DATASET_FOR_TABLE_ERROR,
  ADD_USER_SCHEDULE_REQUEST,
  ADD_USER_SCHEDULE_SUCCESS,
  ADD_USER_SCHEDULE_ERROR,
  GET_ALL_USER_SCHEDULE,
  GET_ALL_USER_SCHEDULE_REQUEST,
  GET_ALL_USER_SCHEDULE_SUCCESS,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  GENERATE_SIGNED_URL_REQUEST,
  GENERATE_SIGNED_URL_SUCCESS,
  FETCH_GROUP_IMAGES_SUCCESS,
  FETCH_GROUP_IMAGES_REQUEST,
  FETCH_BRIEFING_SUCCESS,
  FETCH_BRIEFING_REQUEST,
  SET_BRIEFING_SKETCH_REQUEST,
  SET_BRIEFING_SKETCH_SUCCESS,
  ENVIRONMENT_VAR_LOADED,
} from 'constants/dice-action-types';

import { FETCH_CHAT_DGPT_ONGOING } from 'constants/dgpt-action-types';

import {
  UPDATE_LASSO_FILTER_SUCCESS,
  UPDATE_LASSO_FILTER_ERROR,
  UPDATE_MAP_LOCATION,
  UPDATE_MAP_LOCATION_ERROR,
  DASHBOARD_LAYOUT_UPDATE,
  DASHBOARD_LAYOUT_UPDATE_ERROR,
  ZOOM_MAP_TO_DATA,
  ZOOM_MAP_TO_DATA_ERROR,
  UPDATE_LASSO_COORDINATES_REQUEST,
  UPDATE_LASSO_COORDINATES_SUCCESS,
  UPDATE_LASSO_COORDINATES_ERROR,
  TOGGLE_MAP_LEGEND_VISIBLE_REQUEST,
  TOGGLE_MAP_LEGEND_VISIBLE_SUCCESS,
  TOGGLE_MAP_LEGEND_VISIBLE_ERROR,
  TOGGLE_MAP_DATA_PANE_VISIBLE_REQUEST,
  TOGGLE_MAP_DATA_PANE_VISIBLE_SUCCESS,
  TOGGLE_MAP_DATA_PANE_VISIBLE_ERROR,
  UPDATE_MAP_DATA_PANE_DATA_REQUEST,
  UPDATE_MAP_DATA_PANE_DATA_SUCCESS,
  UPDATE_MAP_DATA_PANE_DATA_ERROR,
  ADD_TO_MAP_DATA_PANE_DATA_REQUEST,
  ADD_TO_MAP_DATA_PANE_DATA_SUCCESS,
  ADD_TO_MAP_DATA_PANE_DATA_ERROR,
  REMOVE_FROM_MAP_DATA_PANE_DATA_REQUEST,
  REMOVE_FROM_MAP_DATA_PANE_DATA_SUCCESS,
  REMOVE_FROM_MAP_DATA_PANE_DATA_ERROR,
} from 'constants/map-action-types';

import {
  CREATE_OR_GET_USER_REQUEST,
  CREATE_OR_GET_USER_SUCCESS,
  CREATE_OR_GET_USER_ERROR,
  FETCH_GROUPS_REQUEST,
  FETCH_GROUPS_SUCCESS,
  FETCH_GROUPS_ERROR,
  FETCH_GROUPS_FOR_USER_REQUEST,
  FETCH_GROUPS_FOR_USER_SUCCESS,
  FETCH_GROUPS_FOR_USER_ERROR,
  CREATE_GROUP_REQUEST,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_ERROR,
  CLOSE_GROUP_REQUEST,
  CLOSE_GROUP_SUCCESS,
  CLOSE_GROUP_ERROR,
  JOIN_GROUP_REQUEST,
  JOIN_GROUP_SUCCESS,
  JOIN_GROUP_ERROR,
  FETCH_INCIDENTS_REQUEST,
  FETCH_INCIDENTS_SUCCESS,
  FETCH_INCIDENTS_ERROR,
  UPDATE_ROLE_REQUEST,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_ERROR,
  FETCH_GROUP_SOLICITATIONS_REQUEST,
  FETCH_GROUP_SOLICITATIONS_SUCCESS,
  FETCH_GROUP_SOLICITATIONS_ERROR,
  FETCH_USER_SUBSCRIPTIONS_REQUEST,
  FETCH_USER_SUBSCRIPTIONS_SUCCESS,
  FETCH_USER_SUBSCRIPTIONS_ERROR,
  FETCH_SEAT_ASSIGNMENTS_REQUEST,
  FETCH_SEAT_ASSIGNMENTS_SUCCESS,
  FETCH_SEAT_ASSIGNMENTS_ERROR,
  ASSIGN_SEAT_REQUEST,
  ASSIGN_SEAT_SUCCESS,
  ASSIGN_SEAT_ERROR,
  UNASSIGN_SEAT_REQUEST,
  UNASSIGN_SEAT_SUCCESS,
  UNASSIGN_SEAT_ERROR,
  INVITE_GROUP_MEMBER_REQUEST,
  INVITE_GROUP_MEMBER_SUCCESS,
  INVITE_GROUP_MEMBER_ERROR,
  UPDATE_CURRENTLY_SELECTED_REQUEST,
  UPDATE_CURRENTLY_SELECTED_SUCCESS,
  UPDATE_CURRENTLY_SELECTED_ERROR,
  UPDATE_USER_PROFILE_SETTINGS_REQUEST,
  UPDATE_USER_PROFILE_SETTINGS_SUCCESS,
  UPDATE_USER_PROFILE_SETTINGS_ERROR,
  BULK_USER_IMPORT_REQUEST,
  BULK_USER_IMPORT_SUCCESS,
  BULK_USER_IMPORT_ERROR,
  GET_USER_BY_ID_REQUEST,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_ERROR,
  UPDATE_FOCUS_USER_PROFILE_SETTINGS_SUCCESS,
  CONFIRM_USER_NOT_NEW_REQUEST,
  CONFIRM_USER_NOT_NEW_SUCCESS,
  CONFIRM_USER_NOT_NEW_ERROR,
  FETCH_USER_GROUPS_ROLES_INCIDENTS_REQUEST,
  FETCH_USER_GROUPS_ROLES_INCIDENTS_SUCCESS,
  FETCH_USER_GROUPS_ROLES_INCIDENTS_ERROR,
  GET_ALL_GROUP_QUESTIONS_SUCCESS,
  GET_ALL_GROUP_QUESTIONS_REQUEST,
  GET_ALL_GROUP_QUESTIONS,
  ADD_GROUP_QUESTIONS_REQUEST,
  ADD_GROUP_QUESTIONS_SUCCESS,
  DELETE_GROUP_QUESTIONS_SUCCESS,
  DELETE_GROUP_QUESTIONS_REQUEST,
  ADD_GROUP_ANSWER,
  GET_ALL_GROUP_ANSWER_FOR_ALL_MEMBERS,
  UPDATE_INCIDENT_STATUS,
} from 'constants/profile-action-types';

import {
  GET_ROSTER_FOR_INCIDENT_REQUEST,
  GET_ROSTER_FOR_INCIDENT_SUCCESS,
  GET_ROSTER_FOR_INCIDENT_ERROR,
  ADD_USER_TO_INCIDENT_BY_ADMIN_REQUEST,
  ADD_USER_TO_INCIDENT_BY_ADMIN_SUCCESS,
  ADD_USER_TO_INCIDENT_BY_ADMIN_ERROR,
  EDIT_INCIDENT_MEMBER_BY_ADMIN_REQUEST,
  EDIT_INCIDENT_MEMBER_BY_ADMIN_SUCCESS,
  EDIT_INCIDENT_MEMBER_BY_ADMIN_ERROR,
  REMOVE_USER_FROM_INCIDENT_BY_ADMIN_REQUEST,
  REMOVE_USER_FROM_INCIDENT_BY_ADMIN_SUCCESS,
  REMOVE_USER_FROM_INCIDENT_BY_ADMIN_ERROR,
  REMOVE_SELF_FROM_INCIDENT_REQUEST,
  REMOVE_SELF_FROM_INCIDENT_SUCCESS,
  REMOVE_SELF_FROM_INCIDENT_ERROR,
  RESPOND_TO_REQUEST_TO_JOIN_INCIDENT_REQUEST,
  RESPOND_TO_REQUEST_TO_JOIN_INCIDENT_SUCCESS,
  RESPOND_TO_REQUEST_TO_JOIN_INCIDENT_ERROR,
  GET_DASHBOARD_REQUEST,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_ERROR,
  ADD_USER_TO_INCIDENT_SUCCESS,
  GET_INCIDENT_LOGS,
  ADD_NON_USER_TO_INCIDENT_BY_ADMIN_REQUEST,
  ADD_NON_USER_TO_INCIDENT_SUCCESS,
  DEACTIVATE_USER_TO_INCIDENT_REQUEST,
  DEACTIVATE_USER_TO_INCIDENT_SUCCESS,
  UPDATE_INCIDENT_LOGS_WITH_WORKLOG,
  GET_ALL_INCIDENT_OBJECTIVES_SUCCESS,
  GET_ALL_INCIDENT_OBJECTIVES_REQUEST,
  GET_ALL_INCIDENT_OBJECTIVES,
  ADD_INCIDENT_OBJECTIVES_REQUEST,
  ADD_INCIDENT_OBJECTIVES_SUCCESS,
  GET_ALL_INCIDENT_RESOURCES,
  GET_ALL_INCIDENT_RESOURCES_REQUEST,
  GET_ALL_INCIDENT_RESOURCES_SUCCESS,
  ADD_INCIDENT_RESOURCES_REQUEST,
  ADD_INCIDENT_RESOURCES_SUCCESS,
  GET_ALL_GROUP_OBJECTIVES,
  GET_ALL_GROUP_OBJECTIVES_REQUEST,
  GET_ALL_GROUP_RESOURCES,
  GET_ALL_GROUP_RESOURCES_REQUEST,
  SELF_ACTIVATION_REQUEST,
  SELF_ACTIVATION_SUCCESS,
  ADD_SCHEDULE_TO_INCIDENT_SUCCESS,
  ADD_SCHEDULE_TO_INCIDENT_REQUEST,
  GET_ALL_SCHEDULE_TO_INCIDENT,
  GET_ALL_SCHEDULE_TO_INCIDENT_REQUEST,
  GET_USER_SCHEDULE_TO_INCIDENT,
  UPDATE_INCIDENT_MEMBER_WORK_LOG_REQUEST,
  UPDATE_INCIDENT_MEMBER_WORK_LOG_SUCCESS,
  BULK_ADD_OBJECTIVES_REQUEST,
  BULK_ADD_OBJECTIVES_SUCCESS,
  BULK_ADD_OBJECTIVES_ERROR,
  BULK_ADD_ACTIONS_REQUEST,
  BULK_ADD_ACTIONS_SUCCESS,
  BULK_ADD_ACTIONS_ERROR,
  BULK_ADD_RESOURCES_REQUEST,
  BULK_ADD_RESOURCES_SUCCESS,
  BULK_ADD_RESOURCES_ERROR,
  UPDATE_INCIDENT_ACTION_SELECTION_REQUEST,
  UPDATE_INCIDENT_ACTION_SELECTION_SUCCESS,
  UPDATE_INCIDENT_ACTION_SELECTION_ERROR,
} from 'constants/incident-action-types';

import {
  FETCH_ALL_ROLES_IN_GROUP_REQUEST,
  FETCH_ALL_ROLES_IN_GROUP_SUCCESS,
  FETCH_ALL_ROLES_IN_GROUP_ERROR,
  FETCH_ALL_ROLE_ASSIGNMENTS_IN_GROUP_REQUEST,
  FETCH_ALL_ROLE_ASSIGNMENTS_IN_GROUP_SUCCESS,
  FETCH_ALL_ROLE_ASSIGNMENTS_IN_GROUP_ERROR,
  FETCH_ROLE_ACTIONS_LOG_REQUEST,
  FETCH_ROLE_ACTIONS_LOG_SUCCESS,
  FETCH_ROLE_ACTIONS_LOG_ERROR,
  CREATE_ROLE_REQUEST,
  CREATE_ROLE_SUCCESS,
  CREATE_ROLE_ERROR,
  EDIT_ROLE_REQUEST,
  EDIT_ROLE_SUCCESS,
  EDIT_ROLE_ERROR,
  DELETE_ROLE_REQUEST,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_ERROR,
  ASSIGN_ROLE_REQUEST,
  ASSIGN_ROLE_SUCCESS,
  ASSIGN_ROLE_ERROR,
  UNASSIGN_ROLE_REQUEST,
  UNASSIGN_ROLE_SUCCESS,
  UNASSIGN_ROLE_ERROR,
  FETCH_ROSTERS_FOR_GROUPS_REQUEST,
  FETCH_ROSTERS_FOR_GROUPS_SUCCESS,
  FETCH_ROSTERS_FOR_GROUPS_ERROR,
  FETCH_ALL_PERMISSIONS_REQUEST,
  FETCH_ALL_PERMISSIONS_SUCCESS,
  FETCH_ALL_PERMISSIONS_ERROR,
  FETCH_ALL_PERMISSIONS_IN_ROLE_REQUEST,
  FETCH_ALL_PERMISSIONS_IN_ROLE_SUCCESS,
  FETCH_ALL_PERMISSIONS_IN_ROLE_ERROR,
  REMOVE_USER_FROM_GROUP_REQUEST,
  REMOVE_USER_FROM_GROUP_SUCCESS,
  REMOVE_USER_FROM_GROUP_ERROR,
  EDIT_GROUP_REQUEST,
  EDIT_GROUP_SUCCESS,
  EDIT_GROUP_ERROR,
  DELETE_GROUP_REQUEST,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_ERROR,
  FETCH_INCIDENTS_FOR_GROUP_REQUEST,
  FETCH_INCIDENTS_FOR_GROUP_SUCCESS,
  FETCH_INCIDENTS_FOR_GROUP_ERROR,
} from 'constants/role-action-types';

import {
  VALIDATE_RBAC_PERMISSION_FOR_ACTION_REQUEST,
  VALIDATE_RBAC_PERMISSION_FOR_ACTION_SUCCESS,
  VALIDATE_RBAC_PERMISSION_FOR_ACTION_ERROR,
  VALIDATE_USER_IN_GROUPS_REQUEST,
  VALIDATE_USER_IN_GROUPS_SUCCESS,
  VALIDATE_USER_IN_GROUPS_ERROR,
  VALIDATE_USER_IN_GROUP_WITH_ROLE_REQUEST,
  VALIDATE_USER_IN_GROUP_WITH_ROLE_SUCCESS,
  VALIDATE_USER_IN_GROUP_WITH_ROLE_ERROR,
  VALIDATE_USER_SUBSCRIPTION_REQUEST,
  VALIDATE_USER_SUBSCRIPTION_SUCCESS,
  VALIDATE_USER_SUBSCRIPTION_ERROR,
  VALIDATE_USER_IN_INCIDENT_REQUEST,
  VALIDATE_USER_IN_INCIDENT_SUCCESS,
  VALIDATE_USER_IN_INCIDENT_ERROR,
  VALIDATE_USER_INCIDENT_ADMIN_REQUEST,
  VALIDATE_USER_INCIDENT_ADMIN_SUCCESS,
  VALIDATE_USER_INCIDENT_ADMIN_ERROR,
  VALIDATE_USER_SUBSCRIPTION_ACTION_BY_MINIMUM_PLAN_REQUIRED_REQUEST,
  VALIDATE_USER_SUBSCRIPTION_ACTION_BY_MINIMUM_PLAN_REQUIRED_SUCCESS,
  VALIDATE_USER_SUBSCRIPTION_ACTION_BY_MINIMUM_PLAN_REQUIRED_ERROR,
  VALIDATE_USER_IN_POD_SECURITY_POLICY_REQUEST,
  VALIDATE_USER_IN_POD_SECURITY_POLICY_SUCCESS,
  VALIDATE_USER_IN_POD_SECURITY_POLICY_ERROR,
} from 'constants/validation-action-types';

import {
  FETCH_PODS_REQUEST,
  FETCH_PODS_SUCCESS,
  FETCH_PODS_ERROR,
  CREATE_POD_REQUEST,
  CREATE_POD_SUCCESS,
  CREATE_POD_ERROR,
  EDIT_POD_REQUEST,
  EDIT_POD_SUCCESS,
  EDIT_POD_ERROR,
  DELETE_POD_REQUEST,
  DELETE_POD_SUCCESS,
  DELETE_POD_ERROR,
  FETCH_POD_NTKL_REQUEST,
  FETCH_POD_NTKL_SUCCESS,
  FETCH_POD_NTKL_ERROR,
  ADD_TO_POD_NTKL_REQUEST,
  ADD_TO_POD_NTKL_SUCCESS,
  ADD_TO_POD_NTKL_ERROR,
  REMOVE_FROM_POD_NTKL_REQUEST,
  REMOVE_FROM_POD_NTKL_SUCCESS,
  REMOVE_FROM_POD_NTKL_ERROR,
  FETCH_PODS_DATASETS_REQUEST,
  FETCH_PODS_DATASETS_SUCCESS,
  FETCH_PODS_DATASETS_ERROR,
  CREATE_POD_DATA_REQUEST,
  CREATE_POD_DATA_SUCCESS,
  CREATE_POD_DATA_ERROR,
  EDIT_POD_DATA_REQUEST,
  EDIT_POD_DATA_SUCCESS,
  EDIT_POD_DATA_ERROR,
  DELETE_POD_DATA_REQUEST,
  DELETE_POD_DATA_SUCCESS,
  DELETE_POD_DATA_ERROR,
  FETCH_POD_DATASET_FOR_DASHBOARD_REQUEST,
  FETCH_POD_DATASET_FOR_DASHBOARD_SUCCESS,
  FETCH_POD_DATASET_FOR_DASHBOARD_ERROR,
  FETCH_LIVESTREAM_DATA_JSON_REQUEST,
  FETCH_LIVESTREAM_DATA_JSON_SUCCESS,
  FETCH_LIVESTREAM_DATA_JSON_ERROR,
} from 'constants/pods-action-types';

import {
  FETCH_ACTIVITIES_REQUEST,
  FETCH_ACTIVITIES_SUCCESS,
  FETCH_ACTIVITIES_ERROR,
  FETCH_ACTIVITY_RECORDS_REQUEST,
  FETCH_ACTIVITY_RECORDS_SUCCESS,
  FETCH_ACTIVITY_RECORDS_ERROR,
  CREATE_ACTIVITY_REQUEST,
  CREATE_ACTIVITY_SUCCESS,
  CREATE_ACTIVITY_ERROR,
  EDIT_ACTIVITY_REQUEST,
  EDIT_ACTIVITY_SUCCESS,
  EDIT_ACTIVITY_ERROR,
  DELETE_ACTIVITY_REQUEST,
  DELETE_ACTIVITY_SUCCESS,
  DELETE_ACTIVITY_ERROR,
  UPLOAD_ACTIVITY_RECORDS_REQUEST,
  UPLOAD_ACTIVITY_RECORDS_SUCCESS,
  UPLOAD_ACTIVITY_RECORDS_ERROR,
  EDIT_ACTIVITY_RECORD_REQUEST,
  EDIT_ACTIVITY_RECORD_SUCCESS,
  EDIT_ACTIVITY_RECORD_ERROR,
  DELETE_ACTIVITY_RECORD_REQUEST,
  DELETE_ACTIVITY_RECORD_SUCCESS,
  DELETE_ACTIVITY_RECORD_ERROR,
  ASSIGN_ACTIVITY_RECORD_REQUEST,
  ASSIGN_ACTIVITY_RECORD_SUCCESS,
  ASSIGN_ACTIVITY_RECORD_ERROR,
  FETCH_ASSIGNMENTS_FOR_ACTIVITY_REQUEST,
  FETCH_ASSIGNMENTS_FOR_ACTIVITY_SUCCESS,
  FETCH_ASSIGNMENTS_FOR_ACTIVITY_ERROR,
  NOTIFY_ASSIGNEE_SUCCESS,
  NOTIFY_ASSIGNEE_ERROR,
  RESPOND_TO_TASK_ASSIGNMENT_SUCCESS,
  RESPOND_TO_TASK_ASSIGNMENT_ERROR,
  COMPLETE_TASK_ASSIGNMENT_SUCCESS,
  COMPLETE_TASK_ASSIGNMENT_ERROR,
} from 'constants/activity-action-types';

import {
  TOGGLE_ASSETS_ON_MAP_REQUEST,
  TOGGLE_ASSETS_ON_MAP_SUCCESS,
  TOGGLE_ASSETS_ON_MAP_ERROR,
  TOGGLE_ASSET_ON_MAP_REQUEST,
  TOGGLE_ASSET_ON_MAP_SUCCESS,
  TOGGLE_ASSET_ON_MAP_ERROR,
  FETCH_DATASET_GROUPS_REQUEST,
  FETCH_DATASET_GROUPS_SUCCESS,
  FETCH_DATASET_GROUPS_ERROR,
  GET_SELECTED_DATASET_GROUPS_REQUEST,
  GET_SELECTED_DATASET_GROUPS_SUCCESS,
  GET_SELECTED_DATASET_GROUPS_ERROR,
  FETCH_ASSET_RISKS_REQUEST,
  FETCH_ASSET_RISKS_SUCCESS,
  FETCH_PRECOMPILED_ASSET_RISKS_SUCCESS,
} from 'constants/asset-action-types';

import {
  SEND_NOTIFICATION_REQUEST,
  SEND_NOTIFICATION_SUCCESS,
  SEND_NOTIFICATION_ERROR,
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_ERROR,
  UPSERT_NOTIFICATION_REQUEST,
  UPSERT_NOTIFICATION_SUCCESS,
  UPSERT_NOTIFICATION_ERROR,
  DELETE_NOTIFICATION_REQUEST,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_ERROR,
  FETCH_NOTIFICATION_LOG_REQUEST,
  FETCH_NOTIFICATION_LOG_SUCCESS,
  FETCH_NOTIFICATION_LOG_ERROR,
  FETCH_NOTIFICATION_LOG_INSTANCE_REQUEST,
  FETCH_NOTIFICATION_LOG_INSTANCE_SUCCESS,
  FETCH_NOTIFICATION_LOG_INSTANCE_ERROR,
  FETCH_ALL_NOTIFICATION_LOG_SUMMARIES_REQUEST,
  FETCH_ALL_NOTIFICATION_LOG_SUMMARIES_SUCCESS,
  FETCH_ALL_NOTIFICATION_LOG_SUMMARIES_ERROR,
  FETCH_ALL_DISTRIBUTION_LIST,
  CREATE_DISTRIBUTION_LIST,
  DELETE_DISTRIBUTION_LIST_ITEM,
  CLEAR_NOTIFICATION_STATUS,
  CLEAR_UPSERT_NOTIFICATION_STATUS,
  SET_NOTIFICATION_PAGE_DATA,
  CLEAR_NOTIFICATION_PAGE_DATA,
} from 'constants/notification-action-types';

import {
  CHANGE_FEATURE_FLAGS_SUCCESS,
  CHANGE_FEATURE_FLAGS_ERROR,
} from 'constants/feature-flag-action-types';

import {
  FETCH_FORM_RECORDS_REQUEST,
  FETCH_FORM_RECORDS_SUCCESS,
  FETCH_FORM_RECORDS_ERROR,
  UPSERT_FORM_RECORD_REQUEST,
  UPSERT_FORM_RECORD_SUCCESS,
  UPSERT_FORM_RECORD_ERROR,
  DELETE_FORM_RECORD_REQUEST,
  DELETE_FORM_RECORD_SUCCESS,
  DELETE_FORM_RECORD_ERROR,
  EXPORT_FORM_TO_PDF_REQUEST,
  EXPORT_FORM_TO_PDF_SUCCESS,
  EXPORT_FORM_TO_PDF_ERROR,
  DOWNLOAD_FORMS_DATA_ARRAY_REQUEST,
  DOWNLOAD_FORMS_DATA_ARRAY_SUCCESS,
  DOWNLOAD_FORMS_DATA_ARRAY_ERROR,
  GET_ALL_ACTION_REQUEST,
  GET_ALL_ACTION_SUCCESS,
  GET_ALL_ACTION,
  ADD_NEW_ACTION_REQUEST,
  ADD_NEW_ACTION_SUCCESS,
  UPDATE_SITUATION_REQUEST,
  UPDATE_SITUATION_SUCCESS,
  GET_ALL_BRANCH,
  GET_ALL_DIVISION,
  GET_PLANNING_WORKSHEET,
  GET_ALL_WORK_ASSIGNMENT,
  FETCH_MEDICAL_PLAN,
  FETCH_MEDICAL_AID_STATION_LIST,
  FETCH_TRANSPORTATION_LIST,
  FETCH_HOSPITAL_LIST,
  GET_ICS_OBJECTIVES_DATA,
  GET_IAP_COVER_SHEET_DATA,
  FETCH_SAFTY_AND_HEALTH_FORM_DATA,
  FETCH_SAFETY_AND_HEALTH_FORM_DATA,
  UPSERT_COMMUNICATION,
  FETCH_RESOURCE_REQUEST,
  FETCH_ALL_RESOURCE_REQUEST,
  FETCH_MEETINGS,
  EXPORT_AS_FORM_REQUEST,
  EXPORT_AS_FORM_SUCCESS,
  EXPORT_AS_FORM_ERROR,
  FETCH_INCIDENT_ACTIONS_REQUEST,
  FETCH_INCIDENT_ACTIONS_SUCCESS,
  FETCH_INCIDENT_ACTIONS_ERROR,
} from 'constants/form-action-types';

import {
  FETCH_SIGNATURES_REQUEST,
  FETCH_SIGNATURES_SUCCESS,
  FETCH_SIGNATURES_ERROR,
  UPSERT_SIGNATURE_REQUEST,
  UPSERT_SIGNATURE_SUCCESS,
  UPSERT_SIGNATURE_ERROR,
  DELETE_SIGNATURE_REQUEST,
  DELETE_SIGNATURE_SUCCESS,
  DELETE_SIGNATURE_ERROR,
  FETCH_PREPARED_BY_SIGNATURES_SUCCESS,
  FETCH_REVIEWED_BY_SIGNATURES_SUCCESS,
} from 'constants/signature-action-types';

import {
  FETCH_WORKFLOW_NODES_REQUEST,
  FETCH_WORKFLOW_NODES_SUCCESS,
  FETCH_WORKFLOW_NODES_ERROR,
  FETCH_WORKFLOWS_REQUEST,
  FETCH_WORKFLOWS_SUCCESS,
  FETCH_WORKFLOWS_ERROR,
  UPSERT_WORKFLOW_REQUEST,
  UPSERT_WORKFLOW_SUCCESS,
  UPSERT_WORKFLOW_ERROR,
  DELETE_WORKFLOW_REQUEST,
  DELETE_WORKFLOW_SUCCESS,
  DELETE_WORKFLOW_ERROR,
  EXECUTE_WORKFLOW_REQUEST,
  EXECUTE_WORKFLOW_SUCCESS,
  EXECUTE_WORKFLOW_ERROR,
  REMOVE_WORKFLOW_EDGE_REQUEST,
  REMOVE_WORKFLOW_EDGE_SUCCESS,
  REMOVE_WORKFLOW_EDGE_ERROR,
  ROLLBACK_WORKFLOW_REQUEST,
  ROLLBACK_WORKFLOW_SUCCESS,
  ROLLBACK_WORKFLOW_ERROR,
} from 'constants/workflow-action-types';

import {
  IS_SOCKET_OPEN_SUCCESS,
  SOCKET_MESSAGE_SUCCESS,
  SOCKET_ASSET_RISKS_SUCCESS,
} from 'constants/websocket-action-types';

import {
  ADD_INITIAL_INCIDENT_REPORT_CHECKLIST_ERROR,
  ADD_INITIAL_INCIDENT_REPORT_CHECKLIST_REQUEST,
  ADD_INITIAL_INCIDENT_REPORT_CHECKLIST_SUCCESS,
  ADD_INITIAL_INCIDENT_REPORT_COMMENT_REQUEST,
  ADD_INITIAL_INCIDENT_REPORT_COMMENT_SUCCESS,
  ADD_INITIAL_INCIDENT_REPORT_ERROR,
  ADD_INITIAL_INCIDENT_REPORT_TEAM_CHECKLIST_ERROR,
  ADD_INITIAL_INCIDENT_REPORT_TEAM_CHECKLIST_REQUEST,
  ADD_INITIAL_INCIDENT_REPORT_TEAM_CHECKLIST_SUCCESS,
  ADD_INITIAL_INCIDENT_REQUEST,
  ADD_INITIAL_INCIDENT_SUCCESS,
  GET_ALL_INITIAL_INCIDENT_REPORT,
  GET_ALL_INITIAL_INCIDENT_REPORT_CHECKLIST,
  GET_ALL_INITIAL_INCIDENT_REPORT_CHECKLIST_REQUEST,
  GET_ALL_INITIAL_INCIDENT_REPORT_LOGS,
  GET_ALL_INITIAL_INCIDENT_REPORT_LOGS_REQUEST,
  GET_ALL_INITIAL_INCIDENT_REPORT_LOGS_SUCCESS,
  GET_ALL_INITIAL_INCIDENT_REPORT_RECIPIENT_LIST,
  GET_ALL_INITIAL_INCIDENT_REPORT_REQUEST,
  GET_ALL_INITIAL_INCIDENT_REPORT_SUCCESS,
  GET_ALL_INITIAL_INCIDENT_REPORT_TEAM_CHECKLIST,
  GET_ALL_INITIAL_INCIDENT_REPORT_TEAM_CHECKLIST_REQUEST,
  GET_INITIAL_INCIDENT_REPORT,
  GET_INITIAL_INCIDENT_REPORT_REQUEST,
  GET_INITIAL_INCIDENT_REPORT_SUCCESS,
  GET_ALL_ACTIVITY_LOG,
} from 'constants/incident-report-Incident-types';
import {
  FETCH_ALL_USER_WORKSPACE,
  GET_ALL_EPIC_WORKSPACE,
} from 'constants/workspace-action-types';
import {
  FETCH_AOR,
  FETCH_NESTED_CATEGORIES,
  FETCH_NESTED_CATEGORY_ITEMS,
  GET_ALL_GROUP_LOCATION,
  GET_ALL_LOCATION_ASSET,
  GET_ALL_LOCATION_ENTERPRISE,
  GET_ALL_LOCATION_ENTITY,
  GET_ALL_LOCATION_SUB_ENTITY,
} from 'constants/location-action-types';
import {
  FETCH_TWITTER_DATA_REQUEST,
  FETCH_TWITTER_DATA_SUCCESS,
  FETCH_TWITTER_DATA_ERROR,
  FETCH_TWITTER_DATA_ENRICHED_REQUEST,
  FETCH_TWITTER_DATA_ENRICHED_SUCCESS,
  FETCH_TWITTER_DATA_ENRICHED_ERROR,
  FETCH_OSINT_ASK_REQUEST,
  FETCH_OSINT_ASK_SUCCESS,
  FETCH_OSINT_ASK_ERROR,
} from 'constants/osint-action-types';
import {
  ADD_TASK_COMMENT_ERROR,
  ADD_TASK_COMMENT_REQUEST,
  ADD_TASK_COMMENT_SUCCESS,
  FETCH_TASK_LIST_ERROR,
  FETCH_TASK_LIST_REQUEST,
  FETCH_TASK_LIST_SUCCESS,
  GET_ALL_TASK_COMMENT,
  GET_ALL_TASK_COMMENT_ERROR,
  GET_ALL_TASK_COMMENT_REQUEST,
  GET_ALL_TASK_LOGS,
  GET_ALL_TASK_LOGS_ERROR,
  GET_ALL_TASK_LOGS_REQUEST,
  GET_ALL_TASK_UNIT,
  SAVE_TASK_DATA_ERROR,
  SAVE_TASK_DATA_REQUEST,
  SAVE_TASK_DATA_SUCCESS,
} from 'constants/task-management-types';

import {
  GENERATE_TEAMS_SITE_REQUEST,
  GENERATE_TEAMS_SITE_SUCCESS,
  GENERATE_TEAMS_SITE_ERROR,
  UPLOAD_FILE_TO_TEAMS_REQUEST,
  UPLOAD_FILE_TO_TEAMS_SUCCESS,
  UPLOAD_FILE_TO_TEAMS_ERROR,
  GET_TEAMS_TEMPLATE_SUCCESS,
  UPDATE_TEAMS_TENANT_ID,
  CHECK_TEAMS,
  CHECK_TEAMS_STATUS_SUCCESS,
} from 'constants/teams-action-types';

import {
  STRIPE_CREATE_SESSION_REQUEST,
  STRIPE_CREATE_SESSION_SUCCESS,
  STRIPE_CREATE_SESSION_ERROR,
  CREATE_STRIPE_SUBSCRIPTION_REQUEST,
  CREATE_STRIPE_SUBSCRIPTION_SUCCESS,
  CREATE_STRIPE_SUBSCRIPTION_ERROR,
  FETCH_ASSIGNABLE_USERS_BY_GROUP_REQUEST,
  FETCH_ASSIGNABLE_USERS_BY_GROUP_SUCCESS,
  FETCH_ASSIGNABLE_USERS_BY_GROUP_ERROR,
  UPDATE_STRIPE_SUBSCRIPTION_STATUS_REQUEST,
  UPDATE_STRIPE_SUBSCRIPTION_STATUS_SUCCESS,
  UPDATE_STRIPE_SUBSCRIPTION_STATUS_ERROR,
  ASSIGN_STRIPE_SEATS_REQUEST,
  ASSIGN_STRIPE_SEATS_SUCCESS,
  ASSIGN_STRIPE_SEATS_ERROR,
  UNASSIGN_STRIPE_SEATS_REQUEST,
  UNASSIGN_STRIPE_SEATS_SUCCESS,
  UNASSIGN_STRIPE_SEATS_ERROR,
  FETCH_USER_SUBSCRIPTION_INFO_REQUEST,
  FETCH_USER_SUBSCRIPTION_INFO_SUCCESS,
  FETCH_USER_SUBSCRIPTION_INFO_ERROR,
} from 'constants/stripe-action-types';

export const initialState = {
  rep: {},
  iscreateGroupLoaded: true,
  areGroupsLoaded: true,
  isCloseGroupLoaded: true,
  isJoinGroupLoaded: true,
  areIncidentsLoaded: true,
  isRoleUpdateLoaded: true,
  isfetchGroupsolicitationsLoaded: true,
  isFetchUserSubscriptionsLoaded: true,
  isFetchSeatAssignmentsLoaded: true,
  isSeatAssignmentLoaded: true,
  isSeatUnassignmentLoaded: true,
  isFetchSubscriptionPlansLoaded: true,
  isInviteGroupMemberLoaded: true,
  inviteGroupMemberSuccess: false,
  inviteGroupMemberError: false,
  isUpdateCurrentIncidentLoaded: true,
  isCreateWidgetLoaded: true,
  isCreateIncidentLoaded: true,
  isUpdateIncidentLoaded: true,
  isCreateDashboardLoaded: true,
  isUpdateDashboardLoaded: true,
  isFetchDashboardsLoaded: true,
  isToggleWidgetLoaded: true,
  isUpdateWidgetLoaded: true,
  isFetchDashboardDataLoaded: true,
  isUpdateCurrentDashboardLoaded: true,
  isUpdateMapWidgetAlreadyReloadedLoaded: true,
  isUpdateIncidentGroupLoaded: true,
  isFetchIncidentAssetsLoaded: true,
  isFetchIncidentAssetLogsLoaded: true,
  isCreateIncidentAssetLoaded: true,
  isUpdateIncidentAssetLoaded: true,
  isFetchODDSMetadataLoaded: true,
  isFetchODDSDatasetLoaded: true,
  areNotificationsLoaded: true,
  areRolesLoaded: true,
  isFetchAllRolesInGroupLoaded: true,
  isFetchAllRoleAssignmentsInGroupLoaded: true,
  isFetchRoleActionsLogLoaded: true,
  isCreateRoleLoaded: true,
  isEditRoleLoaded: true,
  isDeleteRoleLoaded: true,
  isAssignRoleLoaded: true,
  isUnassignRoleLoaded: true,
  isFetchRostersForGroupsLoaded: true,
  isFetchAllPermissionsLoaded: true,
  isFetchAllPermissionsInRoleLoaded: true,
  isValidateRBACPermissionForActionLoaded: true,
  validateRBACPermissionForActionResult: undefined,
  isValidateUserInGroupsLoaded: true,
  isValidateUserInGroupWithRoleLoaded: true,
  isValidateUserSubscriptionLoaded: true,
  isValidateUserInIncidentLoaded: true,
  isValidateUserIncidentAdminLoaded: true,
  isvalidateUserSubscriptionActionByMinimumPlanIdRequiredLoaded: true,
  isGetRosterForIncidentLoaded: true,
  isAddUserToIncidentByAdminLoaded: true,
  isAddNonUserToIncidentByAdminLoaded: true,
  isUserDeactivateToIncidentLoaded: true,
  isEditIncidentMemberByAdminLoaded: true,
  isRemoveUserFromIncidentByAdminLoaded: true,
  isRemoveSelfFromIncidentLoaded: true,
  isRespondToRequestToJoinIncidentLoaded: true,
  isGetDashboardLoaded: true,
  isFetchPodsLoaded: true,
  isCreatePodLoaded: true,
  isEditPodLoaded: true,
  isDeletePodLoaded: true,
  isFetchPodNTKLLoaded: true,
  isAddToPodNTKLLoaded: true,
  isRemoveFromPodNTKLLoaded: true,
  isFetchPodsDatasetsLoaded: true,
  isCreatePodDataLoaded: true,
  isEditPodDataLoaded: true,
  isDeletePodDataLoaded: true,
  isFetchPodDatasetForDashboardLoaded: true,
  isValidateUserInPodSecurityPolicyLoaded: true,
  validateUserInPodSecurityPolicyResult: {},
  isRemoveUserFromGroupLoaded: true,
  isEditGroupLoaded: true,
  isDeleteGroupLoaded: true,
  isFetchLivestreamDataJsonLoaded: true,
  currentlySelectedGroup: undefined,
  isFetchActivitiesLoaded: true,
  isFetchActivityRecordsLoaded: true,
  isCreateActivityLoaded: true,
  isEditActivityLoaded: true,
  isDeleteActivityLoaded: true,
  isUploadActivityRecordsLoaded: true,
  isEditActivityRecordLoaded: true,
  isDeleteActivityRecordLoaded: true,
  isExportMarkdownToDocumentLoaded: true,
  exportedMarkdownToDocument: undefined,
  isFetchDatasetMetadataForWidgetLoaded: true,
  datasetMetadataForWidgets: [],
  isUpdateCurrentlySelectedGroupLoaded: true,
  isGroupQuestionLoaded: true,
  isAddGroupQuestionLoaded: true,
  isDeleteGroupQuestionLoaded: true,
  allGroupQuestions: undefined,
  allGroupQuestionAnswers: undefined,
  groups: undefined,
  incidents: undefined,
  currentIncident: undefined,
  currentDashboard: undefined,
  dashboardData: undefined,
  isRemoveWidgetLoaded: true,
  activities: [],
  isSendNotificationLoaded: true,
  sendNotificationResult: undefined,
  isFetchNotificationsLoaded: true,
  isUpsertNotificationLoaded: true,
  isDeleteNotificationLoaded: true,
  notifications: [],
  lassoCoordinates: {},
  crossFilter: [],
  isUpdateCrossFilterRequestLoaded: true,
  isClearCrossFilterLoaded: true,
  mapBoundingBox: undefined,
  incidentHazards: [],
  isFetchIncidentHazardsLoaded: true,
  upsertedIncidentHazard: undefined,
  isUpsertIncidentHazardLoaded: true,
  deletedIncidentHazard: undefined,
  isDeleteIncidentHazardLoaded: true,
  isDeleteIncidentAssetLoaded: true,
  deletedIncidentAsset: undefined,
  assetsOnMap: true,
  isClearDatasetMetadataForWidgetLoaded: true,
  filteredDashboardDatasets: [],
  datasetMetadata: undefined,
  isFetchDatasetMetadataLoaded: true,
  featureFlags: [],
  isFetchDatasetGroupsLoaded: true,
  isGetSelectedDatasetGroupsLoaded: true,
  isFetchAssetRisksLoaded: true,
  formRecords: [],
  isFetchFormRecordsLoaded: true,
  upsertedFormRecord: undefined,
  isUpsertFormRecordLoaded: true,
  deletedFormRecord: undefined,
  incidentLogs: undefined,
  isDeleteFormRecordLoaded: true,
  incidentObjectives: undefined,
  incidentResources: undefined,
  isIncidentObjectivesLoaded: true,
  isAddIncidentObjectivesLoaded: true,
  isIncidentResourcesLoaded: true,
  isAddIncidentResourcesLoaded: true,
  groupObjectives: undefined,
  groupResources: undefined,
  isGroupObjectivesLoaded: true,
  isGroupResourcesLoaded: true,
  isSelfActivationLoaded: true,
  isAddScheduleToIncidentLoaded: true,
  isAllScheduleToIncidentLoaded: true,
  allScheduleToIncident: undefined,
  isAddUserScheduleLoaded: true,
  isAddUserScheduleError: false,
  allUserSchedule: undefined,
  isGetAllUserScheduleLoaded: true,
  exportedFormsToPDF: [],
  isExportFormToPDFLoaded: true,
  signatures: [],
  isFetchSignaturesLoaded: true,
  upsertedSignature: undefined,
  isUpsertSignatureLoaded: true,
  deletedSignature: undefined,
  isDeleteSignatureLoaded: true,
  areGrousForUserLoaded: true,
  isFetchIncdentsForGroupLoaded: true,
  workflowNodes: [],
  isFetchWorkflowNodesLoaded: true,
  workflows: [],
  isFetchWorkflowsLoaded: true,
  upsertedWorkflow: undefined,
  isUpsertWorkflowLoaded: true,
  deletedWorkflow: undefined,
  isDeleteWorkflowLoaded: true,
  executedWorkflow: undefined,
  isExecuteWorkflowLoaded: true,
  removedWorkflowEdgeId: undefined,
  isUpdateUserProfileSettingsLoaded: true,
  bulkImportedUsers: [],
  isBulkImportUsersLoaded: true,
  cloneDefaults: undefined,
  isFetchCloneDefaultsLoaded: true,
  isSetCloneDefaultsLoaded: true,
  fetchedDICELog: {},
  isFetchDICELogLoaded: true,
  notificationLog: undefined,
  isFetchNotificationLogLoaded: true,
  isFetchNotificationLogInstanceLoaded: true,
  isDistributionListLoaded: true,
  distributionListItems: [],
  notificationInstance: undefined,
  upsertedNotification: undefined,
  currentlySelected: undefined,
  isUpdateCurrentlySelectedLoaded: true,
  downloadedFormsDataArray: [],
  isDownloadFormsDataArrayLoaded: true,
  mapLegendVisible: false,
  mapDataPaneVisible: false,
  mapDataPaneData: [],
  isConfirmNewUserNotNewLoaded: true,
  isFetchUserGroupsRolesIncidentsLoaded: true,
  userGroupsRolesIncidents: [],
  isDeleteLayoutLoaded: true,
  rolledBackStableWorkflow: undefined,
  isRollbackWorkflowLoaded: true,
  odds_metadatasets: [],
  isFetchOddsDatasetForDashboardLoaded: true,
  oddsDatasetForDashboard: undefined,
  notificationPageData: undefined,
  datasetsForTables: {},
  isFetchAI2FPDataLoaded: true,
  AI2FPData: {},
  ADLs: [],
  isFetchADLsLoaded: true,
  isUpsertADLLoaded: true,
  isDeleteADLLoaded: true,
  isAssignADLLoaded: true,
  isUnassignADLLoaded: true,
  HVAs: [],
  HVATemplateCategories: [],
  HVATemplateRisks: [],
  HVAData: undefined,
  isFetchHVAsLoaded: true,
  isFetchHVADataLoaded: true,
  isUpsertHVALoaded: true,
  isDeleteHVALoaded: true,
  isFetchHVATemplateCategoriesLoaded: true,
  isUpsertHVATemplateCategoryLoaded: true,
  isDeleteHVATemplateCategoryLoaded: true,
  isFetchHVATemplateRisksLoaded: true,
  isUpsertHVATemplateRiskLoaded: true,
  isDeleteHVATemplateRiskLoaded: true,
  isFetchHVATemplateColumnsLoaded: true,
  HVATemplateColumns: [],
  isCloneHVALoaded: true,
  alerts: [],
  isFetchAlertsLoaded: true,
  isUpdateAlertLoaded: true,
  isFetchCoreComponentsLoaded: true,
  coreComponents: undefined,
  upsertedCoreComponent: undefined,
  isUpsertCoreComponentLoaded: true,
  isLinkCoreComponentLoaded: true,
  isUnlinkCoreComponentLoaded: true,
  isDeleteCoreComponentLoaded: true,
  currentAOR: undefined,
  thresholdsForAOR: {},
  isFetchThresholdsForAORLoaded: true,
  isUpsertThresholdLoaded: true,
  isUploadFileLoaded: true,
  isSignedUrlLoaded: true,
  anomalies: [AnomalySetup],
  anomaliesFetched: false,
  currentAnomaly: AnomalySetup,
  isFetchAnomaliesLoaded: true,
  isArchiveAnomalyLoaded: true,
  isAnomalyToIncidentLoaded: true,
  anomalyForIncident: undefined,
  isUpdateIncidentMemberWorkLogLoaded: true,
  actions: undefined,
  isActionLoaded: true,
  isAddActionLoaded: true,
  isGroupImagesLoaded: true,
  isBriefingLoaded: true,
  allInitialIncidentReport: [],
  isAllInitialIncidentReportLoaded: true,
  isAddInitialIncidentReportLoaded: true,
  hasInitialIncidentReportError: false,
  allInitialIncidentReportLogs: [],
  isAllInitialIncidentReportLogsLoaded: true,
  isAddInitialIncidentReportCommentLoaded: true,
  allInitialIncidentReportRecipientList: [],
  allEpicWorkspaces: [],
  allInitialIncidentReportChecklist: [],
  isAllInitialIncidentReportChecklistLoaded: true,
  isAddInitialIncidentReportChecklistLoaded: true,
  hasAddInitialIncidentReportChecklistError: false,

  allInitialIncidentReportTeamChecklist: [],
  isAllInitialIncidentReportTeamChecklistLoaded: true,
  isAddInitialIncidentReportTeamChecklistLoaded: true,
  hasAddInitialIncidentReportTeamChecklistError: false,

  singleInitialIncidentReportLoaded: true,
  singleInitialIncidentReport: undefined,

  locationEntity: [],
  locationSubEntity: [],
  locationEnterprise: [],
  locationAsset: [],
  groupLocation: null,
  allBranches: [],
  allDivisions: [],
  allWorkAssignments: [],
  planningWorksheet: {},
  medical_plan: undefined,
  medicalAidStationsList: [],
  transportationsList: [],
  hospitalsList: [],
  prepared_by_signatures: [],
  reviewed_by_signatures: [],
  ics202ObjectivesData: undefined,
  iapCoverSheet: undefined,
  safetyAndHealthForms: [],
  communication: null,
  resource_request: undefined,
  all_resource_request: [],
  meetings: [],
  diceActivityLogs: [],
  allAor: [],
  alluserWorkspaces: [],
  teamsTemplates: [],
  tenantId: undefined,
  allUsersWithSubscription: [],

  // osint data
  twitterData: [],
  twitterDataEnriched: [],
  OSINTAskResponses: {},
  isTwitterDataLoaded: true,
  isTwitterDataEnrichedLoaded: true,
  isOSINTAskLoaded: true,

  // task management
  taskList: [],
  totalTaskCount: 0,
  isTaskListDataLoaded: true,
  saveTaskSuccess: false,
  isSaveTaskLoaded: true,
  addTaskCommentSuccess: true,
  isTaskCommentLoaded: true,
  allTaskComments: [],
  isTaskLogLoaded: true,
  allTaskLogs: [],
  taskUnits: [],

  // api error handle data
  apiError: null,
  teamStatusFromIncident: null,
  checkTeamCounter: { incidentId: '', count: 0, timeout: null },
  isGenerateTeamsSiteLoaded: true,
  isUploadFileToTeamsLoaded: true,
  isStripeCreateSessionLoaded: true,
  isCreateStripeSubscriptionLoaded: true,
  isUpdateStripeSubscriptionStatus: true,
  isFetchAssignableUsersByGroupLoaded: true,
  assignableUsersByGroup: [],
  isAssignStripeSeatsLoaded: true,
  isUnassignStripeSeatsLoaded: true,
  isFetchUserSubscriptionInfoLoaded: true,
  userSubscriptionInfo: {},

  chatDGPTSessions: [],
  geolocationsRisk: {},
  isFetchGeolocationRiskLoaded: true,
  isFetchGeolocationRiskResultLoaded: true,
  isExportAsFormLoaded: true,
  exportAsFormResult: null,
  isEnvironmentVariableLoaded: false,

  // Object containing KV pairs where K is mei_type and V is the most recent set of suggestions for that type
  // K|V: mei_type:[]
  isBulkAddObjectivesLoaded: true,
  isBulkAddActionsLoaded: true,
  isBulkAddResourcesLoaded: true,
  incidentActions: [],
  isFetchIncidentActionsLoaded: true,
  isUpdateIncidentActionSelectionLoaded: true,
};

const appReducer = (state = initialState, action) => {
  const queryParams = getQueryParams(document.location.search);

  let newCurrentIncident;
  let newCurrentDashboard;
  let isNewDataset = false;
  let newDataset;
  let newMapDataPaneData;

  switch (action.type) {
    case UPDATE_INCIDENT_STATUS:
      return {
        ...state,
        currentIncident: {
          ...state.currentIncident,
          team_status: action.payload,
        },
      };
    case LOAD_DATASET_REQUEST:
      return {
        ...state,
        isLoaded: false,
      };
    case LOAD_DATASET_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        datasets: action.payload.reduce((acc, item) => {
          return [...acc, { ...item }];
        }, []),
      };
    case LOAD_DATASET_ERROR:
      return {
        ...state,
        isLoaded: true,
        datasets: [],
      };
    case UPDATE_LASSO_FILTER_SUCCESS:
      return {
        ...state,
        lassoFilteredFeatures: action.payload,
      };
    case UPDATE_LASSO_FILTER_ERROR:
      return {
        ...state,
      };
    case UPDATE_MAP_LOCATION:
      return {
        ...state,
        mapLocation: action.payload,
      };
    case UPDATE_MAP_LOCATION_ERROR:
      return {
        ...state,
      };
    case DASHBOARD_LAYOUT_UPDATE:
      return {
        ...state,
        dashboardLayout: action.payload,
      };
    case DASHBOARD_LAYOUT_UPDATE_ERROR:
      return {
        ...state,
      };
    case ZOOM_MAP_TO_DATA:
      return {
        ...state,
        zoomMapTarget: action.payload,
      };
    case ZOOM_MAP_TO_DATA_ERROR:
      return {
        ...state,
      };
    case CREATE_OR_GET_USER_REQUEST:
      return {
        ...state,
        isUserLoaded: false,
      };
    case CREATE_OR_GET_USER_SUCCESS:
      return {
        ...state,
        isUserLoaded: true,
        user: action.payload,
        apiError: null,
      };
    case CREATE_OR_GET_USER_ERROR:
      return {
        ...state,
        isUserLoaded: true,
        apiError: action.payload,
      };
    case GET_USER_BY_ID_REQUEST:
      return {
        ...state,
        isUserLoaded: false,
      };
    case GET_USER_BY_ID_SUCCESS:
      return {
        ...state,
        isUserLoaded: true,
        focusUser: action.payload,
      };
    case GET_USER_BY_ID_ERROR:
      return {
        ...state,
        isUserLoaded: true,
      };
    case FETCH_GROUPS_FOR_USER_REQUEST:
      return {
        ...state,
        areGroupsForUserLoaded: false,
      };
    case FETCH_GROUPS_FOR_USER_SUCCESS:
      return {
        ...state,
        areGroupsForUserLoaded: true,
        groupsForUser: action.payload,
      };
    case FETCH_GROUPS_FOR_USER_ERROR:
      return {
        ...state,
        areGroupsForUserLoaded: true,
        groupsForUser: [],
      };
    case FETCH_GROUPS_REQUEST:
      return {
        ...state,
        areGroupsLoaded: false,
      };
    case FETCH_GROUPS_SUCCESS: {
      const {
        user: { currently_selected },
      } = state;
      const { group_guid: persistedGroupId } = currently_selected || {};

      let currentlySelectedGroupId;
      if (!!queryParams.group) {
        currentlySelectedGroupId = queryParams.group;
      } else if (!!action.payload.group_id) {
        currentlySelectedGroupId = action.payload.group_id;
      } else {
        currentlySelectedGroupId = persistedGroupId;
      }

      let currentlySelectedGroup = action.payload.groups.find((group) => {
        // if there is an existing selected group use that. otherwise take the first one.
        return currentlySelectedGroupId
          ? group.group_guid === currentlySelectedGroupId
          : true;
      });

      const activeFlags =
        currentlySelectedGroup &&
        currentlySelectedGroup.default_settings &&
        currentlySelectedGroup.default_settings.active_flags;

      let featureFlags = state.featureFlags;

      if (activeFlags) {
        // remove duplicates...
        featureFlags = Array.from(new Set([...featureFlags, ...activeFlags]));
      }

      return {
        ...state,
        areGroupsLoaded: true,
        groups: action.payload.groups,
        ...(currentlySelectedGroup ? { currentlySelectedGroup } : {}),
        featureFlags,
      };
    }
    case FETCH_GROUPS_ERROR:
      return {
        ...state,
        areGroupsLoaded: true,
        groups: [],
      };
    case CREATE_GROUP_REQUEST:
      return {
        ...state,
        iscreateGroupLoaded: false,
      };
    case CREATE_GROUP_SUCCESS: {
      let addedGroup = action.groups.find(
        (group) => group.group_name === action.group_name
      );
      const newCurrentlySelectedGroup = (!!addedGroup && addedGroup) || {};
      return {
        ...state,
        groups: action.groups,
        currentIncident: null,
        currentDashboard: null,
        currentlySelectedGroup: newCurrentlySelectedGroup,
        iscreateGroupLoaded: true,
      };
    }
    case CREATE_GROUP_ERROR:
      return {
        ...state,
        iscreateGroupLoaded: true,
      };
    case CLOSE_GROUP_REQUEST:
      return {
        ...state,
        isCloseGroupLoaded: false,
      };
    case CLOSE_GROUP_SUCCESS:
      return {
        ...state,
        isCloseGroupLoaded: true,
      };
    case CLOSE_GROUP_ERROR:
      return {
        ...state,
        isCloseGroupLoaded: true,
      };
    case JOIN_GROUP_REQUEST:
      return {
        ...state,
        isJoinGroupLoaded: false,
      };
    case JOIN_GROUP_SUCCESS:
      return {
        ...state,
        isJoinGroupLoaded: true,
      };
    case JOIN_GROUP_ERROR:
      return {
        ...state,
        isJoinGroupLoaded: true,
      };
    case FETCH_INCIDENTS_REQUEST:
      return {
        ...state,
        areIncidentsLoaded: false,
      };
    case FETCH_INCIDENTS_SUCCESS:
      if (state.currentIncident) {
        newCurrentIncident = action.payload.incidents.find(
          (incident) => incident.id === state.currentIncident.id
        );

        // missmatch between app and server
        if (!newCurrentIncident) {
          newCurrentIncident = action.payload.incidents[0];
        }
      } else {
        // the persisted version should take precedence
        newCurrentIncident = action.payload.incidents.find(
          (incident) =>
            incident.id === (newCurrentIncident && newCurrentIncident.id)
        );

        if (action.payload.incidents.length && !newCurrentIncident) {
          if (!!action.payload.queryIncidentId) {
            newCurrentIncident = action.payload.incidents.find(
              (incident) => incident.id === action.payload.queryIncidentId
            );
          } else {
            newCurrentIncident = action.payload.incidents.filter(
              (incident) =>
                !incident.archived &&
                !!state.user &&
                !!state.user.currently_selected &&
                !!state.user.currently_selected.group_guid &&
                incident.group_guid === state.user.currently_selected.group_guid
            )[0];

            if (!newCurrentIncident) {
              newCurrentIncident = action.payload.incidents.filter(
                (incident) =>
                  incident.group_guid ===
                  state.currentlySelectedGroup.group_guid
              )[0];
            }
          }
        }

        if (
          (newCurrentIncident && newCurrentIncident.group_guid) !==
          (state.currentlySelectedGroup &&
            state.currentlySelectedGroup.group_guid)
        ) {
          newCurrentIncident = undefined;
        }
      }

      return {
        ...state,
        areIncidentsLoaded: true,
        incidents: action.payload.incidents.filter(
          (incident) => !incident.archived
        ),
        currentIncident: newCurrentIncident,
      };
    case FETCH_INCIDENTS_ERROR:
      return {
        ...state,
        areIncidentsLoaded: true,
        incidents: [],
      };
    case UPDATE_ROLE_REQUEST:
      return {
        ...state,
        isRoleUpdateLoaded: false,
      };
    case UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        isRoleUpdateLoaded: true,
      };
    case UPDATE_ROLE_ERROR:
      return {
        ...state,
        isRoleUpdateLoaded: true,
      };
    case FETCH_GROUP_SOLICITATIONS_REQUEST:
      return {
        ...state,
        isfetchGroupsolicitationsLoaded: false,
      };
    case FETCH_GROUP_SOLICITATIONS_SUCCESS:
      return {
        ...state,
        isfetchGroupsolicitationsLoaded: true,
        solicitations: action.payload,
      };
    case FETCH_GROUP_SOLICITATIONS_ERROR:
      return {
        ...state,
        isfetchGroupsolicitationsLoaded: true,
        solicitations: [],
      };
    case FETCH_INCIDENTS_FOR_GROUP_REQUEST:
      return {
        ...state,
        isfetchIncidentsForGroupLoaded: false,
      };
    case FETCH_INCIDENTS_FOR_GROUP_SUCCESS:
      return {
        ...state,
        isfetchIncidentsForGroupLoaded: true,
        incidentsForGroup: action.payload,
      };
    case FETCH_INCIDENTS_FOR_GROUP_ERROR:
      return {
        ...state,
        isfetchIncidentsForGroupLoaded: true,
        incidentsForGroup: [],
      };
    case FETCH_USER_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        isFetchUserSubscriptionsLoaded: false,
      };
    case FETCH_USER_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        isFetchUserSubscriptionsLoaded: true,
        subscriptions: action.payload,
        apiError: null,
      };
    case FETCH_USER_SUBSCRIPTIONS_ERROR:
      return {
        ...state,
        isFetchUserSubscriptionsLoaded: true,
        subscriptions: [],
        apiError: action.payload,
      };
    case FETCH_SEAT_ASSIGNMENTS_REQUEST:
      return {
        ...state,
        isFetchSeatAssignmentsLoaded: false,
      };
    case FETCH_SEAT_ASSIGNMENTS_SUCCESS:
      return {
        ...state,
        isFetchSeatAssignmentsLoaded: true,
        seatAssignments: action.payload,
      };
    case FETCH_SEAT_ASSIGNMENTS_ERROR:
      return {
        ...state,
        isFetchSeatAssignmentsLoaded: true,
        seatAssignments: [],
      };
    case ASSIGN_SEAT_REQUEST:
      return {
        ...state,
        isSeatAssignmentLoaded: false,
      };
    case ASSIGN_SEAT_SUCCESS:
      return {
        ...state,
        isSeatAssignmentLoaded: true,
      };
    case ASSIGN_SEAT_ERROR:
      return {
        ...state,
        isSeatAssignmentLoaded: true,
      };
    case UNASSIGN_SEAT_REQUEST:
      return {
        ...state,
        isSeatUnassignmentLoaded: false,
      };
    case UNASSIGN_SEAT_SUCCESS:
      return {
        ...state,
        isSeatUnassignmentLoaded: true,
      };
    case UNASSIGN_SEAT_ERROR:
      return {
        ...state,
        isSeatUnassignmentLoaded: true,
      };
    case FETCH_HVAS_REQUEST:
      return {
        ...state,
        isFetchHVAsLoaded: false,
      };
    case FETCH_HVAS_SUCCESS:
      return {
        ...state,
        isFetchHVAsLoaded: true,
        HVAs: action.payload,
      };
    case FETCH_HVAS_ERROR:
      return {
        ...state,
        isFetchHVAsLoaded: true,
      };
    case FETCH_HVA_DATA_REQUEST:
      return {
        ...state,
        isFetchHVADataLoaded: false,
      };
    case FETCH_HVA_DATA_SUCCESS:
      return {
        ...state,
        isFetchHVADataLoaded: true,
        HVAData: action.payload,
      };
    case FETCH_HVA_DATA_ERROR:
      return {
        ...state,
        isFetchHVADataLoaded: true,
      };
    case UPSERT_HVA_REQUEST:
      return {
        ...state,
        isUpsertHVALoaded: false,
      };
    case UPSERT_HVA_SUCCESS:
      return {
        ...state,
        isUpsertHVALoaded: true,
      };
    case UPSERT_HVA_ERROR:
      return {
        ...state,
        isUpsertHVALoaded: true,
      };
    case DELETE_HVA_REQUEST:
      return {
        ...state,
        isDeleteHVADataLoaded: false,
      };
    case DELETE_HVA_SUCCESS:
      return {
        ...state,
        isDeleteHVADataLoaded: true,
      };
    case DELETE_HVA_ERROR:
      return {
        ...state,
        isDeleteHVADataLoaded: true,
      };
    case FETCH_HVA_TEMPLATE_CATEGORIES_REQUEST:
      return {
        ...state,
        isFetchHVATemplateCategoriesLoaded: false,
      };
    case FETCH_HVA_TEMPLATE_CATEGORIES_SUCCESS:
      return {
        ...state,
        HVATemplateCategories: action.payload,
        isFetchHVATemplateCategoriesLoaded: true,
      };
    case FETCH_HVA_TEMPLATE_CATEGORIES_ERROR:
      return {
        ...state,
        isFetchHVATemplateCategoriesLoaded: true,
      };
    case UPSERT_HVA_TEMPLATE_CATEGORY_REQUEST:
      return {
        ...state,
        isUpsertHVATemplateCategoryLoaded: false,
      };
    case UPSERT_HVA_TEMPLATE_CATEGORY_SUCCESS:
      return {
        ...state,
        isUpsertHVATemplateCategoryLoaded: true,
      };
    case UPSERT_HVA_TEMPLATE_CATEGORY_ERROR:
      return {
        ...state,
        isUpsertHVATemplateCategoryLoaded: true,
      };
    case DELETE_HVA_TEMPLATE_CATEGORY_REQUEST:
      return {
        ...state,
        isDeleteHVATemplateCategoryLoaded: false,
      };
    case DELETE_HVA_TEMPLATE_CATEGORY_SUCCESS:
      return {
        ...state,
        isDeleteHVATemplateCategoryLoaded: true,
      };
    case DELETE_HVA_TEMPLATE_CATEGORY_ERROR:
      return {
        ...state,
        isDeleteHVATemplateCategoryLoaded: true,
      };

    case FETCH_HVA_TEMPLATE_RISKS_REQUEST:
      return {
        ...state,
        isFetchHVATemplateRisksLoaded: false,
      };
    case FETCH_HVA_TEMPLATE_RISKS_SUCCESS:
      return {
        ...state,
        isFetchHVATemplateRisksLoaded: true,
        HVATemplateRisks: action.payload,
      };
    case FETCH_HVA_TEMPLATE_RISKS_ERROR:
      return {
        ...state,
        isFetchHVATemplateRisksLoaded: true,
      };
    case UPSERT_HVA_TEMPLATE_RISK_REQUEST:
      return {
        ...state,
        isUpsertHVATemplateRiskLoaded: false,
      };
    case UPSERT_HVA_TEMPLATE_RISK_SUCCESS:
      return {
        ...state,
        isUpsertHVATemplateRiskLoaded: true,
      };
    case UPSERT_HVA_TEMPLATE_RISK_ERROR:
      return {
        ...state,
        isUpsertHVATemplateRiskLoaded: true,
      };
    case DELETE_HVA_TEMPLATE_RISK_REQUEST:
      return {
        ...state,
        isDeleteHVATemplateRiskLoaded: false,
      };
    case DELETE_HVA_TEMPLATE_RISK_SUCCESS:
      return {
        ...state,
        isDeleteHVATemplateRiskLoaded: true,
      };
    case DELETE_HVA_TEMPLATE_RISK_ERROR:
      return {
        ...state,
        isDeleteHVATemplateRiskLoaded: true,
      };
    case FETCH_HVA_TEMPLATE_COLUMNS_REQUEST:
      return {
        ...state,
        isFetchHVATemplateColumnsLoaded: false,
      };
    case FETCH_HVA_TEMPLATE_COLUMNS_SUCCESS:
      return {
        ...state,
        HVATemplateColumns: action.payload,
        isFetchHVATemplateColumnsLoaded: true,
      };
    case FETCH_HVA_TEMPLATE_COLUMNS_ERROR:
      return {
        ...state,
        isFetchHVATemplateColumnsLoaded: true,
      };
    case CLONE_HVA_REQUEST:
      return {
        ...state,
        isCloneHVALoaded: false,
      };
    case CLONE_HVA_SUCCESS:
      return {
        ...state,
        isCloneHVALoaded: true,
      };
    case CLONE_HVA_ERROR:
      return {
        ...state,
        isCloneHVALoaded: true,
      };
    case FETCH_ALERTS_REQUEST:
      return {
        ...state,
        isFetchAlertsLoaded: false,
      };
    case FETCH_ALERTS_SUCCESS:
      return {
        ...state,
        alerts: action.payload,
        isFetchAlertsLoaded: true,
      };
    case FETCH_ALERTS_ERROR:
      return {
        ...state,
        isFetchAlertsLoaded: true,
      };
    case FETCH_GEOLOCATION_RISK_ORG_REQUEST:
      return {
        ...state,
        isFetchGeolocationRiskLoaded: false,
      };
    case FETCH_GEOLOCATION_RISK_ORG_SUCCESS: {
      let newgeolocationsRisk = { ...state.geolocationsRisk };
      newgeolocationsRisk[action.payload.aor.id] = action.payload.data;
      return {
        ...state,
        geolocationsRisk: newgeolocationsRisk,
        isFetchGeolocationRiskLoaded: true,
      };
    }
    case FETCH_GEOLOCATION_RISK_ORG_ERROR:
      return {
        ...state,
        isFetchGeolocationRiskLoaded: true,
      };
    case FETCH_GEOLOCATION_RISK_ORG_RESULT_REQUEST:
      return {
        ...state,
        isFetchGeolocationRiskResultLoaded: false,
      };
    case FETCH_GEOLOCATION_RISK_ORG_RESULT_SUCCESS: {
      let newgeolocationsRisk = { ...state.geolocationsRisk };
      newgeolocationsRisk[action.payload.aor.id] = action.payload.data;
      return {
        ...state,
        geolocationsRisk: newgeolocationsRisk,
        isFetchGeolocationRiskResultLoaded: true,
      };
    }
    case FETCH_GEOLOCATION_RISK_ORG_RESULT_ERROR:
      return {
        ...state,
        isFetchGeolocationRiskResultLoaded: true,
      };
    case INVITE_GROUP_MEMBER_REQUEST:
      return {
        ...state,
        isInviteGroupMemberLoaded: false,
        inviteGroupMemberSuccess: false,
        inviteGroupMemberError: false,
      };
    case INVITE_GROUP_MEMBER_SUCCESS:
      return {
        ...state,
        isInviteGroupMemberLoaded: true,
        inviteGroupMemberSuccess: true,
        inviteGroupMemberError: false,
      };
    case INVITE_GROUP_MEMBER_ERROR:
      return {
        ...state,
        isInviteGroupMemberLoaded: true,
        inviteGroupMemberSuccess: false,
        inviteGroupMemberError: action.payload,
      };
    case UPDATE_CURRENT_INCIDENT_REQUEST:
      return {
        ...state,
        isUpdateCurrentIncidentLoaded: false,
      };
    case UPDATE_CURRENT_INCIDENT_SUCCESS:
      return {
        ...state,
        isUpdateCurrentIncidentLoaded: true,
        mapBoundingBox: undefined,
        dashboards: undefined,
        dashboardData: undefined,
        filteredDashboardDatasets: [],
        currentDashboard: undefined,
        updatedDashboard: undefined,
        updatedWidget: undefined,
        lassoCoordinates: {},
        currentIncident: action.payload,
        mapDataPaneData: [],
        mapDataPaneVisible: false,
        planAISuggestions: {},
        // sitreps: [],
      };
    case UPDATE_CURRENT_INCIDENT_ERROR:
      return {
        ...state,
        isUpdateCurrentIncidentLoaded: true,
      };
    case CREATE_WIDGET_REQUEST:
      return {
        ...state,
        isCreateWidgetLoaded: false,
        createdWidget: undefined,
      };
    case CREATE_WIDGET_SUCCESS:
      return {
        ...state,
        isCreateWidgetLoaded: true,
        dashboardData: [...state.dashboardData, action.payload],
        createdWidget: action.payload,
      };
    case CREATE_WIDGET_ERROR:
      return {
        ...state,
        isCreateWidgetLoaded: true,
        createdWidget: undefined,
      };
    case CREATE_INCIDENT_REQUEST:
      return {
        ...state,
        mapBoundingBox: undefined,
        isCreateIncidentLoaded: false,
      };
    case CREATE_INCIDENT_SUCCESS:
      return {
        ...state,
        isCreateIncidentLoaded: true,
        lassoCoordinates: {},
        currentIncident: action.payload,
      };
    case CREATE_INCIDENT_ERROR:
      return {
        ...state,
        isCreateIncidentLoaded: true,
      };
    case UPDATE_INCIDENT_REQUEST:
      return {
        ...state,
        mapBoundingBox: undefined,
        isUpdateIncidentLoaded: false,
      };
    case UPDATE_INCIDENT_SUCCESS:
      return {
        ...state,
        isUpdateIncidentLoaded: true,
        incidents:
          (action.payload.archived === 1 &&
            state.incidents.filter((i) => i.id !== action.payload.id)) ||
          state.incidents.map(
            (i) => (i.id === action.payload.id && action.payload) || i
          ),
        currentIncident:
          action.payload.archived === 1
            ? state.incidents.filter(
                (i) =>
                  i.id !== action.payload.id &&
                  i.group_guid === state.currentlySelectedGroup.group_guid
              )[0]
            : action.payload,
      };
    case UPDATE_INCIDENT_ERROR:
      return {
        ...state,
        isUpdateIncidentLoaded: true,
      };
    case CREATE_DASHBOARD_REQUEST:
      return {
        ...state,
        mapBoundingBox: undefined,
        isCreateDashboardLoaded: false,
        updatedWidget: undefined,
      };
    case CREATE_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboards: [...state.dashboards, action.payload],
        currentDashboard: action.payload,
        isCreateDashboardLoaded: true,
        lassoCoordinates: {},
      };
    case CREATE_DASHBOARD_ERROR:
      return {
        ...state,
        isCreateDashboardLoaded: true,
      };
    case UPDATE_DASHBOARD_REQUEST:
      return {
        ...state,
        mapBoundingBox: undefined,
        isUpdateDashboardLoaded: false,
        updatedWidget: undefined,
      };
    case UPDATE_DASHBOARD_SUCCESS: {
      let newlayoutsdashdata =
        action.payload.updatedDashboard.layout &&
        state.dashboardData.map((w) => {
          const newLayoutForWidget = action.payload.updatedDashboard.layout.find(
            (l) => l.i === w.id
          );

          return {
            ...w,
            layouts: {
              ...w.layouts,
              lg:
                (!!w.layouts.lg && {
                  ...w.layouts.lg,
                  x: !!newLayoutForWidget
                    ? newLayoutForWidget.x
                    : w.layouts.lg.x,
                  y: !!newLayoutForWidget
                    ? newLayoutForWidget.y
                    : w.layouts.lg.y,
                  w: !!newLayoutForWidget
                    ? newLayoutForWidget.w
                    : w.layouts.lg.w,
                  h: !!newLayoutForWidget
                    ? newLayoutForWidget.h
                    : w.layouts.lg.h,
                }) ||
                {},
            },
          };
        });
      return {
        ...state,
        isUpdateDashboardLoaded: true,
        updatedDashboard: action.payload.dashboard,
        currentDashboard: action.payload.dashboard,
        dashboards: [
          ...state.dashboards.filter(
            (dashboard) => dashboard.id !== action.payload.dashboard.id
          ),
          action.payload.dashboard,
        ],
        ...(newlayoutsdashdata ? { dashboardData: newlayoutsdashdata } : {}),
      };
    }
    case UPDATE_DASHBOARD_ERROR:
      return {
        ...state,
        isUpdateDashboardLoaded: true,
      };
    case FETCH_DASHBOARDS_REQUEST:
      return {
        ...state,
        isFetchDashboardsLoaded: false,
        updatedWidget: undefined,
      };
    case FETCH_DASHBOARDS_SUCCESS:
      newCurrentDashboard = state.currentDashboard;
      if (
        action.payload.length &&
        (!newCurrentDashboard ||
          !action.payload.find(
            (dashboard) =>
              dashboard.id ===
              (state.currentDashboard && state.currentDashboard.id)
          ))
      ) {
        if (!!queryParams.layout) {
          newCurrentDashboard = action.payload.find(
            (dashboard) => dashboard.id === queryParams.layout
          );
        } else {
          newCurrentDashboard = action.payload[0];
        }
      }
      return {
        ...state,
        isFetchDashboardsLoaded: true,
        dashboards: action.payload,
        currentDashboard: newCurrentDashboard,
      };
    case FETCH_DASHBOARDS_ERROR:
      return {
        ...state,
        isFetchDashboardsLoaded: true,
      };
    case TOGGLE_WIDGET_REQUEST:
      return {
        ...state,
        isToggleWidgetLoaded: false,
        updatedWidget: undefined,
        dashboardData: [
          ...state.dashboardData.filter(
            (widget) => widget.id !== action.payload.id
          ),
          action.payload,
        ],
      };
    case TOGGLE_WIDGET_SUCCESS:
      return {
        ...state,
        isToggleWidgetLoaded: true,
        dashboardData: [
          ...state.dashboardData.filter(
            (widget) => widget.id !== action.payload.id
          ),
          action.payload,
        ],
        updatedWidget: action.payload,
      };
    case TOGGLE_WIDGET_ERROR:
      return {
        ...state,
        isToggleWidgetLoaded: true,
      };
    case UPDATE_WIDGET_REQUEST:
      return {
        ...state,
        isUpdateWidgetLoaded: false,
        updatedWidget: undefined,
      };
    case UPDATE_WIDGET_SUCCESS:
      return {
        ...state,
        isUpdateWidgetLoaded: true,
        dashboardData: [
          ...state.dashboardData.filter(
            (widget) => widget.id !== action.payload.updatedWidgetResponse.id
          ),
          action.payload.updatedWidgetResponse,
        ],
        updatedWidget: action.payload.updatedWidgetResponse,
        alreadyReloadedMapWidget: undefined,
      };
    case UPDATE_WIDGET_ERROR:
      return {
        ...state,
        isUpdateWidgetLoaded: true,
      };
    case REMOVE_WIDGET_REQUEST:
      return {
        ...state,
        isRemoveWidgetLoaded: false,
        updatedWidget: undefined,
        dashboardData: [
          ...state.dashboardData.filter(
            (widget) => widget.id !== action.payload.id
          ),
        ],
      };
    case REMOVE_WIDGET_SUCCESS:
      return {
        ...state,
        isRemoveWidgetLoaded: true,
        removedWidget: action.payload,
      };
    case REMOVE_WIDGET_ERROR:
      return {
        ...state,
        isRemoveWidgetLoaded: true,
      };
    case FETCH_DASHBOARD_DATA_REQUEST:
      return {
        ...state,
        isFetchDashboardDataLoaded: false,
        updatedWidget: undefined,
        crossFilter: [],
        filteredDashboardDatasets: [],
      };
    case FETCH_DASHBOARD_DATA_SUCCESS: {
      let newDashboardData;

      if (
        !!state.dasboardData &&
        state.currentDashboard?.id !== action.payload.dashboard.id
      ) {
        // this is probably due to an async call that no longer applies to the front-end
        // so we'll throw out the new data which is out-of-sync with the
        newDashboardData = state.dashboardData;
      } else {
        newDashboardData = action.payload.dashboardData;
      }

      return {
        ...state,
        isFetchDashboardDataLoaded: true,
        dashboardData: newDashboardData,
        filteredDashboardDatasets: [],
      };
    }
    case FETCH_DASHBOARD_DATA_ERROR:
      return {
        ...state,
        isFetchDashboardDataLoaded: true,
        dashboardData: [],
        filteredDashboardDatasets: [],
      };
    case UPDATE_CURRENT_DASHBOARD_REQUEST:
      return {
        ...state,
        isUpdateCurrentDashboardLoaded: false,
      };
    case UPDATE_CURRENT_DASHBOARD_SUCCESS:
      return {
        ...state,
        isUpdateCurrentDashboardLoaded: true,
        dashboardData: undefined,
        filteredDashboardDatasets: [],
        currentDashboard: action.payload,
        updatedDashboard: undefined,
        lassoCoordinates: {},
        mapDataPaneData: [],
        mapDataPaneVisible: false,
      };
    case UPDATE_CURRENT_DASHBOARD_ERROR:
      return {
        ...state,
        isUpdateCurrentDashboardLoaded: true,
      };
    case UPDATE_MAP_WIDGET_ALREADY_RELOADED_REQUEST:
      return {
        ...state,
        isUpdateMapWidgetAlreadyReloadedLoaded: false,
      };
    case UPDATE_MAP_WIDGET_ALREADY_RELOADED_SUCCESS:
      return {
        ...state,
        isUpdateMapWidgetAlreadyReloadedLoaded: true,
        alreadyReloadedMapWidget: action.payload,
        updatedWidget: undefined,
      };
    case UPDATE_MAP_WIDGET_ALREADY_RELOADED_ERROR:
      return {
        ...state,
        isUpdateMapWidgetAlreadyReloadedLoaded: true,
      };
    case UPDATE_INCIDENT_GROUP_REQUEST:
      return {
        ...state,
        isUpdateIncidentGroupLoaded: false,
      };
    case UPDATE_INCIDENT_GROUP_SUCCESS: {
      const currentIncidents = state.incidents;

      const adjustedIncidents = currentIncidents.map((incident) => {
        if (incident.id === action.payload.incident_id) {
          return {
            ...incident,
            group_guid: action.payload.group_id,
          };
        } else {
          return incident;
        }
      });
      return {
        ...state,
        isUpdateIncidentGroupLoaded: true,
        updateIncidentGroupMessage: action.payload,
        currentIncident: {
          ...state.currentIncident,
          group_guid: action.payload.group_id,
        },
        incidents: adjustedIncidents,
      };
    }
    case UPDATE_INCIDENT_GROUP_ERROR:
      return {
        ...state,
        isUpdateIncidentGroupLoaded: true,
      };
    case FETCH_INCIDENT_ASSETS_REQUEST:
      return {
        ...state,
        isFetchIncidentAssetsLoaded: false,
      };
    case FETCH_INCIDENT_ASSETS_SUCCESS:
      return {
        ...state,
        isFetchIncidentAssetsLoaded: true,
        incidentAssets: action.payload,
      };
    case FETCH_INCIDENT_ASSETS_ERROR:
      return {
        ...state,
        isFetchIncidentAssetsLoaded: true,
        incidentAssets: [],
      };
    case FETCH_INCIDENT_ASSET_LOGS_REQUEST:
      return {
        ...state,
        isFetchIncidentAssetLogsLoaded: false,
      };
    case FETCH_INCIDENT_ASSET_LOGS_SUCCESS:
      return {
        ...state,
        isFetchIncidentAssetLogsLoaded: true,
        incidentAssetLogs: action.payload,
      };
    case FETCH_INCIDENT_ASSET_LOGS_ERROR:
      return {
        ...state,
        isFetchIncidentAssetLogsLoaded: true,
        incidentAssetLogs: [],
      };
    case CREATE_INCIDENT_ASSET_REQUEST:
      return {
        ...state,
        isCreateIncidentAssetLoaded: false,
      };
    case CREATE_INCIDENT_ASSET_SUCCESS:
      return {
        ...state,
        isCreateIncidentAssetLoaded: true,
        creatrdIncidentAsset: action.payload,
      };
    case CREATE_INCIDENT_ASSET_ERROR:
      return {
        ...state,
        isCreateIncidentAssetLoaded: true,
      };
    case UPDATE_INCIDENT_ASSET_REQUEST:
      return {
        ...state,
        isUpdateIncidentAssetLoaded: false,
      };
    case UPDATE_INCIDENT_ASSET_SUCCESS:
      return {
        ...state,
        isUpdateIncidentAssetLoaded: true,
        updatedIncidentAsset: action.payload,
      };
    case UPDATE_INCIDENT_ASSET_ERROR:
      return {
        ...state,
        isUpdateIncidentAssetLoaded: true,
      };
    case FETCH_ODDS_METADATASETS_REQUEST:
      return {
        ...state,
        isFetchODDSMetadataLoaded: false,
      };
    case FETCH_ODDS_METADATASETS_SUCCESS:
      return {
        ...state,
        isFetchODDSMetadataLoaded: true,
        odds_metadatasets: action.payload,
      };
    case FETCH_ODDS_METADATASETS_ERROR:
      return {
        ...state,
        isFetchODDSMetadataLoaded: true,
        odds_metadatasets: [],
      };
    case FETCH_ODDS_DATASET_REQUEST:
      return {
        ...state,
        isFetchODDSDatasetLoaded: false,
      };
    case FETCH_ODDS_DATASET_SUCCESS:
      return {
        ...state,
        isFetchODDSDatasetLoaded: true,
        oddsDatasetFetched: action.payload,
      };
    case FETCH_ODDS_DATASET_ERROR:
      return {
        ...state,
        isFetchODDSDatasetLoaded: true,
      };
    case FETCH_ALL_ROLES_IN_GROUP_REQUEST:
      return {
        ...state,
        isFetchAllRolesInGroupLoaded: false,
      };
    case FETCH_ALL_ROLES_IN_GROUP_SUCCESS:
      return {
        ...state,
        isFetchAllRolesInGroupLoaded: true,
        allRolesInGroup: action.payload,
      };
    case FETCH_ALL_ROLES_IN_GROUP_ERROR:
      return {
        ...state,
        isFetchAllRolesInGroupLoaded: true,
        allRolesInGroup: [],
      };
    case FETCH_ALL_ROLE_ASSIGNMENTS_IN_GROUP_REQUEST:
      return {
        ...state,
        isFetchAllRoleAssignmentsInGroupLoaded: false,
      };
    case FETCH_ALL_ROLE_ASSIGNMENTS_IN_GROUP_SUCCESS:
      return {
        ...state,
        isFetchAllRoleAssignmentsInGroupLoaded: true,
        allRoleAssignmentsInGroup: action.payload,
      };
    case FETCH_ALL_ROLE_ASSIGNMENTS_IN_GROUP_ERROR:
      return {
        ...state,
        isFetchAllRoleAssignmentsInGroupLoaded: true,
        allRoleAssignmentsInGroup: [],
      };
    case FETCH_ROLE_ACTIONS_LOG_REQUEST:
      return {
        ...state,
        isFetchRoleActionsLogLoaded: false,
      };
    case FETCH_ROLE_ACTIONS_LOG_SUCCESS:
      return {
        ...state,
        isFetchRoleActionsLogLoaded: true,
        roleActionsLog: action.payload,
      };
    case FETCH_ROLE_ACTIONS_LOG_ERROR:
      return {
        ...state,
        isFetchRoleActionsLogLoaded: true,
        roleActionsLog: [],
      };
    case CREATE_ROLE_REQUEST:
      return {
        ...state,
        isCreateRoleLoaded: false,
      };
    case CREATE_ROLE_SUCCESS:
      return {
        ...state,
        isCreateRoleLoaded: true,
        createdRole: action.payload,
      };
    case CREATE_ROLE_ERROR:
      return {
        ...state,
        isCreateRoleLoaded: true,
      };
    case EDIT_ROLE_REQUEST:
      return {
        ...state,
        isEditRoleLoaded: false,
      };
    case EDIT_ROLE_SUCCESS:
      return {
        ...state,
        isEditRoleLoaded: true,
        editedRole: action.payload,
      };
    case EDIT_ROLE_ERROR:
      return {
        ...state,
        isEditRoleLoaded: true,
      };
    case DELETE_ROLE_REQUEST:
      return {
        ...state,
        isDeleteRoleLoaded: false,
      };
    case DELETE_ROLE_SUCCESS:
      return {
        ...state,
        isDeleteRoleLoaded: true,
        deletedRole: action.payload,
      };
    case DELETE_ROLE_ERROR:
      return {
        ...state,
        isDeleteRoleLoaded: true,
      };
    case ASSIGN_ROLE_REQUEST:
      return {
        ...state,
        isAssignRoleLoaded: false,
      };
    case ASSIGN_ROLE_SUCCESS:
      return {
        ...state,
        isAssignRoleLoaded: true,
        assignedRole: action.payload,
      };
    case ASSIGN_ROLE_ERROR:
      return {
        ...state,
        isAssignRoleLoaded: true,
      };
    case UNASSIGN_ROLE_REQUEST:
      return {
        ...state,
        isUnassignRoleLoaded: false,
      };
    case UNASSIGN_ROLE_SUCCESS:
      return {
        ...state,
        isUnassignRoleLoaded: true,
        unassignedRole: action.payload,
      };
    case UNASSIGN_ROLE_ERROR:
      return {
        ...state,
        isUnassignRoleLoaded: true,
      };
    case FETCH_ROSTERS_FOR_GROUPS_REQUEST:
      return {
        ...state,
        isFetchRostersForGroupsLoaded: false,
      };
    case FETCH_ROSTERS_FOR_GROUPS_SUCCESS:
      return {
        ...state,
        isFetchRostersForGroupsLoaded: true,
        rostersForGroups: action.payload,
      };
    case FETCH_ROSTERS_FOR_GROUPS_ERROR:
      return {
        ...state,
        isFetchRostersForGroupsLoaded: true,
        rostersForGroups: [],
      };
    case FETCH_ALL_PERMISSIONS_REQUEST:
      return {
        ...state,
        isFetchAllPermissionsLoaded: false,
      };
    case FETCH_ALL_PERMISSIONS_SUCCESS:
      return {
        ...state,
        isFetchAllPermissionsLoaded: true,
        allPermissions: action.payload,
      };
    case FETCH_ALL_PERMISSIONS_ERROR:
      return {
        ...state,
        isFetchAllPermissionsLoaded: true,
        allPermissions: [],
      };
    case FETCH_ALL_PERMISSIONS_IN_ROLE_REQUEST:
      return {
        ...state,
        isFetchAllPermissionsInRoleLoaded: false,
      };
    case FETCH_ALL_PERMISSIONS_IN_ROLE_SUCCESS:
      return {
        ...state,
        isFetchAllPermissionsInRoleLoaded: true,
        allPermissionsInRole: action.payload,
      };
    case FETCH_ALL_PERMISSIONS_IN_ROLE_ERROR:
      return {
        ...state,
        isFetchAllPermissionsInRoleLoaded: true,
        allPermissionsInRole: [],
      };
    case VALIDATE_RBAC_PERMISSION_FOR_ACTION_REQUEST:
      return {
        ...state,
        isValidateRBACPermissionForActionLoaded: false,
      };
    case VALIDATE_RBAC_PERMISSION_FOR_ACTION_SUCCESS:
      return {
        ...state,
        isValidateRBACPermissionForActionLoaded: true,
        validateRBACPermissionForActionResult: action.payload,
      };
    case VALIDATE_RBAC_PERMISSION_FOR_ACTION_ERROR:
      return {
        ...state,
        isValidateRBACPermissionForActionLoaded: true,
        validateRBACPermissionForActionResult: {},
      };
    case VALIDATE_USER_IN_GROUPS_REQUEST:
      return {
        ...state,
        isValidateUserInGroupsLoaded: false,
      };
    case VALIDATE_USER_IN_GROUPS_SUCCESS:
      return {
        ...state,
        isValidateUserInGroupsLoaded: true,
        validateUserInGroupsResult: action.payload,
      };
    case VALIDATE_USER_IN_GROUPS_ERROR:
      return {
        ...state,
        isValidateUserInGroupsLoaded: true,
        validateUserInGroupsResult: false,
      };
    case VALIDATE_USER_IN_GROUP_WITH_ROLE_REQUEST:
      return {
        ...state,
        isValidateUserInGroupWithRoleLoaded: false,
      };
    case VALIDATE_USER_IN_GROUP_WITH_ROLE_SUCCESS:
      return {
        ...state,
        isValidateUserInGroupWithRoleLoaded: true,
        validateUserInGroupWithRoleResult: action.payload,
      };
    case VALIDATE_USER_IN_GROUP_WITH_ROLE_ERROR:
      return {
        ...state,
        isValidateUserInGroupWithRoleLoaded: true,
        validateUserInGroupWithRoleResult: false,
      };
    case VALIDATE_USER_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        isValidateUserSubscriptionLoaded: false,
      };
    case VALIDATE_USER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isValidateUserSubscriptionLoaded: true,
        validateUserSubscriptionResult: action.payload,
      };
    case VALIDATE_USER_SUBSCRIPTION_ERROR:
      return {
        ...state,
        isValidateUserSubscriptionLoaded: true,
        validateUserSubscriptionResult: false,
      };
    case VALIDATE_USER_IN_INCIDENT_REQUEST:
      return {
        ...state,
        isValidateUserInIncidentLoaded: false,
      };
    case VALIDATE_USER_IN_INCIDENT_SUCCESS:
      return {
        ...state,
        isValidateUserInIncidentLoaded: true,
        validateUserInIncidentResult: action.payload,
      };
    case VALIDATE_USER_IN_INCIDENT_ERROR:
      return {
        ...state,
        isValidateUserInIncidentLoaded: true,
        validateUserInIncidentResult: false,
      };
    case VALIDATE_USER_INCIDENT_ADMIN_REQUEST:
      return {
        ...state,
        isValidateUserIncidentAdminLoaded: false,
      };
    case VALIDATE_USER_INCIDENT_ADMIN_SUCCESS:
      return {
        ...state,
        isValidateUserIncidentAdminLoaded: true,
        validateUserIncidentAdminResult: action.payload,
      };
    case VALIDATE_USER_INCIDENT_ADMIN_ERROR:
      return {
        ...state,
        isValidateUserIncidentAdminLoaded: true,
        validateUserIncidentAdminResult: false,
      };
    case VALIDATE_USER_SUBSCRIPTION_ACTION_BY_MINIMUM_PLAN_REQUIRED_REQUEST:
      return {
        ...state,
        isvalidateUserSubscriptionActionByMinimumPlanIdRequiredLoaded: false,
      };
    case VALIDATE_USER_SUBSCRIPTION_ACTION_BY_MINIMUM_PLAN_REQUIRED_SUCCESS:
      return {
        ...state,
        isvalidateUserSubscriptionActionByMinimumPlanIdRequiredLoaded: true,
        validateUserSubscriptionActionByMinimumPlanIdRequiredResult:
          action.payload,
      };
    case VALIDATE_USER_SUBSCRIPTION_ACTION_BY_MINIMUM_PLAN_REQUIRED_ERROR:
      return {
        ...state,
        isvalidateUserSubscriptionActionByMinimumPlanIdRequiredLoaded: true,
        validateUserSubscriptionActionByMinimumPlanIdRequiredResult: false,
      };
    case GET_ROSTER_FOR_INCIDENT_REQUEST:
      return {
        ...state,
        isGetRosterForIncidentLoaded: false,
      };
    case GET_ROSTER_FOR_INCIDENT_SUCCESS:
      return {
        ...state,
        isGetRosterForIncidentLoaded: true,
        rosterForIncident: action.payload,
      };
    case GET_ROSTER_FOR_INCIDENT_ERROR:
      return {
        ...state,
        isGetRosterForIncidentLoaded: true,
        rosterForIncident: [],
      };
    case ADD_USER_TO_INCIDENT_BY_ADMIN_REQUEST:
      return {
        ...state,
        isAddUserToIncidentByAdminLoaded: false,
      };
    case ADD_NON_USER_TO_INCIDENT_BY_ADMIN_REQUEST:
      return {
        ...state,
        isAddNonUserToIncidentByAdminLoaded: false,
      };
    case ADD_USER_TO_INCIDENT_BY_ADMIN_SUCCESS:
      return {
        ...state,
        isAddUserToIncidentByAdminLoaded: true,
        userAddedToIncident: action.payload,
      };
    case ADD_NON_USER_TO_INCIDENT_SUCCESS:
      return {
        ...state,
        isAddNonUserToIncidentByAdminLoaded: true,
      };
    case DEACTIVATE_USER_TO_INCIDENT_REQUEST:
      return {
        ...state,
        isUserDeactivateToIncidentLoaded: false,
      };
    case DEACTIVATE_USER_TO_INCIDENT_SUCCESS:
      if (action.payload) {
        let incidentMember = [...state.rosterForIncident];
        const index = incidentMember.findIndex(
          (ele) => ele.incident_member_id === action.payload
        );
        incidentMember[index] = { ...incidentMember[index], active: 0 };
        return {
          ...state,
          isUserDeactivateToIncidentLoaded: true,
          rosterForIncident: incidentMember,
        };
      }
      return {
        ...state,
        isUserDeactivateToIncidentLoaded: true,
      };
    case ADD_USER_TO_INCIDENT_SUCCESS:
      return {
        ...state,
        isAddUserToIncidentByAdminLoaded: true,
      };
    case ADD_USER_TO_INCIDENT_BY_ADMIN_ERROR:
      return {
        ...state,
        isAddUserToIncidentByAdminLoaded: true,
      };
    case EDIT_INCIDENT_MEMBER_BY_ADMIN_REQUEST:
      return {
        ...state,
        isEditIncidentMemberByAdminLoaded: false,
      };
    case EDIT_INCIDENT_MEMBER_BY_ADMIN_SUCCESS:
      return {
        ...state,
        isEditIncidentMemberByAdminLoaded: true,
        editedIncidentMember: action.payload,
        currentIncident: {
          ...state.currentIncident,
          seat_name: action.payload.seat_name,
        },
      };
    case EDIT_INCIDENT_MEMBER_BY_ADMIN_ERROR:
      return {
        ...state,
        isEditIncidentMemberByAdminLoaded: true,
      };
    case REMOVE_USER_FROM_INCIDENT_BY_ADMIN_REQUEST:
      return {
        ...state,
        isRemoveUserFromIncidentByAdminLoaded: false,
      };
    case REMOVE_USER_FROM_INCIDENT_BY_ADMIN_SUCCESS:
      return {
        ...state,
        isRemoveUserFromIncidentByAdminLoaded: true,
        removedIncidentMember: action.payload,
      };
    case REMOVE_USER_FROM_INCIDENT_BY_ADMIN_ERROR:
      return {
        ...state,
        isRemoveUserFromIncidentByAdminLoaded: true,
      };
    case REMOVE_SELF_FROM_INCIDENT_REQUEST:
      return {
        ...state,
        isRemoveSelfFromIncidentLoaded: false,
      };
    case REMOVE_SELF_FROM_INCIDENT_SUCCESS:
      return {
        ...state,
        isRemoveSelfFromIncidentLoaded: true,
        removedSelfIncidentMember: action.payload,
        currentIncident: undefined,
      };
    case REMOVE_SELF_FROM_INCIDENT_ERROR:
      return {
        ...state,
        isRemoveSelfFromIncidentLoaded: true,
        currentIncident: undefined,
      };
    case RESPOND_TO_REQUEST_TO_JOIN_INCIDENT_REQUEST:
      return {
        ...state,
        isRespondToRequestToJoinIncidentLoaded: false,
      };
    case RESPOND_TO_REQUEST_TO_JOIN_INCIDENT_SUCCESS:
      return {
        ...state,
        isRespondToRequestToJoinIncidentLoaded: true,
        responseToJoinIncident: action.payload,
      };
    case RESPOND_TO_REQUEST_TO_JOIN_INCIDENT_ERROR:
      return {
        ...state,
        isRespondToRequestToJoinIncidentLoaded: true,
      };
    case GET_DASHBOARD_REQUEST:
      return {
        ...state,
        isGetDashboardLoaded: false,
        getDashboardError: undefined,
      };
    case GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        isGetDashboardLoaded: true,
        getDashboardError: undefined,
      };
    case GET_DASHBOARD_ERROR:
      return {
        ...state,
        isGetDashboardLoaded: true,
        getDashboardError: action.error,
      };
    case FETCH_PODS_REQUEST:
      return {
        ...state,
        isFetchPodsLoaded: false,
      };
    case FETCH_PODS_SUCCESS:
      return {
        ...state,
        isFetchPodsLoaded: true,
        fetchedPods: action.payload,
      };
    case FETCH_PODS_ERROR:
      return {
        ...state,
        isFetchPodsLoaded: true,
        fetchedPods: [],
      };
    case CREATE_POD_REQUEST:
      return {
        ...state,
        isCreatePodLoaded: false,
      };
    case CREATE_POD_SUCCESS:
      return {
        ...state,
        isCreatePodLoaded: true,
        createdPod: action.payload,
      };
    case CREATE_POD_ERROR:
      return {
        ...state,
        isCreatePodLoaded: true,
      };
    case EDIT_POD_REQUEST:
      return {
        ...state,
        isEditPodLoaded: false,
      };
    case EDIT_POD_SUCCESS:
      return {
        ...state,
        isEditPodLoaded: true,
        editedPod: action.payload,
      };
    case EDIT_POD_ERROR:
      return {
        ...state,
        isEditPodLoaded: true,
      };
    case DELETE_POD_REQUEST:
      return {
        ...state,
        isDeletePodLoaded: false,
      };
    case DELETE_POD_SUCCESS:
      return {
        ...state,
        isDeletePodLoaded: true,
        deletedPod: action.payload,
      };
    case DELETE_POD_ERROR:
      return {
        ...state,
        isDeletePodLoaded: true,
      };
    case FETCH_POD_NTKL_REQUEST:
      return {
        ...state,
        isFetchPodNTKLLoaded: false,
      };
    case FETCH_POD_NTKL_SUCCESS:
      return {
        ...state,
        isFetchPodNTKLLoaded: true,
        podNTKL: action.payload,
      };
    case FETCH_POD_NTKL_ERROR:
      return {
        ...state,
        isFetchPodNTKLLoaded: true,
        podNTKL: [],
      };
    case ADD_TO_POD_NTKL_REQUEST:
      return {
        ...state,
        isAddToPodNTKLLoaded: false,
      };
    case ADD_TO_POD_NTKL_SUCCESS:
      return {
        ...state,
        isAddToPodNTKLLoaded: true,
        NTKLAddition: action.payload,
      };
    case ADD_TO_POD_NTKL_ERROR:
      return {
        ...state,
        isAddToPodNTKLLoaded: true,
      };
    case REMOVE_FROM_POD_NTKL_REQUEST:
      return {
        ...state,
        isRemoveFromPodNTKLLoaded: false,
      };
    case REMOVE_FROM_POD_NTKL_SUCCESS:
      return {
        ...state,
        isRemoveFromPodNTKLLoaded: true,
        NTKLRemoval: action.payload,
      };
    case REMOVE_FROM_POD_NTKL_ERROR:
      return {
        ...state,
        isRemoveFromPodNTKLLoaded: true,
      };
    case FETCH_PODS_DATASETS_REQUEST:
      return {
        ...state,
        isFetchPodsDatasetsLoaded: false,
      };
    case FETCH_PODS_DATASETS_SUCCESS:
      return {
        ...state,
        isFetchPodsDatasetsLoaded: true,
        fetchedPodsDatasets: action.payload,
      };
    case FETCH_PODS_DATASETS_ERROR:
      return {
        ...state,
        isFetchPodsDatasetsLoaded: true,
        fetchedPodsDatasets: [],
      };
    case CREATE_POD_DATA_REQUEST:
      return {
        ...state,
        isCreatePodDataLoaded: false,
      };
    case CREATE_POD_DATA_SUCCESS:
      return {
        ...state,
        isCreatePodDataLoaded: true,
        createdPodData: action.payload,
      };
    case CREATE_POD_DATA_ERROR:
      return {
        ...state,
        isCreatePodDataLoaded: true,
      };
    case EDIT_POD_DATA_REQUEST:
      return {
        ...state,
        isEditPodDataLoaded: false,
      };
    case EDIT_POD_DATA_SUCCESS:
      return {
        ...state,
        isEditPodDataLoaded: true,
        editedPodData: action.payload,
      };
    case EDIT_POD_DATA_ERROR:
      return {
        ...state,
        isEditPodDataLoaded: true,
      };
    case DELETE_POD_DATA_REQUEST:
      return {
        ...state,
        isDeletePodDataLoaded: false,
      };
    case DELETE_POD_DATA_SUCCESS:
      return {
        ...state,
        isDeletePodDataLoaded: true,
        deletedPodData: action.payload,
      };
    case DELETE_POD_DATA_ERROR:
      return {
        ...state,
        isDeletePodDataLoaded: true,
      };
    case FETCH_POD_DATASET_FOR_DASHBOARD_REQUEST:
      return {
        ...state,
        isFetchPodDatasetForDashboardLoaded: false,
      };
    case FETCH_POD_DATASET_FOR_DASHBOARD_SUCCESS:
      return {
        ...state,
        isFetchPodDatasetForDashboardLoaded: true,
        podDatasetForDashboard: action.payload,
      };
    case FETCH_POD_DATASET_FOR_DASHBOARD_ERROR:
      return {
        ...state,
        isFetchPodDatasetForDashboardLoaded: true,
      };
    case VALIDATE_USER_IN_POD_SECURITY_POLICY_REQUEST:
      return {
        ...state,
        isValidateUserInPodSecurityPolicyLoaded: false,
      };
    case VALIDATE_USER_IN_POD_SECURITY_POLICY_SUCCESS: {
      let newValidateUserInPodSecurityPolicyResult =
        state.validateUserInPodSecurityPolicyResult;
      newValidateUserInPodSecurityPolicyResult[action.payload.pod_id] =
        action.payload.actions;
      return {
        ...state,
        isValidateUserInPodSecurityPolicyLoaded: true,
        validateUserInPodSecurityPolicyResult: newValidateUserInPodSecurityPolicyResult,
      };
    }
    case VALIDATE_USER_IN_POD_SECURITY_POLICY_ERROR:
      return {
        ...state,
        isValidateUserInPodSecurityPolicyLoaded: true,
      };
    case REMOVE_USER_FROM_GROUP_REQUEST:
      return {
        ...state,
        isRemoveUserFromGroupLoaded: false,
      };
    case REMOVE_USER_FROM_GROUP_SUCCESS:
      return {
        ...state,
        isRemoveUserFromGroupLoaded: true,
        removedUserFromGroup: action.payload,
      };
    case REMOVE_USER_FROM_GROUP_ERROR:
      return {
        ...state,
        isRemoveUserFromGroupLoaded: true,
      };
    case EDIT_GROUP_REQUEST:
      return {
        ...state,
        isEditGroupLoaded: false,
      };
    case EDIT_GROUP_SUCCESS:
      return {
        ...state,
        isEditGroupLoaded: true,
        editedGroup: action.payload,
        currentlySelectedGroup: {
          ...state.currentlySelectedGroup,
          ...action.payload,
        },
        groups: [
          ...state.groups.map((group) => {
            if (group.group_guid === action.payload.group_guid) {
              const updatedGroup = {
                ...group,
                ...action.payload,
              };

              return updatedGroup;
            }

            return group;
          }),
        ],
      };
    case EDIT_GROUP_ERROR:
      return {
        ...state,
        isEditGroupLoaded: true,
      };
    case DELETE_GROUP_REQUEST:
      return {
        ...state,
        isDeleteGroupLoaded: false,
      };
    case DELETE_GROUP_SUCCESS:
      return {
        ...state,
        isDeleteGroupLoaded: true,
        deletedGroup: action.payload,
      };
    case DELETE_GROUP_ERROR:
      return {
        ...state,
        isDeleteGroupLoaded: true,
      };
    case FETCH_ROLES_BY_INCIDENT_REQUEST:
      return {
        ...state,
      };
    case FETCH_ROLES_BY_INCIDENT_SUCCESS:
      return {
        ...state,
        incidentRoles: action.payload,
      };
    case FETCH_ROLES_BY_INCIDENT_ERROR:
      return {
        ...state,
        incidentRoles: [],
      };
    case FETCH_LIVESTREAM_DATA_JSON_REQUEST:
      return {
        ...state,
        isFetchLivestreamDataJsonLoaded: false,
      };
    case FETCH_LIVESTREAM_DATA_JSON_SUCCESS:
      return {
        ...state,
        isFetchLivestreamDataJsonLoaded: true,
        livestreamDataJson: action.payload,
      };
    case FETCH_LIVESTREAM_DATA_JSON_ERROR:
      return {
        ...state,
        isFetchLivestreamDataJsonLoaded: true,
        livestreamDataJson: {},
      };
    case FETCH_ACTIVITIES_REQUEST:
      return {
        ...state,
        isFetchActivitiesLoaded: false,
      };
    case FETCH_ACTIVITIES_SUCCESS:
      return {
        ...state,
        isFetchActivitiesLoaded: true,
        activities: action.payload,
      };
    case FETCH_ACTIVITIES_ERROR:
      return {
        ...state,
        isFetchActivitiesLoaded: true,
        activities: [],
      };
    case FETCH_ACTIVITY_RECORDS_REQUEST:
      return {
        ...state,
        isFetchActivityRecordsLoaded: false,
      };
    case FETCH_ACTIVITY_RECORDS_SUCCESS:
      return {
        ...state,
        isFetchActivityRecordsLoaded: true,
        activityRecords: action.payload,
      };
    case FETCH_ACTIVITY_RECORDS_ERROR:
      return {
        ...state,
        isFetchActivityRecordsLoaded: true,
        activityRecords: [],
      };
    case CREATE_ACTIVITY_REQUEST:
      return {
        ...state,
        isCreateActivityLoaded: false,
      };
    case CREATE_ACTIVITY_SUCCESS:
      return {
        ...state,
        isCreateActivityLoaded: true,
        createdActivity: action.payload,
      };
    case CREATE_ACTIVITY_ERROR:
      return {
        ...state,
        isCreateActivityLoaded: true,
      };
    case EDIT_ACTIVITY_REQUEST:
      return {
        ...state,
        isEditActivityLoaded: false,
      };
    case EDIT_ACTIVITY_SUCCESS:
      return {
        ...state,
        isEditActivityLoaded: true,
        editedActivity: action.payload,
      };
    case EDIT_ACTIVITY_ERROR:
      return {
        ...state,
        isEditActivityLoaded: true,
      };
    case ASSIGN_ACTIVITY_RECORD_REQUEST:
    case FETCH_ASSIGNMENTS_FOR_ACTIVITY_REQUEST:
      return {
        ...state,
        isFetchAssignmentsForActivityLoaded: false,
      };
    case FETCH_ASSIGNMENTS_FOR_ACTIVITY_ERROR:
    case ASSIGN_ACTIVITY_RECORD_ERROR:
    case NOTIFY_ASSIGNEE_ERROR:
    case RESPOND_TO_TASK_ASSIGNMENT_ERROR:
    case COMPLETE_TASK_ASSIGNMENT_ERROR:
      return {
        ...state,
        isFetchAssignmentsForActivityLoaded: true,
      };
    case ASSIGN_ACTIVITY_RECORD_SUCCESS:
    case FETCH_ASSIGNMENTS_FOR_ACTIVITY_SUCCESS:
    case NOTIFY_ASSIGNEE_SUCCESS:
    case RESPOND_TO_TASK_ASSIGNMENT_SUCCESS:
    case COMPLETE_TASK_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        isFetchAssignmentsForActivityLoaded: true,
        activityRecordAssignments: action.payload,
      };
    case DELETE_ACTIVITY_REQUEST:
      return {
        ...state,
        isDeleteActivityLoaded: false,
      };
    case DELETE_ACTIVITY_SUCCESS:
      return {
        ...state,
        isDeleteActivityLoaded: true,
        deletedActivity: action.payload,
      };
    case DELETE_ACTIVITY_ERROR:
      return {
        ...state,
        isDeleteActivityLoaded: true,
      };
    case UPLOAD_ACTIVITY_RECORDS_REQUEST:
      return {
        ...state,
        isUploadActivityRecordsLoaded: false,
      };
    case UPLOAD_ACTIVITY_RECORDS_SUCCESS:
      return {
        ...state,
        isUploadActivityRecordsLoaded: true,
        uploadedActivityRecords: action.payload,
      };
    case UPLOAD_ACTIVITY_RECORDS_ERROR:
      return {
        ...state,
        isUploadActivityRecordsLoaded: true,
      };
    case EDIT_ACTIVITY_RECORD_REQUEST:
      return {
        ...state,
        isEditActivityRecordLoaded: false,
      };
    case EDIT_ACTIVITY_RECORD_SUCCESS:
      return {
        ...state,
        isEditActivityRecordLoaded: true,
        editedActivityRecord: action.payload,
      };
    case EDIT_ACTIVITY_RECORD_ERROR:
      return {
        ...state,
        isEditActivityRecordLoaded: true,
      };
    case DELETE_ACTIVITY_RECORD_REQUEST:
      return {
        ...state,
        isDeleteActivityRecordLoaded: false,
      };
    case DELETE_ACTIVITY_RECORD_SUCCESS:
      return {
        ...state,
        isDeleteActivityRecordLoaded: true,
        deletedActivityRecord: action.payload,
      };
    case DELETE_ACTIVITY_RECORD_ERROR:
      return {
        ...state,
        isDeleteActivityRecordLoaded: true,
      };
    case EXPORT_MARKDOWN_TO_DOCUMENT_REQUEST:
      return {
        ...state,
        isExportMarkdownToDocumentLoaded: false,
      };
    case EXPORT_MARKDOWN_TO_DOCUMENT_SUCCESS:
      return {
        ...state,
        isExportMarkdownToDocumentLoaded: true,
        exportedMarkdownToDocument: action.payload,
      };
    case EXPORT_MARKDOWN_TO_DOCUMENT_ERROR:
      return {
        ...state,
        isExportMarkdownToDocumentLoaded: true,
      };
    case FETCH_DATASET_METADATA_FOR_WIDGET_REQUEST:
      return {
        ...state,
        isFetchDatasetMetadataForWidgetLoaded: false,
      };
    case FETCH_DATASET_METADATA_FOR_WIDGET_SUCCESS: {
      let newDatasetMetadataForWidgets = [...state.datasetMetadataForWidgets];
      if (
        !newDatasetMetadataForWidgets.find(
          (metadata) => metadata.widget.id === action.payload.widget.id
        )
      ) {
        newDatasetMetadataForWidgets = [
          ...state.datasetMetadataForWidgets,
          action.payload,
        ];
      } else {
        newDatasetMetadataForWidgets = [
          ...state.datasetMetadataForWidgets.filter(
            (metadata) => metadata.widget.id !== action.payload.widget.id
          ),
          action.payload,
        ];
      }
      return {
        ...state,
        isFetchDatasetMetadataForWidgetLoaded: true,
        datasetMetadataForWidgets: newDatasetMetadataForWidgets,
      };
    }
    case FETCH_DATASET_METADATA_FOR_WIDGET_ERROR:
      return {
        ...state,
        isFetchDatasetMetadataForWidgetLoaded: true,
      };
    case UPDATE_CURRENTLY_SELECTED_GROUP_REQUEST:
      return {
        ...state,
        isUpdateCurrentlySelectedGroupLoaded: false,
        inviteGroupMemberError: undefined,
      };
    case UPDATE_CURRENTLY_SELECTED_GROUP_SUCCESS: {
      const currentlySelectedGroup = action.payload;

      const activeFlags =
        currentlySelectedGroup &&
        currentlySelectedGroup.default_settings &&
        currentlySelectedGroup.default_settings.active_flags;

      let featureFlags = state.featureFlags;

      if (activeFlags) {
        const allFlags = [...featureFlags, ...activeFlags];

        featureFlags = Array.from(new Set(allFlags));
      }

      return {
        ...state,
        isUpdateCurrentlySelectedGroupLoaded: true,
        currentlySelectedGroup,
        mapDataPaneData: [],
        mapDataPaneVisible: false,
        fetchedPods: undefined,
        editedPodData: undefined,
        createdPodData: undefined,
        fetchedPodsDatasets: undefined,
        podNTKL: undefined,
        editedPod: undefined,
        createdPod: undefined,
        validateUserInPodSecurityPolicyResult: {},
        podDatasetForDashboard: undefined,
        deletedPodData: undefined,
        featureFlags,
      };
    }
    case UPDATE_CURRENTLY_SELECTED_GROUP_ERROR:
      return {
        ...state,
        isUpdateCurrentlySelectedGroupLoaded: true,
      };
    case SEND_NOTIFICATION_REQUEST:
      return {
        ...state,
        isSendNotificationLoaded: false,
      };
    case SEND_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isSendNotificationLoaded: true,
        sendNotificationResult: action.payload,
      };
    case SEND_NOTIFICATION_ERROR:
      return {
        ...state,
        isSendNotificationLoaded: true,
      };
    case FETCH_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        isFetchNotificationsLoaded: false,
      };
    case FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        isFetchNotificationsLoaded: true,
        notifications: action.payload,
      };
    case FETCH_NOTIFICATIONS_ERROR:
      return {
        ...state,
        isFetchNotificationsLoaded: true,
        notifications: [],
      };
    case FETCH_ALL_NOTIFICATION_LOG_SUMMARIES_REQUEST:
      return {
        ...state,
        isFetchNotificationsLoaded: false,
      };
    case FETCH_ALL_NOTIFICATION_LOG_SUMMARIES_SUCCESS:
      return {
        ...state,
        isFetchNotificationsLoaded: true,
        allNotificationLogSummaries: action.payload,
      };
    case FETCH_ALL_NOTIFICATION_LOG_SUMMARIES_ERROR:
      return {
        ...state,
        isFetchNotificationsLoaded: true,
        allNotificationLogSummaries: [],
      };

    case UPSERT_NOTIFICATION_REQUEST:
      return {
        ...state,
        isUpsertNotificationLoaded: false,
      };
    case UPSERT_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isUpsertNotificationLoaded: true,
        upsertedNotification: action.payload,
      };
    case UPSERT_NOTIFICATION_ERROR:
      return {
        ...state,
        isUpsertNotificationLoaded: true,
      };
    case DELETE_NOTIFICATION_REQUEST:
      return {
        ...state,
        isDeleteNotificationLoaded: false,
      };
    case DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isDeleteNotificationLoaded: true,
        deletedNotification: action.payload,
      };
    case DELETE_NOTIFICATION_ERROR:
      return {
        ...state,
        isDeleteNotificationLoaded: true,
      };
    case UPDATE_LASSO_COORDINATES_REQUEST:
      return {
        ...state,
        isUpdateLassoCoordinatesLoaded: false,
      };
    case UPDATE_LASSO_COORDINATES_SUCCESS:
      return {
        ...state,
        isUpdateLassoCoordinatesLoaded: true,
        filteredDashboardDatasets: [],
        lassoCoordinates: action.payload,
      };
    case UPDATE_LASSO_COORDINATES_ERROR:
      return {
        ...state,
        isUpdateLassoCoordinatesLoaded: true,
      };
    case TOGGLE_ASSETS_ON_MAP_REQUEST:
      return {
        ...state,
      };
    case TOGGLE_ASSETS_ON_MAP_SUCCESS:
      return {
        ...state,
        assetsOnMap: action.payload,
      };
    case TOGGLE_ASSETS_ON_MAP_ERROR:
      return {
        ...state,
      };
    case TOGGLE_ASSET_ON_MAP_REQUEST:
      return {
        ...state,
      };
    case TOGGLE_ASSET_ON_MAP_SUCCESS: {
      const hiddenAssetsOnMap = state.hiddenAssetsOnMap || {};

      const existingAssetContext =
        hiddenAssetsOnMap && hiddenAssetsOnMap[action.payload.context];

      if (existingAssetContext) {
        let visibleIds;

        if (existingAssetContext.length) {
          const { filtered, toAdd } = existingAssetContext.reduce(
            (acc, curr) => {
              const index = acc.toAdd.findIndex((v) => v === curr);
              index === -1
                ? acc.filtered.push(curr)
                : acc.toAdd.splice(index, 1);
              return acc;
            },
            { filtered: [], toAdd: [action.payload.id] }
          );

          visibleIds = [...filtered, ...toAdd];
        } else {
          visibleIds = [action.payload.id];
        }

        hiddenAssetsOnMap[action.payload.context] = visibleIds;
      } else {
        hiddenAssetsOnMap[action.payload.context] = [action.payload.id];
      }

      return {
        ...state,
        hiddenAssetsOnMap: { ...hiddenAssetsOnMap },
      };
    }
    case TOGGLE_ASSET_ON_MAP_ERROR:
      return {
        ...state,
      };
    case CLEAR_DATASET_METADATA_FOR_WIDGET_REQUEST:
      return {
        ...state,
        isClearDatasetMetadataForWidgetLoaded: false,
      };
    case CLEAR_DATASET_METADATA_FOR_WIDGET_SUCCESS:
      return {
        ...state,
        isClearDatasetMetadataForWidgetLoaded: true,
        datasetMetadataForWidgets: action.payload,
      };
    case CLEAR_DATASET_METADATA_FOR_WIDGET_ERROR:
      return {
        ...state,
        isClearDatasetMetadataForWidgetLoaded: true,
      };

    case FETCH_DATASET_GROUPS_REQUEST:
      return {
        ...state,
        isFetchDatasetGroupsLoaded: false,
      };
    case FETCH_DATASET_GROUPS_SUCCESS:
      return {
        ...state,
        datasetGroups: action.payload,
        isFetchDatasetGroupsLoaded: true,
      };
    case FETCH_DATASET_GROUPS_ERROR:
      return {
        ...state,
        isFetchDatasetGroupsLoaded: true,
      };
    case FETCH_ASSET_RISKS_REQUEST:
      return {
        ...state,
        isFetchAssetRisksLoaded: false,
      };
    case FETCH_ASSET_RISKS_SUCCESS:
    case FETCH_PRECOMPILED_ASSET_RISKS_SUCCESS:
      return {
        ...state,
        assetRisks: action.payload,
        isFetchAssetRisksLoaded: true,
      };
    case GET_SELECTED_DATASET_GROUPS_REQUEST:
      return {
        ...state,
        isGetSelectedDatasetGroupsLoaded: false,
      };
    case GET_SELECTED_DATASET_GROUPS_SUCCESS:
      return {
        ...state,
        selectedDatasetGroups: action.payload,
        isGetSelectedDatasetGroupsLoaded: true,
      };
    case GET_SELECTED_DATASET_GROUPS_ERROR:
      return {
        ...state,
        isGetSelectedDatasetGroupsLoaded: true,
      };
    case FETCH_DATASET_METADATA_REQUEST:
      return {
        ...state,
        isFetchDatasetMetadataLoaded: false,
        datasetMetadata: undefined,
      };
    case FETCH_DATASET_METADATA_SUCCESS:
      return {
        ...state,
        isFetchDatasetMetadataLoaded: true,
        datasetMetadata: action.payload,
      };
    case FETCH_DATASET_METADATA_ERROR:
      return {
        ...state,
        isFetchDatasetMetadataLoaded: true,
        datasetMetadata: {},
      };
    case CLEAR_DATASET_METADATA_REQUEST:
      return {
        ...state,
        datasetMetadata: undefined,
      };
    case CLEAR_DATASET_METADATA_SUCCESS:
      return {
        ...state,
      };
    case CLEAR_DATASET_METADATA_ERROR:
      return {
        ...state,
      };
    case CHANGE_FEATURE_FLAGS_SUCCESS:
      return {
        ...state,
        featureFlags: action.payload,
      };
    case CHANGE_FEATURE_FLAGS_ERROR:
      return {
        ...state,
      };
    case FETCH_FORM_RECORDS_REQUEST:
      return {
        ...state,
        isFetchFormRecordsLoaded: false,
      };
    case FETCH_FORM_RECORDS_SUCCESS:
      return {
        ...state,
        formRecords: action.payload,
        isFetchFormRecordsLoaded: true,
      };
    case FETCH_FORM_RECORDS_ERROR:
      return {
        ...state,
        formRecords: [],
        isFetchFormRecordsLoaded: true,
      };
    case UPSERT_FORM_RECORD_REQUEST:
      return {
        ...state,
        isUpsertFormRecordLoaded: false,
      };
    case UPSERT_FORM_RECORD_SUCCESS:
      return {
        ...state,
        upsertedFormRecord: action.payload,
        isUpsertFormRecordLoaded: true,
      };
    case UPSERT_FORM_RECORD_ERROR:
      return {
        ...state,
        upsertedFormRecord: undefined,
        isUpsertFormRecordLoaded: true,
      };
    case DELETE_FORM_RECORD_REQUEST:
      return {
        ...state,
        isDeleteFormRecordLoaded: false,
      };
    case DELETE_FORM_RECORD_SUCCESS:
      return {
        ...state,
        deletedFormRecord: action.payload,
        isDeleteFormRecordLoaded: true,
      };
    case DELETE_FORM_RECORD_ERROR:
      return {
        ...state,
        deletedFormRecord: undefined,
        isDeleteFormRecordLoaded: true,
      };
    case EXPORT_FORM_TO_PDF_REQUEST:
      return {
        ...state,
        isExportFormToPDFLoaded: false,
      };
    case EXPORT_FORM_TO_PDF_SUCCESS:
      return {
        ...state,
        exportedFormsToPDF: [
          ...state.exportedFormsToPDF.filter(
            (form) => form.id !== action.payload.id
          ),
          action.payload,
        ],
        isExportFormToPDFLoaded: true,
      };
    case EXPORT_FORM_TO_PDF_ERROR:
      return {
        ...state,
        exportedFormsToPDF: [],
        isExportFormToPDFLoaded: true,
      };
    case SELECT_OPERATIONAL_PERIOD_REQUEST:
      return {
        ...state,
        isSelectOperationalPeriodLoaded: false,
      };
    case SELECT_OPERATIONAL_PERIOD_SUCCESS:
      return {
        ...state,
        selectedOperationalPeriod: action.payload,
        isSelectOperationalPeriodLoaded: true,
      };
    case SELECT_OPERATIONAL_PERIOD_ERROR:
      return {
        ...state,
        selectedOperationalPeriod: undefined,
        isSelectOperationalPeriodLoaded: true,
      };
    case FETCH_SIGNATURES_REQUEST:
      return {
        ...state,
        isFetchSignaturesLoaded: false,
      };
    case FETCH_SIGNATURES_SUCCESS:
      return {
        ...state,
        signatures: action.payload,
        isFetchSignaturesLoaded: true,
      };
    case FETCH_PREPARED_BY_SIGNATURES_SUCCESS:
      return {
        ...state,
        prepared_by_signatures: action.payload,
        isFetchSignaturesLoaded: true,
      };
    case FETCH_REVIEWED_BY_SIGNATURES_SUCCESS:
      return {
        ...state,
        reviewed_by_signatures: action.payload,
        isFetchSignaturesLoaded: true,
      };
    case FETCH_SIGNATURES_ERROR:
      return {
        ...state,
        signatures: [],
        isFetchSignaturesLoaded: true,
      };
    case UPSERT_SIGNATURE_REQUEST:
      return {
        ...state,
        isUpsertSignatureLoaded: false,
      };
    case UPSERT_SIGNATURE_SUCCESS:
      return {
        ...state,
        upsertedSignature: action.payload,
        isUpsertSignatureLoaded: true,
      };
    case UPSERT_SIGNATURE_ERROR:
      return {
        ...state,
        upsertedSignature: undefined,
        isUpsertSignatureLoaded: true,
      };
    case DELETE_SIGNATURE_REQUEST:
      return {
        ...state,
        isDeleteSignatureLoaded: false,
      };
    case DELETE_SIGNATURE_SUCCESS:
      return {
        ...state,
        deletedSignature: action.payload,
        isDeleteSignatureLoaded: true,
      };
    case DELETE_SIGNATURE_ERROR:
      return {
        ...state,
        deletedSignature: undefined,
        isDeleteSignatureLoaded: true,
      };
    case FETCH_WORKFLOW_NODES_REQUEST:
      return {
        ...state,
        isFetchWorkflowNodesLoaded: false,
      };
    case FETCH_WORKFLOW_NODES_SUCCESS:
      return {
        ...state,
        workflowNodes: action.payload,
        isFetchWorkflowNodesLoaded: true,
      };
    case FETCH_WORKFLOW_NODES_ERROR:
      return {
        ...state,
        workflowNodes: [],
        isFetchWorkflowNodesLoaded: true,
      };
    case FETCH_WORKFLOWS_REQUEST:
      return {
        ...state,
        isFetchWorkflowsLoaded: false,
      };
    case FETCH_WORKFLOWS_SUCCESS:
      return {
        ...state,
        workflows: action.payload,
        isFetchWorkflowsLoaded: true,
      };
    case FETCH_WORKFLOWS_ERROR:
      return {
        ...state,
        workflows: [],
        isFetchWorkflowsLoaded: true,
      };
    case UPSERT_WORKFLOW_REQUEST:
      return {
        ...state,
        isUpsertWorkflowLoaded: false,
      };
    case UPSERT_WORKFLOW_SUCCESS:
      return {
        ...state,
        upsertedWorkflow: action.payload,
        isUpsertWorkflowLoaded: true,
      };
    case UPSERT_WORKFLOW_ERROR:
      return {
        ...state,
        upsertedWorkflow: undefined,
        isUpsertWorkflowLoaded: true,
      };
    case DELETE_WORKFLOW_REQUEST:
      return {
        ...state,
        isDeleteWorkflowLoaded: false,
      };
    case DELETE_WORKFLOW_SUCCESS:
      return {
        ...state,
        deletedWorkflow: action.payload,
        isDeleteWorkflowLoaded: true,
      };
    case DELETE_WORKFLOW_ERROR:
      return {
        ...state,
        deletedWorkflow: undefined,
        isDeleteWorkflowLoaded: true,
      };
    case EXECUTE_WORKFLOW_REQUEST:
      return {
        ...state,
        isExecuteWorkflowLoaded: false,
      };
    case EXECUTE_WORKFLOW_SUCCESS:
      return {
        ...state,
        executedWorkflow: action.payload,
        isExecuteWorkflowLoaded: true,
      };
    case EXECUTE_WORKFLOW_ERROR:
      return {
        ...state,
        executedWorkflow: undefined,
        isExecuteWorkflowLoaded: true,
      };
    case REMOVE_WORKFLOW_EDGE_REQUEST:
      return {
        ...state,
      };
    case REMOVE_WORKFLOW_EDGE_SUCCESS:
      return {
        ...state,
        removedWorkflowEdgeId: action.payload,
      };
    case REMOVE_WORKFLOW_EDGE_ERROR:
      return {
        ...state,
        executedWorkflow: undefined,
      };
    case UPDATE_USER_PROFILE_SETTINGS_REQUEST:
      return {
        ...state,
        isUpdateUserProfileSettingsLoaded: false,
      };
    case UPDATE_USER_PROFILE_SETTINGS_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          profile_settings: action.payload,
          user_name: action.payload.userName || state.user.user_name,
        },
        isUpdateUserProfileSettingsLoaded: true,
      };
    case UPDATE_USER_PROFILE_SETTINGS_ERROR:
      return {
        ...state,
        isUpdateUserProfileSettingsLoaded: true,
      };

    case UPDATE_FOCUS_USER_PROFILE_SETTINGS_SUCCESS:
      return {
        ...state,
        focusUser: {
          ...state.focusUser,
          profile_settings: action.payload,
        },
        isUpdateUserProfileSettingsLoaded: true,
      };
    case BULK_USER_IMPORT_REQUEST:
      return {
        ...state,
        isBulkUserImportLoaded: false,
      };
    case BULK_USER_IMPORT_SUCCESS:
      return {
        ...state,
        bulkImportedUsers: action.payload,
        isBulkUserImportLoaded: true,
      };
    case BULK_USER_IMPORT_ERROR:
      return {
        ...state,
        isBulkUserImportLoaded: true,
      };
    case SET_CLONE_DEFAULTS_REQUEST:
      return {
        ...state,
        isSetCloneDefaultsLoaded: false,
      };
    case SET_CLONE_DEFAULTS_SUCCESS:
      return {
        ...state,
        isSetCloneDefaultsLoaded: true,
        cloneDefaults: action.payload,
      };
    case SET_CLONE_DEFAULTS_ERROR:
      return {
        ...state,
        isSetCloneDefaultsLoaded: true,
      };
    case FETCH_CLONE_DEFAULTS_REQUEST:
      return {
        ...state,
        isFetchCloneDefaultsLoaded: false,
      };
    case FETCH_CLONE_DEFAULTS_SUCCESS:
      return {
        ...state,
        isFetchCloneDefaultsLoaded: true,
        cloneDefaults: action.payload,
      };
    case FETCH_CLONE_DEFAULTS_ERROR:
      return {
        ...state,
        isFetchCloneDefaultsLoaded: true,
      };
    case FETCH_NOTIFICATION_LOG_REQUEST:
      return {
        ...state,
        isFetchNotificationLogLoaded: false,
      };
    case FETCH_NOTIFICATION_LOG_SUCCESS:
      return {
        ...state,
        notificationLog: action.payload,
        isFetchNotificationLogLoaded: true,
      };
    case FETCH_NOTIFICATION_LOG_ERROR:
      return {
        ...state,
        isFetchNotificationLogLoaded: true,
      };
    case FETCH_DICE_LOG_REQUEST:
      return {
        ...state,
        isFetchDICELogLoaded: false,
      };
    case FETCH_DICE_LOG_SUCCESS: {
      let existingFetchedDICELog = { ...state.fetchedDICELog };
      existingFetchedDICELog[action.payload.log_name] = action.payload.DICELog;
      return {
        ...state,
        isFetchDICELogLoaded: true,
        fetchedDICELog: existingFetchedDICELog,
      };
    }
    case FETCH_DICE_LOG_ERROR:
      return {
        ...state,
        isFetchDICELogLoaded: true,
      };
    case FETCH_NOTIFICATION_LOG_INSTANCE_REQUEST:
      return {
        ...state,
        isFetchNotificationLogInstanceLoaded: false,
      };
    case FETCH_NOTIFICATION_LOG_INSTANCE_SUCCESS:
      return {
        ...state,
        notificationLogInstance: action.payload,
        isFetchNotificationLogInstanceLoaded: true,
      };
    case FETCH_NOTIFICATION_LOG_INSTANCE_ERROR:
      return {
        ...state,
        isFetchNotificationLogInstanceLoaded: true,
      };
    case UPDATE_CURRENTLY_SELECTED_REQUEST:
      return {
        ...state,
        isUpdateCurrentlySelectedLoaded: false,
      };
    case UPDATE_CURRENTLY_SELECTED_SUCCESS:
      return {
        ...state,
        isUpdateCurrentlySelectedLoaded: true,
        currentlySelected: action.payload,
        user: {
          ...state.user,
          currently_selected: action.payload,
        },
      };
    case UPDATE_CURRENTLY_SELECTED_ERROR:
      return {
        ...state,
        isUpdateCurrentlySelectedLoaded: true,
      };
    case DOWNLOAD_FORMS_DATA_ARRAY_REQUEST:
      return {
        ...state,
        isDownloadFormsDataArrayLoaded: false,
      };
    case DOWNLOAD_FORMS_DATA_ARRAY_SUCCESS:
      return {
        ...state,
        exportedFormsToPDF: [
          ...state.downloadedFormsDataArray.filter(
            (workflow) => workflow.id !== action.payload.id
          ),
          action.payload,
        ],
        isDownloadFormsDataArrayLoaded: true,
      };
    case DOWNLOAD_FORMS_DATA_ARRAY_ERROR:
      return {
        ...state,
        isDownloadFormsDataArrayLoaded: true,
      };
    case IS_SOCKET_OPEN_SUCCESS:
      return {
        ...state,
        isSocketOpen: action.payload,
      };
    case SOCKET_MESSAGE_SUCCESS:
      return {
        ...state,
        socketMessage: action.payload,
      };
    case SOCKET_ASSET_RISKS_SUCCESS:
      return {
        ...state,
        assetRisks: action.payload.calculation,
      };
    case TOGGLE_MAP_LEGEND_VISIBLE_REQUEST:
      return {
        ...state,
      };
    case TOGGLE_MAP_LEGEND_VISIBLE_SUCCESS:
      return {
        ...state,
        mapLegendVisible: !state.mapLegendVisible,
      };
    case TOGGLE_MAP_LEGEND_VISIBLE_ERROR:
      return {
        ...state,
        mapLegendVisible: false,
      };
    case TOGGLE_MAP_DATA_PANE_VISIBLE_REQUEST:
      return {
        ...state,
      };
    case TOGGLE_MAP_DATA_PANE_VISIBLE_SUCCESS:
      return {
        ...state,
        mapDataPaneVisible: !state.mapDataPaneVisible,
      };
    case TOGGLE_MAP_DATA_PANE_VISIBLE_ERROR:
      return {
        ...state,
        mapDataPaneVisible: false,
      };
    case UPDATE_MAP_DATA_PANE_DATA_REQUEST:
      return {
        ...state,
      };
    case UPDATE_MAP_DATA_PANE_DATA_SUCCESS:
      return {
        ...state,
        mapDataPaneData: action.payload,
        mapDataPaneVisible: !!action.payload.length,
      };
    case UPDATE_MAP_DATA_PANE_DATA_ERROR:
      return {
        ...state,
      };
    case ADD_TO_MAP_DATA_PANE_DATA_REQUEST:
      return {
        ...state,
      };
    case ADD_TO_MAP_DATA_PANE_DATA_ERROR:
      return {
        ...state,
      };
    case REMOVE_FROM_MAP_DATA_PANE_DATA_REQUEST:
      return {
        ...state,
      };
    case REMOVE_FROM_MAP_DATA_PANE_DATA_ERROR:
      return {
        ...state,
      };
    case CONFIRM_USER_NOT_NEW_REQUEST:
      return {
        ...state,
        isConfirmNewUserNotNewLoaded: false,
      };
    case CONFIRM_USER_NOT_NEW_SUCCESS:
      return {
        ...state,
        isConfirmNewUserNotNewLoaded: true,
        user: {
          ...state.user,
          profile_settings: {
            ...state.user.profile_settings,
            is_new_user: 'false',
          },
        },
      };
    case CONFIRM_USER_NOT_NEW_ERROR:
      return {
        ...state,
        isConfirmNewUserNotNewLoaded: true,
      };
    case FETCH_USER_GROUPS_ROLES_INCIDENTS_REQUEST:
      return {
        ...state,
        isFetchUserGroupsRolesIncidentsLoaded: false,
      };
    case FETCH_USER_GROUPS_ROLES_INCIDENTS_SUCCESS:
      return {
        ...state,
        isFetchUserGroupsRolesIncidentsLoaded: true,
        userGroupsRolesIncidents: action.payload,
      };
    case FETCH_USER_GROUPS_ROLES_INCIDENTS_ERROR:
      return {
        ...state,
        isFetchUserGroupsRolesIncidentsLoaded: true,
      };
    case DELETE_LAYOUT_REQUEST:
      return {
        ...state,
        isDeleteLayoutLoaded: false,
      };
    case DELETE_LAYOUT_SUCCESS: {
      const deleteLayoutRemainingLayouts = state.dashboards.filter(
        (d) => d.id !== action.payload.id
      );
      return {
        ...state,
        isDeleteLayoutLoaded: true,
        dashboards: deleteLayoutRemainingLayouts,
        currentDashboard: deleteLayoutRemainingLayouts[0],
        lassoCoordinates: {},
      };
    }
    case DELETE_LAYOUT_ERROR:
      return {
        ...state,
        isDeleteLayoutLoaded: true,
      };
    case ROLLBACK_WORKFLOW_REQUEST:
      return {
        ...state,
        isRollbackWorkflowLoaded: false,
      };
    case ROLLBACK_WORKFLOW_SUCCESS:
      return {
        ...state,
        isRollbackWorkflowLoaded: true,
        rolledBackStableWorkflow: action.payload,
      };
    case ROLLBACK_WORKFLOW_ERROR:
      return {
        ...state,
        isRollbackWorkflowLoaded: true,
      };
    case FETCH_ODDS_DATASET_FOR_DASHBOARD_REQUEST:
      return {
        ...state,
        isFetchOddsDatasetForDashboardLoaded: false,
      };
    case FETCH_ODDS_DATASET_FOR_DASHBOARD_SUCCESS:
      return {
        ...state,
        isFetchOddsDatasetForDashboardLoaded: true,
        oddsDatasetForDashboard: action.payload,
      };
    case FETCH_ODDS_DATASET_FOR_DASHBOARD_ERROR:
      return {
        ...state,
        isFetchOddsDatasetForDashboardLoaded: true,
      };
    case FETCH_DATASET_FOR_TABLE_REQUEST:
      return {
        ...state,
      };
    case FETCH_DATASET_FOR_TABLE_SUCCESS: {
      let newDatasetsForTables = { ...state.datasetsForTables };
      newDatasetsForTables[action.payload.widget.id] = {
        tableData: action.payload.tableData,
        geoTableData: action.payload.geoTableData,
      };
      return {
        ...state,
        datasetsForTables: newDatasetsForTables,
      };
    }
    case FETCH_DATASET_FOR_TABLE_ERROR:
      return {
        ...state,
      };
    case CREATE_DISTRIBUTION_LIST:
      return {
        ...state,
        distributionListItems: [
          ...state.distributionListItems.filter(
            (ele) => ele.id !== action.payload.id
          ),
          action.payload,
        ],
      };
    case FETCH_ALL_DISTRIBUTION_LIST:
      return {
        ...state,
        isDistributionListLoaded: true,
        distributionListItems: [...action.payload],
      };
    case DELETE_DISTRIBUTION_LIST_ITEM:
      return {
        ...state,
        distributionListItems: [
          ...state.distributionListItems.filter(
            (ele) => ele.id !== action.payload
          ),
        ],
      };
    case CLEAR_NOTIFICATION_STATUS:
      return {
        ...state,
        sendNotificationResult: undefined,
      };
    case SET_NOTIFICATION_PAGE_DATA:
      return {
        ...state,
        notificationPageData: action.payload,
      };
    case CLEAR_NOTIFICATION_PAGE_DATA:
      return {
        ...state,
        notificationPageData: undefined,
      };
    case CLEAR_UPSERT_NOTIFICATION_STATUS:
      return {
        ...state,
        upsertedNotification: undefined,
      };
    case FETCH_AI2FP_DATA_REQUEST:
      return {
        ...state,
        isFetchAI2FPDataLoaded: false,
      };
    case FETCH_AI2FP_DATA_SUCCESS: {
      let newAI2FPData = { ...state.AI2FPData };
      if (!newAI2FPData[action.payload.cc_table_name])
        newAI2FPData[action.payload.cc_table_name] = {};
      newAI2FPData[action.payload.cc_table_name][action.payload.cc_table_id] =
        action.payload.data;
      return {
        ...state,
        isFetchAI2FPDataLoaded: true,
        AI2FPData: newAI2FPData,
      };
    }
    case FETCH_ADLS_REQUEST:
      return {
        ...state,
        isFetchADLsLoaded: false,
      };
    case FETCH_ADLS_SUCCESS:
      return {
        ...state,
        ADLs: action.payload,
        isFetchADLsLoaded: true,
      };
    case FETCH_ADLS_ERROR:
      return {
        ...state,
        isFetchADLsLoaded: true,
      };
    case UPSERT_ADL_REQUEST:
      return {
        ...state,
        isUpsertADLLoaded: false,
      };
    case UPSERT_ADL_SUCCESS:
      return {
        ...state,
        isUpsertADLLoaded: true,
      };
    case UPSERT_ADL_ERROR:
      return {
        ...state,
        isUpsertADLLoaded: true,
      };
    case DELETE_ADL_REQUEST:
      return {
        ...state,
        isDeleteADLLoaded: false,
      };
    case DELETE_ADL_SUCCESS:
      return {
        ...state,
        isDeleteADLLoaded: true,
      };
    case DELETE_ADL_ERROR:
      return {
        ...state,
        isDeleteADLLoaded: true,
      };
    case ASSIGN_ADL_REQUEST:
      return {
        ...state,
        isAssignADLLoaded: false,
      };
    case ASSIGN_ADL_SUCCESS:
      return {
        ...state,
        isAssignADLLoaded: true,
      };
    case ASSIGN_ADL_ERROR:
      return {
        ...state,
        isAssignADLLoaded: true,
      };
    case UNASSIGN_ADL_REQUEST:
      return {
        ...state,
        isUnassignADLLoaded: false,
      };
    case UNASSIGN_ADL_SUCCESS:
      return {
        ...state,
        isUnassignADLLoaded: true,
      };
    case UNASSIGN_ADL_ERROR:
      return {
        ...state,
        isUnassignADLLoaded: true,
      };
    case GET_INCIDENT_LOGS:
      return {
        ...state,
        incidentLogs: action.payload,
      };
    case UPDATE_INCIDENT_MEMBER_WORK_LOG_REQUEST:
      return {
        ...state,
        isUpdateIncidentMemberWorkLogLoaded: false,
      };
    case UPDATE_INCIDENT_MEMBER_WORK_LOG_SUCCESS:
      return {
        ...state,
        isUpdateIncidentMemberWorkLogLoaded: true,
      };
    case UPDATE_INCIDENT_LOGS_WITH_WORKLOG: {
      let incidentLogs = { ...state.incidentLogs };
      const index = incidentLogs.incidentMembersLogs.findIndex(
        (ele) => ele.incident_member_id === action.payload.id
      );
      incidentLogs.incidentMembersLogs[index] = {
        ...incidentLogs.incidentMembersLogs[index],
        logs: action.payload.logs,
      };
      if (action.payload.logs.length > 0) {
        return {
          ...state,
          incidentLogs: incidentLogs,
        };
      }
      break;
    }
    case GET_ALL_INCIDENT_OBJECTIVES_REQUEST:
      return {
        ...state,
        isIncidentObjectivesLoaded: false,
      };
    case GET_ALL_INCIDENT_OBJECTIVES_SUCCESS:
      return {
        ...state,
        isIncidentObjectivesLoaded: true,
      };
    case GET_ALL_INCIDENT_OBJECTIVES:
      return {
        ...state,
        incidentObjectives: action.payload,
      };
    case ADD_INCIDENT_OBJECTIVES_REQUEST:
      return {
        ...state,
        isAddIncidentObjectivesLoaded: false,
      };
    case ADD_INCIDENT_OBJECTIVES_SUCCESS:
      return {
        ...state,
        isAddIncidentObjectivesLoaded: true,
      };
    case GET_ALL_INCIDENT_RESOURCES_REQUEST:
      return {
        ...state,
        isIncidentResourcesLoaded: false,
      };
    case GET_ALL_INCIDENT_RESOURCES_SUCCESS:
      return {
        ...state,
        isIncidentResourcesLoaded: true,
      };
    case GET_ALL_INCIDENT_RESOURCES:
      return {
        ...state,
        incidentResources: action.payload,
      };
    case ADD_INCIDENT_RESOURCES_REQUEST:
      return {
        ...state,
        isAddIncidentResourcesLoaded: false,
      };
    case ADD_INCIDENT_RESOURCES_SUCCESS:
      return {
        ...state,
        isAddIncidentResourcesLoaded: true,
      };
    case GET_ALL_GROUP_OBJECTIVES:
      return {
        ...state,
        groupObjectives: action.payload,
        isGroupObjectivesLoaded: true,
      };
    case GET_ALL_GROUP_RESOURCES:
      return {
        ...state,
        groupResources: action.payload,
        isGroupResourcesLoaded: true,
      };
    case GET_ALL_GROUP_RESOURCES_REQUEST:
      return {
        ...state,
        isGroupResourcesLoaded: false,
      };
    case GET_ALL_GROUP_OBJECTIVES_REQUEST:
      return {
        ...state,
        isGroupObjectivesLoaded: false,
      };
    case SELF_ACTIVATION_REQUEST:
      return {
        ...state,
        isSelfActivationLoaded: false,
      };
    case SELF_ACTIVATION_SUCCESS:
      if (action.payload) {
        let incidentMember = [...state.rosterForIncident];
        const index = incidentMember.findIndex(
          (ele) => ele.incident_member_id === action.payload
        );
        incidentMember[index] = {
          ...incidentMember[index],
          active: 1,
          active_time: new Date(),
        };
        return {
          ...state,
          isSelfActivationLoaded: true,
          rosterForIncident: incidentMember,
        };
      }
      return {
        ...state,
        isSelfActivationLoaded: true,
      };

    case ADD_SCHEDULE_TO_INCIDENT_REQUEST:
      return {
        ...state,
        isAddScheduleToIncidentLoaded: false,
      };

    case ADD_SCHEDULE_TO_INCIDENT_SUCCESS:
      return {
        ...state,
        isAddScheduleToIncidentLoaded: true,
      };
    case GET_ALL_SCHEDULE_TO_INCIDENT:
      return {
        ...state,
        allScheduleToIncident: action.payload,
        isAllScheduleToIncidentLoaded: true,
      };
    case GET_ALL_SCHEDULE_TO_INCIDENT_REQUEST:
      return {
        ...state,
        isAllScheduleToIncidentLoaded: false,
      };
    case GET_USER_SCHEDULE_TO_INCIDENT: {
      let rosterForIncident = [...state.rosterForIncident];
      const rosterForIncidentIndex = rosterForIncident.findIndex(
        (ele) => ele.incident_member_id === action.payload.incident_member_id
      );
      rosterForIncident[rosterForIncidentIndex] = {
        ...rosterForIncident[rosterForIncidentIndex],
        schedule: action.payload.schedule,
      };
      return {
        ...state,
        rosterForIncident: rosterForIncident,
      };
    }
    case GET_ALL_GROUP_QUESTIONS:
      return {
        ...state,
        allGroupQuestions: action.payload,
      };
    case GET_ALL_GROUP_ANSWER_FOR_ALL_MEMBERS:
      return {
        ...state,
        allGroupQuestionAnswers: action.payload,
      };
    case GET_ALL_GROUP_QUESTIONS_REQUEST:
      return {
        ...state,
        isGroupQuestionLoaded: false,
      };
    case GET_ALL_GROUP_QUESTIONS_SUCCESS:
      return {
        ...state,
        isGroupQuestionLoaded: true,
      };
    case ADD_GROUP_QUESTIONS_REQUEST:
      return {
        ...state,
        isAddGroupQuestionLoaded: false,
      };
    case ADD_GROUP_QUESTIONS_SUCCESS:
      return {
        ...state,
        isAddGroupQuestionLoaded: true,
      };
    case DELETE_GROUP_QUESTIONS_REQUEST:
      return {
        ...state,
        isDeleteGroupQuestionLoaded: false,
      };
    case DELETE_GROUP_QUESTIONS_SUCCESS:
      return {
        ...state,
        isDeleteGroupQuestionLoaded: true,
      };
    case ADD_GROUP_ANSWER: {
      let group = [...state.userGroupsRolesIncidents];
      const groupIndex = group.findIndex(
        (ele) => ele.group_guid === action.payload.group_guid
      );
      group[groupIndex] = {
        ...group[groupIndex],
        group_question: action.payload.response,
      };
      return {
        ...state,
        userGroupsRolesIncidents: group,
      };
    }
    case ADD_USER_SCHEDULE_REQUEST:
      return {
        ...state,
        isAddUserScheduleLoaded: false,
        isAddUserScheduleError: false,
      };
    case ADD_USER_SCHEDULE_SUCCESS:
      return {
        ...state,
        isAddUserScheduleLoaded: true,
      };
    case ADD_USER_SCHEDULE_ERROR:
      return {
        ...state,
        isAddUserScheduleError: true,
        isAddUserScheduleLoaded: true,
      };
    case UPLOAD_FILE_REQUEST:
      return {
        ...state,
        isUploadFileLoaded: false,
      };
    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        isUploadFileLoaded: true,
        uploadedFileUrl: action.payload,
      };
    case GENERATE_SIGNED_URL_REQUEST:
      return {
        ...state,
        isSignedUrlLoaded: false,
      };
    case GENERATE_SIGNED_URL_SUCCESS:
      return {
        ...state,
        isSignedUrlLoaded: true,
        generatedSignedUrl: action.payload,
      };
    case FETCH_GROUP_IMAGES_REQUEST:
      return {
        ...state,
        isGroupImagesLoaded: false,
      };
    case FETCH_GROUP_IMAGES_SUCCESS:
      return {
        ...state,
        groupImages: action.payload,
        isGroupImagesLoaded: true,
      };
    case FETCH_BRIEFING_REQUEST:
    case SET_BRIEFING_SKETCH_REQUEST:
      return {
        ...state,
        isBriefingLoaded: false,
      };
    case SET_BRIEFING_SKETCH_SUCCESS:
      return {
        ...state,
        isBriefingLoaded: true,
      };
    case FETCH_BRIEFING_SUCCESS:
      return {
        ...state,
        isBriefingLoaded: true,
        briefing: action.payload,
      };
    case GET_ALL_USER_SCHEDULE_REQUEST:
      return {
        ...state,
        isGetAllUserScheduleLoaded: false,
      };
    case GET_ALL_USER_SCHEDULE_SUCCESS:
      return {
        ...state,
        isGetAllUserScheduleLoaded: true,
      };
    case GET_ALL_USER_SCHEDULE:
      return {
        ...state,
        allUserSchedule: action.payload,
      };
    case FETCH_AI2FP_DATA_ERROR:
      return {
        ...state,
        isFetchAI2FPDataLoaded: true,
      };
    case FETCH_CORE_COMPONENTS_REQUEST:
      return {
        ...state,
        isFetchCoreComponentsLoaded: false,
      };
    case FETCH_CORE_COMPONENTS_SUCCESS: {
      return {
        ...state,
        isFetchCoreComponentsLoaded: true,
        coreComponents: action.payload,
        allAor:
          (action.payload.CCs &&
            action.payload.CCs['Areas of Responsibility']) ||
          [],
      };
    }
    case FETCH_CORE_COMPONENTS_ERROR:
      return {
        ...state,
        isFetchCoreComponentsLoaded: true,
      };
    case UPSERT_CORE_COMPONENT_REQUEST:
      return {
        ...state,
        isUpsertCoreComponentLoaded: false,
      };
    case UPSERT_CORE_COMPONENT_SUCCESS: {
      return {
        ...state,
        isUpsertCoreComponentLoaded: true,
        upsertedCoreComponent: action.payload,
        currentAOR:
          (!!action.payload &&
            action.payload.cc_type === 'Areas of Responsibility' &&
            !!state.currentAOR &&
            state.currentAOR.id === action.payload.id &&
            action.payload) ||
          state.currentAOR,
      };
    }
    case UPSERT_CORE_COMPONENT_ERROR:
      return {
        ...state,
        isUpsertCoreComponentLoaded: true,
      };
    case LINK_CORE_COMPONENTS_REQUEST:
      return {
        ...state,
        isLinkCoreComponentLoaded: false,
      };
    case LINK_CORE_COMPONENTS_SUCCESS: {
      return {
        ...state,
        isLinkCoreComponentLoaded: true,
      };
    }
    case LINK_CORE_COMPONENTS_ERROR:
      return {
        ...state,
        isLinkCoreComponentLoaded: true,
      };
    case UNLINK_CORE_COMPONENTS_REQUEST:
      return {
        ...state,
        isUnlinkCoreComponentLoaded: false,
      };
    case UNLINK_CORE_COMPONENTS_SUCCESS: {
      return {
        ...state,
        isUnlinkCoreComponentLoaded: true,
      };
    }
    case UNLINK_CORE_COMPONENTS_ERROR:
      return {
        ...state,
        isUnlinkCoreComponentLoaded: true,
      };
    case DELETE_CORE_COMPONENT_REQUEST:
      return {
        ...state,
        isDeleteCoreComponentLoaded: false,
      };
    case DELETE_CORE_COMPONENT_SUCCESS: {
      return {
        ...state,
        isDeleteCoreComponentLoaded: true,
      };
    }
    case DELETE_CORE_COMPONENT_ERROR:
      return {
        ...state,
        isDeleteCoreComponentLoaded: true,
      };
    case UPDATE_CURRENT_AOR_REQUEST:
      return {
        ...state,
      };
    case UPDATE_CURRENT_AOR_SUCCESS: {
      return {
        ...state,
        currentAOR: action.payload,
      };
    }
    case UPDATE_CURRENT_AOR_ERROR:
      return {
        ...state,
      };
    case FETCH_THRESHOLDS_FOR_AOR_REQUEST:
      return {
        isFetchThresholdsForAORLoaded: false,
        ...state,
      };
    case FETCH_THRESHOLDS_FOR_AOR_SUCCESS: {
      return {
        ...state,
        isFetchThresholdsForAORLoaded: true,
        thresholdsForAOR: action.payload,
      };
    }
    case FETCH_THRESHOLDS_FOR_AOR_ERROR:
      return {
        isFetchThresholdsForAORLoaded: true,
        ...state,
      };
    case UPSERT_THRESHOLD_REQUEST:
      return {
        isUpsertThresholdLoaded: false,
        ...state,
      };
    case UPSERT_THRESHOLD_SUCCESS:
      return {
        ...state,
        isUpsertThresholdLoaded: true,
      };
    case UPSERT_THRESHOLD_ERROR:
      return {
        isUpsertThresholdLoaded: true,
        ...state,
      };
    case UPDATE_CURRENT_ANOMALY_REQUEST:
      return {
        ...state,
      };
    case UPDATE_CURRENT_ANOMALY_SUCCESS:
      return {
        ...state,
        currentAnomaly: action.payload,
      };
    case UPDATE_CURRENT_ANOMALY_ERROR:
      return {
        ...state,
      };
    case FETCH_ANOMALIES_REQUEST:
      return {
        ...state,
        isFetchAnomaliesLoaded: false,
        anomaliesFetched: false,
      };
    case FETCH_ANOMALIES_SUCCESS:
      return {
        ...state,
        anomalies: [AnomalySetup, ...action.payload],
        isFetchAnomaliesLoaded: true,
        anomaliesFetched: true,
      };
    case FETCH_ANOMALIES_ERROR:
      return {
        ...state,
        isFetchAnomaliesLoaded: true,
        anomaliesFetched: false,
      };
    case ARCHIVE_ANOMALY_REQUEST:
      return {
        ...state,
        isArchiveAnomalyLoaded: false,
      };
    case ARCHIVE_ANOMALY_SUCCESS:
      return {
        ...state,
        archivedAnomaly: action.payload,
        currentAnomaly: state.anomalies[0],
        isArchiveAnomalyLoaded: true,
      };
    case ARCHIVE_ANOMALY_ERROR:
      return {
        ...state,
        isArchiveAnomalyLoaded: true,
      };
    case ANOMALY_TO_INCIDENT_REQUEST:
      return {
        ...state,
        isAnomalyToIncidentLoaded: false,
      };
    case ANOMALY_TO_INCIDENT_SUCCESS:
      return {
        ...state,
        isUpdateCurrentIncidentLoaded: true,
        mapBoundingBox: undefined,
        dashboards: undefined,
        dashboardData: undefined,
        filteredDashboardDatasets: [],
        currentDashboard: action.payload.dashboard,
        updatedDashboard: undefined,
        updatedWidget: undefined,
        lassoCoordinates: {},
        currentIncident: action.payload.incident,
        mapDataPaneData: [],
        mapDataPaneVisible: false,
        anomalyForIncident: action.payload.anomaly,
        isAnomalyToIncidentLoaded: true,
      };
    case ANOMALY_TO_INCIDENT_ERROR:
      return {
        ...state,
        isAnomalyToIncidentLoaded: true,
      };
    case ADD_NEW_ACTION_REQUEST:
      return {
        ...state,
        isAddActionLoaded: false,
      };
    case ADD_NEW_ACTION_SUCCESS:
      return {
        ...state,
        isAddActionLoaded: true,
      };
    case UPDATE_SITUATION_REQUEST:
      return {
        ...state,
        isBriefingLoaded: false,
      };
    case UPDATE_SITUATION_SUCCESS:
      return {
        ...state,
        isBriefingLoaded: true,
      };
    case GET_ALL_ACTION_REQUEST:
      return {
        ...state,
        isActionLoaded: false,
      };
    case GET_ALL_ACTION_SUCCESS:
      return {
        ...state,
        isActionLoaded: true,
      };
    case GET_ALL_ACTION:
      return {
        ...state,
        actions: action.payload,
      };
    case GET_ALL_INITIAL_INCIDENT_REPORT_REQUEST:
      return {
        ...state,
        isAllInitialIncidentReportLoaded: false,
      };
    case GET_ALL_INITIAL_INCIDENT_REPORT_SUCCESS:
      return {
        ...state,
        isAllInitialIncidentReportLoaded: true,
      };
    case GET_ALL_INITIAL_INCIDENT_REPORT:
      return {
        ...state,
        allInitialIncidentReport: action.payload,
        isAddInitialIncidentReportLoaded: true,
      };
    case ADD_INITIAL_INCIDENT_REQUEST:
      return {
        ...state,
        hasInitialIncidentReportError: false,
        isAddInitialIncidentReportLoaded: false,
      };
    case ADD_INITIAL_INCIDENT_SUCCESS:
      return {
        ...state,
        hasInitialIncidentReportError: false,
        isAddInitialIncidentReportLoaded: true,
      };
    case ADD_INITIAL_INCIDENT_REPORT_ERROR:
      return {
        ...state,
        hasInitialIncidentReportError: true,
      };
    case GET_ALL_INITIAL_INCIDENT_REPORT_LOGS:
      return {
        ...state,
        allInitialIncidentReportLogs: action.payload,
        isAllInitialIncidentReportLogsLoaded: true,
      };
    case GET_ALL_INITIAL_INCIDENT_REPORT_LOGS_REQUEST:
      return {
        ...state,
        isAllInitialIncidentReportLogsLoaded: false,
      };
    case GET_ALL_INITIAL_INCIDENT_REPORT_LOGS_SUCCESS:
      return {
        ...state,
        isAllInitialIncidentReportLogsLoaded: true,
      };
    case ADD_INITIAL_INCIDENT_REPORT_COMMENT_REQUEST:
      return {
        ...state,
        isAddInitialIncidentReportCommentLoaded: false,
      };
    case ADD_INITIAL_INCIDENT_REPORT_COMMENT_SUCCESS:
      return {
        ...state,
        isAddInitialIncidentReportCommentLoaded: true,
      };
    case GET_ALL_INITIAL_INCIDENT_REPORT_RECIPIENT_LIST:
      return {
        ...state,
        allInitialIncidentReportRecipientList: action.payload,
      };
    case GET_ALL_EPIC_WORKSPACE:
      return {
        ...state,
        allEpicWorkspaces: action.payload,
      };
    case ADD_INITIAL_INCIDENT_REPORT_CHECKLIST_SUCCESS:
      return {
        ...state,
        isAddInitialIncidentReportChecklistLoaded: true,
        hasAddInitialIncidentReportChecklistError: false,
      };
    case GET_ALL_INITIAL_INCIDENT_REPORT_CHECKLIST:
      return {
        ...state,
        allInitialIncidentReportChecklist: action.payload,
        isAllInitialIncidentReportChecklistLoaded: true,
      };
    case ADD_INITIAL_INCIDENT_REPORT_CHECKLIST_ERROR:
      return {
        ...state,
        hasAddInitialIncidentReportChecklistError: true,
      };
    case ADD_INITIAL_INCIDENT_REPORT_CHECKLIST_REQUEST:
      return {
        ...state,
        isAddInitialIncidentReportChecklistLoaded: false,
        hasAddInitialIncidentReportChecklistError: false,
      };
    case GET_ALL_INITIAL_INCIDENT_REPORT_CHECKLIST_REQUEST:
      return {
        ...state,
        isAllInitialIncidentReportChecklistLoaded: false,
      };

    case ADD_INITIAL_INCIDENT_REPORT_TEAM_CHECKLIST_SUCCESS:
      return {
        ...state,
        isAddInitialIncidentReportTeamChecklistLoaded: true,
        hasAddInitialIncidentReportTeamChecklistError: false,
      };
    case GET_ALL_INITIAL_INCIDENT_REPORT_TEAM_CHECKLIST:
      return {
        ...state,
        allInitialIncidentReportTeamChecklist: action.payload,
        isAllInitialIncidentReportTeamChecklistLoaded: true,
      };
    case ADD_INITIAL_INCIDENT_REPORT_TEAM_CHECKLIST_ERROR:
      return {
        ...state,
        hasAddInitialIncidentReportTeamChecklistError: true,
      };
    case ADD_INITIAL_INCIDENT_REPORT_TEAM_CHECKLIST_REQUEST:
      return {
        ...state,
        isAddInitialIncidentReportTeamChecklistLoaded: false,
        hasAddInitialIncidentReportTeamChecklistError: false,
      };
    case GET_ALL_INITIAL_INCIDENT_REPORT_TEAM_CHECKLIST_REQUEST:
      return {
        ...state,
        isAllInitialIncidentReportTeamChecklistLoaded: false,
      };
    case GET_INITIAL_INCIDENT_REPORT:
      return {
        ...state,
        singleInitialIncidentReport: action.payload,
      };
    case GET_INITIAL_INCIDENT_REPORT_REQUEST:
      return {
        ...state,
        singleInitialIncidentReportLoaded: false,
      };
    case GET_INITIAL_INCIDENT_REPORT_SUCCESS:
      return {
        ...state,
        singleInitialIncidentReportLoaded: true,
      };
    case GET_ALL_ACTIVITY_LOG:
      return {
        ...state,
        diceActivityLogs: action.payload,
      };
    case GET_ALL_LOCATION_ENTITY:
      return {
        ...state,
        locationEntity: action.payload,
      };
    case GET_ALL_LOCATION_SUB_ENTITY:
      return {
        ...state,
        locationSubEntity: action.payload,
      };
    case GET_ALL_LOCATION_ENTERPRISE:
      return {
        ...state,
        locationEnterprise: action.payload,
      };
    case GET_ALL_LOCATION_ASSET:
      return {
        ...state,
        locationAsset: action.payload,
      };
    case GET_ALL_GROUP_LOCATION:
      return {
        ...state,
        groupLocation: action.payload,
      };
    case FETCH_NESTED_CATEGORIES:
      return {
        ...state,
        nestedCategories: action.payload,
      };
    case FETCH_NESTED_CATEGORY_ITEMS:
      return {
        ...state,
        nestedCategoryItems: action.payload,
      };
    case GET_ALL_BRANCH:
      return {
        ...state,
        allBranches: action.payload,
      };
    case GET_ALL_DIVISION:
      return {
        ...state,
        allDivisions: action.payload,
      };
    case GET_PLANNING_WORKSHEET:
      return {
        ...state,
        planningWorksheet: action.payload,
      };
    case GET_ALL_WORK_ASSIGNMENT:
      return {
        ...state,
        allWorkAssignments: action.payload,
      };
    case FETCH_MEDICAL_PLAN:
      return {
        ...state,
        medical_plan: action.payload,
      };
    case FETCH_MEDICAL_AID_STATION_LIST:
      return {
        ...state,
        medicalAidStationsList: action.payload,
      };
    case FETCH_TRANSPORTATION_LIST:
      return {
        ...state,
        transportationsList: action.payload,
      };
    case FETCH_HOSPITAL_LIST:
      return {
        ...state,
        hospitalsList: action.payload,
      };
    case GET_ICS_OBJECTIVES_DATA:
      return {
        ...state,
        ics202ObjectivesData: action.payload,
      };
    case GET_IAP_COVER_SHEET_DATA:
      return {
        ...state,
        iapCoverSheet: action.payload,
      };
    case FETCH_SAFETY_AND_HEALTH_FORM_DATA:
      return {
        ...state,
        safetyAndHealthForms: action.payload,
      };
    case UPSERT_COMMUNICATION:
      return {
        ...state,
        communication: action.payload,
      };
    case FETCH_RESOURCE_REQUEST:
      return {
        ...state,
        resource_request: action.payload,
      };
    case FETCH_ALL_RESOURCE_REQUEST:
      return {
        ...state,
        all_resource_request: action.payload,
      };
    case FETCH_MEETINGS:
      return {
        ...state,
        meetings: action.payload,
      };
    case FETCH_AOR:
      return {
        ...state,
        allAor: action.payload,
      };
    case FETCH_ALL_USER_WORKSPACE:
      return {
        ...state,
        alluserWorkspaces: action.payload,
      };
    case FETCH_TWITTER_DATA_REQUEST:
      return {
        ...state,
        twitterData: [],
        isTwitterDataLoaded: false,
      };
    case FETCH_TWITTER_DATA_SUCCESS:
      return {
        ...state,
        twitterData: action.payload || [],
        isTwitterDataLoaded: true,
      };
    case FETCH_TWITTER_DATA_ERROR:
      return {
        ...state,
        isTwitterDataLoaded: true,
      };
    case FETCH_TWITTER_DATA_ENRICHED_REQUEST:
      return {
        ...state,
        twitterDataEnriched: [],
        isTwitterDataEnrichedLoaded: false,
      };
    case FETCH_TWITTER_DATA_ENRICHED_SUCCESS:
      return {
        ...state,
        twitterDataEnriched: action.payload || [],
        isTwitterDataEnrichedLoaded: true,
      };
    case FETCH_TWITTER_DATA_ENRICHED_ERROR:
      return {
        ...state,
        isTwitterDataEnrichedLoaded: true,
      };
    case FETCH_OSINT_ASK_REQUEST:
      return {
        ...state,
        isOSINTAskLoaded: false,
      };
    case FETCH_OSINT_ASK_SUCCESS:
      return {
        ...state,
        OSINTAskResponses: action.payload,
        isOSINTAskLoaded: true,
      };
    case FETCH_OSINT_ASK_ERROR:
      return {
        ...state,
        isOSINTAskLoaded: true,
      };
    case FETCH_TASK_LIST_REQUEST:
      return {
        ...state,
        taskList: [],
        totalTaskList: 0,
        isTaskListDataLoaded: false,
      };
    case FETCH_TASK_LIST_SUCCESS:
      return {
        ...state,
        taskList: action.payload.taskList || [],
        totalTaskList: action.payload.count,
        isTaskListDataLoaded: true,
      };
    case FETCH_TASK_LIST_ERROR:
      return {
        ...state,
        isTaskListDataLoaded: true,
      };
    case SAVE_TASK_DATA_REQUEST:
      return {
        ...state,
        saveTask: null,
        isSaveTaskLoaded: false,
        saveTaskSuccess: false,
      };
    case SAVE_TASK_DATA_SUCCESS:
      return {
        ...state,
        saveTaskSuccess: true,
        isSaveTaskLoaded: true,
      };
    case SAVE_TASK_DATA_ERROR:
      return {
        ...state,
        isSaveTaskLoaded: true,
        saveTaskSuccess: false,
      };
    case GENERATE_TEAMS_SITE_REQUEST: {
      return {
        ...state,
        teamStatusFromIncident: action.payload,
        isGenerateTeamsSiteLoaded: false,
      };
    }
    case GENERATE_TEAMS_SITE_SUCCESS: {
      return {
        ...state,
        isGenerateTeamsSiteLoaded: true,
        teamStatusFromIncident: null,
        checkTeamCounter: { incidentId: '', count: 0, timeout: null },
      };
    }
    case CHECK_TEAMS: {
      return {
        ...state,
        checkTeamCounter: action.payload,
      };
    }
    case CHECK_TEAMS_STATUS_SUCCESS: {
      if (state.currentIncident.id === action.payload.incidentId) {
        return {
          ...state,
          currentIncident: {
            ...state.currentIncident,
            ...action.payload.teamsStatusInfo,
          },
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case GET_TEAMS_TEMPLATE_SUCCESS: {
      return {
        ...state,
        teamsTemplates: action.payload,
      };
    }
    case UPDATE_TEAMS_TENANT_ID: {
      return {
        ...state,
        tenantId: action.payload,
      };
    }
    case GENERATE_TEAMS_SITE_ERROR: {
      return {
        ...state,
        isGenerateTeamsSiteLoaded: true,
      };
    }
    case UPLOAD_FILE_TO_TEAMS_REQUEST: {
      return {
        ...state,
        isUploadFileToTeamsLoaded: false,
      };
    }
    case UPLOAD_FILE_TO_TEAMS_SUCCESS: {
      return {
        ...state,
        isUploadFileToTeamsLoaded: true,
      };
    }
    case UPLOAD_FILE_TO_TEAMS_ERROR: {
      return {
        ...state,
        isUploadFileToTeamsLoaded: true,
      };
    }
    case ADD_TASK_COMMENT_REQUEST: {
      return {
        ...state,
        addTaskCommentSuccess: false,
      };
    }
    case ADD_TASK_COMMENT_SUCCESS: {
      return {
        ...state,
        addTaskCommentSuccess: true,
      };
    }
    case ADD_TASK_COMMENT_ERROR: {
      return {
        ...state,
        addTaskCommentSuccess: true,
      };
    }
    case GET_ALL_TASK_COMMENT_REQUEST: {
      return {
        ...state,
        isTaskCommentLoaded: false,
      };
    }
    case GET_ALL_TASK_COMMENT_ERROR: {
      return {
        ...state,
        isTaskLogLoaded: true,
      };
    }
    case GET_ALL_TASK_COMMENT: {
      return {
        ...state,
        allTaskComments: action.payload,
        isTaskCommentLoaded: true,
      };
    }
    case GET_ALL_TASK_LOGS_REQUEST: {
      return {
        ...state,
        isTaskLogLoaded: false,
      };
    }
    case GET_ALL_TASK_LOGS_ERROR: {
      return {
        ...state,
        isTaskLogLoaded: true,
      };
    }
    case GET_ALL_TASK_LOGS: {
      return {
        ...state,
        allTaskLogs: action.payload,
        isTaskLogLoaded: true,
      };
    }
    case GET_ALL_TASK_UNIT: {
      return {
        ...state,
        taskUnits: action.payload,
      };
    }
    case STRIPE_CREATE_SESSION_REQUEST: {
      return {
        ...state,
        isStripeCreateSessionLoaded: false,
      };
    }
    case STRIPE_CREATE_SESSION_SUCCESS: {
      return {
        ...state,
        isStripeCreateSessionLoaded: true,
      };
    }
    case STRIPE_CREATE_SESSION_ERROR: {
      return {
        ...state,
        isStripeCreateSessionLoaded: true,
      };
    }
    case CREATE_STRIPE_SUBSCRIPTION_REQUEST: {
      return {
        ...state,
        isCreateStripeSubscriptionLoaded: false,
      };
    }
    case UPDATE_STRIPE_SUBSCRIPTION_STATUS_REQUEST: {
      return {
        ...state,
        isUpdateStripeSubscriptionStatus: false,
      };
    }
    case UPDATE_STRIPE_SUBSCRIPTION_STATUS_SUCCESS: {
      return {
        ...state,
        isUpdateStripeSubscriptionStatus: true,
      };
    }
    case UPDATE_STRIPE_SUBSCRIPTION_STATUS_ERROR: {
      return {
        ...state,
        isUpdateStripeSubscriptionStatus: true,
      };
    }
    case FETCH_ASSIGNABLE_USERS_BY_GROUP_REQUEST: {
      return {
        ...state,
        isFetchAssignableUsersByGroupLoaded: false,
      };
    }
    case FETCH_ASSIGNABLE_USERS_BY_GROUP_SUCCESS: {
      return {
        ...state,
        isFetchAssignableUsersByGroupLoaded: true,
        assignableUsersByGroup: action.payload,
      };
    }
    case FETCH_ASSIGNABLE_USERS_BY_GROUP_ERROR: {
      return {
        ...state,
        isFetchAssignableUsersByGroupLoaded: true,
      };
    }
    case ASSIGN_STRIPE_SEATS_REQUEST: {
      return {
        ...state,
        isAssignStripeSeatsLoaded: false,
      };
    }
    case ASSIGN_STRIPE_SEATS_SUCCESS: {
      return {
        ...state,
        isAssignStripeSeatsLoaded: true,
      };
    }
    case ASSIGN_STRIPE_SEATS_ERROR: {
      return {
        ...state,
        isAssignStripeSeatsLoaded: true,
      };
    }
    case UNASSIGN_STRIPE_SEATS_REQUEST: {
      return {
        ...state,
        isUnassignStripeSeatsLoaded: false,
      };
    }
    case UNASSIGN_STRIPE_SEATS_SUCCESS: {
      return {
        ...state,
        isUnassignStripeSeatsLoaded: true,
      };
    }
    case UNASSIGN_STRIPE_SEATS_ERROR: {
      return {
        ...state,
        isUnassignStripeSeatsLoaded: true,
      };
    }
    case FETCH_USER_SUBSCRIPTION_INFO_REQUEST: {
      return {
        ...state,
        isFetchUserSubscriptionInfoLoaded: false,
      };
    }
    case FETCH_USER_SUBSCRIPTION_INFO_SUCCESS: {
      return {
        ...state,
        isFetchUserSubscriptionInfoLoaded: true,
        userSubscriptionInfo: action.payload,
      };
    }
    case FETCH_USER_SUBSCRIPTION_INFO_ERROR: {
      return {
        ...state,
        isFetchUserSubscriptionInfoLoaded: true,
      };
    }
    case FETCH_CHAT_DGPT_ONGOING: {
      return {
        ...state,
        chatDGPTSessions: [
          ...state.chatDGPTSessions.filter(
            (s) => s.dgpt_session_id !== action.payload.dgpt_session_id
          ),
          action.payload,
        ],
      };
    }
    case EXPORT_AS_FORM_REQUEST: {
      return {
        ...state,
        isExportAsFormLoaded: false,
        exportAsFormResult: null,
      };
    }
    case EXPORT_AS_FORM_SUCCESS: {
      return {
        ...state,
        isExportAsFormLoaded: true,
        exportAsFormResult: action.payload,
      };
    }
    case EXPORT_AS_FORM_ERROR: {
      return {
        ...state,
        isExportAsFormLoaded: true,
      };
    }
    case ENVIRONMENT_VAR_LOADED: {
      return {
        ...state,
        isEnvironmentVariableLoaded: true,
      };
    }
    case BULK_ADD_OBJECTIVES_REQUEST: {
      return {
        ...state,
        isBulkAddObjectivesLoaded: false,
      };
    }
    case BULK_ADD_OBJECTIVES_SUCCESS: {
      return {
        ...state,
        isBulkAddObjectivesLoaded: true,
      };
    }
    case BULK_ADD_OBJECTIVES_ERROR: {
      return {
        ...state,
        isBulkAddObjectivesLoaded: true,
      };
    }
    case BULK_ADD_ACTIONS_REQUEST: {
      return {
        ...state,
        isBulkAddActionsLoaded: false,
      };
    }
    case BULK_ADD_ACTIONS_SUCCESS: {
      return {
        ...state,
        isBulkAddActionsLoaded: true,
      };
    }
    case BULK_ADD_ACTIONS_ERROR: {
      return {
        ...state,
        isBulkAddActionsLoaded: true,
      };
    }
    case BULK_ADD_RESOURCES_REQUEST: {
      return {
        ...state,
        isBulkAddResourcesLoaded: false,
      };
    }
    case BULK_ADD_RESOURCES_SUCCESS: {
      return {
        ...state,
        isBulkAddResourcesLoaded: true,
      };
    }
    case BULK_ADD_RESOURCES_ERROR: {
      return {
        ...state,
        isBulkAddResourcesLoaded: true,
      };
    }

    case FETCH_INCIDENT_ACTIONS_REQUEST: {
      return {
        ...state,
        isFetchIncidentActionsLoaded: false,
      };
    }
    case FETCH_INCIDENT_ACTIONS_SUCCESS: {
      return {
        ...state,
        incidentActions: action.payload,
        isFetchIncidentActionsLoaded: true,
      };
    }
    case FETCH_INCIDENT_ACTIONS_ERROR: {
      return {
        ...state,
        isFetchIncidentActionsLoaded: true,
      };
    }

    case UPDATE_INCIDENT_ACTION_SELECTION_REQUEST: {
      return {
        ...state,
        isUpdateIncidentActionSelectionLoaded: false,
      };
    }
    case UPDATE_INCIDENT_ACTION_SELECTION_SUCCESS: {
      return {
        ...state,
        isUpdateIncidentActionSelectionLoaded: true,
      };
    }
    case UPDATE_INCIDENT_ACTION_SELECTION_ERROR: {
      return {
        ...state,
        isUpdateIncidentActionSelectionLoaded: true,
      };
    }

    default:
      return state;
  }
};

export default appReducer;
