import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import {
  deleteDivision,
  getAllBranch,
  getAllDivisionByGroupId,
  upsertPlanningWorksheet,
} from 'actions/IAPForm204Actions';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import AddUpdateDivisionModal from './AddUpdateDivisionModal';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import StylishNewTableAction from 'components/DesignSystems/New/StylishNewTableAction';

export default function DivisionModal({
  show,
  onClose,
  selectedDivision,
  selectedBranch,
  parent,
  rowSelectable,
  updateSelectedDivision,
}) {
  const reduxDispatch = useDispatch();

  useEffect(() => {
    reduxDispatch(getAllBranch());
    if (!rowSelectable) {
      reduxDispatch(getAllDivisionByGroupId());
    }
  }, []);

  const [division, setSelectedDivision] = useState(selectedDivision);
  const [showNewDivisionModal, setShowNewDivisionModal] = useState(false);
  const [deleteConfirmationData, setDeleteConfirmationData] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [editDivisionData, setEditDivisionData] = useState(null);

  const reduxCurrentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });
  const divisions = useSelector((state) => state.app.allDivisions);

  const onDeleteAction = (row) => {
    setDeleteConfirmationData(row);
    setShowConfirmDialog(true);
  };

  const onConfirmedDeleteActionData = () => {
    reduxDispatch(startLoading());
    reduxDispatch(
      deleteDivision(
        deleteConfirmationData.id,
        deleteConfirmationData.branch_id,
        'getAll'
      )
    );
    setDeleteConfirmationData(null);
    setShowConfirmDialog(false);
  };

  const columns = [
    {
      dataField: 'name',
      text: 'Division',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Branch' },
    },
    {
      dataField: '',
      text: 'Action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => (
        <StylishNewTableAction
          name="Division"
          cell={cell}
          row={row}
          rowIndex={rowIndex}
          onDelete={() => onDeleteAction(row)}
          onEdit={() => {
            setEditDivisionData(row);
            setShowNewDivisionModal(true);
          }}
        />
      ),
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
      attrs: { title: 'Action' },
    },
  ];

  const selectRow = {
    mode: 'radio',
    clickToSelect: true,
    hideSelectAll: true,
    selected: !!selectedDivision && [selectedDivision.id],
    selectionHeaderRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} />
        <span className="icon"></span>
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} />
        <span className="icon"></span>
      </div>
    ),
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelectedDivision(row);
      }
    },
  };

  const onSave = async () => {
    if (!!reduxCurrentIncident && !reduxCurrentIncident.current_period) {
      return;
    }
    try {
      reduxDispatch(startLoading());
      if (!!parent && parent === 'Work Assignment') {
        updateSelectedDivision(division);
      }
      else {
        reduxDispatch(upsertPlanningWorksheet('Division', division?.id));
      }
      onClose();
    } catch (error) {
      console.error(error);
    } finally {
      reduxDispatch(endLoading());
    }
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Division / Group / Staging</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-2">
        <StylishNewButton
          onClick={() => {
            setEditDivisionData(null);
            setShowNewDivisionModal(true);
          }}
          className={'button--primary mb-4'}
        >
          Add New
        </StylishNewButton>
        <div className="mb-4">
          <h5 className="mb-0">Branch</h5>
          <p className="m-0">{selectedBranch?.name}</p>
        </div>

        <StylishNewTable keyField={'id'} columns={columns} rows={divisions} selectRow={rowSelectable ? selectRow : undefined} />
      </Modal.Body>
      <Modal.Footer>
        <div className="button-group">
          <StylishNewButton
            className="button--secondary button--reverse"
            type="button"
            onClick={onClose}
          >
            Close
          </StylishNewButton>
          {parent !== 'IncidentWorkspace' &&
            <StylishNewButton
              className={'button--primary'}
              onClick={onSave}
              disabled={
                !(
                  !!divisions &&
                  !!divisions.length &&
                  !!division && [division.id]
                )
              }
            >
              Save
            </StylishNewButton>
          }
        </div>
      </Modal.Footer>

      {showNewDivisionModal && (
        <AddUpdateDivisionModal
          show={showNewDivisionModal}
          onClose={() => setShowNewDivisionModal(false)}
          selectedBranch={selectedBranch}
          selectedDivision={editDivisionData}
          parent={parent}
        />
      )}

      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Delete Confirmation'}
          dialogContent={'Are you sure you want to delete this division?'}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={() => onConfirmedDeleteActionData()}
        />
      )}
    </Modal>
  );
}
