import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import EditIncidentDialog from 'components/IncidentConfig/EditIncidentDialog';
import { updateIncident } from 'actions/diceActions';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import { IncidentTypes } from 'components/IncidentConfig/IncidentTypes';
import { SeverityLevels } from 'components/IncidentConfig/SeverityLevels';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';

export default function IncidentAdminToolbar() {
  const reduxDispatch = useDispatch();
  const [isEditIncidentOpen, setIsEditIncidentOpen] = useState(false);
  const [incidentType, setIncidentType] = useState([]);
  const [severity, setSeverity] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const reduxIncidents = useSelector((state) => {
    return state.app.incidents;
  });

  const reduxCurrentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });

  const reduxValidateRBACPermissionForActionResult = useSelector((state) => {
    return state.app.validateRBACPermissionForActionResult;
  });

  useEffect(() => {
    reduxDispatch(endLoading());
  }, [reduxCurrentIncident]);

  const handleArchiveClick = () => {
    setShowConfirmDialog(true);
  };

  const onDeleteAdditionalMemberData = () => {
    const updatedIncident = reduxCurrentIncident;
    updatedIncident.archived = 1;
    reduxDispatch(startLoading());
    reduxDispatch(updateIncident(updatedIncident,true));
    setShowConfirmDialog(false);
  };

  useEffect(() => {
    if (
      !!reduxCurrentIncident &&
      reduxCurrentIncident?.incident_types?.length > 0
    ) {
      setIncidentType(
        reduxCurrentIncident.incident_types.map((i) => ({
          label: i.trim(),
          value: i.trim(),
        }))
      );
    } else {
      setIncidentType([]);
    }
    setSeverity(
      reduxCurrentIncident && reduxCurrentIncident.severity
        ? {
            label: reduxCurrentIncident.severity.trim(),
            value: reduxCurrentIncident.severity.trim(),
          }
        : null
    );
  }, [reduxCurrentIncident]);

  const incidentTypeHandler = (e) => {
    if (e.length <= 0) {
      return;
    }
    setIncidentType(e);
    const updatedIncident = { ...reduxCurrentIncident };
    updatedIncident.incident_types = e.map((i) => i.value.trim());
    reduxDispatch(startLoading());
    reduxDispatch(updateIncident(updatedIncident));
  };

  const severityTypeHandler = (e) => {
    setSeverity({ label: e.label, value: e.value });
    const updatedSeverity = { ...reduxCurrentIncident };
    updatedSeverity.severity = e.value.trim();
    reduxDispatch(startLoading());
    reduxDispatch(updateIncident(updatedSeverity));
  };

  if (
    typeof reduxIncidents === 'undefined' ||
    reduxIncidents.ERROR ||
    !reduxValidateRBACPermissionForActionResult
  ) {
    return <div className="incidents-admin-toolbar">Loading Incidents...</div>;
  }

  return (
    <>
      <div className="bg-gray-800 rounded p-3 p-md-4 mb-4">
        <h6 className="text-uppercase mt-0">Incident Configuration</h6>
        <hr className="dashed my-3" />
        {(!!reduxCurrentIncident && (
          <>
            <label className="form-label">Currently Selected Incident</label>
            <div><b>{reduxCurrentIncident && reduxCurrentIncident.name}</b></div>
          </>
        )) || (
          <span className="d-block txt--sml mb-3">
            You do not have an Incident selected. You will need to select an
            Incident to use Layouts and other tools.
          </span>
        )}
        {!!reduxCurrentIncident && (
          <>
            <div className="mt-3">
              <label className="form-label">Incident Type</label>
              <StylishNewSelect
                options={
                  IncidentTypes &&
                  IncidentTypes.map((i) => ({ label: i, value: i }))
                }
                value={incidentType}
                placeholder="Select a Type"
                onChange={(e) => incidentTypeHandler(e)}
                isClearable={false}
                isSearchable={false}
                isMulti={true}
              />
            </div>
            <div className="mt-3">
              <label className="form-label">Severity</label>
              <StylishNewSelect
                options={
                  SeverityLevels &&
                  SeverityLevels.map((i) => ({ label: i, value: i }))
                }
                value={severity}
                placeholder="Select a Severity"
                onChange={(e) => severityTypeHandler(e)}
                isClearable={false}
                isSearchable={false}
                isMulti={false}
              />
            </div>
          </>
        )}
        {!!reduxCurrentIncident && (
          <div className="button-group flex-wrap justify-content-end mt-4">
            <StylishNewButton
              type="button"
              className="button--secondary"
              onClick={() => setIsEditIncidentOpen(true)}
              disabled={
                !reduxValidateRBACPermissionForActionResult[
                  'Create Group Incident'
                ]
              }
            >
              Edit
            </StylishNewButton>
            <StylishNewButton
              type="button"
              className="button--tertiary button--reverse"
              disabled={
                !reduxValidateRBACPermissionForActionResult[
                  'Open or Close Incident'
                ]
              }
              onClick={() => handleArchiveClick()}
            >
              Archive
            </StylishNewButton>
          </div>
        )}
        {!reduxValidateRBACPermissionForActionResult[
          'Create Group Incident'
        ] && (
          <span className="form-text form-error multiline mt-3">
            You do not have permission to edit Incident name.
          </span>
        )}
        {!reduxValidateRBACPermissionForActionResult[
          'Open or Close Incident'
        ] && (
          <span className="form-text form-error multiline mt-3">
            You do not have permission to archive Incident.
          </span>
        )}
      </div>

      {isEditIncidentOpen && (
        <EditIncidentDialog
          show={isEditIncidentOpen}
          incident={reduxCurrentIncident}
          onClose={() => setIsEditIncidentOpen(false)}
        />
      )}

      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Archive Confirmation'}
          dialogContent={'Do you want to archive this incident?'}
          dialogButtonText={'Archive'}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={onDeleteAdditionalMemberData}
        />
      )}
    </>
  );
}
