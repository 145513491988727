import ReactDOM from 'react-dom/client';
import App from './App';
import EntraIdAuth from './EntraIdAuth';
import { getStore } from './store/store';
import { Provider } from 'react-redux';
import { productionEnv } from './constants/debug';
import { isFirefox, isSafari } from 'react-device-detect';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import * as Msal from '@azure/msal-browser';
import ThemeProvider from './ThemeProvider';

// Third party styles
import './vendors.scss';

import { Auth0Provider, useAuth0, User } from '@auth0/auth0-react';
import * as microsoftTeams from '@microsoft/teams-js';
import parseJWT from 'utils/parseJWT';
import { loginRequest, msalConfig } from 'components/Auth/MSALAuthObjects';
import { datadogRum } from '@datadog/browser-rum';
import { LoginPageButton } from 'LoginPageButton';
import TeamsAppErrorDialog from 'components/Auth/TeamsAppErrorDialog';
import { Auth0ProviderOptions } from '@auth0/auth0-react/src/auth0-provider';

const datadogAppId = productionEnv && window.env.DATA_DOG_APP_ID;

const datadogClientToken = productionEnv && window.env.DATA_DOG_CLIENT_TOKEN;

if (!!datadogAppId && !!datadogClientToken) {
  datadogRum.init({
    applicationId: datadogAppId,
    clientToken: datadogClientToken,
    site: 'datadoghq.com',
    service: 'pratus',
    env: 'dev',
    // Specify a version number to identify the deployed version of your application in Datadog
    version: process.env.REACT_APP_PLATFORM_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
}

const msalInstance = new Msal.PublicClientApplication(msalConfig);
let currentAccounts = msalInstance.getAllAccounts();

const root = ReactDOM.createRoot(document.getElementById('root')!);
const isBrave = async () => {
  return (navigator.brave && (await navigator.brave.isBrave())) || false;
};

// IMPORTANT: Auth notes
// Auth is very complex with Microsoft and our Teams integration.  Following the steps of action is essential to understanding what is going on
// 1: Check if app is running in browser or Teams.
// 1.1: If teams, render login page with function to use SSO to log in to Teams
// 1.2: If not teams...
// 1.2.1: Check to see if any accounts are known to the browser with msalInstance.getAllAccounts()
// 1.2.1.1: If valid accounts, add account as loginHint to ssoRequest, attempt acquireTokenSilent -> If no accounts or request fail, render login button
// 1.2.2: Login button click => redirect

let teamsInitialized = false;

function initTeamsAuth() {
  microsoftTeams.authentication
    .getAuthToken()
    .then((result) => {
      renderAppInTeams(result);
    })
    .catch((error) => {
      console.warn('Failure: ' + error);
      renderTeamsAppError(error);
    });
}

microsoftTeams.app
  .initialize()
  .then(() => {
    teamsInitialized = true;
    console.log('Teams initialized:', teamsInitialized);

    // Call the function to initialize Teams authentication
    initTeamsAuth();
  })
  .catch((error) => {
    console.error('Teams initialization failed:', error);
    // Handle initialization failure if needed
  });

const auth0Domain = productionEnv
  ? window.env.AUTH_0_DOMAIN
  : process.env.REACT_APP_AUTH_0_DOMAIN;

const auth0ClientId = productionEnv
  ? window.env.AUTH_0_CLIENT_ID
  : process.env.REACT_APP_AUTH_0_CLIENT_ID;

const auth0Audience = productionEnv
  ? window.env.AUTH_0_AUDIENCE
  : process.env.REACT_APP_AUTH_0_AUDIENCE;

const providerConfig: Auth0ProviderOptions = {
  domain: auth0Domain,
  clientId: auth0ClientId,
  authorizationParams: {
    redirect_uri: window.location.origin,
    audience: auth0Audience,
  },
  // useRefreshTokens: true,
  cacheLocation: 'memory',
};

const auth0Primary = productionEnv
  ? window.env.AUTH_0_PRIMARY
  : process.env.REACT_APP_AUTH_0_PRIMARY;

// The environment must explicitly set the value to false.
// Otherwise, we assume we have access to Auth0
const isAuth0Primary = !(auth0Primary === 'false');

setTimeout(async () => {
  if (!isAuth0Primary && !teamsInitialized) {
    await msalInstance.initialize();

    // TODO: We should try to await the conclusion of the "loginRedirect"
    // rather than call this in parallel with the "handleRedirectPromise()".
    if (sessionStorage['hasAgreedToTerms']) {
      msalInstance
        .loginRedirect(loginRequest)
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .then(() => {})
        .catch((error: any) => {
          // handle error
          console.warn('REDIRECT ERROR: ', error);
        });
    }

    const redirectResponse = await msalInstance.handleRedirectPromise();

    if (redirectResponse !== null) {
      const { accessToken } = redirectResponse;

      if (!accessToken) {
        console.log("We don't have the token yet.");
      } else {
        currentAccounts = msalInstance.getAllAccounts();
        renderAppWithoutAuth0(redirectResponse, currentAccounts);
      }
    } else {
      root.render(
        <>
          <EntraIdAuth />
          <LoginPageButton
            isUsingTeams={teamsInitialized}
            isAuth0Primary={isAuth0Primary}
            msalInstance={msalInstance}
          />
        </>
      );
    }
  } else {
    const useRefreshTokens =
      isSafari || isFirefox || (await isBrave()) ? true : false;
    providerConfig['useRefreshTokens'] = useRefreshTokens;
    providerConfig['cacheLocation'] = useRefreshTokens
      ? 'localstorage'
      : 'memory';

    root.render(
      <Auth0Provider {...providerConfig}>
        <InitApp teamsInitialized={teamsInitialized} />
      </Auth0Provider>
    );
  }
}, 250);

type InitAppProps = {
  teamsInitialized: boolean;
  isAuth0Primary?: boolean;
};

function InitApp({ teamsInitialized, isAuth0Primary = true }: InitAppProps) {
  if (window.location.pathname !== '/') {
    sessionStorage.initialPath = window.location.pathname;
  }

  let isLoading,
    isAuthenticatedWithAuth0 = false;
  let user: User | undefined;
  let error: Error | undefined;

  if (isAuth0Primary) {
    ({
      isLoading,
      isAuthenticated: isAuthenticatedWithAuth0,
      error,
      user,
      // eslint-disable-next-line react-hooks/rules-of-hooks
    } = useAuth0());
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <LoginPageButton isUsingTeams={teamsInitialized} error={error} />;
  }
  if (teamsInitialized) {
    initTeamsAuth();
    return <div>Loading...</div>;
  }

  if (isAuthenticatedWithAuth0) {
    const finalAuthInfo = {
      teamsInitialized: teamsInitialized || false,
      account: {
        userName: user?.email,
      },
      type: 'AUTH0',
    };

    const authProvider = !!user && user?.sub?.split('|')[0];
    if (!user || (!user?.email_verified && authProvider === 'auth0')) {
      return <LoginPageButton isUsingTeams={teamsInitialized} user={user} />;
    }
    return (
      <DndProvider backend={HTML5Backend}>
        <Provider
          store={getStore({
            auth: finalAuthInfo,
          })}
        >
          <ThemeProvider>
            <App />
          </ThemeProvider>
        </Provider>
      </DndProvider>
    );
  } else {
    root.render(
      <>
        {!isAuth0Primary && <EntraIdAuth />}
        <Auth0Provider {...providerConfig}>
          <LoginPageButton
            isUsingTeams={teamsInitialized}
            isAuth0Primary={isAuth0Primary}
            msalInstance={msalInstance}
          />
        </Auth0Provider>
      </>
    );
    return null;
  }
}

const renderAppWithoutAuth0 = (
  authInfo: Msal.AuthenticationResult,
  accounts: Msal.AccountInfo[] = []
) => {
  const email_address = accounts[0]?.username;
  const finalAuthInfo: any = {
    ...authInfo,
    teamsInitialized: teamsInitialized ?? false,
    msalInstance: msalInstance,
    type: 'MSAL',
    account: {
      ...authInfo.account,
      userName: email_address,
    },
  };

  sessionStorage.setItem('isUsingTeams', 'false');

  root.render(
    <DndProvider backend={HTML5Backend}>
      <Provider
        store={getStore({
          auth: finalAuthInfo,
        })}
      >
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </Provider>
    </DndProvider>
  );
};

const renderAppInTeams = (authInfo: string) => {
  const parsedID = parseJWT(authInfo);
  const finalAuthInfo: any = {
    account: {
      userName: parsedID['preferred_username'],
    },
    accessToken: authInfo,
    teamsInitialized: true,
    expiresOn: parsedID.expiresOn,
    msalInstance: msalInstance,
    type: 'MSAL',
  };

  sessionStorage.setItem('accessToken', authInfo);
  sessionStorage.setItem('isUsingTeams', 'true');
  root.render(
    <DndProvider backend={HTML5Backend}>
      <Provider
        store={getStore({
          auth: finalAuthInfo,
        })}
      >
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </Provider>
    </DndProvider>
  );
};

const renderTeamsAppError = (error: Error) => {
  root.render(
    <TeamsAppErrorDialog
      show={true}
      error={error}
      clientId={msalConfig.auth.clientId}
    />
  );
};
