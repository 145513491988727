import SunCalc from 'suncalc';

export const MapSkySource = {
  type: 'raster-dem',
  url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
};

export const MapTerrainConfig = {
  source: 'mapbox-dem',
  exaggeration: ['interpolate', ['exponential', 0.5], ['zoom'], 0, 0.2, 7, 1],
};

// For Mapbox Atmospheric Sky (https://docs.mapbox.com/mapbox-gl-js/example/atmospheric-sky/)
function getSunPosition(map, date = null) {
  var center = map.getCenter();
  var sunPos = SunCalc.getPosition(date || Date.now(), center.lat, center.lng);
  var sunAzimuth = 180 + (sunPos.azimuth * 180) / Math.PI;
  var sunAltitude = 90 - (sunPos.altitude * 180) / Math.PI;
  return [sunAzimuth, sunAltitude];
}

export const MapSkyConfig = (map) => {
  return {
    id: 'sky',
    type: 'sky',
    paint: {
      'sky-opacity': ['interpolate', ['linear'], ['zoom'], 0, 0, 5, 0.3, 8, 1],
      // set up the sky layer for atmospheric scattering
      'sky-type': 'atmosphere',
      // explicitly set the position of the sun rather than allowing the sun to be attached to the main light source
      'sky-atmosphere-sun': getSunPosition(map),
      // set the intensity of the sun as a light source (0-100 with higher values corresponding to brighter skies)
      'sky-atmosphere-sun-intensity': 5,
    },
  };
};
