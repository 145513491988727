// ExternalMapManager.js
import React, { useState } from 'react';
import {
  useCreateExternalMap,
  useExternalMaps,
  useDeleteExternalMap,
  useUpdateExternalMap,
} from './hooks/useExternalMaps';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import SimpleEntityList from 'components/DesignSystems/New/SimpleEntityList';
import { StylishSwitcher } from 'components/DesignSystems/New/StylishSwitcher';
import { useToggle } from 'react-use';
import ExternalMapEditDrawer from './ExternalMapEditDrawer';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
import { ListGroup, ListGroupItem, Modal } from 'react-bootstrap';
import { SharedIcon } from '../SharedIcon/SharedIcon';

// Default external maps configuration
const DEFAULT_MAPS = [
  {
    name: 'Naver CCTV',
    url: 'https://map.naver.com/p/settings?c=12.81,0,0,0,bcdh'
  },
];

const ExternalMapManager = ({ onSelectMap, onClose, currentMap }) => {
  const {
    data: externalMaps = [],
    isLoading,
    isError,
  } = useExternalMaps();
  const createExternalMapMutation = useCreateExternalMap();
  const updateExternalMapMutation = useUpdateExternalMap();
  const deleteExternalMapMutation = useDeleteExternalMap();

  const [drawerOpen, toggleDrawer] = useToggle(false);
  const [selectedMap, setSelectedMap] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const handleOpenAddDrawer = () => {
    setSelectedMap(null);
    toggleDrawer(true);
  };

  const handleOpenEditDrawer = (map, e) => {
    e.stopPropagation();
    setSelectedMap(map);
    toggleDrawer(true);
  };

  const handleCloseDrawer = () => {
    setSelectedMap(null);
    toggleDrawer(false);
  };

  const handleSaveMap = (mapData) => {
    if (selectedMap) {
      updateExternalMapMutation.mutate(mapData, {
        onSuccess: () => {
          toast.success('External map updated successfully.', toastConfig);
          handleCloseDrawer();
        },
        onError: (error) => {
          console.error('Error updating external map:', error);
          toast.error('Failed to update external map.', toastConfig);
        },
      });
    } else {
      createExternalMapMutation.mutate(mapData, {
        onSuccess: () => {
          toast.success('External map created successfully.', toastConfig);
          handleCloseDrawer();
        },
        onError: (error) => {
          console.error('Error creating external map:', error);
          toast.error('Failed to create external map.', toastConfig);
        },
      });
    }
  };

  const handleDeleteMap = (id, e) => {
    e.stopPropagation();
    setDeleteId(id);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    if (deleteId) {
      deleteExternalMapMutation.mutate(deleteId, {
        onSuccess: () => {
          toast.success('External map deleted successfully.', toastConfig);
          // If the deleted map was selected, deselect it
          if (currentMap?.id === deleteId) {
            onSelectMap(null);
          }
          setShowDeleteModal(false);
          setDeleteId(null);
        },
        onError: (error) => {
          console.error('Error deleting external map:', error);
          toast.error('Failed to delete external map.', toastConfig);
          setShowDeleteModal(false);
          setDeleteId(null);
        },
      });
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
    setDeleteId(null);
  };

  const handleToggleMap = (map) => {
    onSelectMap(map);
  };

  const isMapActive = (map) => {
    return currentMap?.url === map.url;
  };

  const filteredMaps = externalMaps.filter((map) =>
    map.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (isError) {
    return <div className="error-message">Error loading External Maps.</div>;
  }

  return (
    <div className="sidebar-content-wide">
      <div className="sidebar-title">
        <h4 className="m-0">External Maps</h4>
        <StylishNewButton customButton className="button--icon" onClick={onClose}>
          <SharedIcon iconName="close" size="sm" />
        </StylishNewButton>
      </div>

      <div className="sidebar-inner">
        <div className="d-flex gap-2 mb-3 align-items-center">
          <StylishNewButton onClick={handleOpenAddDrawer} className="button--primary">
            Add Map
          </StylishNewButton>
          <div className="flex-grow-1">
            <StylishNewSearchBar
              value={searchTerm}
              onChangeSearchTerm={setSearchTerm}
              placeholder="Search External Maps..."
            />
          </div>
        </div>

        {isLoading ? (
          <div>Loading External Maps...</div>
        ) : (
          <ListGroup className="py-0">
            {/* Filter both default and custom maps */}
            {[
              ...DEFAULT_MAPS.filter(map => 
                map.name.toLowerCase().includes(searchTerm.toLowerCase())
              ),
              ...filteredMaps
            ].map((map, index) => (
              <ListGroupItem
                key={map.id || `default-${index}`}
                className="d-flex justify-content-between align-items-center py-3"
              >
                <div>{DEFAULT_MAPS.find(m=>m.name===map.name) && `(Default) ` || `(Custom) `}{map.name}</div>
                <div className="d-flex align-items-center">
                  <StylishSwitcher
                    className={map.id ? "me-3" : "ms-auto"}
                    checked={isMapActive(map)}
                    onChange={() => handleToggleMap(map)}
                  />
                  {map.id && (
                    <>
                      <StylishNewButton
                        className="button--secondary button--icon me-2"
                        onClick={(e) => handleOpenEditDrawer(map, e)}
                      >
                        <SharedIcon iconName="edit" size="sm" />
                      </StylishNewButton>
                      <StylishNewButton
                        className="button--secondary button--icon"
                        onClick={(e) => handleDeleteMap(map.id, e)}
                      >
                        <SharedIcon iconName="delete" size="sm" />
                      </StylishNewButton>
                    </>
                  )}
                </div>
              </ListGroupItem>
            ))}
          </ListGroup>
        )}
      </div>

      {drawerOpen && (
        <ExternalMapEditDrawer
          show={drawerOpen}
          onHide={handleCloseDrawer}
          onSave={handleSaveMap}
          externalMap={selectedMap}
        />
      )}

      <Modal
        show={showDeleteModal}
        onHide={handleCancelDelete}
        centered
        backdrop={true}
        keyboard={true}
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Delete External Map</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this External Map?
        </Modal.Body>
        <Modal.Footer>
          <StylishNewButton
            className="button--secondary"
            onClick={handleCancelDelete}
          >
            Cancel
          </StylishNewButton>
          <StylishNewButton
            className="button--tertiary"
            onClick={handleConfirmDelete}
          >
            Delete
          </StylishNewButton>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ExternalMapManager;