import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import config from 'constants/apiConfig';

export const useAirportsData = (
  searchInput: string,
  limit: number,
  page: number,
  aors?: string[]
) => {
  return useQuery({
    queryKey: ['airports', searchInput, page, aors],
    queryFn: async () => {
      const response = await axios.get(`${config.apiGateway.fetchAirports}`, {
        params: { searchInput, page, limit, aors },
      });
      return response.data;
    },
  });
};

export const useAirportsDataCount = (searchInput: string) => {
  return useQuery({
    queryKey: ['airportsCount', searchInput],
    queryFn: async () => {
      const response = await axios.get(
        `${config.apiGateway.fetchAirportsCount}`,
        {
          params: { searchInput },
        }
      );
      return response.data;
    },
  });
};

export const useSeaportsData = (
  searchInput: string,
  limit: number,
  page: number,
  aors?: string[]
) => {
  return useQuery({
    queryKey: ['seaports', searchInput, page, aors],
    queryFn: async () => {
      const response = await axios.get(`${config.apiGateway.fetchSeaports}`, {
        params: { searchInput, page, limit, aors },
      });
      return response.data;
    },
  });
};

export const useSeaportsDataCount = (searchInput: string) => {
  return useQuery({
    queryKey: ['searportsCount', searchInput],
    queryFn: async () => {
      const response = await axios.get(
        `${config.apiGateway.fetchSeaportsCount}`,
        {
          params: { searchInput },
        }
      );
      return response.data;
    },
  });
};


export const useDepartureData = (iata_codes: string[]) => {
  
  return useQuery({
    queryKey: ['DepartureData', iata_codes],
    queryFn: async () => {
      const response = await axios.post(
        `${config.apiGateway.importAirportDepartures}`,
        { iata_codes },
      );
      return response.data;
    }
  });
};

export const useArrivalData = (iata_codes: string[]) => {
  
  return useQuery({
    queryKey: ['ArrivalData', iata_codes],
    queryFn: async () => {
      const response = await axios.post(
        `${config.apiGateway.importAirportArrivals}`,
        { iata_codes },
      );
      return response.data;
    }
  });
};

export const useAssetsData = (icao_codes: string[]) => {
  
  return useQuery({
    queryKey: ['AssetsData', icao_codes],
    queryFn: async () => {
      const response = await axios.get(
        `${config.apiGateway.importAirAssests}`,{
          params: { icao_codes },
        });
      return response.data;
    }
  });
};