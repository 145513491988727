import * as turf from '@turf/turf';
import { v4 as uuidv4 } from 'uuid';

export function createLocationFromGeojson(
  gj,
  mapboxTokenActual,
  setLocation,
  setSelectedLat,
  setSelectedLng
) {
  // 1: Normalize all Locations to FeatureCollections
  let normalizedGeojsonFeatures = [];
  if (gj.type === 'FeatureCollection') {
    normalizedGeojsonFeatures = gj.features;
  } else if (gj.type === 'Feature') {
    normalizedGeojsonFeatures = [gj];
  }

  // 2: Get centroid if not point, otherwise create centroid
  const coordinates = normalizedGeojsonFeatures[0].geometry.coordinates;
  let centroid = coordinates;
  let placeUrl;

  if (normalizedGeojsonFeatures[0].geometry.type === 'Polygon') {
    const polygon = turf.polygon(coordinates);
    centroid = turf.centroid(polygon);
    placeUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${centroid.geometry.coordinates[0]},${centroid.geometry.coordinates[1]}.json?access_token=${mapboxTokenActual}`;
  } else if (normalizedGeojsonFeatures[0].geometry.type === 'MultiPolygon') {
    const polygon = turf.multiPolygon(coordinates);
    centroid = turf.centroid(polygon);
    placeUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${centroid.geometry.coordinates[0]},${centroid.geometry.coordinates[1]}.json?access_token=${mapboxTokenActual}`;
  } else if (normalizedGeojsonFeatures[0].geometry.type === 'Point') {
    centroid = normalizedGeojsonFeatures[0];
    placeUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${coordinates[0]},${coordinates[1]}.json?access_token=${mapboxTokenActual}`;
  }

  // 3: Geocode for address
  fetch(placeUrl)
    .then((res) => res.json())
    .then((response) => {
      const place = response.features.find(
        (f) =>
          f.id.includes('place') ||
          f.id.includes('district') ||
          f.id.includes('region') ||
          f.id.includes('country')
      );
      const placeName = (!!place && place.place_name) || '';

      const addressFeature = response.features.find(
        (f) =>
          f.id.includes('address') ||
          f.id.includes('poi') ||
          f.id.includes('postcode')
      );
      const addressName = (!!addressFeature && addressFeature.place_name) || '';

      const newLocation = {
        id: uuidv4(),
        address: addressName,
        name: placeName,
        centroid,
        geojson: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: normalizedGeojsonFeatures,
          },
        },
      };
      setLocation(newLocation);
      setSelectedLat(newLocation.centroid.geometry.coordinates[1]);
      setSelectedLng(newLocation.centroid.geometry.coordinates[0]);
    });
}
