import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { FormBuilderDashboardMain } from './FormBuilderDashboardMain';
import { FormBuilderDashboardSidebar } from './FormBuilderDashboardSidebar';
import AddUpdateWorkAssignment from './WorkAssignment/AddUpdateWorkAssignment';
import { useDispatch, useSelector } from 'react-redux';
import NewResourcesDialog from 'components/IncidentConfig/NewResourcesDialog';
import NewObjectiveDialog from 'components/IncidentConfig/NewObjectiveDialog';

import {
  getAllObjectiveToIncident,
  getAllResourcesToIncident,
} from 'actions/incidentActions';
import { endLoading } from 'reducers/loading/loading.action';
import PageTitle from '../headers/PageTitle';
import { SharedIcon } from '../SharedIcon/SharedIcon';

export const FormBuilderDashboard = () => {
  const reduxDispatch = useDispatch();
  const incidentObjectives = useSelector(
    (state) => state.app.incidentObjectives
  );
  const reduxIncidentResources = useSelector(
    (state) => state.app.incidentResources
  );
  const reduxCurrentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });

  const isIncidentObjectivesLoaded = useSelector((state) => {
    return state.app.isIncidentObjectivesLoaded;
  });

  const isGroupObjectivesLoaded = useSelector((state) => {
    return state.app.isGroupObjectivesLoaded;
  });

  const isIncidentResourcesLoaded = useSelector((state) => {
    return state.app.isIncidentResourcesLoaded;
  });

  const isGroupResourcesLoaded = useSelector((state) => {
    return state.app.isGroupResourcesLoaded;
  });

  const [
    isWorkAssignmentsDialogOpen,
    setIsWorkAssignmentsDialogOpen,
  ] = useState(false);
  useEffect(() => {
    if (!!reduxCurrentIncident && !!reduxCurrentIncident.id) {
      reduxDispatch(getAllResourcesToIncident());
      reduxDispatch(getAllObjectiveToIncident());
    }
  }, [reduxCurrentIncident]);
  const [isNewResourcesDialogOpen, setIsNewResourcesDialogOpen] = useState(
    false
  );

  const [isNewObjectiveDialogOpen, setIsNewObjectiveDialogOpen] = useState(
    false
  );

  useEffect(() => {
    if (
      isIncidentObjectivesLoaded ||
      isGroupObjectivesLoaded ||
      isIncidentResourcesLoaded ||
      isGroupResourcesLoaded
    ) {
      reduxDispatch(endLoading());
    }
  }, [
    isIncidentObjectivesLoaded,
    isGroupObjectivesLoaded,
    isIncidentResourcesLoaded,
    isGroupResourcesLoaded,
  ]);

  return (
    <>
        <PageTitle title="Incident Management Overview" parents={['Incident Management']}>
          <Dropdown>
            <Dropdown.Toggle className="button button--primary button--create">
              <SharedIcon iconName="add" classes="px-0" bold />
              <span>Create</span>
              <SharedIcon iconName="keyboard_arrow_down" classes="px-0" bold />
            </Dropdown.Toggle>
            <Dropdown.Menu align="start">
              <Dropdown.Item
                onClick={() => setIsWorkAssignmentsDialogOpen(true)}
              >
                New Work Assignment
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setIsNewObjectiveDialogOpen(true)}>
                New Objective
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setIsNewResourcesDialogOpen(true)}>
                New Resource
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </PageTitle>
      <div className="dashboard">
        <div className="row">
          <div className="col-xl-3 d-none d-xl-block">
            <FormBuilderDashboardSidebar />
          </div>
          <div className="col-xl-9">
            <FormBuilderDashboardMain />
          </div>
        </div>
      </div>
      {isWorkAssignmentsDialogOpen && (
        <AddUpdateWorkAssignment
          incidentObjectives={incidentObjectives}
          incidentResources={reduxIncidentResources}
          workAssignmentModalType={'New'}
          updateActionData={null}
          show={isWorkAssignmentsDialogOpen}
          onClose={() => setIsWorkAssignmentsDialogOpen(false)}
        />
      )}
      {isNewResourcesDialogOpen && (
        <NewResourcesDialog
          show={isNewResourcesDialogOpen}
          modalType={'New'}
          onClose={() => setIsNewResourcesDialogOpen(false)}
        />
      )}
      {isNewObjectiveDialogOpen && (
        <NewObjectiveDialog
          show={isNewObjectiveDialogOpen}
          modalType={'New'}
          onClose={() => setIsNewObjectiveDialogOpen(false)}
        />
      )}
    </>
  );
};
