import PratusLogo from './assets/images/pratus__logo.svg';
import PratusLogoIcon from './assets/images/pratus__logoIcon.svg';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { useAuth0, User } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import EmailVerificationDialog from 'components/Auth/EmailVerificationDialog';
import UserAuthorizationFailedDialog from 'components/Auth/UserAuthorizationFailedDialog';

import * as Msal from '@azure/msal-browser';
import { loginRequest, msalConfig } from 'components/Auth/MSALAuthObjects';

type LoginPageProps = {
  isAuth0Primary?: boolean;
  isUsingTeams: boolean;
  user?: User;
  error?: any;
  msalInstance?: any;
};

export function LoginPageButton({
  isAuth0Primary = true,
  isUsingTeams = false,
  user,
  error,
  msalInstance,
}: LoginPageProps) {
  let loginFunction;

  if (isAuth0Primary) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    ({ loginWithRedirect: loginFunction } = useAuth0());
  } else {
    loginFunction = initMSALAuth;
  }

  async function initMSALAuth() {
    sessionStorage['hasAgreedToTerms'] = true;

    let instance = msalInstance;

    if (!instance) {
      instance = new Msal.PublicClientApplication(msalConfig);
      await instance.initialize(); // Initialize the MSAL instance before any other operations
    }

    instance
      .loginRedirect(loginRequest)
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .then(() => {})
      .catch((error: any) => {
        // handle error
        console.warn('REDIRECT ERROR: ', error);
      });
  }

  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [showEmailConfirmDialog, setShowEmailConfirmDialog] = useState(false);
  useEffect(() => {
    if (error) {
      setShowErrorDialog(true);
    }
    const authProvider = !!user && user.sub?.split('|')[0];

    if (user && !user.email_verified && authProvider === 'auth0') {
      setShowEmailConfirmDialog(true);
    }
  }, [error, user]);
  return (
    <>
      <div className="site-authentication">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="site-authentication__logo mb-5">
                <img className="d-none d-md-flex" src={PratusLogo} alt="" />
                <img className="d-flex d-md-none" src={PratusLogoIcon} alt="" />
              </div>
              <div className="row justify-content-center">
                <div className="col-md-10 col-lg-10 col-xl-6">
                  <p className="m-0">
                    To connect with the Disastertech team, visit <br />
                    <a href="https://www.disastertech.com" target="_blank" rel="noreferrer">
                      https://www.disastertech.com
                    </a>
                  </p>
                </div>
              </div>
              <div className="button-group d-block d-md-flex justify-content-center mt-5">
                {!!(isUsingTeams === false) && (
                  <StylishNewButton
                    className="button--primary w-100 w-md-auto mt-3 mt-md-0 ms-0 ms-md-3"
                    onClick={() => loginFunction()}
                  >
                    Log into PRATUS
                  </StylishNewButton>
                )}
              </div>
              <p className="mt-4 mb-0">
                By entering, you consent to our{' '}
                <a href="/privacy.html" target="_blank">
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a href="/tos.html" target="_blank">
                  Terms of Service
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
      {showEmailConfirmDialog ? (
        <EmailVerificationDialog
          user={user}
          show={showEmailConfirmDialog}
          onClose={() => setShowEmailConfirmDialog(false)}
        />
      ) : null}
      {showErrorDialog ? (
        <UserAuthorizationFailedDialog
          show={showErrorDialog}
          onClose={() => setShowErrorDialog(false)}
        />
      ) : null}
    </>
  );
}
