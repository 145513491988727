import config from 'constants/apiConfig';
import axios from 'axios';
import parseErrorMessage from 'utils/parseErrorMessage';
import { useQuery } from '@tanstack/react-query';

import {
  SEND_NOTIFICATION_REQUEST,
  SEND_NOTIFICATION_SUCCESS,
  SEND_NOTIFICATION_ERROR,
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_ERROR,
  UPSERT_NOTIFICATION_REQUEST,
  UPSERT_NOTIFICATION_SUCCESS,
  UPSERT_NOTIFICATION_ERROR,
  DELETE_NOTIFICATION_REQUEST,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_ERROR,
  FETCH_NOTIFICATION_LOG_REQUEST,
  FETCH_NOTIFICATION_LOG_SUCCESS,
  FETCH_NOTIFICATION_LOG_ERROR,
  FETCH_NOTIFICATION_LOG_INSTANCE_REQUEST,
  FETCH_NOTIFICATION_LOG_INSTANCE_SUCCESS,
  FETCH_NOTIFICATION_LOG_INSTANCE_ERROR,
  FETCH_ALL_NOTIFICATION_LOG_SUMMARIES_REQUEST,
  FETCH_ALL_NOTIFICATION_LOG_SUMMARIES_SUCCESS,
  FETCH_ALL_NOTIFICATION_LOG_SUMMARIES_ERROR,
  CREATE_DISTRIBUTION_LIST,
  FETCH_ALL_DISTRIBUTION_LIST,
  DELETE_DISTRIBUTION_LIST_ITEM,
  CLEAR_NOTIFICATION_STATUS,
  CLEAR_UPSERT_NOTIFICATION_STATUS,
  CLEAR_NOTIFICATION_PAGE_DATA,
  SET_NOTIFICATION_PAGE_DATA,
} from 'constants/notification-action-types';
import { toast } from 'react-toastify';
import { noAutoClose } from 'assets/data/config';

export function fetchNotificationsByGroupRequest() {
  return {
    type: FETCH_NOTIFICATIONS_REQUEST,
  };
}

export const fetchNotificationsByGroupSuccess = (fetchedNotifications) => ({
  type: FETCH_NOTIFICATIONS_SUCCESS,
  payload: fetchedNotifications,
});

export function fetchNotificationsByGroupError(error) {
  // TODO CRITICAL
  const errorMessage =
    'fetchNotificationsByGroupError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '');
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_NOTIFICATIONS_ERROR,
  };
}

export function fetchNotificationsByGroup(group_guid) {
  return (dispatch) => {
    dispatch(fetchNotificationsByGroupRequest());
    return axios(config.apiGateway.fetchNotificationsByGroup, {
      method: 'POST',
      data: JSON.stringify({
        group_guid,
      }),
    })
      .then((response) => {
        const fetchedNotifications = response.data || {};
        dispatch(fetchNotificationsByGroupSuccess(fetchedNotifications));

        return fetchedNotifications;
      })
      .catch((error) => {
        dispatch(fetchNotificationsByGroupError(error));
      });
  };
}

function upsertNotificationByGroupRequest() {
  return {
    type: UPSERT_NOTIFICATION_REQUEST,
  };
}

export const upsertNotificationByGroupSuccess = (upsertedNotification) => ({
  type: UPSERT_NOTIFICATION_SUCCESS,
  payload: upsertedNotification,
});

function upsertNotificationByGroupError(error) {
  const errorMessage =
    'upsertNotificationByGroupError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '');
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: UPSERT_NOTIFICATION_ERROR,
  };
}

export function upsertNotificationByGroup(notification) {
  return (dispatch) => {
    dispatch(upsertNotificationByGroupRequest());
    return axios(config.apiGateway.upsertNotification, {
      method: 'POST',
      data: JSON.stringify({
        notification: notification,
      }),
    })
      .then((response) => {
        const upsertedNotification = response.data || {};
        dispatch(upsertNotificationByGroupSuccess(upsertedNotification));
        dispatch(fetchNotificationsByGroup(notification.group_guid));
        setTimeout(() => {
          dispatch(clearUpsertedNotificationStatus());
        }, 3000);
        return upsertedNotification;
      })
      .catch((error) => {
        dispatch(upsertNotificationByGroupError(error));
      });
  };
}

function deleteNotificationByGroupRequest() {
  return {
    type: DELETE_NOTIFICATION_REQUEST,
  };
}

export const deleteNotificationByGroupSuccess = (deletedNotification) => ({
  type: DELETE_NOTIFICATION_SUCCESS,
  payload: deletedNotification,
});

function deleteNotificationByGroupError(error) {
  const errorMessage =
    'deleteNotificationError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '');
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: DELETE_NOTIFICATION_ERROR,
  };
}

export function deleteNotificationByGroup(notification) {
  return (dispatch) => {
    dispatch(deleteNotificationByGroupRequest());
    return axios(config.apiGateway.deleteNotification, {
      method: 'POST',
      data: JSON.stringify({
        notification: notification,
      }),
    })
      .then((response) => {
        const deletedNotification = response.data || {};
        dispatch(deleteNotificationByGroupSuccess(deletedNotification));
        dispatch(fetchNotificationsByGroup(notification.group_guid));
        return deletedNotification;
      })
      .catch((error) => {
        dispatch(deleteNotificationByGroupError(error));
      });
  };
}

function sendNotificationByGroupRequest() {
  return {
    type: SEND_NOTIFICATION_REQUEST,
  };
}

export const sendNotificationByGroupSuccess = (notificationResult) => ({
  type: SEND_NOTIFICATION_SUCCESS,
  payload: notificationResult,
});

function sendNotificationByGroupError(error) {
  const errorMessage =
    'sendNotificationByGroupError\n\n' + parseErrorMessage(error);
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: SEND_NOTIFICATION_ERROR,
  };
}

export function sendNotificationByGroup(notification) {
  return (dispatch) => {
    dispatch(sendNotificationByGroupRequest());
    return axios(config.apiGateway.sendNotificationByGroup, {
      method: 'POST',
      data: JSON.stringify({
        notification,
      }),
    })
      .then((response) => {
        const notificationResult = response.data || {};
        dispatch(sendNotificationByGroupSuccess(notificationResult));
        setTimeout(() => {
          dispatch(clearNotificationStatus());
        }, 3000);
        return notificationResult;
      })
      .catch((error) => {
        dispatch(sendNotificationByGroupError(error));
      });
  };
}

function fetchNotificationLogRequest() {
  return {
    type: FETCH_NOTIFICATION_LOG_REQUEST,
  };
}

export const fetchNotificationLogSuccess = (notificationLog) => ({
  type: FETCH_NOTIFICATION_LOG_SUCCESS,
  payload: notificationLog,
});

function fetchNotificationLogError(error) {
  // TODO CRITICAL
  const errorMessage =
    'fetchNotificationLogError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_NOTIFICATION_LOG_ERROR,
  };
}

export function fetchNotificationLog(notification) {
  return (dispatch) => {
    dispatch(fetchNotificationLogRequest());
    return axios(config.apiGateway.fetchNotificationLog, {
      method: 'POST',
      data: JSON.stringify({
        notification,
      }),
    })
      .then((response) => {
        const notificationLog = response.data || {};
        dispatch(fetchNotificationLogSuccess(notificationLog));

        return notificationLog;
      })
      .catch((error) => {
        dispatch(fetchNotificationLogError(error));
      });
  };
}

export const useNotificationCount = (group_guid) => {
  return useQuery({
    queryKey: [group_guid],
    queryFn: async () => {
      const { data } = await axios.get(
        `${config.apiGateway.fetchNotificationCount}?group_guid=${group_guid}`
      );
      return parseInt(data.coalesce);
    },
    enabled: !!group_guid,
  });
};

function fetchNotificationLogInstanceRequest() {
  return {
    type: FETCH_NOTIFICATION_LOG_INSTANCE_REQUEST,
  };
}

export const fetchNotificationLogInstanceSuccess = (
  notificationLogInstance
) => ({
  type: FETCH_NOTIFICATION_LOG_INSTANCE_SUCCESS,
  payload: notificationLogInstance,
});

function fetchNotificationLogInstanceError(error) {
  // TODO CRITICAL
  const errorMessage =
    'fetchNotificationLogInstanceError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_NOTIFICATION_LOG_INSTANCE_ERROR,
  };
}

export function fetchNotificationLogInstance(groupId, notificationLog) {
  return (dispatch) => {
    dispatch(fetchNotificationLogInstanceRequest());
    return axios(config.apiGateway.fetchNotificationLogInstance, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: groupId,
        notificationLog,
      }),
    })
      .then((response) => {
        const notificationLogInstance = response.data || {};
        dispatch(fetchNotificationLogInstanceSuccess(notificationLogInstance));

        return notificationLogInstance;
      })
      .catch((error) => {
        dispatch(fetchNotificationLogInstanceError(error));
      });
  };
}

function fetchAllNotificationLogSummariesRequest() {
  return {
    type: FETCH_ALL_NOTIFICATION_LOG_SUMMARIES_REQUEST,
  };
}

export const fetchAllNotificationLogSummariesSuccess = (
  fetchedNotificationLogSummaries
) => ({
  type: FETCH_ALL_NOTIFICATION_LOG_SUMMARIES_SUCCESS,
  payload: fetchedNotificationLogSummaries,
});

function fetchAllNotificationLogSummariesError(error) {
  // TODO CRITICAL
  const errorMessage =
    'fetchAllNotificationLogSummariesError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '');
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_ALL_NOTIFICATION_LOG_SUMMARIES_ERROR,
  };
}

export function fetchAllNotificationLogSummaries(group_guid) {
  return (dispatch) => {
    dispatch(fetchAllNotificationLogSummariesRequest());
    return axios(config.apiGateway.fetchAllNotificationLogSummaries, {
      method: 'POST',
      data: JSON.stringify({
        group_guid,
      }),
    })
      .then((response) => {
        const fetchedNotificationLogSummaries = response.data || {};
        dispatch(
          fetchAllNotificationLogSummariesSuccess(
            fetchedNotificationLogSummaries
          )
        );
        return fetchedNotificationLogSummaries;
      })
      .catch((error) => {
        dispatch(fetchAllNotificationLogSummariesError(error));
      });
  };
}

export const createDistributionList = (distributionList) => {
  return (dispatch, getState) => {
    const currentlySelectedGroupId =
      getState().app.currentlySelectedGroup.group_guid;
    return axios(config.apiGateway.createDistributionList, {
      method: 'POST',
      data: JSON.stringify({
        distributionList: {
          distribution_list_id: distributionList.id,
          ...distributionList,
          group_guid: currentlySelectedGroupId,
        },
      }),
    })
      .then((response) => {
        dispatch({
          type: CREATE_DISTRIBUTION_LIST,
          payload: {
            ...distributionList,
            group_guid: currentlySelectedGroupId,
          },
        });
        dispatch(
          fetchAllDistributionList(
            getState().app.currentlySelectedGroup.group_guid
          )
        );
        return response;
      })
      .catch((err) => {
        distributionListUpdateError({
          api: 'createDistributionList',
          error: err,
          message: 'Failed to Create Relevant Team',
        });
      });
  };
};

export const fetchAllDistributionList = (group_guid) => {
  return (dispatch) => {
    return axios(config.apiGateway.fetchDistributionListByGroup, {
      method: 'POST',
      data: JSON.stringify({
        group_guid,
      }),
    })
      .then((response) => {
        dispatch({ type: FETCH_ALL_DISTRIBUTION_LIST, payload: response.data });

        return response;
      })
      .catch((err) => {
        distributionListUpdateError({
          api: 'deleteDistributionList',
          error: err,
          message: 'Failed to Fetch Relevant Team',
        });
      });
  };
};

export const deleteDistributionList = (distribution_list_id) => {
  return (dispatch) => {
    return axios(config.apiGateway.deleteDistributionList, {
      method: 'POST',
      data: JSON.stringify({
        distribution_list_id,
      }),
    })
      .then((response) => {
        dispatch({
          type: DELETE_DISTRIBUTION_LIST_ITEM,
          payload: distribution_list_id,
        });
        return response;
      })
      .catch((err) => {
        distributionListUpdateError({
          api: 'deleteDistributionList',
          error: err,
          message: 'Failed to Delete Relevant Team',
        });
      });
  };
};

export const editDistributionList = (distributionList) => {
  return async (dispatch, getState) => {
    let provDistributionList = getState().app.distributionListItems.filter(
      (ele) => ele.id === distributionList.id
    );

    if (provDistributionList.length > 0) {
      provDistributionList = provDistributionList[0];
      const removedMember = [];
      const member = [...distributionList.members];
      const nonMembers = [...distributionList.nonMembers];
      provDistributionList.members.forEach((ele) => {
        const index = distributionList.members.findIndex((e) => e === ele);
        if (index === -1) {
          removedMember.push(ele);
        } else {
          distributionList.members.splice(index, 1);
        }
      });
      const removedNonMember = [];
      provDistributionList.nonMembers.forEach((ele) => {
        const index = distributionList.nonMembers.findIndex((e) => e === ele);
        if (index === -1) {
          removedNonMember.push(ele.id);
        } else {
          distributionList.nonMembers.splice(index, 1);
        }
      });
      return axios(config.apiGateway.removeMembersFromDistributionList, {
        method: 'POST',
        data: JSON.stringify({
          distribution_list_id: distributionList.id,
          removedMember,
          removedNonMember,
        }),
      })
        .then(() => {
          const distributionListData = {
            ...distributionList,
            group_guid: getState().app.currentlySelectedGroup.group_guid,
            distribution_list_id: distributionList.id,
          };
          if (
            distributionListData.members.length > 0 ||
            distributionListData.nonMembers.length > 0 ||
            (provDistributionList.teamLocation &&
              (provDistributionList.teamLocation.location_entity_id !==
                distributionList.location_entity_id ||
                provDistributionList.teamLocation.location_sub_entity_id !==
                  distributionList.location_sub_entity_id ||
                provDistributionList.teamLocation.location_enterprise_id !==
                  distributionList.location_enterprise_id ||
                provDistributionList.teamLocation.location_asset_id !==
                  distributionList.location_asset_id ||
                provDistributionList.name !== distributionList.name ||
                provDistributionList.teamLocation.location_id !==
                  distributionList.location_id))
          ) {
            return axios(config.apiGateway.createDistributionList, {
              method: 'POST',
              data: JSON.stringify({
                distributionList: distributionListData,
                group_guid: provDistributionList.group_guid,
              }),
            });
          } else {
            return;
          }
        })
        .then((response) => {
          const payload = {
            id: distributionList.id,
            distribution_list_id: distributionList.id,
            group_guid: getState().app.currentlySelectedGroup.group_guid,
            members: member,
            nonMembers: nonMembers,
            name: distributionList.name,
          };
          dispatch({ type: CREATE_DISTRIBUTION_LIST, payload: payload });
          dispatch(
            fetchAllDistributionList(
              getState().app.currentlySelectedGroup.group_guid
            )
          );
          return response;
        })
        .catch((err) => {
          distributionListUpdateError({
            api: 'removeMembersFromDistributionList or createDistributionList',
            error: err,
            message: 'Failed to Update Relevant Team',
          });
        });
    }
  };
};

export const addNotificationPageData = (notification_page_data) => {
  return (dispatch) => {
    dispatch({
      type: SET_NOTIFICATION_PAGE_DATA,
      payload: notification_page_data,
    });
  };
};

const distributionListUpdateError = ({ api, message }) => {
  const errorMessage =
    `${api}\n\n` +
    ((!!message && typeof message === 'string' && message) || '');
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
};

export const clearNotificationStatus = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_NOTIFICATION_STATUS });
  };
};

export const clearUpsertedNotificationStatus = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_UPSERT_NOTIFICATION_STATUS });
  };
};

export const clearNotificationPageData = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_NOTIFICATION_PAGE_DATA });
  };
};
