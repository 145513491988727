import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import config from 'constants/apiConfig';
import { toast } from 'react-toastify';

type SendAIContentReport = {
  section: any;
  description: string;
}

export const useSendAIContentReport = () => {
  return useMutation<any, any, SendAIContentReport>({
    mutationFn: async ({section, description}) => {
      const response = await axios.post(
        `${config.apiGateway.sitrepsV2}/sendAIContentReport`,
        {
          section,
          description,
        }
      );
      return response.data;
    },
    onSuccess: (contentReportData) => {
      
      const operationResult = contentReportData?.messageResults.reduce((acc: string, curr: { statusCode: number; }) => {
        if (curr?.statusCode !== 202) {
          return "failed";
        }
        
        return acc;
      }, "success");
      
      if (operationResult === "failed") {
        toast.error('Failed to send report');
      } else if (operationResult === "success") {
        toast.success('Report sent successfully');
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error('Failed to send report');
    },
  });
};