import PratusLogo from 'assets/images/pratus__logo.svg';
import PratusLogoIcon from 'assets/images/pratus__logoIcon.svg';

export function LogoutResultPage() {
  return (
    <>
      <div className="site-authentication">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="site-authentication__logo mb-5">
                <img className="d-none d-md-flex" src={PratusLogo} alt="" />
                <img className="d-flex d-md-none" src={PratusLogoIcon} alt="" />
              </div>
              <div className="row justify-content-center">
                <div className="col-md-10 col-lg-10 col-xl-6">
                  <p className="m-0">
                    To connect with the Disastertech team, fill out the form at{' '}
                    <br />
                    <a
                      href="https://www.disastertech.com/connect-with-us"
                      target="_blank" rel="noreferrer"
                    >
                      https://www.disastertech.com/connect-with-us
                    </a>
                  </p>
                </div>
              </div>
              <p className="mt-4 mb-0">
                You have been successfully logged out of PRATUS.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
