export function closeAirportsOnMap( map: any ) {
  if (map.current.getLayer(airports_points_layer)) {
    map.current.removeLayer(airports_points_layer)
  }
  if (map.current.getSource(airports_points_source)) {
    map.current.removeSource(airports_points_source)
  }
}

export function closeSeaportsOnMap( map: any ) {
  if (map.current.getLayer(seaports_points_layer)) {
    map.current.removeLayer(seaports_points_layer)
  }
  if (map.current.getSource(seaports_points_source)) {
    map.current.removeSource(seaports_points_source)
  }
}

const airportPointLayer = {
  id: '',
  type: 'symbol',
  source: '', // reference the data source
  'layout': {
     'visibility': 'visible',
     'icon-image': ["match", ["get", "node_type"],
        "airport", "airport-15",
        "heliport", "heliport-15",
        "airfield", "airfield-15",
        "airfield-15"
    ],
  },
  metadata: {
    transportationLayer: true,
  }
}

const airports_points_source = "airports_points_source"
const airports_points_layer = "airports_points_layer"
const airports_points_source_layer = "public.transportation_air_nodes_pointers"

const airports_points_layer_config = {
  ...airportPointLayer,
  id: airports_points_layer,
  source: airports_points_source,
  'source-layer': airports_points_source_layer,
  generateId: true,
  metadata: {
    transportationLayer: true,
  },
};

export function transportationAirportsEffect(
  map: any,
  apiPrefix: any,
  showAirportsActive = false,
  setLayerClicked: any,
  airportNodes: any[],
  aorIds: any[],
) {

  const queryString = aorIds?.length > 0 ? aorIds.join(",") : '';
  const airports_points_tiles = `${apiPrefix}/transportation_points/${airports_points_source_layer}/{z}/{x}/{y}.pbf?aors=${queryString}`;

  if (map.current.getLayer(airports_points_layer)) {
    map.current.removeLayer(airports_points_layer)
  }
  if (map.current.getSource(airports_points_source)) {
    map.current.removeSource(airports_points_source)
  }

  // airports nodes
  if (!!showAirportsActive && airportNodes?.length > 0) {

    map.current.addSource(airports_points_source, {
      'type': 'vector',
      'tiles': [
        airports_points_tiles
      ],
    });
    map.current.addLayer(airports_points_layer_config);

    map.current.on('click', airports_points_layer_config.id, (e: any) => 
      setLayerClicked({
        layer: airports_points_layer_config,
        e: e,
        features: e.features,
      })
    );

    map.current.on('touchend', airports_points_layer_config.id, (e: any) => 
      setLayerClicked({
        layer: airports_points_layer_config,
        e: e,
        features: e.features,
      })
    );

    map.current.on('mouseenter', airports_points_layer_config.id, () => {
      map.current.getCanvas().style.cursor = 'pointer';
    });

    map.current.on('mouseleave', airports_points_layer_config.id, () => {
      map.current.getCanvas().style.cursor = 'grab';
    });
  }
}

const seaportPointLayer = {
  id: '',
  type: 'symbol',
  source: '', // reference the data source
  'layout': {
     'visibility': 'visible',
     'icon-image': ["match", ["get", "node_type"],
        "Offshore Terminal", "ferry-15",
        "Anchorage", "amusement-park-15",
        "Shelter", "place-of-worship-15",
        "harbor-15"
    ],
  },
  metadata: {
    transportationLayer: true,
  }
}

const seaports_points_source = "seaports_points_source"
const seaports_points_layer = "seaports_points_layer"
const seaports_points_source_layer = "public.transportation_sea_nodes_pointers"

const seaports_points_layer_config = {
  ...seaportPointLayer,
  id: seaports_points_layer,
  source: seaports_points_source,
  'source-layer': seaports_points_source_layer,
  generateId: true,
  metadata: {
    transportationLayer: true,
  },
};

export function transportationSeaportsEffect(
  map: any,
  apiPrefix: any,
  showSeaportsActive = false,
  setLayerClicked: any,
  seaportNodes: any[],
  aorIds: any[],
) {

  const queryString = aorIds?.length > 0 ? aorIds.join(",") : '';
  const seaports_points_tiles = `${apiPrefix}/transportation_points/${seaports_points_source_layer}/{z}/{x}/{y}.pbf?aors=${queryString}`;

  if (map.current.getLayer(seaports_points_layer)) {
    map.current.removeLayer(seaports_points_layer)
  }
  if (map.current.getSource(seaports_points_source)) {
    map.current.removeSource(seaports_points_source)
  }

  // seaports nodes
  if (!!showSeaportsActive && seaportNodes?.length > 0) {
    map.current.addSource(seaports_points_source, {
      'type': 'vector',
      'tiles': [
        seaports_points_tiles
      ],
    });
    map.current.addLayer(seaports_points_layer_config);

    map.current.on('click', seaports_points_layer_config.id, (e: any) => 
      setLayerClicked({
        layer: seaports_points_layer_config,
        e: e,
        features: e.features,
      })
    );

    map.current.on('touchend', seaports_points_layer_config.id, (e: any) => 
      setLayerClicked({
        layer: seaports_points_layer_config,
        e: e,
        features: e.features,
      })
    );

    map.current.on('mouseenter', seaports_points_layer_config.id, () => {
      map.current.getCanvas().style.cursor = 'pointer';
    });

    map.current.on('mouseleave', seaports_points_layer_config.id, () => {
      map.current.getCanvas().style.cursor = 'grab';
    });
  }
}

const airassetsPointLayer = {
  id: '',
  type: 'symbol',
  source: '', // reference the data source
  'layout': {
    'visibility': 'visible',
    'icon-image': "airport-15",
    'icon-rotate': 60
  },
  metadata: {
    transportationLayer: true,
  }
}

const airassets_points_source = "airassets_points_source"
const airassets_points_layer = "airassets_points_layer"
const airassets_points_source_layer = "public.transportation_air_assets_pointers"

const airassets_points_layer_config = {
  ...airassetsPointLayer,
  id: airassets_points_layer,
  source: airassets_points_source,
  'source-layer': airassets_points_source_layer,
  generateId: true,
  metadata: {
    transportationLayer: true,
  },
};

export function transportationAirAssetsEffect(
  map: any,
  apiPrefix: any,
  airportsActive = false,
  setLayerClicked: any,
  airAssetsNodes: any
) {
  // TO DO When we use icao codes, then the below variable will be used.
  const icaoCodes: any[] = [];
  const queryString = icaoCodes?.length > 0 ? icaoCodes.join(",") : '';
  const airassets_points_tiles = `${apiPrefix}/transportation_points/${airassets_points_source_layer}/{z}/{x}/{y}.pbf?icaocodes=${queryString}`;

  if (map.current.getLayer(airassets_points_layer)) {
    map.current.removeLayer(airassets_points_layer)
  }
  if (map.current.getSource(airassets_points_source)) {
    map.current.removeSource(airassets_points_source)
  }

  if (airportsActive && airAssetsNodes?.length > 0) {
    map.current.addSource(airassets_points_source, {
      'type': 'vector',
      'tiles': [
        airassets_points_tiles
      ],
    });
    map.current.addLayer(airassets_points_layer_config);

    map.current.on('click', airassets_points_layer_config.id, (e: any) => 
      setLayerClicked({
        layer: airassets_points_layer_config,
        e: e,
        features: e.features,
      })
    );

    map.current.on('touchend', airassets_points_layer_config.id, (e: any) => 
      setLayerClicked({
        layer: airassets_points_layer_config,
        e: e,
        features: e.features,
      })
    );

    map.current.on('mouseenter', airassets_points_layer_config.id, () => {
      map.current.getCanvas().style.cursor = 'pointer';
    });

    map.current.on('mouseleave', airassets_points_layer_config.id, () => {
      map.current.getCanvas().style.cursor = 'grab';
    });
  }
}