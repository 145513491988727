export const UPDATE_CURRENT_INCIDENT_REQUEST =
  'UPDATE_CURRENT_INCIDENT_REQUEST';
export const UPDATE_CURRENT_INCIDENT_SUCCESS = 'UPDATE_CURRENT_INCIDENT_SUCCESS';
export const UPDATE_CURRENT_INCIDENT_ERROR = 'UPDATE_CURRENT_INCIDENT_ERROR';
export const CREATE_WIDGET_REQUEST = 'CREATE_WIDGET_REQUEST';
export const CREATE_WIDGET_SUCCESS = 'CREATE_WIDGET_SUCCESS';
export const CREATE_WIDGET_ERROR = 'CREATE_WIDGET_ERROR';
export const CREATE_INCIDENT_REQUEST = 'CREATE_INCIDENT_REQUEST';
export const CREATE_INCIDENT_SUCCESS = 'CREATE_INCIDENT_SUCCESS';
export const CREATE_INCIDENT_ERROR = 'CREATE_INCIDENT_ERROR';
export const UPDATE_INCIDENT_REQUEST = 'UPDATE_INCIDENT_REQUEST';
export const UPDATE_INCIDENT_SUCCESS = 'UPDATE_INCIDENT_SUCCESS';
export const UPDATE_INCIDENT_ERROR = 'UPDATE_INCIDENT_ERROR';
export const CREATE_DASHBOARD_REQUEST = 'CREATE_DASHBOARD_REQUEST';
export const CREATE_DASHBOARD_SUCCESS = 'CREATE_DASHBOARD_SUCCESS';
export const CREATE_DASHBOARD_ERROR = 'CREATE_DASHBOARD_ERROR';
export const UPDATE_DASHBOARD_REQUEST = 'UPDATE_DASHBOARD_REQUEST';
export const UPDATE_DASHBOARD_SUCCESS = 'UPDATE_DASHBOARD_SUCCESS';
export const UPDATE_DASHBOARD_ERROR = 'UPDATE_DASHBOARD_ERROR';
export const FETCH_DASHBOARDS_REQUEST = 'FETCH_DASHBOARDS_REQUEST';
export const FETCH_DASHBOARDS_SUCCESS = 'FETCH_DASHBOARDS_SUCCESS';
export const FETCH_DASHBOARDS_ERROR = 'FETCH_DASHBOARDS_ERROR';
export const TOGGLE_WIDGET_REQUEST = 'TOGGLE_WIDGET_REQUEST';
export const TOGGLE_WIDGET_SUCCESS = 'TOGGLE_WIDGET_SUCCESS';
export const TOGGLE_WIDGET_ERROR = 'TOGGLE_WIDGET_ERROR';
export const UPDATE_WIDGET_REQUEST = 'UPDATE_WIDGET_REQUEST';
export const UPDATE_WIDGET_SUCCESS = 'UPDATE_WIDGET_SUCCESS';
export const UPDATE_WIDGET_ERROR = 'UPDATE_WIDGET_ERROR';
export const REMOVE_WIDGET_REQUEST = 'REMOVE_WIDGET_REQUEST';
export const REMOVE_WIDGET_SUCCESS = 'REMOVE_WIDGET_SUCCESS';
export const REMOVE_WIDGET_ERROR = 'REMOVE_WIDGET_ERROR';
export const FETCH_DASHBOARD_DATA_REQUEST = 'FETCH_DASHBOARD_DATA_REQUEST';
export const FETCH_DASHBOARD_DATA_SUCCESS = 'FETCH_DASHBOARD_DATA_SUCCESS';
export const FETCH_DASHBOARD_DATA_ERROR = 'FETCH_DASHBOARD_DATA_ERROR';
export const UPDATE_CURRENT_DASHBOARD_REQUEST =
  'UPDATE_CURRENT_DASHBOARD_REQUEST';
export const UPDATE_CURRENT_DASHBOARD_SUCCESS =
  'UPDATE_CURRENT_DASHBOARD_SUCCESS';
export const UPDATE_CURRENT_DASHBOARD_ERROR = 'UPDATE_CURRENT_DASHBOARD_ERROR';
export const UPDATE_MAP_WIDGET_ALREADY_RELOADED_REQUEST =
  'UPDATE_MAP_WIDGET_ALREADY_RELOADED_REQUEST';
export const UPDATE_MAP_WIDGET_ALREADY_RELOADED_SUCCESS =
  'UPDATE_MAP_WIDGET_ALREADY_RELOADED_SUCCESS';
export const UPDATE_MAP_WIDGET_ALREADY_RELOADED_ERROR =
  'UPDATE_MAP_WIDGET_ALREADY_RELOADED_ERROR';
export const UPDATE_INCIDENT_GROUP_REQUEST = 'UPDATE_INCIDENT_GROUP_REQUEST';
export const UPDATE_INCIDENT_GROUP_SUCCESS = 'UPDATE_INCIDENT_GROUP_SUCCESS';
export const UPDATE_INCIDENT_GROUP_ERROR = 'UPDATE_INCIDENT_GROUP_ERROR';
export const FETCH_INCIDENT_ASSETS_REQUEST = 'FETCH_INCIDENT_ASSETS_REQUEST';
export const FETCH_INCIDENT_ASSETS_SUCCESS = 'FETCH_INCIDENT_ASSETS_SUCCESS';
export const FETCH_INCIDENT_ASSETS_ERROR = 'FETCH_INCIDENT_ASSETS_ERROR';
export const FETCH_INCIDENT_ASSET_LOGS_REQUEST =
  'FETCH_INCIDENT_ASSET_LOGS_REQUEST';
export const FETCH_INCIDENT_ASSET_LOGS_SUCCESS =
  'FETCH_INCIDENT_ASSET_LOGS_SUCCESS';
export const FETCH_INCIDENT_ASSET_LOGS_ERROR =
  'FETCH_INCIDENT_ASSET_LOGS_ERROR';
export const CREATE_INCIDENT_ASSET_REQUEST = 'CREATE_INCIDENT_ASSET_REQUEST';
export const CREATE_INCIDENT_ASSET_SUCCESS = 'CREATE_INCIDENT_ASSET_SUCCESS';
export const CREATE_INCIDENT_ASSET_ERROR = 'CREATE_INCIDENT_ASSET_ERROR';
export const UPDATE_INCIDENT_ASSET_REQUEST = 'UPDATE_INCIDENT_ASSET_REQUEST';
export const UPDATE_INCIDENT_ASSET_SUCCESS = 'UPDATE_INCIDENT_ASSET_SUCCESS';
export const UPDATE_INCIDENT_ASSET_ERROR = 'UPDATE_INCIDENT_ASSET_ERROR';
export const FETCH_ROLES_BY_INCIDENT_REQUEST =
  'FETCH_ROLES_BY_INCIDENT_REQUEST';
export const FETCH_ROLES_BY_INCIDENT_SUCCESS =
  'FETCH_ROLES_BY_INCIDENT_SUCCESS';
export const FETCH_ROLES_BY_INCIDENT_ERROR = 'FETCH_ROLES_BY_INCIDENT_ERROR';
export const EXPORT_MARKDOWN_TO_DOCUMENT_REQUEST =
  'EXPORT_MARKDOWN_TO_DOCUMENT_REQUEST';
export const EXPORT_MARKDOWN_TO_DOCUMENT_SUCCESS =
  'EXPORT_MARKDOWN_TO_DOCUMENT_SUCCESS';
export const EXPORT_MARKDOWN_TO_DOCUMENT_ERROR =
  'EXPORT_MARKDOWN_TO_DOCUMENT_ERROR';
export const FETCH_DATASET_METADATA_FOR_WIDGET_REQUEST =
  'FETCH_DATASET_METADATA_FOR_WIDGET_REQUEST';
export const FETCH_DATASET_METADATA_FOR_WIDGET_SUCCESS =
  'FETCH_DATASET_METADATA_FOR_WIDGET_SUCCESS';
export const FETCH_DATASET_METADATA_FOR_WIDGET_ERROR =
  'FETCH_DATASET_METADATA_FOR_WIDGET_ERROR';
export const UPDATE_CURRENTLY_SELECTED_GROUP_REQUEST =
  'UPDATE_CURRENTLY_SELECTED_GROUP_REQUEST';
export const UPDATE_CURRENTLY_SELECTED_GROUP_SUCCESS =
  'UPDATE_CURRENTLY_SELECTED_GROUP_SUCCESS';
export const UPDATE_CURRENTLY_SELECTED_GROUP_ERROR =
  'UPDATE_CURRENTLY_SELECTED_GROUP_ERROR';
export const CLEAR_DATASET_METADATA_FOR_WIDGET_REQUEST =
  'CLEAR_DATASET_METADATA_FOR_WIDGET_REQUEST';
export const CLEAR_DATASET_METADATA_FOR_WIDGET_SUCCESS =
  'CLEAR_DATASET_METADATA_FOR_WIDGET_SUCCESS';
export const CLEAR_DATASET_METADATA_FOR_WIDGET_ERROR =
  'CLEAR_DATASET_METADATA_FOR_WIDGET_ERROR';
export const REMOVE_CURRENT_OVERVIEW_FOR_WIDGET_SUCCESS =
  'REMOVE_CURRENT_OVERVIEW_FOR_WIDGET_SUCCESS';
export const REMOVE_CURRENT_OVERVIEW_FOR_WIDGET_ERROR =
  'REMOVE_CURRENT_OVERVIEW_FOR_WIDGET_ERROR';
export const FETCH_DATASET_METADATA_REQUEST = 'FETCH_DATASET_METADATA_REQUEST';
export const FETCH_DATASET_METADATA_SUCCESS = 'FETCH_DATASET_METADATA_SUCCESS';
export const FETCH_DATASET_METADATA_ERROR = 'FETCH_DATASET_METADATA_ERROR';
export const CLEAR_DATASET_METADATA_REQUEST = 'CLEAR_DATASET_METADATA_REQUEST';
export const CLEAR_DATASET_METADATA_SUCCESS = 'CLEAR_DATASET_METADATA_SUCCESS';
export const CLEAR_DATASET_METADATA_ERROR = 'CLEAR_DATASET_METADATA_ERROR';
export const SELECT_OPERATIONAL_PERIOD_REQUEST =
  'SELECT_OPERATIONAL_PERIOD_REQUEST';
export const SELECT_OPERATIONAL_PERIOD_SUCCESS =
  'SELECT_OPERATIONAL_PERIOD_SUCCESS';
export const SELECT_OPERATIONAL_PERIOD_ERROR =
  'SELECT_OPERATIONAL_PERIOD_ERROR';
export const SET_CLONE_DEFAULTS_REQUEST = 'SET_CLONE_DEFAULTS_REQUEST';
export const SET_CLONE_DEFAULTS_SUCCESS = 'SET_CLONE_DEFAULTS_SUCCESS';
export const SET_CLONE_DEFAULTS_ERROR = 'SET_CLONE_DEFAULTS_ERROR';
export const FETCH_CLONE_DEFAULTS_REQUEST = 'FETCH_CLONE_DEFAULTS_REQUEST';
export const FETCH_CLONE_DEFAULTS_SUCCESS = 'FETCH_CLONE_DEFAULTS_SUCCESS';
export const FETCH_CLONE_DEFAULTS_ERROR = 'FETCH_CLONE_DEFAULTS_ERROR';
export const FETCH_DICE_LOG_REQUEST = 'FETCH_DICE_LOG_REQUEST';
export const FETCH_DICE_LOG_SUCCESS = 'FETCH_DICE_LOG_SUCCESS';
export const FETCH_DICE_LOG_ERROR = 'FETCH_DICE_LOG_ERROR';
export const DELETE_LAYOUT_REQUEST = 'DELETE_LAYOUT_REQUEST';
export const DELETE_LAYOUT_SUCCESS = 'DELETE_LAYOUT_SUCCESS';
export const DELETE_LAYOUT_ERROR = 'DELETE_LAYOUT_ERROR';
export const FETCH_DATASET_FOR_TABLE_REQUEST =
  'FETCH_DATASET_FOR_TABLE_REQUEST';
export const FETCH_DATASET_FOR_TABLE_SUCCESS =
  'FETCH_DATASET_FOR_TABLE_SUCCESS';
export const FETCH_DATASET_FOR_TABLE_ERROR = 'FETCH_DATASET_FOR_TABLE_ERROR';
export const ADD_USER_SCHEDULE_REQUEST = 'ADD_USER_SCHEDULE_REQUEST';
export const ADD_USER_SCHEDULE_SUCCESS = 'ADD_USER_SCHEDULE_SUCCESS';
export const ADD_USER_SCHEDULE_ERROR = 'ADD_USER_SCHEDULE_ERROR';
export const DELETE_USER_SCHEDULE_REQUEST = 'DELETE_USER_SCHEDULE_REQUEST';
export const DELETE_USER_SCHEDULE_SUCCESS = 'DELETE_USER_SCHEDULE_SUCCESS';
export const GET_ALL_USER_SCHEDULE_REQUEST = 'GET_ALL_USER_SCHEDULE_REQUEST';
export const GET_ALL_USER_SCHEDULE_SUCCESS = 'GET_ALL_USER_SCHEDULE_SUCCESS';
export const GET_ALL_USER_SCHEDULE = 'GET_ALL_USER_SCHEDULE';
export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const GENERATE_SIGNED_URL_REQUEST = 'GENERATE_SIGNED_URL_REQUEST';
export const GENERATE_SIGNED_URL_SUCCESS = 'GENERATE_SIGNED_URL_SUCCESS';
export const FETCH_GROUP_IMAGES_SUCCESS = 'FETCH_GROUP_IMAGES_SUCCESS';
export const FETCH_GROUP_IMAGES_REQUEST = 'FETCH_GROUP_IMAGES_REQUEST';
export const FETCH_BRIEFING_SUCCESS = 'FETCH_BRIEFING_SUCCESS';
export const FETCH_BRIEFING_REQUEST = 'FETCH_BRIEFING_REQUEST';
export const SET_BRIEFING_SKETCH_SUCCESS = 'SET_BRIEFING_SKETCH_SUCCESS';
export const SET_BRIEFING_SKETCH_REQUEST = 'SET_BRIEFING_SKETCH_REQUEST';
export const ENVIRONMENT_VAR_LOADED = 'ENVIRONMENT_VAR_LOADED';

