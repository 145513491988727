import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IncidentNewMemberDialog from './IncidentNewMemberDialog';
import IncidentEditMemberDialog from './IncidentEditMemberDialog';
import LogNonMemberDialog from './LogNonMemberDialog';
import ViewLogDialog from './ViewLogDialog';
import {
  deleteIncidentSchedule,
  getALlScheduleToIncident,
  getRosterForIncident,
  getUserIncidentSchedule,
  markIncidentUserActive,
  removeSelfFromIncident,
  removeUserFromIncidentByAdmin,
} from 'actions/incidentActions';
import { fetchRostersForGroups } from 'actions/roleActions';
import IncidentNonActiveConfirmDialog from './IncidentNonActiveConfirmDialog';
import ActivationScheduleDialog from './ActivationScheduleDialog';
import AddScheduleActivationDialog from './AddScheduleActivationDialog';
import moment from 'moment';
import OrganizationChart from './OrganizationChart';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishNewToggleButtonGroup } from 'components/DesignSystems/New/StylishNewToggleButtonGroup';
import { StylishNewChip } from 'components/DesignSystems/New/StylishNewChip';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import BranchModal from 'components/FormBuilder/Branch/BranchModal';
import DivisionModal from 'components/FormBuilder/Division/DivisionModal';
import { toast } from 'react-toastify';
import { RemoveUserFromTeamSite } from 'actions/teamsActions';

const numToDay = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
};

export default function IncidentConfig() {
  const reduxDispatch = useDispatch();
  const [memberSelectedForEditing, setMemberSelectedForEditing] = useState();
  const [isNewMemberDialogOpen, setIsNewMemberDialogOpen] = useState(false);
  const [isEditMemberDialogOpen, setIsEditMemberDialogOpen] = useState(false);
  const [logNonMemberDialogOpen, setLogNonMemberDialogOpen] = useState(false);
  const [viewLogDialogOpen, setViewLogDialogOpen] = useState(false);
  const [viewScheduleDialogOpen, setViewScheduleDialogOpen] = useState(false);
  const [
    addScheduleActivationDialogOpen,
    setAddScheduleActivationDialogOpen,
  ] = useState(false);
  const [selectedIncidentMember, setSelectedIncidentMember] = useState();

  const [notActiveMemberDialogOpen, setNotActiveMemberDialogOpen] = useState(
    false
  );
  const [deactivateRow, setDeactivateRow] = useState();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [deleteType, setDeleteType] = useState('');
  const [deleteMemberData, setDeleteMemberData] = useState(null);
  const [scheduleActivationType, setScheduleActivationType] = useState(null);
  const [showBranchModal, setShowBranchModal] = useState(false);
  const [showDivisionModal, setShowDivisionModal] = useState(false);

  const toggleData = [
    { label: 'Checked-In', value: 'Checked-In' },
    { label: 'Checked-Out', value: 'Checked-Out' },
  ];

  const reduxCurrentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });

  const incident_id =
    (reduxCurrentIncident && reduxCurrentIncident.id) || undefined;

  const featureFlags = useSelector((state) => {
    return state.app.featureFlags;
  });

  const reduxRosterForIncident = useSelector((state) => {
    return state.app.rosterForIncident;
  });

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const reduxRostersForGroups = useSelector((state) => {
    return state.app.rostersForGroups || [];
  });

  const isGetRosterForIncidentLoaded = useSelector((state) => {
    return state.app.isGetRosterForIncidentLoaded;
  });

  const { user_guid } = useSelector((state) => {
    return state.app.user;
  });

  const reduxValidateRBACPermissionForActionResult = useSelector((state) => {
    return state.app.validateRBACPermissionForActionResult;
  });

  const isUserDeactivateToIncidentLoaded = useSelector((state) => {
    return state.app.isUserDeactivateToIncidentLoaded;
  });

  const isSelfActivationLoaded = useSelector((state) => {
    return state.app.isSelfActivationLoaded;
  });

  const allScheduleToIncident = useSelector(
    (state) => state.app.allScheduleToIncident
  );

  const planningWorksheet = useSelector((state) => {
    return state.app.planningWorksheet;
  });

  const isGenerateTeamsSiteLoaded = useSelector((state) => {
    return state.app.isGenerateTeamsSiteLoaded;
  });

  const teamStatusFromIncident = useSelector(
    (state) => state.app.teamStatusFromIncident
  );

  //console.log('all: ', reduxRosterForIncident);

  const reduxRostersForTheGroup = useSelector((state) => {
    let rosters = state.app.rostersForGroups;
    if (Array.isArray(rosters) && rosters.length) {
      rosters = rosters
        .filter((member) => {
          return member.group_guid === reduxCurrentlySelectedGroup.group_guid;
        })
        .map((member) => {
          return {
            ...member,
            role_string: member.role_assignments.reduce((acc, item) => {
              acc = acc ? acc + ', ' + item.name : item.name;

              return acc;
            }, ''),
          };
        });
    }
    return rosters || [];
  });

  //console.log('all: ', allScheduleToIncident);

  useEffect(() => {
    reduxDispatch(endLoading());
  }, [reduxRosterForIncident]);

  useEffect(() => {
    if (!reduxRostersForGroups.length) {
      reduxDispatch(
        fetchRostersForGroups([reduxCurrentlySelectedGroup.group_guid])
      );
    }
  }, [reduxCurrentlySelectedGroup]);

  useEffect(() => {
    if (
      typeof user_guid !== 'undefined' &&
      !!reduxCurrentIncident &&
      !!reduxCurrentIncident.id
    ) {
      reduxDispatch(getRosterForIncident());
      reduxDispatch(getALlScheduleToIncident());
    }
  }, [incident_id]);

  useEffect(() => {
    if (
      !isSelfActivationLoaded ||
      !isUserDeactivateToIncidentLoaded ||
      !isGetRosterForIncidentLoaded
    ) {
      reduxDispatch(startLoading());
    } else {
      reduxDispatch(endLoading());
    }
  }, [
    isSelfActivationLoaded,
    isUserDeactivateToIncidentLoaded,
    isGetRosterForIncidentLoaded,
  ]);

  const allColumn = [
    {
      dataField: 'email_address',
      text: 'Incident Member',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Incident Member' },
    },
    {
      dataField: 'incident_seat',
      text: 'Incident Seats',
      sort: true,
      attrs: { title: 'Incident Seats' },
    },
  ];

  function editMemberButtonClicked(member) {
    setMemberSelectedForEditing(member);
    setIsEditMemberDialogOpen(true);
  }

  const deleteConfirmText =
    deleteType === 'Leave Incident'
      ? 'Do you want to remove yourself from this incident?'
      : deleteType === 'Remove Member'
      ? `Do you want to delete the Incident member '${deleteMemberData.email_address}'?`
      : deleteType === 'Remove Scheduled'
      ? 'Are you sure you want to delete this scheduled?'
      : null;

  function removeMemberButtonClicked(member) {
    setDeleteType('Remove Member');
    setDeleteMemberData(member);
    setShowConfirmDialog(true);
  }

  function onClickAssignIncidentSeatsButton() {
    if (
      !isGenerateTeamsSiteLoaded &&
      teamStatusFromIncident == reduxCurrentIncident?.id
    ) {
      toast.success(
        'Creating team site is in progress. Please check back later.',
        {
          autoClose: 2000,
        }
      );
      return;
    }
    setIsNewMemberDialogOpen(true);
  }

  function removeSelfFromIncidentButtonClicked(member) {
    setDeleteType('Leave Incident');
    setDeleteMemberData(member);
    setShowConfirmDialog(true);
  }

  const onDeleteConfirmation = () => {
    reduxDispatch(startLoading());
    if (deleteType === 'Leave Incident') {
      if (reduxCurrentIncident.teams_id) {
        reduxDispatch(
          RemoveUserFromTeamSite(
            'self',
            reduxCurrentIncident.id,
            deleteMemberData,
            'incident'
          )
        );
      } else {
        reduxDispatch(removeSelfFromIncident(deleteMemberData));
      }
    } else if (deleteType === 'Remove Member') {
      if (reduxCurrentIncident.teams_id) {
        reduxDispatch(
          RemoveUserFromTeamSite(
            'member',
            reduxCurrentIncident.id,
            deleteMemberData,
            'incident'
          )
        );
      } else {
        reduxDispatch(removeUserFromIncidentByAdmin(deleteMemberData));
      }
    } else if (deleteType === 'Remove Scheduled') {
      reduxDispatch(
        deleteIncidentSchedule(
          deleteMemberData.scheduleId,
          deleteMemberData.user_guid,
          deleteMemberData.incident_member_id
        )
      );
    }
    setShowConfirmDialog(false);
    setDeleteMemberData(null);
    setDeleteType('');
  };

  const handleToggleButtonClick = (value, row) => {
    if (value === 'Checked-In') {
      if (row.active) {
        return;
      } else {
        reduxDispatch(
          markIncidentUserActive(
            incident_id,
            row.user_guid,
            row.incident_member_id
          )
        );
      }
    } else if (value === 'Checked-Out') {
      if (!row.active) {
        return;
      } else {
        setDeactivateRow(row);
        setNotActiveMemberDialogOpen(true);
      }
    }
  };

  const onAddScheduleActivation = (row) => {
    setSelectedIncidentMember(row);
    setScheduleActivationType('Add');
    setAddScheduleActivationDialogOpen(true);
  };

  const userRow =
    !!reduxRostersForGroups &&
    !!reduxCurrentlySelectedGroup &&
    !!reduxRostersForGroups.length &&
    reduxRostersForGroups.find(
      (r) =>
        r.user_guid === user_guid &&
        r.group_guid === reduxCurrentlySelectedGroup.group_guid
    );
  const userIsAdminForGroup =
    (!!userRow &&
      !!userRow.role_assignments &&
      !!userRow.role_assignments.length &&
      userRow.role_assignments.find((ra) => ra.name === 'Group Admin')) ||
    false;

  const onExpandRow = (index) => {
    const user_guid = reduxRosterForIncident[index].user_guid;
    const incident_member_id = reduxRosterForIncident[index].incident_member_id;
    reduxDispatch(getUserIncidentSchedule(user_guid, incident_member_id));
  };

  const onDeleteSchedule = (scheduleId, row) => {
    setDeleteType('Remove Scheduled');
    setDeleteMemberData({ scheduleId: scheduleId, ...row });
    setShowConfirmDialog(true);
  };

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <div className="row">
            <div className="col-md-6">
              <div className="fw-bold my-2">Profile</div>
              <span className="d-block">Email: {row.email_address}</span>
              <span className="d-block">
                Phone (Mobile): {row.profile_settings?.mobile_phone_number}
              </span>
              <span className="d-block">
                Phone (Home): {row.profile_settings?.home_phone_number}
              </span>
              <span className="d-block">
                Pager: {row.profile_settings?.pager_address}
              </span>
            </div>
            <div className="col-md-6">
              <div className="fw-bold my-2">Seat</div>
              <span className="d-flex align-items-center">
                {row?.incident_seat ? (
                  <span className="me-3">{row.incident_seat}</span>
                ) : null}
                {!!reduxValidateRBACPermissionForActionResult[
                  'Add Member to Incident'
                ] && (
                  <button
                    className="button button--link ps-0"
                    onClick={() => editMemberButtonClicked(row)}
                  >
                    Edit Seat
                  </button>
                )}
              </span>
            </div>
            {row.relevant_team_memberships &&
              Array.isArray(row.relevant_team_memberships) &&
              !!row.relevant_team_memberships.length && (
                <>
                  <div className="fw-bold my-2">Relevant Team Memberships</div>

                  {row.relevant_team_memberships.map((team, idx) => (
                    <div key={`membership-${idx}`}>{team.name}</div>
                  ))}
                </>
              )}
          </div>
          {row.schedule && row.schedule.length > 0 && (
            <div className="form-chip-group mt-4">
              {row.schedule &&
                row.schedule.length > 0 &&
                row.schedule?.map((schedule) => {
                  const schedules = schedule.allday ? (
                    <StylishNewChip
                      key={schedule.id}
                      label={`${moment(schedule.start_time).format(
                        'MM/DD/YYYY'
                      )} - ${moment(schedule.end_time).format(
                        'MM/DD/YYYY'
                      )}, Full Day`}
                      handleDelete={() => onDeleteSchedule(schedule.id, row)}
                    />
                  ) : schedule.isrecurringevent ? (
                    <StylishNewChip
                      key={schedule.id}
                      label={`From ${moment(schedule.start_time).format(
                        'MM/DD/YYYY'
                      )} to ${moment(schedule.end_time).format(
                        'MM/DD/YYYY'
                      )} Every \n ${schedule.daysofweek
                        .map((key) => numToDay[key])
                        .join(', ')} From ${schedule.recurringstarttime} to ${
                        schedule.recurringendtime
                      }`}
                      handleDelete={() => onDeleteSchedule(schedule.id, row)}
                    />
                  ) : (
                    <StylishNewChip
                      key={schedule.id}
                      label={`${moment(schedule.start_time).format(
                        'MM/DD/YYYY HH:mm'
                      )} - ${moment(schedule.end_time).format(
                        'MM/DD/YYYY HH:mm'
                      )}`}
                      handleDelete={() => onDeleteSchedule(schedule.id, row)}
                    />
                  );

                  return schedules;
                })}
            </div>
          )}
          <div className="button-group d-block d-lg-flex mt-4">
            {(row.user_guid === user_guid ||
              !!reduxValidateRBACPermissionForActionResult[
                'Add Member to Incident'
              ]) && (
              <StylishNewToggleButtonGroup
                data={toggleData}
                selected={row.active ? 'Checked-In' : 'Checked-Out'}
                onChange={(value) => handleToggleButtonClick(value, row)}
                className="d-md-inline-flex"
                disabled={
                  !isSelfActivationLoaded ||
                  !isUserDeactivateToIncidentLoaded ||
                  !isGetRosterForIncidentLoaded
                }
              />
            )}
            <div className="button-group ms-auto mt-4 mt-xl-0">
              <StylishNewButton
                className="button--secondary"
                onClick={() => onAddScheduleActivation(row)}
                disabled={
                  !reduxValidateRBACPermissionForActionResult[
                    'Add Member to Incident'
                  ]
                }
              >
                Add Schedule Activation
              </StylishNewButton>
              {reduxRosterForIncident.length > 1 && (
                <>
                  {(!!reduxValidateRBACPermissionForActionResult[
                    'Remove Member from Incident'
                  ] &&
                    row.user_guid !== user_guid && (
                      <StylishNewButton
                        className="button--tertiary button--reverse"
                        onClick={() => removeMemberButtonClicked(row)}
                      >
                        Remove Member
                      </StylishNewButton>
                    )) ||
                    (row.user_guid === user_guid && (
                      <StylishNewButton
                        className="button--tertiary button--reverse"
                        onClick={() => removeSelfFromIncidentButtonClicked(row)}
                      >
                        Leave incident
                      </StylishNewButton>
                    ))}
                </>
              )}
            </div>
          </div>
          {!reduxValidateRBACPermissionForActionResult[
            'Add Member to Incident'
          ] && (
            <span className="form-text form-error d-flex mt-3">
              {"You don't have permission to Add Schedule Activation."}
            </span>
          )}
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
    onExpand: (row, isExpand, rowIndex, e) => {
      onExpandRow(rowIndex);
    },
  };

  const onCloseAddScheduleActivation = () => {
    setAddScheduleActivationDialogOpen(false);
    setScheduleActivationType('');
  };
  const onFocusDivision = () => {
    setShowDivisionModal(true);
  };

  return (
    <>
      {!!reduxCurrentIncident && (
        <>
          <div className="d-md-flex align-items-center mb-3">
            <h4 className="m-0">Incident Seat Roster</h4>
            <div className="ms-auto text-md-end">
              <StylishNewButton
                className="button--secondary ms-auto mt-2 mt-md-0 w-100 w-md-auto ms-0 ms-md-3"
                onClick={onClickAssignIncidentSeatsButton}
                disabled={
                  !reduxValidateRBACPermissionForActionResult[
                    'Add Member to Incident'
                  ]
                }
              >
                Assign Incident Seat
              </StylishNewButton>
              {!reduxValidateRBACPermissionForActionResult[
                'Add Member to Incident'
              ] && (
                <span className="form-text form-error">
                  {"You don't have permission for this action"}
                </span>
              )}
            </div>
          </div>
          <div className="form-block">
            <div className="button-group d-block d-md-flex mb-4">
              <StylishNewButton
                className="button--secondary w-100 w-md-auto"
                onClick={() => setLogNonMemberDialogOpen(true)}
                disabled={
                  !reduxValidateRBACPermissionForActionResult[
                    'Add Member to Incident'
                  ]
                }
              >
                Log Non-member Time
              </StylishNewButton>
              <StylishNewButton
                className="button--secondary-fill w-100 w-md-auto ms-0 ms-md-3 mt-2 mt-md-0"
                onClick={() => setViewLogDialogOpen(true)}
              >
                View Log
              </StylishNewButton>
              <StylishNewButton
                className="button--secondary-fill w-100 w-md-auto ms-0 ms-md-3 mt-2 mt-md-0"
                onClick={() => setViewScheduleDialogOpen(true)}
              >
                View Schedule
              </StylishNewButton>
            </div>
            {!reduxValidateRBACPermissionForActionResult[
              'Add Member to Incident'
            ] && (
              <span className="form-text form-error d-flex mt-3">
                {"You don't have permission for this action."}
              </span>
            )}
            <StylishNewTable
              keyField={'user_guid'}
              columns={allColumn}
              rows={reduxRosterForIncident || []}
              expandRow={expandRow}
            />
          </div>
          {!!reduxRosterForIncident && !!reduxRosterForIncident.length && (
            <>
              <div className="my-4">
                <OrganizationChart incident_members={reduxRosterForIncident} />
              </div>
            </>
          )}
          {isNewMemberDialogOpen && (
            <IncidentNewMemberDialog
              show={isNewMemberDialogOpen}
              onClose={() => setIsNewMemberDialogOpen(false)}
              reduxRosterForIncident={reduxRosterForIncident}
              reduxRostersForTheGroup={reduxRostersForTheGroup}
              incident_id={incident_id}
            />
          )}
          {isEditMemberDialogOpen && (
            <IncidentEditMemberDialog
              show={isEditMemberDialogOpen}
              onClose={() => setIsEditMemberDialogOpen(false)}
              incident_id={incident_id}
              member={memberSelectedForEditing}
            />
          )}
          {logNonMemberDialogOpen && (
            <LogNonMemberDialog
              show={logNonMemberDialogOpen}
              onClose={() => setLogNonMemberDialogOpen(false)}
              incident_id={incident_id}
            />
          )}
          {viewLogDialogOpen && (
            <ViewLogDialog
              show={viewLogDialogOpen}
              incident_id={incident_id}
              onClose={() => setViewLogDialogOpen(false)}
            />
          )}
          {notActiveMemberDialogOpen && (
            <IncidentNonActiveConfirmDialog
              show={notActiveMemberDialogOpen}
              onClose={() => setNotActiveMemberDialogOpen(false)}
              incident_id={incident_id}
              selectedRow={deactivateRow}
            />
          )}
          {viewScheduleDialogOpen && (
            <ActivationScheduleDialog
              show={viewScheduleDialogOpen}
              onClose={() => setViewScheduleDialogOpen(false)}
              allScheduleToIncident={allScheduleToIncident}
            />
          )}
          {addScheduleActivationDialogOpen && (
            <AddScheduleActivationDialog
              show={addScheduleActivationDialogOpen}
              selectedIncidentMember={selectedIncidentMember}
              onClose={onCloseAddScheduleActivation}
              setSelectedIncidentMember={setSelectedIncidentMember}
              scheduleActivationType={scheduleActivationType}
            />
          )}
          {showConfirmDialog && (
            <StylishConfirmDialog
              show={showConfirmDialog}
              dialogTitle={'Delete Confirmation'}
              dialogContent={deleteConfirmText}
              onClose={() => setShowConfirmDialog(false)}
              onConfirm={onDeleteConfirmation}
            />
          )}
          {showBranchModal && (
            <BranchModal
              selectedBranch={
                !!planningWorksheet?.branch_id
                  ? {
                      id: planningWorksheet.branch_id,
                      name: planningWorksheet.branch_name,
                    }
                  : null
              }
              show={showBranchModal}
              rowSelectable={false}
              parent={'IncidentWorkspace'}
              onClose={(e) => {
                if (showBranchModal) {
                  setShowBranchModal(false);
                }
                return;
              }}
            />
          )}
          {showDivisionModal && (
            <DivisionModal
              selectedBranch={
                !!planningWorksheet?.branch_id
                  ? {
                      id: planningWorksheet.branch_id,
                      name: planningWorksheet.branch_name,
                    }
                  : null
              }
              selectedDivision={
                !!planningWorksheet?.division_id
                  ? {
                      id: planningWorksheet.division_id,
                      name: planningWorksheet.division_name,
                    }
                  : null
              }
              show={showDivisionModal}
              rowSelectable={false}
              parent={'IncidentWorkspace'}
              onClose={() => setShowDivisionModal(false)}
            />
          )}
        </>
      )}
    </>
  );
}
