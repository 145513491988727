import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Offcanvas, Row, Modal, Accordion } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import * as dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { IoMdCopy } from 'react-icons/io';
import { AiOutlineFileWord } from 'react-icons/ai';
import { SiMicrosoftsharepoint } from 'react-icons/si';

import { saveAs } from 'file-saver';
import { Document, Packer, Paragraph, TextRun, ExternalHyperlink } from 'docx';

import { docxExport } from './DisasterGPTDataHelpers/DGPTExport';

import SOPs from 'components/DisasterGPT/SOPs';
import SharepointFiles from 'components/DisasterGPT/SharepointFiles';
import DataSources from 'components/DisasterGPT/DataSources';
import PromptLibrary from 'components/DisasterGPT/PromptLibrary';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import DrawerWrapper, { DrawerFooter } from 'components/IAP/DrawerWrapper';

import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw'; // Import rehypeRaw here
import rehypeSanitize, { defaultSchema } from 'rehype-sanitize'; // Import rehypeSanitize and defaultSchema

import {
  setSelectedDChat,
  setCurrentSitrepId,
  cancelRun,
  fetchSOPs,
  checkDeletedSOPFiles,
} from 'slices/dchatSlice';

import {
  usePollDChat,
  usePostDChat,
  useDeleteDisasterChat,
} from './hooks/useDisasterChats';
import { useSharepointFileRefs } from './hooks/useSharepointFileRefs';
import { useSopFileRefs } from './hooks/useSopFileRefs';
import {
  useSharepointFiles,
  useSyncSharepointFiles,
} from './hooks/useSharepointFiles'; // Import the sync hook
import { selectIncident, useAppSelector } from 'slices/commonSelectors';

import './ChatDisasterGPT.css';
import './SitrepSection.css'; // Reuse the same CSS for table styling
import { toastConfig } from 'assets/data/config';

const defaultRealtimeSelections = [
  { source: 'Web', description: 'Web Scraping', id: '' },
  { source: 'News', description: 'News Articles', id: '' },
];

const ChatDisasterGPT = ({
  chatDGPTSession,
  sitrep,
  toggle,
  selectedDatetime,
}) => {
  const reduxDispatch = useDispatch();
  const isInitialRender = useRef(true);

  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [sendMessageTimestamp, setSendMessageTimestamp] = useState();
  const [docSelections, setDocSelections] = useState([]);
  const [sharepointSelections, setSharepointSelections] = useState([]);
  const [realtimeSelections, setRealtimeSelections] = useState(
    defaultRealtimeSelections
  );
  const [priorStatus, setPriorStatus] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isOutOfSync, setIsOutOfSync] = useState(false); // State to manage sync status
  const {
    data: files = [],
    isLoading: isLoadingSharepointFiles,
  } = useSharepointFiles(); // Fetch SharePoint files
  const [shouldShowDiv, setShouldShowDiv] = useState(false);
  const [isClickedFileSync, setIsClickedFileSync] = useState(false);
  const [isFetchingSOPs, setIsFetchingSOPs] = useState(false);
  const incident = useAppSelector(selectIncident);
  const [promptData, setPromptData] = useState([]);

  const handlePromptDataChange = (newPromptData) => {
    setPromptData(newPromptData);
  };

  const [checkedForDeletedFiles, setCheckedForDeletedFiles] = useState(false);

  const hasSharepointLocation =
    incident?.sharepoint_location && incident?.tenant_id;

  const dchatStatus = useSelector((state) => state.dchat.status);
  const dchat = useSelector((state) => state.dchat.dchat);
  const streamtext = useSelector((state) => state.dchat.streamtext);
  const reduxSOPs = useSelector((state) => state.dchat.SOPs);
  const isFetchSOPsRequestLoaded = useSelector(
    (state) => state.dchat.isFetchSOPsRequestLoaded
  );

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const {
    data: sharepointFileRefs,
    isFetching: isFetchingFileRefs,
  } = useSharepointFileRefs(reduxCurrentlySelectedGroup?.group_guid);
  const {
    data: sopFileRefs,
    isFetching: isFetchingSopFileRefs,
  } = useSopFileRefs(reduxCurrentlySelectedGroup?.group_guid);

  const {
    syncSharepointFiles,
    sharepointSyncSession,
    isSyncing,
  } = useSyncSharepointFiles(); // Use the sync hook

  const pollDChat = usePollDChat();
  const postDChat = usePostDChat();
  const deleteDisasterChat = useDeleteDisasterChat();

  const messagesEndRef = useRef(null);

  const reduxCurrentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });
  const [sharepointDriveId, setSharepointDriveId] = useState();
  const [tenantId, setTenantId] = useState();

  const isUsingTeams =
    sessionStorage['isUsingTeams'] === true ||
    sessionStorage['isUsingTeams'] === 'true';

  useEffect(() => {
    if (incident) {
      if (incident.sharepoint_location) {
        setSharepointDriveId(incident.sharepoint_location);
        setTenantId(incident.tenant_id);
      }
    }
  }, [incident]);

  // Handle Sync Status
  useEffect(() => {
    if (sharepointFileRefs && sharepointFileRefs.length > 0) {
      const outOfSyncFiles = sharepointFileRefs.filter((file) => {
        const timestamp = dayjs(file.timestamp);
        return dayjs().diff(timestamp, 'hour') >= 24;
      });
      setIsOutOfSync(outOfSyncFiles.length > 0);
    }
  }, [sharepointFileRefs]);

  // Modified sendMessage function to handle docSelections and sharepointSelections separately
  function sendMessage(
    message,
    newChatName = '',
    prePromptData = null,
    prePromptSOPs = null,
    prePromptSharepoint = null
  ) {
    const sopDocs = prePromptSOPs !== null ? prePromptSOPs : docSelections;
    const sharepointDocs =
      prePromptSharepoint !== null ? prePromptSharepoint : sharepointSelections;
    const data = prePromptData !== null ? prePromptData : realtimeSelections;

    let finalNewChatName = newChatName;
    if (!!newChatName || !newChatName.length) {
      finalNewChatName = message.slice(0, 50);
    }

    postDChat({
      dchat_id: dchat.id,
      message: message,
      docSelections: sopDocs,
      sharepointSelections: sharepointDocs,
      realtimeSelections: data,
      selectedDatetime: selectedDatetime,
      newChatName: finalNewChatName,
      tenantId: tenantId,
      sharepointDriveId: sharepointDriveId,
    });

    setMessages([
      ...messages,
      {
        role: 'USER',
        content: message,
        timestamp: new Date(),
        docSelections: sopDocs,
        sharepointSelections: sharepointDocs,
        realtimeSelections: data,
        selectedDatetime: selectedDatetime,
      },
    ]);
    setInput('');
    setSendMessageTimestamp(new Date());
  }

  useEffect(() => {
    pollDChat(sitrep);
  }, [sitrep]);

  useEffect(() => {
    if (!!dchat) {
      if (dchat.status !== 'Complete' && dchat.status !== 'Error') {
        setPriorStatus(dchat.status);
      } else if (
        (dchat.status === 'Complete' && priorStatus !== 'Complete') ||
        (dchat.status === 'Error' && priorStatus !== 'Error')
      ) {
        setPriorStatus('Initializing');
      } else {
        setPriorStatus(dchat.status);
      }
    }
  }, [dchat]);

  useEffect(() => {
    if (dchat?.messages) {
      let showMsg = [...dchat.messages];
      if (
        streamtext &&
        (dchatStatus === 'loading' || dchatStatus === 'ongoing')
      ) {
        const streamMsg = {
          role: 'ASSISTANT',
          content: streamtext,
        };
        showMsg = [...showMsg, streamMsg];
      }
      setMessages(showMsg);
    }
    if (!dchat || Object.keys(dchat).length === 0) {
      setMessages([]);
    }

    if (dchat?.messages?.length) {
      const lastMessage = dchat.messages[dchat.messages.length - 1];
      const sopSel = lastMessage.docSelections || [];
      const sharepointSel = lastMessage.sharepointSelections || [];
      const rsel = lastMessage.realtimeSelections || [];

      setDocSelections(sopSel);
      setSharepointSelections(sharepointSel);
      setRealtimeSelections(rsel);
    }
  }, [dchat, streamtext]);

  useEffect(() => {
    if (messagesEndRef.current && dchatStatus === 'succeeded') {
      messagesEndRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [dchatStatus]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false; // Set to false after the first render
      return;
    }

    if (
      !isSyncing &&
      !isLoadingSharepointFiles &&
      (sharepointSyncSession ||
        files?.length > 0 ||
        sharepointFileRefs?.length > 0)
    ) {
      showDivIfSyncIsWithinTimeRange(
        sharepointSyncSession,
        files,
        reduxSOPs,
        sharepointFileRefs
      ).then((res) => {
        setShouldShowDiv(isClickedFileSync ? false : res);
        if (
          !isClickedFileSync &&
          res &&
          sharepointSyncSession?.status !== 'Error'
        ) {
          // TODO - restore under better circumstances.  Otherwise this runs file sync on every upload which is inconvenient.
          //onFileSync();
        }
      });
    }
  }, [
    sharepointSyncSession,
    files,
    reduxSOPs,
    isClickedFileSync,
    sharepointFileRefs,
  ]);

  useEffect(() => {
    if (!isSyncing && isClickedFileSync) {
      setIsFetchingSOPs(true);
      reduxDispatch(fetchSOPs());
    }
  }, [isClickedFileSync, isSyncing]);

  useEffect(() => {
    if (isFetchingSOPs && isFetchSOPsRequestLoaded) {
      setIsClickedFileSync(false);
      setIsFetchingSOPs(false);
    }
  }, [isFetchingSOPs, isFetchSOPsRequestLoaded]);

  async function showDivIfSyncIsWithinTimeRange(
    syncSession,
    files = [],
    sopFiles = [],
    fileRefs = []
  ) {
    // Map the lastModifiedDateTime of each file to Date objects
    const lastModifiedDates = files.map(
      (file) => new Date(file.lastModifiedDateTime)
    );

    // 1. If no sync session exists, return true (meaning the div should be shown)
    if (!syncSession) {
      return true;
    }

    // 2. Compare sync time with the last modified dates of files
    const syncTimestamp = new Date(syncSession?.timestamp);

    // Check if any file was modified after the sync timestamp
    const isAnyFileModifiedAfterSync =
      syncTimestamp &&
      lastModifiedDates.some(
        (lastModifiedDate) => lastModifiedDate > syncTimestamp
      );

    if (isAnyFileModifiedAfterSync) {
      return true;
    }

    // 3. Check if a manual uploaded file (SOP file) has not timestamp
    if (sopFiles.some((file) => file?.timestamp === null)) {
      return true;
    }

    // 4. Check if sharepoint files is removed or not.
    const compareSharepointFiles = (arr1, arr2) => {
      return arr1.some((item1) => {
        return arr2.every((item2) => item2.id && item1.id !== item2.id);
      });
    };

    const vectorStoreId = sharepointSyncSession?.vector_store_id;
    if (fileRefs?.length > 0 && vectorStoreId) {
      const filteredSharepointFileRefs = fileRefs.filter(
        (item) => item?.vector_store_id === vectorStoreId
      );
      if (compareSharepointFiles(filteredSharepointFileRefs, files)) {
        return true;
      }
    }

    // 5. Check if sop files is removed or not.
    if (!checkedForDeletedFiles) {
      setCheckedForDeletedFiles(true);
      const isExistedDeletedFiles = await reduxDispatch(
        checkDeletedSOPFiles(vectorStoreId)
      );
      if (isExistedDeletedFiles) {
        return true;
      }
    }

    // If none of the conditions are met, return false
    return false;
  }

  const onFileSync = () => {
    if (
      reduxSOPs.length === 0 &&
      (!hasSharepointLocation || sharepointFileRefs.length === 0)
    ) {
      toast.error(
        'No Files found. Upload Files to use with DisasterGPT (optional)',
        toastConfig
      );
    } else {
      syncSharepointFiles(true);
      setIsClickedFileSync(true);
    }
  };

  function cancelRunClicked() {
    reduxDispatch(cancelRun(dchat));
  }

  function deleteSessionClicked() {
    if (dchat.id) {
      setShowDeleteModal(true);
    }
  }

  function handleConfirmDelete() {
    deleteDisasterChat.mutate(dchat.id);
    closeClicked();
    setShowDeleteModal(false);
  }

  function handleCancelDelete() {
    setShowDeleteModal(false);
  }

  function closeClicked() {
    reduxDispatch(setSelectedDChat({}));
    reduxDispatch(setCurrentSitrepId());
    toggle();
  }

  function areSelectionsValid() {
    if (!!realtimeSelections.length) {
      const sitrepSelection = realtimeSelections.find(
        (s) => s.source === 'SITREP'
      );
      if (!!sitrepSelection && !sitrepSelection.id) {
        return false;
      }
    }
    return true;
  }

  const onUsePrompt = (prompt) => {
    const structuredPrompt = `# ${prompt.title}\n\n${prompt.description}`;
    let promptSOPs = docSelections;
    let promptSharepoint = sharepointSelections;
    let promptData = realtimeSelections;

    // Set docSelections, sharepointSelections, and realtimeSelections to the prompt's settings
    if (prompt.settings) {
      // Documents (SOPs) are distinct from SharePoint files
      if (prompt.settings.documents) {
        const sopDocs = prompt.settings.documents.filter(
          (doc) => doc.source === 'SOP'
        );
        setDocSelections(sopDocs);
        promptSOPs = sopDocs; // Update SOP selections only
      }

      // SharePoint files are separate from SOPs
      if (prompt.settings.sharepoint) {
        const sharepointDocs = prompt.settings.sharepoint;
        setSharepointSelections(sharepointDocs); // Update SharePoint selections
        promptSharepoint = sharepointDocs; // Update SharePoint selections only
      }

      if (prompt.settings.datasources) {
        setRealtimeSelections(prompt.settings.datasources);
        promptData = prompt.settings.datasources;
      }
    }

    // Send message with updated selections for SOPs, SharePoint, and data sources
    sendMessage(
      structuredPrompt,
      prompt.title,
      promptData,
      promptSOPs,
      promptSharepoint
    );
  };

  // Custom components for ReactMarkdown to style tables
  const markdownComponents = {
    table({ node, ...props }) {
      return <table className="custom-markdown-table" {...props} />;
    },
    th({ node, ...props }) {
      return <th className="custom-markdown-th" {...props} />;
    },
    td({ node, ...props }) {
      return <td className="custom-markdown-td" {...props} />;
    },
    tr({ node, ...props }) {
      return <tr className="custom-markdown-tr" {...props} />;
    },
    // ... other custom components if needed
  };

  const customSchema = {
    ...defaultSchema,
    attributes: {
      ...defaultSchema.attributes,
      span: [...(defaultSchema.attributes.span || []), 'style'],
    },
  };

  return (
    <DrawerWrapper
      toggle={closeClicked}
      title={`DisasterChat by DisasterGPT - ${dchat?.name || '(New chat)'}`}
      fullscreen={false}
    >
      <Offcanvas.Body>
        <div>
          {messages.map((message, index) => {
            let timestamp;

            if (dchat?.id) {
              timestamp = message.timestamp;
            } else {
              timestamp = sendMessageTimestamp;
            }
            if (timestamp) {
              timestamp = dayjs(timestamp).format('YYYY-MM-DD HH:mm');
            } else {
              timestamp = '(No datetime provided)';
            }
            // Regex to remove annotations
            let messageContent = message?.content.replace(
              /\*\*\[[^\]]*\]\*\*|【[^】]*】/g,
              ''
            );

            let messageRole = message.role.toUpperCase();
            if (messageRole === 'ASSISTANT') {
              messageRole = 'DisasterGPT';
            }

            return (
              <div key={'dpgt-message-' + index}>
                <div>
                  <strong>{messageRole}:</strong>
                  <IoMdCopy
                    className="clipboard-icon"
                    onClick={() =>
                      docxExport(
                        messageContent,
                        'Clipboard',
                        dchat.name || 'DisasterChat'
                      )
                    }
                    title="Copy to clipboard"
                    style={{ cursor: 'pointer', marginLeft: '10px' }}
                  />
                  <AiOutlineFileWord
                    className="download-icon"
                    onClick={() =>
                      docxExport(
                        [message],
                        'Download',
                        dchat.name || 'DisasterChat'
                      )
                    }
                    title="Click to Download"
                    style={{ cursor: 'pointer', marginLeft: '10px' }}
                  />
                </div>
                <div>
                  <label>{timestamp}</label>
                  <div className="EventAIAnalysis-paragraph">
                    <ReactMarkdown
                      remarkPlugins={[remarkGfm]}
                      rehypePlugins={[
                        [rehypeRaw, { passThrough: ['element'] }],
                        [rehypeSanitize, customSchema],
                      ]}
                      components={{
                        ...markdownComponents, // Include custom components for table styling
                        a: ({ node, ...props }) => (
                          <a
                            {...props}
                            target="_blank"
                            rel="noopener noreferrer"
                          />
                        ),
                      }}
                    >{messageContent}</ReactMarkdown>
                  </div>
                </div>
                <hr />
              </div>
            );
          })}
        </div>
        <Row>
          {dchatStatus === 'loading' || dchatStatus === 'ongoing' ? (
            <div>
              <h5>
                {priorStatus}&nbsp;<i className="fa fa-spinner fa-pulse"></i>
              </h5>
              <div>
                <span>
                  DisasterChat on average is 10-60 seconds but depending on the
                  volume of data can take longer to generate an answer.
                </span>
              </div>
            </div>
          ) : (
            <div className="ChatDisasterGPT-input-wrap">
              <StylishNewTextArea
                type="text"
                value={input}
                disabled={
                  dchatStatus === 'loading' || dchatStatus === 'ongoing'
                }
                onChange={(e) => setInput(e.target.value)}
                onKeyPress={(e) =>
                  e.key === 'Enter'
                    ? !!areSelectionsValid() &&
                      !isSyncing &&
                      input.length > 0 &&
                      sendMessage(input)
                    : null
                }
                placeholder={'Message DisasterGPT...'}
              />
              <h6 className="mt-1 mb-1">
                DisasterGPT is currently in Preview. For bug reports and feature
                requests, please contact{' '}
                <a href="mailto:support@disastertech.com">
                  support@disastertech.com
                </a>
              </h6>
              <div>
                <label>
                  DisasterGPT can make mistakes. Always check important
                  information.
                </label>
              </div>
              <div>
                <div className="ChatDisasterGPT-input-button-wrap">
                  <div className="d-flex justify-content-between">
                    <StylishNewButton
                      onClick={() => sendMessage(input)}
                      disabled={
                        !areSelectionsValid() || isSyncing || input.length === 0
                      } //|| !sharepointSyncSession}
                      className="button--primary" // Optional: Add margin for spacing
                    >
                      {isSyncing ? (
                        <i className="fa fa-spinner fa-pulse"></i>
                      ) : (
                        'Send'
                      )}
                    </StylishNewButton>
                    <StylishNewButton
                      onClick={() => onFileSync()}
                      disabled={
                        isSyncing ||
                        dchatStatus === 'loading' ||
                        dchatStatus === 'ongoing'
                      }
                      className="button--primary" // Optional: Add margin for spacing
                    >
                      {isSyncing ? (
                        <i className="fa fa-spinner fa-pulse"></i>
                      ) : (
                        'File Sync'
                      )}
                    </StylishNewButton>
                  </div>
                </div>
                <div>
                  {(isSyncing && (
                    <p>File Sync can take several minutes.</p>
                  )) || (
                    <>
                      {shouldShowDiv && (
                        <h6 className="mt-1 mb-1 text-error">
                          Press File Sync to refresh files for DisasterGPT.
                        </h6>
                      )}
                      <p>
                        File Sync sends your files to DisasterGPT. Run this when
                        new files have been uploaded. Files in DisasterGPT
                        expire after 24 hours.
                      </p>
                      {!!sharepointSyncSession && (
                        <p>
                          The most recent File Sync run:{' '}
                          {dayjs(sharepointSyncSession.timestamp).format(
                            'YYYY-MM-DD HH:mm'
                          )}
                        </p>
                      )}
                    </>
                  )}
                </div>
                {!areSelectionsValid() && (
                  <span>Choose a SITREP to send to DisasterChat</span>
                )}
              </div>
            </div>
          )}
          <div ref={messagesEndRef} />
        </Row>

        <Accordion className="custom-accordion">
          <Accordion.Item>
            <DataSources
              selections={realtimeSelections}
              setSelections={setRealtimeSelections}
            />
          </Accordion.Item>
          <Accordion.Item eventKey="0" className="mt-0">
            <PromptLibrary
              onUsePrompt={onUsePrompt}
              onPromptDataChange={handlePromptDataChange}
            />
          </Accordion.Item>
          <Accordion.Item eventKey="1" className="mt-0">
            <SOPs selections={docSelections} setSelections={setDocSelections} />
          </Accordion.Item>
          {(isUsingTeams || hasSharepointLocation) && (
            <Accordion.Item eventKey="2" className="mt-0">
              <SharepointFiles
                selections={sharepointSelections}
                setSelections={setSharepointSelections}
                hasSharepointLocation={hasSharepointLocation}
              />
            </Accordion.Item>
          )}
        </Accordion>
      </Offcanvas.Body>
      <DrawerFooter>
        <div className="button-group">
          {dchatStatus === 'loading' ||
            (dchatStatus === 'ongoing' && !!dchat && (
              <StylishNewButton
                className="button--secondary button--reverse"
                type="button"
                onClick={cancelRunClicked}
              >
                Cancel Current Run
              </StylishNewButton>
            ))}

          {!!dchat && !!dchat.id && (
            <>
              <StylishNewButton
                className="button--secondary button--reverse"
                type="button"
                onClick={() =>
                  docxExport(messages, 'Download', dchat.name || 'DisasterChat')
                }
              >
                Download Docx
              </StylishNewButton>

              <StylishNewButton
                className="button--secondary button--reverse"
                type="button"
                onClick={() =>
                  docxExport(
                    messages,
                    'Clipboard',
                    dchat.name || 'DisasterChat'
                  )
                }
              >
                Copy to Clipboard
              </StylishNewButton>
              <StylishNewButton
                className="button--secondary button--reverse"
                type="button"
                onClick={deleteSessionClicked}
              >
                DELETE SESSION
              </StylishNewButton>
            </>
          )}

          <StylishNewButton
            className="button--secondary button--reverse"
            type="button"
            onClick={closeClicked}
          >
            Close
          </StylishNewButton>
        </div>
      </DrawerFooter>
      <Modal
        show={showDeleteModal}
        onHide={handleCancelDelete}
        centered
        backdrop={true}
        keyboard={true}
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Delete Session</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this DisasterChat session?
        </Modal.Body>
        <Modal.Footer>
          <StylishNewButton
            className="button--secondary"
            onClick={handleCancelDelete}
          >
            Cancel
          </StylishNewButton>
          <StylishNewButton
            className="button--tertiary"
            onClick={handleConfirmDelete}
          >
            Delete
          </StylishNewButton>
        </Modal.Footer>
      </Modal>
    </DrawerWrapper>
  );
};

export default ChatDisasterGPT;
