import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startLoading } from 'reducers/loading/loading.action';
import { fetchTeamTemplates } from 'actions/teamsActions';
import { Accordion } from 'components/DesignSystems/Accordion/Accordion';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

interface Props {
    handleTemplateSelect: (template: any) => void;
}

export const ExistingTemplates = ({ handleTemplateSelect }: Props) => {
  const dispatch = useDispatch();

  const tenantId = useSelector((state: any) => state.app.tenantId);
  const teamsTemplates = useSelector((state: any) => state.app.teamsTemplates);

  useEffect(() => {
    if (teamsTemplates.length === 0) {
      dispatch(startLoading());
      dispatch(fetchTeamTemplates(tenantId));
    }
  }, [teamsTemplates, tenantId]);

  return (
    <div className="d-flex flex-column gap-3">
      {teamsTemplates.map((template: any) => (
        <Accordion
          key={template.value}
          header={() => (
            <div className="d-flex justify-content-between align-items-center">
              <span>{template.label}</span>
              <SharedIcon iconName="more_vert" bold classes='cursor-default' />
            </div>
          )}
          eventKey={template.value}
        >
          <div className="d-flex flex-column">
            <div className="accordion-details" />
            <div className="d-flex flex-row justify-content-end me-4 pb-2">
              <button className='btn-blue-text me-1' disabled>Preview</button>
              <button className='primary-btn' onClick={() => handleTemplateSelect(template)}>
                Use This Template
              </button>
            </div>
          </div>
        </Accordion>
      ))}
    </div>
  );
};
