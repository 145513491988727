import React from 'react';
import { Modal } from 'react-bootstrap';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';

export const UnavailableUserNotificationDialog = ({
  show,
  unavailableUsers,
  onClose,
}) => {
  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Unavailable User(s)</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>The following user(s) are currently unavailable:</p>
        {unavailableUsers.map((user) => (
          <p key={user.user_guid}>{user?.user_name || user?.email_address}</p>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <div className="button-group">
          <StylishNewButton
            className="button--secondary button--reverse"
            onClick={onClose}
          >
            Close
          </StylishNewButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
