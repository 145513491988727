import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useToggle } from 'react-use';

import * as turf from '@turf/turf';

import axios from 'axios';

import { BsArrowsFullscreen, BsFullscreenExit } from 'react-icons/bs';

import { useCustomEvents } from 'components/CustomEvents/hooks/customEventsHooks';
import { FaTimes } from 'react-icons/fa';
import dayjs from 'dayjs';
import mapboxgl from '!mapbox-gl';

import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton.js';
import { StylishRangeSlider24hrs } from 'components/DesignSystems/New/StylishRangeSlider24hrs/StylishRangeSlider24hrs.js';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';

import './MapComponent.css';
import { eriScenarios } from './constants/eri';

import { useAuth0 } from '@auth0/auth0-react';

import MapSettings from './mapMenu/MapSettings.js';
import MapAlerts from './mapMenu/MapAlerts.js';
import MapBase from './mapMenu/MapBase.js';
import MapGeolocations from './mapMenu/MapGeolocations.js';
import MapEvents from './mapMenu/MapEvents.js';
import MapWeather from './mapMenu/MapWeather.js';
import MapImpactModels from './mapMenu/MapImpactModels.js';
import MapIncidentReport from './mapMenu/MapIncidentReport.js';
import useMapInit from './mapHooks/useMapInit';
import MapLegend from './mapComponents/MapLegend.js';
import Chart from './mapComponents/Chart.js';
import useMapLayout from './mapHooks/useMapLayout';
import ERIChart from './mapComponents/ERIChart.js';
import MapTaskManagement from './mapMenu/MapTaskManagement';
import MapPowerOutage from './mapMenu/MapPowerOutage';
import PowerOutageChart from './mapComponents/PowerOutageChart';
import { powerOutageScenarios } from './constants/poweroutage';
import MapExternalMaps from './mapMenu/MapExternalMaps';

import ChatDisasterGPT from 'components/DisasterGPT/ChatDisasterGPT';
import DisasterChatSidebar from 'components/DisasterGPT/DisasterChatSidebar';
import SitrepSidebar from 'components/DisasterGPT/SitrepSidebar';
import MapSidebar from './MapSidebar';
import classnames from 'classnames'; // Add this import
import MapOilSpill from './mapMenu/MapOilSpill';
import MapNEO from './mapMenu/MapNEO';

import { useGeolocationLayers } from './mapHooks/useGeolocationLayers';
import { useEventLayers } from './mapHooks/useEventLayers';
import { useERILayers } from './mapHooks/useERILayers';
import { closeAirportsOnMap, closeSeaportsOnMap } from './mapEffects/transportationEffect';

const POPUP_LABELS = {
  WEATHER: 'WEATHER',
  ALERTS: 'ALERTS',
  GEOLOCATION: 'GEOLOCATION',
  EVENTS: 'EVENTS',
  ERI: 'ERI',
  INCIDENT_REPORT: 'INCIDENT_REPORT',
  TASK_MANAGEMENT: 'TASK_MANAGEMENT',
  TRANSPORTATION_NODES: 'TRANSPORTATION_NODES',
};

const VIEWMODES = [
  {
    label: 'Chart',
    value: 'Chart',
  },
  {
    label: 'Table',
    value: 'Table',
  },
];

export default function MapComponent({
  layers = [],
  setLayers,
  sources = [],
  setSources,
  lng,
  lat,
  zoom,
  apiPrefix,
}) {
  const mapboxToken =
    window.env.MAPBOX_ACCESS_TOKEN || process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
  mapboxgl.accessToken = mapboxToken;
  const owmToken =
    window.env.OPEN_WEATHER_MAP_API_KEY ||
    process.env.REACT_APP_OPEN_WEATHER_MAP_API_KEY;

  // constant variables
  const matches = window.matchMedia('(min-width: 768px)').matches;
  const authHeader = `Bearer ${sessionStorage['accessToken']}`;
  const { getAccessTokenSilently } = useAuth0();

  const reduxFeatureFlags = useSelector((state) => {
    return state.app.featureFlags;
  });

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const { data: customEvents = [] } = useCustomEvents(
    reduxCurrentlySelectedGroup?.group_guid
  );

  const auth = useSelector((state) => {
    return state.auth;
  });

  const [sidebarActiveItem, setSidebarActiveItem] = useState();
  const [sidebarSecondary, setSidebarSecondary] = useState({
    show: false,
    key: null,
  });
  const [fullScreenMode, setFullScreenMode] = useState(false);
  const [bottomPanelActive, setBottomPanelActive] = useState();
  const [mapHasLoaded, setMapHasLoaded] = useState(false);
  const [forecastData, setForecastData] = useState();
  const [viewMode, setViewMode] = useState('Chart');
  const [clickEventObject, setClickEventObject] = useState();
  const [clickedLayers, setClickedLayers] = useState([]);
  const [updatedLayer, setUpdatedLayer] = useState();

  const [selectedGeolocation, setSelectedGeolocation] = useState({
    type: null,
    geolocation: null,
  });

  const [ethelData, setEthelData] = useState();
  const [earthquakesData, setEarthquakesData] = useState();
  const [poweroutageData, setPoweroutageData] = useState();
  const [selectedEventTabNews, setSelectedEventTabNews] = useState();
  const [eventsVisibleToggle, setEventsVisibleToggle] = useState(false);
  const [airportsActive, setAirportsActive] = useState(false);
  const [seaportsActive, setSeaportsActive] = useState(false);
  const [oilSpillVisibleToggle, setOilSpillVisibleToggle] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState();
  const [selectedReport, setSelectedReport] = useState();
  const [selectedTask, setSelectedTask] = useState();
  const [selectedNode, setSelectedNode] = useState(); // Transportation Node

  const [selectedCustomEvent, setSelectedCustomEvent] = useState();

  const [lngLatWeather, setLngLatWeather] = useState();

  const [DGPTOpen, toggleDGPTOpen] = useToggle(false);
  const [sitrepOpen, toggleSitrepOpen] = useToggle(false);

  // settings variables.
  const [mapSettings, setMapSettings] = useState({
    trafficToggle: false,
    threeDBuildingsToggle: false,
    basemap: 'mapbox://styles/mapbox/satellite-streets-v11',
    weatherLayer: undefined,
    lng: lng,
    lat: lat,
    zoom: zoom,
    selectedDatetime: dayjs(),
    eriActive: false,
    eriScenario: 'eri_mean',
    powerOutageActive: false,
    oilSpillActive: false,
    powerOutageScenario: 'absolute',
    currentExternalMap: null,
    showExternalMap: false,
    airportsActive: false,
    seaportsActive: false,
  });

  // useRef variables
  const mapRef = useRef(React.createRef());
  const mapContainer = useRef(null);
  const map = useRef(null);
  const drawRef = useRef(React.createRef());

  const geolocationLayerState = useGeolocationLayers(
    map,
    mapHasLoaded,
    apiPrefix,
    mapSettings,
    (data) => handleLayersClicked(POPUP_LABELS.GEOLOCATION, data)
  );

  const eventLayerState = useEventLayers(
    map,
    mapHasLoaded,
    apiPrefix,
    mapSettings,
    (data) => handleLayersClicked(POPUP_LABELS.EVENTS, data),
    eventsVisibleToggle,
    setEventsVisibleToggle
  );
  const eriLayerState = useERILayers(
    map,
    mapHasLoaded,
    apiPrefix,
    mapSettings,
    (data) => handleLayersClicked(POPUP_LABELS.ERI, data),
    mapSettings
  );

  // functions
  const sidebarSecondaryHandler = (show, key) => {
    setSidebarSecondary({
      show: show,
      key: key,
    });
  };

  const handleFullScreenMap = () => {
    if (mapRef.current) {
      if (document.fullscreenElement === mapRef.current) {
        setFullScreenMode(false);
        document.exitFullscreen();
      } else {
        setFullScreenMode(true);
        mapRef.current.requestFullscreen();
      }
    }
  };

  const sidebarActiveItemHandler = (key) => {
    if (key !== sidebarActiveItem) {
      setSidebarActiveItem(key);
    } else {
      setSidebarActiveItem(null);
    }
  };

  const handleCloseMenu = () => {
    setSidebarActiveItem(null);
    closeAirportsOnMap(map);
    closeSeaportsOnMap (map);
    const controls = document.getElementsByClassName(
      'mapbox-gl-draw_ctrl-draw-btn'
    );
    controls[0].style.display = 'none';
    controls[1].style.display = 'none';
    controls[2].style.display = 'none';
    drawRef.current.changeMode('simple_select');
  };

  const updateLayer = (oldLayer, layer, layerFeature, newProps) => {
    // If update to feature, make sure to update sources
    if (!!layerFeature && !!newProps) {
      const newSource = {
        ...sources.find((s) => s.id === layer.source),
      };
      const newData = (!!newSource.data && newSource.data) || newSource._data;
      newData.features = newData.features.map((f) => {
        return {
          ...f,
          properties: f.id === layerFeature.id ? newProps : f.properties,
        };
      });
      setSources([
        ...sources.filter((s) => s.id !== layer.source),
        { ...newSource },
      ]);
    }
    // ID has changed so make sure old layer no longer in list
    if (!!oldLayer) {
      map.current.removeLayer(oldLayer.id);
      setLayers([...layers.filter((l) => l.id !== oldLayer.id)]);
    }
    setUpdatedLayer(layer);
  };

  const eventSelected = (event) => {
    if (event?.eventType === 'custom') {
      setSelectedCustomEvent(event); // Handle custom events
    } else {
      setSelectedEvent(event); // Handle other events
    }
    setSelectedEventTabNews(); // Reset the news tab
  };

  const reportSelected = (report) => {
    setSelectedReport(report);
  };

  const taskSelected = (task) => {
    setSelectedTask(task);
  };

  const nodeSelected = (node) => {
    setSelectedNode(node);
  };

  const handleLayersClicked = (key, data) => {
    if (key === POPUP_LABELS.WEATHER) {
      setClickEventObject((prev) => data?.e);
    }
    setClickedLayers((prev) => {
      const _layers = [...prev];
      const existingLayer = _layers.find((layer) => layer.key === key);
      if (existingLayer) {
        existingLayer.data = data;
      } else {
        _layers.push({ key, data });
      }
      return _layers;
    });
  };

  const ethelDataAndEarthquakesDataWrapper = ({
    selectedDatetime,
    featureFlags,
  }) => {
    const formattedDatetime = selectedDatetime
      .utc()
      .format('YYYY-MM-DD HH:mm:ss');

    let hazardNameFilterList = '';

    axios(`${apiPrefix}/dice/map/ethelData_alternate`, {
      method: 'GET',
      params: {
        selectedDatetime: formattedDatetime,
        hazardNameFilterList,
      },
    })
      .then((response) => {
        const responseData = response.data || {};
        setEthelData(responseData);
      })
      .catch((error) => {
        // for now, do nothing
      });

    const endDatetime = dayjs(formattedDatetime).format('YYYY-MM-DD HH:mm:ss');

    axios(`${apiPrefix}/dice/map/earthquakesData`, {
      method: 'GET',
      params: {
        selectedDatetime: formattedDatetime,
        endDatetime,
      },
    })
      .then((response) => {
        const responseData = response.data || {};
        setEarthquakesData(responseData);
      })
      .catch((error) => {
        // for now, do nothing
      });

    if (featureFlags.includes('POWER_OUTAGES')) {
      const selectedDatetimeForPowerOutage = selectedDatetime
        .minute(0)
        .second(0)
        .utc()
        .format('YYYY-MM-DD HH:mm:ss');

      axios(`${apiPrefix}/dice/map/poweroutageData`, {
        method: 'GET',
        params: {
          selectedDatetime: selectedDatetimeForPowerOutage,
        },
      })
        .then((response) => {
          const responseData = response.data || {};
          setPoweroutageData(responseData);
        })
        .catch((error) => {
          // for now, do nothing
        });
    }
  };

  const handleCustomEventSelected = (event) => {
    const fullCustomEvent = customEvents.find((e) => e.id === event.id);
    eventSelected();
    setSidebarActiveItem('Events');
    sidebarSecondaryHandler(true, 'Selected Custom Event');
    setSelectedEventTabNews();
    setSelectedCustomEvent(fullCustomEvent);
  };

  // Use Effect for map container
  const { location, setLocation } = useMapInit({
    map,
    mapboxgl,
    mapContainer,
    layers,
    setLayers,
    drawRef,
    mapSettings,
    mapHasLoaded,
    clickEventObject,
    apiPrefix,
    setMapSettings,
    setMapHasLoaded,
    sources,
    setSources,
    updatedLayer,
    setUpdatedLayer,
  });

  const { sidebarSelection, selectedModelFeature } = useMapLayout({
    mapboxgl,
    map,
    mapSettings,
    owmToken,
    layers,
    clickedLayers,
    ethelData,
    sources,
    setLayers,
    updateLayer,
    setSelectedGeolocation,
    setBottomPanelActive,
    eventSelected,
    reportSelected,
    taskSelected,
    nodeSelected,
    sidebarSecondaryHandler,
    setSidebarActiveItem,
    setSelectedEventTabNews,
    setClickedLayers,
    setLngLatWeather,
    setSources,
    handleCustomEventSelected,
    eventLayerState,
  });

  useEffect(() => {
    if (!!mapHasLoaded && !bottomPanelActive) {
      map.current.resize();
    }
  }, [mapHasLoaded, bottomPanelActive]);

  useEffect(() => {
    map.current.resize();
  }, [sidebarActiveItem, bottomPanelActive]);

  useEffect(() => {
    if (
      eventsVisibleToggle &&
      reduxFeatureFlags &&
      !!mapSettings?.selectedDatetime
    ) {
      ethelDataAndEarthquakesDataWrapper({
        selectedDatetime: mapSettings?.selectedDatetime,
        featureFlags: reduxFeatureFlags,
      });
    }
  }, [eventsVisibleToggle, mapSettings?.selectedDatetime, reduxFeatureFlags]);

  useEffect(() => {
    if (sidebarActiveItem !== 'Events' && !!selectedEvent) {
      eventSelected();
      sidebarSecondaryHandler(false, null);
    }
  }, [sidebarActiveItem]);

  useEffect(() => {
    if (sidebarActiveItem === 'Custom Events' && selectedCustomEvent) {
      eventSelected(selectedCustomEvent);
      sidebarSecondaryHandler(true, 'Selected Custom Event'); // Handle custom events in the sidebar
    }
  }, [sidebarActiveItem, selectedCustomEvent]);

  // Function to fly to the custom event location
  const flyToCustomEventFeature = (customEvent) => {
    // Get the features from the custom event's geolocation
    const features = customEvent?.geolocation?.geojson?.data?.features;
    if (!features || features.length === 0) {
      console.error('No features available for this custom event.');
      return;
    }

    const feature = features[0]; // Assuming the first feature
    if (!feature) {
      console.error('No feature found.');
      return;
    }

    const geometry = feature?.geometry;
    if (!geometry) {
      console.error('No geometry found.');
      return;
    }

    const coordinates = geometry?.coordinates;
    if (!coordinates) {
      console.error('No coordinates found.');
      return;
    }

    // Check if the geometry type is a 'Point'
    if (geometry.type === 'Point') {
      // Use flyTo for a Point since there's no bounding box
      map.current.flyTo({
        center: coordinates,
        zoom: 15, // Adjust zoom level as necessary
      });
    } else {
      // For other geometry types (Polygon, LineString, etc.), use turf.bbox
      try {
        const box = turf.bbox(feature);
        map.current.fitBounds(box, { padding: 50, maxZoom: 10 });
      } catch (error) {
        console.error('Error calculating bbox for the feature: ', error);
      }
    }
  };

  return (
    <div
      className={classnames('site-main__map', {
        'external-map-active': mapSettings.showExternalMap,
      })}
      ref={mapRef}
    >
      <div className="map-wrapper">
        <div className="d-inline-flex align-items-center map-full-screen">
          <StylishNewButton
            customButton
            className={`button--icon primary`}
            style={{ marginTop: '-18px', marginRight: '10px' }}
            onClick={() => handleFullScreenMap()}
          >
            {fullScreenMode ? <BsFullscreenExit /> : <BsArrowsFullscreen />}
          </StylishNewButton>
        </div>
        <div
          ref={mapContainer}
          className={`map-container ${
            bottomPanelActive ? 'with-bottom-panel' : ''
          }`}
        >
          {mapSettings.showExternalMap && mapSettings.currentExternalMap ? (
            <iframe
              src={mapSettings.currentExternalMap.url}
              style={{
                width: '100%',
                height: '100%',
                border: 'none',
              }}
              title={mapSettings.currentExternalMap.name}
            />
          ) : (
            <>
              {!!mapSettings?.weatherLayer && (
                <MapLegend
                  matches={matches}
                  weatherLayer={mapSettings?.weatherLayer}
                />
              )}
            </>
          )}
        </div>

        <div
          className={classnames('map-range', {
            'map-range-sidebar-expanded': Boolean(sidebarActiveItem),
          })}
          style={{
            bottom: !!bottomPanelActive ? '320px' : null
          }}
        >
          <StylishRangeSlider24hrs
            defaultValue={mapSettings?.selectedDatetime.hour()}
            minValue="0"
            maxValue="23"
            selectedDatetime={mapSettings?.selectedDatetime}
            setSelectedDatetime={(val) =>
              setMapSettings({
                ...mapSettings,
                selectedDatetime: val,
              })
            }
          />
        </div>

        {!!bottomPanelActive && (
          <div
            className={classnames('map-bottom-panel', {
              'map-bottom-panel-sidebar-expanded': Boolean(sidebarActiveItem),
            })}
          >
            <div className="map-bottom-panel-controls">
              <StylishNewButton
                customButton
                className={classnames(`button--icon primary cancel`, {
                  'icon-button-sidebar-expanded': Boolean(sidebarActiveItem),
                })}
                onClick={() => setBottomPanelActive()}
              >
                <FaTimes />
              </StylishNewButton>
              {bottomPanelActive?.isERI && selectedModelFeature && (
                <div className="map-bottom-panel-select">
                  <StylishNewSelect
                    options={VIEWMODES}
                    defaultValue={viewMode}
                    value={viewMode}
                    onChange={(e) => {
                      setViewMode(e.value);
                    }}
                    placeholder={viewMode}
                    isClearable={true}
                    isSearchable={true}
                    isMulti={false}
                    isDisabled={false}
                  />
                  <div className="map-bottom-panel-eri-legend-label">
                    Scenarios
                  </div>
                  <div className="map-bottom-panel-eri-legend">
                    {eriScenarios.map((s, idx) => (
                      <div
                        key={idx}
                        className="map-bottom-panel-eri-legend-scenario"
                      >
                        <div
                          className="map-bottom-panel-legend-scenario-color"
                          style={{ backgroundColor: s.color }}
                        ></div>
                        <div className="map-bottom-panel-legend-scenario-name">
                          {s.labelSmall}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {bottomPanelActive?.isPowerOutage && selectedModelFeature && (
                <div className="map-bottom-panel-select">
                  <StylishNewSelect
                    options={VIEWMODES}
                    defaultValue={viewMode}
                    value={viewMode}
                    onChange={(e) => {
                      setViewMode(e.value);
                    }}
                    placeholder={viewMode}
                    isClearable={true}
                    isSearchable={true}
                    isMulti={false}
                    isDisabled={false}
                  />
                  <div className="map-bottom-panel-eri-legend-label">
                    Scenarios
                  </div>
                  <div className="map-bottom-panel-eri-legend">
                    {powerOutageScenarios.map((s, idx) => (
                      <div
                        key={idx}
                        className="map-bottom-panel-eri-legend-scenario"
                      >
                        <div
                          className="map-bottom-panel-legend-scenario-color"
                          style={{ backgroundColor: s.color }}
                        ></div>
                        <div className="map-bottom-panel-legend-scenario-name">
                          {s.label}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            {bottomPanelActive?.isWeather && (
              <Chart
                layer={bottomPanelActive.layer}
                owmToken={owmToken}
                clickEventObject={clickEventObject}
                selectedDatetime={mapSettings?.selectedDatetime}
                forecastData={forecastData}
                setForecastData={setForecastData}
              />
            )}
            {bottomPanelActive?.isERI && selectedModelFeature && (
              <ERIChart
                layer={bottomPanelActive.layer}
                feature={selectedModelFeature}
                selectedDatetime={mapSettings?.selectedDatetime}
                apiPrefix={apiPrefix}
                eriScenario={mapSettings?.eriScenario}
                eriScenarios={eriScenarios}
                sidebarSelection={sidebarSelection}
                sidebarActiveItem={sidebarActiveItem}
                viewMode={viewMode}
                authHeader={authHeader}
              />
            )}

            {bottomPanelActive?.isPowerOutage && selectedModelFeature && (
              <PowerOutageChart
                layer={bottomPanelActive.layer}
                feature={selectedModelFeature}
                selectedDatetime={mapSettings?.selectedDatetime}
                apiPrefix={apiPrefix}
                powerOutageScenarios={powerOutageScenarios}
                sidebarActiveItem={sidebarActiveItem}
                viewMode={viewMode}
                authHeader={authHeader}
              />
            )}
          </div>
        )}
      </div>
      <MapSidebar
        sidebarActiveItem={sidebarActiveItem}
        sidebarActiveItemHandler={sidebarActiveItemHandler}
      >
        {sidebarActiveItem === 'DisasterChats' && (
          <DisasterChatSidebar
            toggleDGPTOpen={toggleDGPTOpen}
            DGPTOpen={DGPTOpen}
            onClose={() => handleCloseMenu()}
          />
        )}
        {sidebarActiveItem === 'SITREPS' && (
          <SitrepSidebar
            toggleSitrepOpen={toggleSitrepOpen}
            sitrepOpen={sitrepOpen}
            onClose={() => handleCloseMenu()}
            selectedDatetime={mapSettings?.selectedDatetime}
            map={map}
            mapHasLoaded={mapHasLoaded}
            toggleDGPTOpen={toggleDGPTOpen}
          />
        )}
        {sidebarActiveItem === 'Settings' && (
          <MapSettings
            mapSettings={mapSettings}
            handleMapSettings={(val) => setMapSettings(val)}
            onClose={() => handleCloseMenu()}
          />
        )}
        {sidebarActiveItem === 'AOR Reports' && (
          <MapAlerts
            map={map}
            mapSettings={mapSettings}
            mapboxToken={mapboxToken}
            mapHasLoaded={mapHasLoaded}
            apiPrefix={apiPrefix}
            handleMapSettings={(val) => setMapSettings(val)}
            setLayerClicked={(val) =>
              handleLayersClicked(POPUP_LABELS.ALERTS, val)
            }
            onClose={() => handleCloseMenu()}
            eventsVisibleToggle={eventsVisibleToggle}
            setEventsVisibleToggle={setEventsVisibleToggle}
            ethelData={ethelData}
            earthquakesData={earthquakesData}
            poweroutageData={poweroutageData}
            setEarthquakesData={setEarthquakesData}
            setSidebarActiveItem={setSidebarActiveItem}
            toggleDGPTOpen={toggleDGPTOpen}
          />
        )}
        {sidebarActiveItem === 'Basemap' && (
          <MapBase
            mapSettings={mapSettings}
            handleMapSettings={(val) => setMapSettings(val)}
            onClose={() => handleCloseMenu()}
          />
        )}
        {sidebarActiveItem === 'Geolocations' && (
          <MapGeolocations
            map={map}
            mapHasLoaded={mapHasLoaded}
            apiPrefix={apiPrefix}
            selectedGeolocation={selectedGeolocation}
            setLayerClicked={(val) =>
              handleLayersClicked(POPUP_LABELS.GEOLOCATION, val)
            }
            onClose={handleCloseMenu}
            layerState={geolocationLayerState} // Add this prop
            location={location}
            setLocation={setLocation}
            drawRef={drawRef}
          />
        )}
        {sidebarActiveItem === 'Events' && (
          <MapEvents
            map={map}
            mapSettings={mapSettings}
            mapHasLoaded={mapHasLoaded}
            apiPrefix={apiPrefix}
            mapboxToken={mapboxToken}
            sidebarSecondary={sidebarSecondary}
            sidebarActiveItem={sidebarActiveItem}
            eventsVisibleToggle={eventsVisibleToggle}
            setEventsVisibleToggle={setEventsVisibleToggle}
            selectedEvent={selectedEvent}
            setSelectedEvent={setSelectedEvent}
            selectedEventTabNews={selectedEventTabNews}
            setSelectedEventTabNews={setSelectedEventTabNews}
            eventSelected={eventSelected}
            setLayerClicked={(val) =>
              handleLayersClicked(POPUP_LABELS.EVENTS, val)
            }
            onClose={handleCloseMenu}
            toggleDGPTOpen={toggleDGPTOpen}
            selectedCustomEvent={selectedCustomEvent}
            setSelectedCustomEvent={setSelectedCustomEvent}
            flyToCustomEventFeature={flyToCustomEventFeature}
            layerState={eventLayerState} // Add this prop
          />
        )}
        {sidebarActiveItem === 'Weather' && (
          <MapWeather
            map={map}
            mapSettings={mapSettings}
            mapHasLoaded={mapHasLoaded}
            owmToken={owmToken}
            handleMapSettings={(val) => setMapSettings(val)}
            setLayerClicked={(val) =>
              handleLayersClicked(POPUP_LABELS.WEATHER, val)
            }
            onClose={() => handleCloseMenu()}
          />
        )}
        {sidebarActiveItem === 'Impact Models' && (
          <MapImpactModels
            map={map}
            mapHasLoaded={mapHasLoaded}
            apiPrefix={apiPrefix}
            mapSettings={mapSettings}
            handleMapSettings={(val) => setMapSettings(val)}
            setLayerClicked={(val) =>
              handleLayersClicked(POPUP_LABELS.ERI, val)
            }
            onClose={() => handleCloseMenu()}
          />
        )}
        {sidebarActiveItem === 'Incident Report' && (
          <MapIncidentReport
            map={map}
            mapHasLoaded={mapHasLoaded}
            apiPrefix={apiPrefix}
            selectedReport={selectedReport}
            setSelectedReport={setSelectedReport}
            setLayerClicked={(val) =>
              handleLayersClicked(POPUP_LABELS.INCIDENT_REPORT, val)
            }
            onClose={() => handleCloseMenu()}
          />
        )}
        {sidebarActiveItem === 'Task Management' && (
          <MapTaskManagement
            map={map}
            mapHasLoaded={mapHasLoaded}
            apiPrefix={apiPrefix}
            selectedTask={selectedTask}
            setSelectedTask={setSelectedTask}
            setLayerClicked={(val) =>
              handleLayersClicked(POPUP_LABELS.TASK_MANAGEMENT, val)
            }
            onClose={() => handleCloseMenu()}
          />
        )}
        {sidebarActiveItem === 'Power Outage' && (
          <MapPowerOutage
            map={map}
            mapHasLoaded={mapHasLoaded}
            apiPrefix={apiPrefix}
            mapSettings={mapSettings}
            handleMapSettings={(val) => setMapSettings(val)}
            setLayerClicked={(val) =>
              handleLayersClicked(POPUP_LABELS.ERI, val)
            }
            onClose={() => handleCloseMenu()}
          />
        )}
        {sidebarActiveItem === 'Oil Spill' && (
          <MapOilSpill
            map={map}
            mapHasLoaded={mapHasLoaded}
            apiPrefix={apiPrefix}
            mapSettings={mapSettings}
            handleMapSettings={(val) => setMapSettings(val)}
            onClose={() => handleCloseMenu()}
          />
        )}
        {sidebarActiveItem === 'External Maps' && (
          <MapExternalMaps
            mapSettings={mapSettings}
            handleMapSettings={(val) => setMapSettings(val)}
            onClose={() => handleCloseMenu()}
          />
        )}
        {sidebarActiveItem === 'NEO' && (
          <MapNEO
            map={map}
            mapHasLoaded={mapHasLoaded}
            mapSettings={mapSettings}
            apiPrefix={apiPrefix}
            selectedNode={selectedNode}
            airportsActive={airportsActive}
            layerState={geolocationLayerState}
            setAirportsActive={setAirportsActive}
            seaportsActive={seaportsActive}
            setSeaportsActive={setSeaportsActive}
            setSelectedNode={setSelectedNode}
            setLayerClicked={(val) =>
              handleLayersClicked(POPUP_LABELS.TRANSPORTATION_NODES, val)
            }
            onClose={() => handleCloseMenu()}
          />
        )}
      </MapSidebar>
      {DGPTOpen ? (
        <ChatDisasterGPT
          toggle={toggleDGPTOpen}
          selectedDatetime={mapSettings?.selectedDatetime}
        />
      ) : null}
    </div>
  );
}
