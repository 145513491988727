import {
  NotificationJob,
  useUpdateNotificationJob,
  useAors,
  useNotificationJobs,
  useRedisHealth,
} from './jobs/notificationJobHooks';
import { Card } from 'react-bootstrap';
import AddNotificationJobDrawer from './jobs/AddNotificationJobDrawer';
import { useToggle } from 'react-use';
import PageTitle from '../headers/PageTitle';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { SharedIcon } from '../SharedIcon/SharedIcon';
import classNames from 'classnames';
import cronstrue from 'cronstrue';
import { extractTimestampFromUUIDv7 } from '../../utils/extractTimestampFromUuidV7';
import dayjs from 'dayjs';
import { useAppSelector } from '../../slices/commonSelectors';
import { getEmailRecipients } from './generateNotificationEntity';
import StylishNewTable from '../DesignSystems/New/StylishNewTable';
import Dropdown from 'react-bootstrap/Dropdown';

const NotificationJobs = () => {
  const [addOpen, toggleAddDrawer] = useToggle(false);

  const redisHealth = useRedisHealth();

  const jobsQuery = useNotificationJobs();
  const mutateJob = useUpdateNotificationJob();
  const aors = useAors();
  const templates = useAppSelector((s) => s.app.notifications);

  const toggleJob = async (row: NotificationJob) => {
    await mutateJob.mutateAsync({
      id: row.id,
      active: !row.active,
    });
  };

  const columns = [
    {
      dataField: 'short_description',
      text: 'Short Description',
      formatter: (_, row) => {
        return row.short_description ?? row.scheduler_name;
      },
    },
    {
      text: 'Type',
      dataField: 'job_type',
      formatter: (_, row) => {
        if (aors.isSuccess) {
          const aor = aors.data.find((a) => a.id === row.aor_id);
          if (aor) {
            return `Wind Risk Job (${aor.name}): ${row.job_options.threshold} mph`;
          }
        }
        return `Wind Risk Job (${row.job_options.threshold} mph)`;
      },
    },
    {
      text: 'Schedule',
      dataField: 'repeat_options.pattern',
      formatter: (cell) => {
        return cronstrue.toString(cell);
      },
    },
    {
      text: 'Method',
      formatter: (_, row) => {
        const notification = templates.find(
          (n: any) => n.notification_id === row.notification_id
        );
        if (!notification) {
          console.log('No Notification in method');
          return 'No Notification';
        }
        return notification.settings.recipientSettings.recipientTypes.join(
          ', '
        );
      },
    },
    {
      text: 'Recipients',
      formatter: (_, row) => {
        const notification = templates.find(
          (n: any) => n.notification_id === row.notification_id
        );
        if (!notification) {
          console.log('No Notification in recipients');
          return 'No Notification';
        }
        return getEmailRecipients(notification);
      },
    },
    {
      text: 'Active',
      dataField: 'active',
      formatter: (cell, row) => {
        let mismatch = false;
        if (jobsQuery.isSuccess) {
          const schedulers = jobsQuery.data?.repeatableJobs;
          const scheduler = schedulers.find(
            (j) => j.name === row.scheduler_name
          );
          if (!scheduler && row.active) {
            mismatch = true;
          }
        }
        return (
          <span className="d-flex align-items-center text-uppercase">
            <span
              className={classNames('status-dot', {
                success: row.active && !mismatch,
                danger: mismatch,
              })}
            ></span>
            <span>
              {mismatch ? 'Error' : row.active ? 'Active' : 'Inactive'}
            </span>
          </span>
        );
      },
    },
    {
      text: 'Date Created',
      isDummyField: true,
      formatter: (_, row) => {
        return dayjs(extractTimestampFromUUIDv7(row.id)).format(
          'hh:mm A MM/DD/YYYY'
        );
      },
    },
    {
      isDummyField: true,
      formatter: (_, row) => {
        return (
          <>
            <Dropdown className="flex-shrink-0 ms-auto dropdown-actions">
              <Dropdown.Toggle className="button--icon">
                <SharedIcon iconName={'more_vert'} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => toggleJob(row)}>
                  {row.active ? 'Deactivate' : 'Activate'}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        );
      },
      headerStyle: {
        width: '4rem',
      },
    },
  ] as ColumnDescription<NotificationJob>[];

  return (
    <>
      <PageTitle title="Alerts">
        <div className="d-flex align-items-center">
          <span
            className={classNames('status-dot', {
              success: redisHealth.isSuccess,
            })}
          ></span>
          <span>
            {redisHealth.isSuccess
              ? 'Queue Healthy'
              : redisHealth.isPending
              ? 'Status Loading'
              : 'Queue Stalled'}
          </span>
          <StylishNewButton
            className="button--primary ms-3"
            onClick={toggleAddDrawer}
          >
            Add Alert
          </StylishNewButton>
        </div>
      </PageTitle>

      <Card bg="inverse" className="p-3 w-100">
        <StylishNewTable
          classes={'pratus-table'}
          columns={columns}
          rows={jobsQuery?.data?.notificationJobs ?? []}
          keyField={'id'}
          noDataIndication={
            jobsQuery.isLoading ? (
              'Loading...'
            ) : jobsQuery.isError ? (
              <div className="form-text form-error">
                {jobsQuery.error?.message}
              </div>
            ) : (
              'No Jobs'
            )
          }
        />
      </Card>

      {addOpen && <AddNotificationJobDrawer toggle={toggleAddDrawer} />}
    </>
  );
};

export default NotificationJobs;
