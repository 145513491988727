import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';

import { 
  selectUser,
} from 'slices/commonSelectors';

import {
  ADD_WORKSPACE_TO_WORKSPACE_LIST,
  CHANGE_SELECTED_WORKSPACE,
  CHANGE_WORKSPACE_TIMEZONE,
  UPDATE_ALL_WORKSPACE_LIST,
  CHANGE_SELECTED_WORKSPACE_STATUS,
} from './workspaces.constants';

import { Dispatch as ReduxDispatch } from 'redux';

type Dispatch = ReduxDispatch<any, any>;
type GetState = () => any;

export const changeSelectedWorkSpace = (proposedId: string | undefined) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: CHANGE_SELECTED_WORKSPACE,
      payload: proposedId,
    });
  };
};

export const persistUserCurrentExerciseWorkSpace = (workspaceId: string | undefined)=> {
  return (_: Dispatch, getState: GetState) => {
    const { epic_user_id } = selectUser(getState());

    return Network.post(API.updateUserCurrentExerciseWorkspace, {
      workspaceId,
      userId: epic_user_id,
    }).catch((error) => {
      // TODO: Toast?
      console.error(error);
    });
  };
};

export const changeSelectedWorkSpaceStatus = (status: string) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: CHANGE_SELECTED_WORKSPACE_STATUS,
      payload: status,
    });
  };
};

export const updateWorkSpaceTimeZone = (timezone: string, timezoneOffset: string) => {
  return (dispatch:Dispatch) => {
    dispatch({
      type: CHANGE_WORKSPACE_TIMEZONE,
      payload: { timezone, timezoneOffset },
    });
  };
};

export const updateAllWorkSpaceList = (workspaceList: ExerciseWorkspace[]) => {
  return (dispatch:Dispatch) => {
    dispatch({
      type: UPDATE_ALL_WORKSPACE_LIST,
      payload: workspaceList,
    });
  };
};

export const updateWorkSpaceListById = (workspace: ExerciseWorkspace) => {
  return (dispatch:Dispatch) => {
    dispatch({
      type: ADD_WORKSPACE_TO_WORKSPACE_LIST,
      payload: workspace,
    });
  };
};
