import React from 'react';
import { Offcanvas, Row, Col, Form, Spinner } from 'react-bootstrap';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import DrawerWrapper, { DrawerFooter } from './DrawerWrapper';
import FormTextField from './formFields/FormTextField';
import FormSelectField from './formFields/FormSelectField';
import { useSplitResourceSet } from './hooks/resourceSetHooks';
import { useResourceSites } from './hooks/resourceSiteHooks';

const ResourceSetSplitDrawer = ({ toggle, selectedEntity }) => {
    const form = useForm({
        mode: 'onChange',
        defaultValues: {
            quantity: '',
            name: '',
            resource_site_id: selectedEntity.resource_site_id, // Set default to current resource site
        },
    });
    const { handleSubmit, control } = form;
    const { mutate: splitResourceSet, isLoading: isProcessing } = useSplitResourceSet();
    const { data: resourceSites = [] } = useResourceSites(); // Fetch resource sites

    // Watch the quantity field to compare it with the original quantity
    const quantity = useWatch({
        control,
        name: 'quantity',
    });

    const onSubmit = async (data) => {
        splitResourceSet(
            {
                resource_set_id: selectedEntity.resource_set_id,
                quantity: Number(data.quantity),
                name: data.name,
                resource_site_id: data.resource_site_id, // Include resource site in submission
            },
            {
                onSuccess: () => toggle(), // Close drawer after processing
            }
        );
    };

    // Validate the quantity to ensure it's a number and within the valid range
    const isQuantityInvalid =
        isNaN(quantity) || // Check if the value is not a number
        quantity === '' ||
        Number(quantity) <= 0 ||
        Number(quantity) > selectedEntity.quantity;

    return (
        <DrawerWrapper toggle={toggle} title="Split Resource Set">
            <FormProvider {...form}>
                <form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column justify-content-between flex-grow-1">
                    <Offcanvas.Body>
                        <Row className="mb-3">
                            <Col>
                                <Form.Label>Original Quantity</Form.Label>
                                <div>{selectedEntity.quantity}</div>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <FormSelectField
                                title="Resource Site"
                                fieldName="resource_site_id"
                                options={resourceSites.map((site) => ({ value: site.id, label: site.name }))}
                                variant="half"
                            />
                        </Row>
                        <Row className="mb-3">
                            <FormTextField
                                title="Quantity to Split"
                                fieldName="quantity"
                                rules={{
                                    required: { value: true, message: 'This field is required' },
                                    validate: (value) => !isNaN(value) || 'Quantity must be a valid number',
                                    min: { value: 1, message: 'Quantity must be a positive number greater than 0' },
                                    max: {
                                        value: selectedEntity.quantity,
                                        message: `Quantity must be less than or equal to ${selectedEntity.quantity}`,
                                    },
                                }}
                                variant="half"
                            />
                        </Row>
                        
                        <Row>
                            <FormTextField
                                title="New Resource Set Name"
                                fieldName="name"
                                variant="full"
                            />
                        </Row>
                    </Offcanvas.Body>
                    <DrawerFooter>
                        <div className="button-group">
                            <StylishNewButton
                                className="button--secondary button--reverse"
                                type="button"
                                onClick={toggle}
                                disabled={isProcessing} // Disable close button while processing
                            >
                                Close
                            </StylishNewButton>
                            <StylishNewButton
                                className="button--primary"
                                type="submit"
                                disabled={isQuantityInvalid || isProcessing}
                            >
                                {isProcessing ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Split'}
                            </StylishNewButton>
                        </div>
                    </DrawerFooter>
                </form>
            </FormProvider>
        </DrawerWrapper>
    );
};

export default ResourceSetSplitDrawer;
