import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as turf from '@turf/turf';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { MdLocationOn, MdOutlineWarning } from 'react-icons/md';
import { HiOutlineExternalLink } from 'react-icons/hi';
import Accordion from 'react-bootstrap/Accordion';

import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import IconArrowLeft from '../../../assets/images/icon__arrow--left.svg';
import EventDetails from '../mapComponents/EventDetails.js';
import { StylishSwitcher } from 'components/DesignSystems/New/StylishSwitcher';
import { eriScenarios, eriValCols } from '../constants/eri';

import IconClose from '../../../assets/images/icon__times.svg';
import IconArrowRight from '../../../assets/images/icon__arrow--right.svg';
import { fetchGeolocationRiskOrg } from 'actions/alertActions';
import queryBingNewsEffect from '../mapEffects/queryBingNewsEffect';
import moment from 'moment';
import { sampleDrawLayer } from '../constants/sampleLayers.js';
import {
  additionalWildFiresHazard,
  eventHazardsCategory,
} from '../constants/eventHazards.js';
import CoreComponentCreationDialog from 'components/AI2FP/CoreComponentCreationDialog';

import geolocationsEffect from '../mapEffects/geolocationsEffect.js';

import mapEventsEffect from '../mapEffects/mapEventsEffect';

import validateSubscription from 'utils/validateSubscription';
import SubscriptionRequired from 'components/SubscriptionManagement/SubscriptionRequired';

import { fetchCoreComponents } from 'actions/ai2fpActions';

import axios from 'axios';

const percentileToPercentage = {
  eri_mean: 'Mean Average Probability',
  eri_10th: '90%',
  eri_25th: '75%',
  eri_75th: '25%',
  eri_90th: '10%',
};

const MapAlerts = (props) => {
  const {
    map,
    mapSettings,
    mapboxToken,
    mapHasLoaded,
    apiPrefix,
    handleMapSettings,
    setLayerClicked,
    onClose,
    eventsVisibleToggle,
    setEventsVisibleToggle,
    ethelData,
    earthquakesData,
    poweroutageData,
    setSidebarActiveItem,
    toggleDGPTOpen,
  } = props;

  const reduxDispatch = useDispatch();

  // Constant variables
  const selectedDatetime = mapSettings?.selectedDatetime;
  const eriScenario = mapSettings?.eriScenario;
  const eriActive = mapSettings?.eriActive;

  const bingNewsKey = window.env.BING_NEWS_API_KEY;
  const stateToCountyZoomThreshold = 5;
  const eri_source_id = 'eri_source_id';
  const eri_source_id_state = 'eri_source_id_state';
  const eri_layer_id = 'eri_layer_id';
  const eri_layer_id_state = 'eri_layer_id_state';
  const eri_source_layer = 'public.eri_layer';
  const eri_source_layer_state = 'public.eri_layer_state';
  let eriTiles;
  let eriTilesState;
  const authHeader = `Bearer ${sessionStorage['accessToken']}`;

  // Selector variables
  const reduxCoreComponents = useSelector((state) => {
    return state.app.coreComponents;
  });
  const reduxCoreComponentsLoaded = useSelector((state) => {
    return state.app.isFetchCoreComponentsLoaded;
  });
  const reduxGeolocationsRisk = useSelector((state) => {
    return state.app.geolocationsRisk;
  });
  const reduxGeolocationsRiskLoaded = useSelector((state) => {
    return state.app.isFetchGeolocationRiskLoaded;
  });
  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });
  const reduxCurrentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });

  const user = useSelector((state) => {
    return state.app.user;
  });
  const reduxUserSubscriptionInfo = useSelector((state) => {
    return state.app.userSubscriptionInfo;
  });
  const featureFlags = useSelector((state) => {
    return state.app.featureFlags;
  });

  // State Declarations
  const [isLoading, setIsLoading] = useState(!reduxCoreComponentsLoaded);
  const [AORVisibleToggle, setAORVisibleToggle] = useState(true);
  const [showSidebarOrder, setShowSidebarOrder] = useState(1);
  const [eventData, setEventData] = useState(null);
  const [eriData, setEriData] = useState(null);
  const [activeAORItem, setActiveAORItem] = useState(null);
  const [activeEvent, setActiveEvent] = useState(null);
  const [eventLocationInfo, setEventLocationInfo] = useState();
  const [selectedEventNews, setSelectedEventNews] = useState();
  const [tab, setTab] = useState('info');

  const [aorCreationDialogActive, setAORCreationDialogActive] = useState(false);

  // functions
  const groupedEventDataByCategory = useMemo(() => {
    if (!eventData && !poweroutageData) return null;

    const mergedEventData = [...(eventData || []), ...(poweroutageData || [])];
    // grouped events
    let groupedEthelData = mergedEventData.reduce((groups, event) => {
      let category = event?.hazard_data_category;
      if (!groups[category]) {
        groups[category] = [];
      }

      groups[category].push(event);

      // add some events from NWS to Hurricane
      if (
        category !== 'Hurricane' &&
        event?.hazard_name.includes('Hurricane')
      ) {
        if (!groups['Hurricane']) groups['Hurricane'] = [];
        groups['Hurricane'].push(event);
      }
      // add some events from NWS to Wild Fires
      if (
        category === 'NWS alerts' &&
        additionalWildFiresHazard.filter(
          (w) => w.hazard_name === event?.hazard_name
        )?.length > 0
      ) {
        if (!groups['Wild Fires']) groups['Wild Fires'] = [];
        groups['Wild Fires'].push(event);
      }
      return groups;
    }, {});

    const groupedEventDataByCategory = Object.keys(groupedEthelData)
      .sort()
      .reduce((acc, key) => {
        acc[key] = groupedEthelData[key].sort((a, b) =>
          a.hazard_name.localeCompare(b.hazard_name)
        );
        return acc;
      }, {});

    return groupedEventDataByCategory;
  }, [eventData, poweroutageData]);

  useEffect(() => {
    if (!!activeAORItem) {
      flyToGeolocation(activeAORItem);
    }
  }, [activeAORItem]);

  useEffect(() => {
    if (
      reduxGeolocationsRisk &&
      activeAORItem?.id &&
      !!reduxGeolocationsRisk[activeAORItem.id]
    ) {
      setEventData(reduxGeolocationsRisk[activeAORItem.id].events);
      setEriData(reduxGeolocationsRisk[activeAORItem.id].eris);
    } else {
      setEventData(null);
      setEriData(null);
    }
  }, [reduxGeolocationsRisk]);

  const handleClickClose = () => {
    onClose();
  };

  const flyToGeolocation = (item) => {
    if (
      !!item.geolocation &&
      item.geolocation.geojson &&
      item.geolocation.geojson.data &&
      item.geolocation.geojson.data.features &&
      item.geolocation.geojson.data.features.length
    ) {
      var box = turf.bbox(item.geolocation.geojson.data.features[0].geometry);
      map.current.fitBounds(box, { padding: 50, maxZoom: 10 });
    }
  };

  const handleGotoBack = () => {
    setTab('events');
    if (showSidebarOrder !== 3) {
      setActiveAORItem(null);
    }
    setShowSidebarOrder((_showSidebarOrder) => _showSidebarOrder - 1);
  };

  const handleSelectAOR = (aor) => {
    // when loading, don't go to the next step.
    if (!!isLoading) return;

    setShowSidebarOrder(2);
    setTab('events');
    setActiveAORItem(aor);

    if (reduxCurrentlySelectedGroup.group_guid && aor) {
      const formattedDatetime = selectedDatetime
        .utc()
        .format('YYYY-MM-DD HH:mm:ss');
      // reduxDispatch(
      //   fetchGeolocationRiskOrg(reduxCurrentlySelectedGroup.group_guid, formattedDatetime, aor)
      // );
    }

    /*
    if (reduxGeolocationsRisk?.length > 0) {
      setEventData(reduxGeolocationsRisk.find(elem => elem?.aor_id === aor.id)?.events);
      setEriData(reduxGeolocationsRisk.find(elem => elem?.aor_id === aor.id)?.eris)
    } else {
      setEventData(null);
      setEriData(null);
    }
    */
  };

  const handleSelectEvent = (event) => {
    setShowSidebarOrder(3);
    setTab('info');
    setActiveEvent(event);
  };

  const flyToEventFeature = (event) => {
    if (event.hazard_name === 'Hurricane') {

      axios(`${apiPrefix}/dice/map/hurricaneBounds`, {
        method: 'GET',
        params: {
          hurricaneBounds: event.storm_id,
        },
      })
        .then((response) => {

          const responseData = response.data || {};
          var box = turf.bbox(responseData);
          map.current.fitBounds(box, { padding: 50, maxZoom: 10 });
        })
        .catch((error) => {
          console.warn('Error:', error);
        });
    }

    if (!event.geojson) return;
    if (event.geojson.type === 'Point') {
      map.current.flyTo({ center: event.geojson.coordinates, zoom: 13 });
    } else {
      const box = turf.bbox(event.geojson);
      map.current.fitBounds(box, { padding: 50, maxZoom: 10 });
    }
  };

  // useEffect for side effect
  useEffect(() => {
    if (!reduxCoreComponentsLoaded) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [reduxCoreComponentsLoaded]);

  useEffect(() => {
    const isValidSubscription = featureFlags.includes('STRIPE')
      ? !!validateSubscription(user, reduxUserSubscriptionInfo)
      : true;
    if (!reduxCoreComponents) {
      reduxDispatch(
        fetchCoreComponents(reduxCurrentlySelectedGroup.group_guid)
      );
    }
    if (
      reduxCurrentlySelectedGroup?.group_guid &&
      selectedDatetime &&
      isValidSubscription &&
      !!activeAORItem &&
      !reduxGeolocationsRisk[activeAORItem.id] &&
      !!reduxGeolocationsRiskLoaded
    ) {
      const formattedDatetime = selectedDatetime
        .utc()
        .format('YYYY-MM-DD HH:mm:ss');
      // reduxDispatch(
      //   fetchGeolocationRiskOrg(reduxCurrentlySelectedGroup.group_guid, formattedDatetime, activeAORItem)
      // );
      if (!eventsVisibleToggle) setEventsVisibleToggle(true);
    }
  }, [
    reduxCurrentlySelectedGroup,
    selectedDatetime,
    activeAORItem,
    reduxGeolocationsRiskLoaded,
  ]);

  useEffect(() => {
    if (!!activeEvent?.geojson?.coordinates) {
      let centroid = activeEvent.geojson.coordinates;
      let placeUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${centroid[0]},${centroid[1]}.json?access_token=${mapboxToken}`;

      try {
        if (activeEvent.geojson.type !== 'Point') {
          const polygon = turf.multiPolygon(activeEvent.geojson.coordinates);
          centroid = turf.centroid(polygon);
          placeUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${centroid.geometry.coordinates[0]},${centroid.geometry.coordinates[1]}.json?access_token=${mapboxToken}`;
        }

        fetch(placeUrl)
          .then((res) => res.json())
          .then((response) => {
            const place = response.features.find((f) => f.id.includes('place'));
            const placeName = (!!place && place.place_name) || '';

            const country = response.features.find((f) =>
              f.id.includes('country')
            );
            const cc =
              (!!country &&
                !!country.properties &&
                !!country.properties.short_code &&
                country.properties.short_code.toUpperCase()) ||
              'US';
            setEventLocationInfo({
              place: place,
              placeName: placeName,
              country: country,
              cc: cc,
            });
          });
      } catch (e) {
        setEventLocationInfo();
      }
    } else {
      setEventLocationInfo();
    }
  }, [activeEvent, mapboxToken]);

  useEffect(() => {
    if (!!mapHasLoaded && !!reduxCurrentlySelectedGroup) {
      geolocationsEffect({
        map,
        mapHasLoaded,
        apiPrefix,
        reduxCurrentlySelectedGroup,
        reduxCurrentIncident,
        showAORsOnMap: AORVisibleToggle,
        setLayerClicked,
      });
    }
  }, [map, mapHasLoaded, reduxCurrentlySelectedGroup, AORVisibleToggle]);

  useEffect(() => {
    queryBingNewsEffect(
      activeEvent,
      selectedEventNews,
      setSelectedEventNews,
      eventLocationInfo,
      bingNewsKey,
      tab,
      mapSettings?.selectedDatetime
    );
  }, [activeEvent, eventLocationInfo, bingNewsKey, tab]);

  useEffect(() => {
    if (!mapHasLoaded || !featureFlags) return;

    if (!!eriActive) {
      if (!!map.current.getStyle().layers.find((l) => l.id === eri_layer_id))
        map.current.removeLayer(eri_layer_id);
      if (
        !!map.current.getStyle().layers.find((l) => l.id === eri_layer_id_state)
      )
        map.current.removeLayer(eri_layer_id_state);
      if (!!map.current.getSource(eri_source_id))
        map.current.removeSource(eri_source_id);
      if (!!map.current.getSource(eri_source_id_state))
        map.current.removeSource(eri_source_id_state);

      const formattedDatetime = selectedDatetime
        .minute(0)
        .second(0)
        .format('YYYY-MM-DD HH:mm:ss');

      if (!map.current.getSource(eri_source_id)) {
        eriTiles = `${apiPrefix}/model_tiles/${eri_source_layer}/{z}/{x}/{y}.pbf`;

        map.current.addSource(eri_source_id, {
          type: 'vector',
          tiles: [eriTiles + '?datetime_selected=' + formattedDatetime],
        });
      }

      if (!map.current.getSource(eri_source_id_state)) {
        eriTilesState = `${apiPrefix}/model_tiles/${eri_source_layer_state}/{z}/{x}/{y}.pbf`;

        map.current.addSource(eri_source_id_state, {
          type: 'vector',
          tiles: [eriTilesState + '?datetime_selected=' + formattedDatetime],
        });
      }

      if (
        !map.current.getStyle().layers.find((l) => l.id === eri_layer_id) &&
        !map.current.getStyle().layers.find((l) => l.id === eri_layer_id_state)
      ) {
        const foundScenario = eriScenarios.find((s) => s.value === eriScenario);
        const eriLayerConfig = {
          ...sampleDrawLayer,
          id: eri_layer_id,
          source: eri_source_id,
          'source-layer': eri_source_layer,
          generateId: true,
          paint: {
            ...sampleDrawLayer.paint,
            'fill-color': [
              'match',
              ['get', `${eriScenario}`],
              0,
              '#FFFFFF',
              1,
              '#fecc5c',
              2,
              '#fd8d3c',
              3,
              '#f03b20',
              4,
              '#FF33F9',
              5,
              '#bd0026',
              '#FFFFFF',
            ],
            'fill-opacity': 0.4,
            'fill-outline-color': foundScenario?.color,
          },
          metadata: {
            eventlayer: false,
            usermade: undefined,
            model_layer: true,
            model_details: {
              model_name: 'Energy Reliability Index',
              model_code: 'ERI',
              political_boundaries_type: 'county',
            },
          },
          minzoom: stateToCountyZoomThreshold,
        };

        map.current.addLayer(eriLayerConfig);
        map.current.on('click', eriLayerConfig.id, (e) =>
          setLayerClicked({ layer: eriLayerConfig, e: e, features: e.features })
        );
        map.current.on('touchend', eriLayerConfig.id, (e) =>
          setLayerClicked({ layer: eriLayerConfig, e: e, features: e.features })
        );
        map.current.on('mouseenter', eriLayerConfig.id, () => {
          map.current.getCanvas().style.cursor = 'pointer';
        });
        map.current.on('mouseleave', eriLayerConfig.id, () => {
          map.current.getCanvas().style.cursor = 'grab';
        });

        let eriLayerConfigState = {
          ...eriLayerConfig,
          metadata: {
            ...eriLayerConfig.metadata,
            model_details: {
              ...eriLayerConfig.metadata.model_details,
              political_boundaries_type: 'state',
            },
          },
          id: eri_layer_id_state,
          source: eri_source_id_state,
          'source-layer': eri_source_layer_state,
          minzoom: 0,
          maxzoom: stateToCountyZoomThreshold,
        };
        map.current.addLayer(eriLayerConfigState);
        map.current.on('click', eriLayerConfigState.id, (e) =>
          setLayerClicked({
            layer: eriLayerConfigState,
            e: e,
            features: e.features,
          })
        );
        map.current.on('touchend', eriLayerConfigState.id, (e) =>
          setLayerClicked({
            layer: eriLayerConfigState,
            e: e,
            features: e.features,
          })
        );
        map.current.on('mouseenter', eriLayerConfigState.id, () => {
          map.current.getCanvas().style.cursor = 'pointer';
        });
        map.current.on('mouseleave', eriLayerConfigState.id, () => {
          map.current.getCanvas().style.cursor = 'grab';
        });
      }
    } else {
      if (!!map.current.getStyle().layers.find((l) => l.id === eri_layer_id))
        map.current.removeLayer(eri_layer_id);
      if (
        !!map.current.getStyle().layers.find((l) => l.id === eri_layer_id_state)
      )
        map.current.removeLayer(eri_layer_id_state);
      if (!!map.current.getSource(eri_source_id))
        map.current.removeSource(eri_source_id);
      if (!!map.current.getSource(eri_source_id_state))
        map.current.removeSource(eri_source_id_state);
    }
  }, [eriActive, mapHasLoaded, selectedDatetime, eriScenario, featureFlags]);

  useEffect(() => {
    const eventToggles = undefined;
    
    mapEventsEffect(
      map,
      mapHasLoaded,
      ethelData,
      eventsVisibleToggle,
      selectedDatetime,
      setLayerClicked,
      apiPrefix,
      earthquakesData,
      poweroutageData,
      featureFlags.includes('POWER_OUTAGES'),
      eventToggles,
      featureFlags
    );
  }, [
    map,
    eventsVisibleToggle,
    mapHasLoaded,
    ethelData,
    earthquakesData,
    poweroutageData,
    featureFlags,
  ]);

  const { placeName, lastObservedSelected } = useMemo(() => {
    let placeName;
    let lastObservedSelected;
    if (!!activeEvent) {
      if (!!eventLocationInfo && !!eventLocationInfo.placeName) {
        placeName = eventLocationInfo.placeName;
      }

      if (activeEvent.hazard_data_category === 'Hurricane') {
        lastObservedSelected = activeEvent.hurricane_data_points.find(
          (p) => p.storm_data_category === 'Observed Points'
        );
        if (!lastObservedSelected)
          lastObservedSelected =
            activeEvent.hurricane_data_points[
              activeEvent.hurricane_data_points.length - 1
            ];
        placeName =
          lastObservedSelected.unified_storm_category +
          ' ' +
          lastObservedSelected.unified_storm_name;
      }
      if (activeEvent.hazard_data_category === 'Earthquakes') {
        placeName = activeEvent?.properties?.place;
      }
      if (activeEvent.hazard_data_category === 'Power Outage') {
        placeName =
          (activeEvent.political_boundaries_type === 'county' &&
            `${activeEvent.county_name} County, ${activeEvent.state_name}`) ||
          (activeEvent.political_boundaries_type === 'state' &&
            activeEvent.state_name);
      }
    }

    return { placeName, lastObservedSelected };
  }, [activeEvent, eventLocationInfo]);

  function geolocationsClicked() {
    setSidebarActiveItem('Geolocations');
  }

  let subscriptionValidated = true;
  if (
    featureFlags.includes('STRIPE') &&
    !validateSubscription(user, reduxUserSubscriptionInfo)
  ) {
    subscriptionValidated = false;
  }

  return (
    <div className="sidebar-content-wide">
      <div className="sidebar-title">
        {showSidebarOrder > 1 ? (
          <StylishNewButton
            customButton
            className={'button--icon'}
            onClick={() => handleGotoBack()}
          >
            <img src={IconArrowLeft} alt="" />
          </StylishNewButton>
        ) : null}
        <h4 className="m-0">
          AOR Reports
          <span className="p--l ms-3">
            {isLoading && subscriptionValidated && (
              <i className="fa fa-spinner fa-pulse"></i>
            )}
          </span>
        </h4>
        <StylishNewButton
          customButton
          className={'button--icon'}
          onClick={() => handleClickClose()}
        >
          <img src={IconClose} alt="" />
        </StylishNewButton>
      </div>

      {!subscriptionValidated && <SubscriptionRequired />}
      <div className="sidebar-inner">
        {showSidebarOrder === 1 && (
          <>
            {(!!reduxCoreComponents?.CCs?.['Areas of Responsibility']
              .length && (
              <>
                <div className="d-flex align-items-center mb-3">
                  <StylishNewButton
                    className={'button--primary button--sml ms-auto'}
                    onClick={() => {
                      setAORCreationDialogActive(true);
                    }}
                  >
                    Create AOR
                  </StylishNewButton>
                </div>
                <hr className="thin w-10 mt-3 mb-2" />
                <div className="d-flex align-items-center mb-3">
                  <span>Show AOR</span>
                  <StylishSwitcher
                    className="ms-auto"
                    checked={AORVisibleToggle}
                    onChange={() => setAORVisibleToggle(!AORVisibleToggle)}
                  />
                </div>
                <hr className="thin w-10 mt-3 mb-2" />
              </>
            )) || (
              <>
                <span>
                  No AORs found. To use AORs for Alerts, go to{' '}
                  <a href="#" onClick={() => geolocationsClicked()}>
                    Geolocations
                  </a>
                  .
                </span>
              </>
            )}

            {reduxCoreComponents?.CCs?.['Areas of Responsibility']?.map(
              (aor, idx) => (
                <React.Fragment key={idx}>
                  {idx !== 0 && <hr className="dashed w-10 thin m-0" />}
                  <div
                    className="d-flex align-items-center cursor-pointer py-3"
                    onClick={() => handleSelectAOR(aor)}
                  >
                    <span className="text-truncate">{aor.name}</span>
                    <img
                      src={IconArrowRight}
                      alt=""
                      className="ms-auto img-h-16"
                    />
                  </div>
                </React.Fragment>
              )
            ) ||
              (reduxCoreComponents?.CCs?.['Areas of Responsibility']?.length ===
                0 && <></>) || <div>Loading Alerts...</div>}
          </>
        )}
        {showSidebarOrder === 2 && (
          <div className="tab-wrapper map-tab-container">
            <Tabs activeKey={tab} onSelect={(k) => setTab(k)}>
              <Tab
                eventKey="events"
                title={`Events (
                  ${reduxGeolocationsRiskLoaded ? eventData?.length || 0 : 0}
                  )`}
              >
                <div
                  className="anchor anchor--white cursor-pointer d-flex align-items-center bg-inherit p-3 rounded mb-3"
                  onClick={() => flyToGeolocation(activeAORItem)}
                >
                  <MdLocationOn className="img-h-20 me-3" />
                  {!!activeAORItem && <>{activeAORItem?.name}</>}
                </div>

                <div className="scroll scroll-alerts pe-3">
                  {!!reduxGeolocationsRiskLoaded && (
                    <StylishNewButton
                      className={'button--primary button--sml ms-auto'}
                      onClick={() => {
                        const formattedDatetime = selectedDatetime
                          .utc()
                          .format('YYYY-MM-DD HH:mm:ss');
                        reduxDispatch(
                          fetchGeolocationRiskOrg(
                            reduxCurrentlySelectedGroup.group_guid,
                            formattedDatetime,
                            activeAORItem,
                            true
                          )
                        );
                      }}
                    >
                      Refresh Alerts
                    </StylishNewButton>
                  )}
                  {reduxGeolocationsRiskLoaded ? (
                    eventData?.length > 0 &&
                    Object.keys(groupedEventDataByCategory).length > 0 ? (
                      <Accordion
                        defaultActiveKey="0"
                        className="standard-accordion"
                      >
                        {Object.keys(groupedEventDataByCategory).map(
                          (category, index) => {
                            let categoryName = category;
                            const categoryColor = eventHazardsCategory.find(
                              (eventCategory) =>
                                eventCategory?.hazard_data_category === category
                            )?.hazard_color;
                            if (categoryName === 'Wild Fires') {
                              categoryName = 'Wildfires';
                            }
                            if (categoryName === 'NWS alerts') {
                              categoryName = 'NWS Active Alerts';
                            }
                            return (
                              <Accordion.Item
                                eventKey={index}
                                key={'category-' + index}
                                className="mt-0"
                              >
                                <Accordion.Header className="border-b">
                                  {categoryName} (
                                  {groupedEventDataByCategory[category].length})
                                </Accordion.Header>
                                <Accordion.Body>
                                  {groupedEventDataByCategory[category].map(
                                    (item, idx) => {
                                      let hazard_name = item.hazard_name;
                                      if (hazard_name === 'Hurricane') {
                                        let lastObservedItem = item.hurricane_data_points.find(
                                          (p) =>
                                            p.storm_data_category ===
                                            'Observed Points'
                                        );
                                        if (!lastObservedItem) {
                                          lastObservedItem =
                                            item.hurricane_data_points[
                                              item.hurricane_data_points
                                                .length - 1
                                            ];
                                        }
                                        hazard_name =
                                          lastObservedItem.unified_storm_category +
                                          ' ' +
                                          lastObservedItem.unified_storm_name;
                                      } else if (hazard_name === 'Wild Fires') {
                                        hazard_name =
                                          '(Wildfires) ' +
                                          item.properties?.poly_IncidentName;
                                      } else if (
                                        hazard_name === 'Earthquakes'
                                      ) {
                                        hazard_name =
                                          '(Earthquakes) ' +
                                          item.properties?.place;
                                      }
                                      return (
                                        <div
                                          key={`filteredEth-${idx}`}
                                          className={`events-${idx}`}
                                        >
                                          {idx !== 0 && (
                                            <hr
                                              key={idx}
                                              className="dashed thin w-10 m-0"
                                            />
                                          )}
                                          <div
                                            className={`d-flex align-items-center cursor-pointer py-3`}
                                            key={item.instance_id}
                                            onClick={() =>
                                              handleSelectEvent(item)
                                            }
                                          >
                                            <span
                                              style={{
                                                color: categoryColor || 'unset',
                                              }}
                                            >
                                              <MdOutlineWarning className="img-h-24 me-3" />
                                            </span>
                                            <span className="text-truncate">
                                              {hazard_name}
                                            </span>
                                            <img
                                              src={IconArrowRight}
                                              alt=""
                                              className="ms-auto img-h-16"
                                            />
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </Accordion.Body>
                              </Accordion.Item>
                            );
                          }
                        )}
                      </Accordion>
                    ) : (
                      <p className="m-0">No Event Found</p>
                    )
                  ) : (
                    <>
                      <p className="m-0">
                        Loading Events...
                        <i className="fa fa-spinner fa-pulse"></i>
                      </p>
                    </>
                  )}
                </div>
              </Tab>
              <Tab
                eventKey="eri"
                title={`ERI (
                  ${reduxGeolocationsRiskLoaded ? eriData?.length || 0 : 0}
                  )`}
              >
                <div className="eri-container">
                  <h5 className="mb-1">Energy Reliability Index</h5>
                  {(!!eriData &&
                    eriData
                      .sort((a, b) => {
                        const compare = a.state_name.localeCompare(
                          b.state_name
                        );
                        if (compare === 0) {
                          if (a.county_name === 'NaN') return -1;
                          if (b.county_name === 'NaN') return 1;
                          return a.county_name.localeCompare(b.county_name);
                        }
                        return compare;
                      })
                      .map((eri, idx) => {
                        let highestValue = 0;
                        let highestValueLabel = '';
                        let highestValueTimestamp = '';

                        if (eri.eri_90th.val > highestValue) {
                          highestValue = eri.eri_90th.val;
                          highestValueLabel = 'eri_90th';
                          highestValueTimestamp = eri.eri_90th.timestamp;
                        }
                        if (eri.eri_75th.val > highestValue) {
                          highestValue = eri.eri_75th.val;
                          highestValueLabel = 'eri_75th';
                          highestValueTimestamp = eri.eri_75th.timestamp;
                        }
                        if (eri.eri_mean.val > highestValue) {
                          highestValue = eri.eri_mean.val;
                          highestValueLabel = 'eri_mean';
                          highestValueTimestamp = eri.eri_mean.timestamp;
                        }
                        if (eri.eri_25th.val > highestValue) {
                          highestValue = eri.eri_25th.val;
                          highestValueLabel = 'eri_25th';
                          highestValueTimestamp = eri.eri_25th.timestamp;
                        }
                        if (eri.eri_10th.val > highestValue) {
                          highestValue = eri.eri_10th.val;
                          highestValueLabel = 'eri_10th';
                          highestValueTimestamp = eri.eri_10th.timestamp;
                        }

                        return (
                          <div key={idx + eri.boundary_id}>
                            <h5 className="mb-1">
                              {(!!eri.county_name &&
                                isNaN(eri.county_name) &&
                                eri.county_name !== 'NaN' &&
                                eri.county_name + ' County, ') ||
                                'State of '}
                              {eri?.state_name}
                            </h5>
                            <span className="d-block mb-1"></span>
                            <table className="table eri-table mb-2">
                              <tbody>
                                <tr>
                                  <th>% Probability</th>
                                  <th>ERI (datetime)</th>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      color: eriScenarios.find(
                                        (e) => e.value === highestValueLabel
                                      )?.color,
                                    }}
                                  >
                                    {percentileToPercentage[highestValueLabel]}
                                  </td>
                                  <td
                                    style={{
                                      color: eriValCols.find(
                                        (e) => e.value === highestValueLabel
                                      )?.color,
                                    }}
                                  >
                                    {moment(highestValueTimestamp).format(
                                      'HH:mm, ddd MMM DD, YYYY'
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        );
                      })) || <div className="weight-600">No ERIs found</div>}
                </div>
              </Tab>
            </Tabs>
          </div>
        )}
        {showSidebarOrder === 3 && (
          <div className="tab-wrapper map-tab-container">
            <Tabs activeKey={tab} onSelect={(k) => setTab(k)}>
              <Tab eventKey="info" title="Info">
                <div
                  className="anchor anchor--white cursor-pointer d-flex align-items-center bg-gray-900 p-3 rounded mb-3"
                  onClick={() => flyToEventFeature(activeEvent)}
                >
                  <MdLocationOn className="img-h-20 me-3" />
                  {!!placeName && <>{placeName}</>}
                </div>
                <EventDetails event={activeEvent} />
              </Tab>
              <Tab eventKey="news" title="News">
                <div className="event-news-container">
                  {(!!selectedEventNews &&
                    selectedEventNews.map((n, idx) => {
                      return (
                        <>
                          {idx !== 0 ? <hr className="dashed my-3" /> : null}
                          <div key={idx + n.name}>
                            <h5 className="mb-1">{n.name} </h5>
                            <span className="d-block mb-1">
                              <span className="weight-600">Category: </span>
                              {n?.category}
                            </span>
                            {moment(n?.datePublished).isValid() && (
                              <span className="d-block mb-1">
                                <span className="weight-600">
                                  Published At:{' '}
                                </span>
                                {moment(n?.datePublished).format(
                                  'MM/DD/YYYY HH:mm'
                                )}
                              </span>
                            )}

                            <p className="mt-3">{n?.description}</p>
                            <div className="d-flex align-items-center mt-3">
                              <span className="weight-600">
                                {n?.provider[0]?.name}
                              </span>
                              <span
                                className="cursor-pointer ms-auto"
                                onClick={() => window.open(n.url, '_blank')}
                              >
                                <HiOutlineExternalLink className="img-h-24" />
                              </span>
                            </div>
                          </div>
                        </>
                      );
                    })) || <div className="weight-600">Loading News...</div>}
                </div>
              </Tab>
            </Tabs>
          </div>
        )}
      </div>
      {!!aorCreationDialogActive && (
        <CoreComponentCreationDialog
          show={aorCreationDialogActive}
          onClose={() => {
            setAORCreationDialogActive(false);
          }}
          ccType="Areas of Responsibility"
        />
      )}
    </div>
  );
};

export default MapAlerts;
