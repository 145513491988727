import { samplePointLayer, sampleDrawLayer } from '../constants/sampleLayers';

// Define sources, layers, and layer configs for each type
const geolocation_tiles_sources = {
  aors: {
    polygons: 'geolocation_tiles_aors_polygons_source',
    points: 'geolocation_tiles_aors_points_source',
    polygons_layer: 'public.geolocation_tiles_aors_polygons',
    points_layer: 'public.geolocation_tiles_aors_points',
  },
  resource_sites: {
    polygons: 'geolocation_tiles_resource_sites_polygons_source',
    points: 'geolocation_tiles_resource_sites_points_source',
    polygons_layer: 'public.geolocation_tiles_resource_sites_polygons',
    points_layer: 'public.geolocation_tiles_resource_sites_points',
  },
  resource_sets: {
    polygons: 'geolocation_tiles_resource_sets_polygons_source',
    points: 'geolocation_tiles_resource_sets_points_source',
    polygons_layer: 'public.geolocation_tiles_resource_sets_polygons',
    points_layer: 'public.geolocation_tiles_resource_sets_points',
  },
  work_assignments: {
    polygons: 'geolocation_tiles_work_assignments_polygons_source',
    points: 'geolocation_tiles_work_assignments_points_source',
    polygons_layer: 'public.geolocation_tiles_work_assignments_polygons',
    points_layer: 'public.geolocation_tiles_work_assignments_points',
  },
};

const createLayerConfig = (
  type,
  layerType,
  sampleLayer,
  source,
  sourceLayer
) => ({
  ...sampleLayer,
  id: `${type}_${layerType}_layer`,
  source: source,
  'source-layer': sourceLayer,
  generateId: true,
  paint: {
    ...sampleLayer.paint,
    'fill-opacity': layerType === 'polygons' ? 0.3 : undefined,
    'circle-opacity': layerType === 'points' ? 0.3 : undefined,
  },
  metadata: {
    eventlayer: false,
    usermade: undefined,
    model_layer: false,
    model_details: undefined,
    geolocation: true,
  },
});

export function addPolyLayer(
  map,
  type,
  polygonsSource,
  polygonsLayer,
  setLayerClicked
) {
  const polyLayer = createLayerConfig(
    type,
    'polygons',
    sampleDrawLayer,
    polygonsSource,
    polygonsLayer
  );
  delete polyLayer.paint['circle-opacity'];
  map.current.addLayer(polyLayer);

  // Add event listeners for polygons
  map.current.on('click', polyLayer.id, (e) =>
    setLayerClicked({
      layer: polyLayer,
      e: e,
      features: e.features,
    })
  );
  map.current.on('touchend', polyLayer.id, (e) =>
    setLayerClicked({
      layer: polyLayer,
      e: e,
      features: e.features,
    })
  );
  map.current.on('mouseenter', polyLayer.id, () => {
    if (map.current.getCanvas().style.cursor === 'crosshair') return;
    map.current.getCanvas().style.cursor = 'pointer';
  });
  map.current.on('mouseleave', polyLayer.id, () => {
    if (map.current.getCanvas().style.cursor === 'crosshair') return;
    map.current.getCanvas().style.cursor = 'grab';
  });
}

export default function geolocationsEffect({
  map,
  mapHasLoaded,
  apiPrefix,
  reduxCurrentlySelectedGroup,
  reduxCurrentIncident,
  showAORsOnMap = false,
  showPlanEventsOnMap = false,
  showResourceSitesOnMap = false,
  showResourceSetsOnMap = false,
  showWorkAssignmentsOnMap = false,
  showAirportsOnMap = false,
  showSeaportsOnMap = false,
  setLayerClicked,
}) {
  if (!mapHasLoaded) return;

  const layersAndSources = [
    ...Object.keys(geolocation_tiles_sources).map((type) => ({
      type,
      polygons: geolocation_tiles_sources[type].polygons,
      points: geolocation_tiles_sources[type].points,
      polygons_layer: geolocation_tiles_sources[type].polygons_layer,
      points_layer: geolocation_tiles_sources[type].points_layer,
    })),
  ];

  // Helper function to remove layers and sources
  const removeLayerAndSource = (layerId, sourceId) => {
    if (map.current.getLayer(layerId)) {
      map.current.removeLayer(layerId);
    }
    if (map.current.getSource(sourceId)) {
      map.current.removeSource(sourceId);
    }
  };

  // Helper function to add layers and sources
  const addLayersAndSources = (type, showOnMap, reduxKey, incidentKey) => {
    const polygonsSource = geolocation_tiles_sources[type].polygons;
    const pointsSource = geolocation_tiles_sources[type].points;
    const polygonsLayer = geolocation_tiles_sources[type].polygons_layer;
    const pointsLayer = geolocation_tiles_sources[type].points_layer;

    const polygonLayerId = `${type}_polygons_layer`;
    const pointLayerId = `${type}_points_layer`;

    // Only remove if explicitly hiding
    if (!showOnMap) {
      removeLayerAndSource(polygonLayerId, polygonsSource);
      removeLayerAndSource(pointLayerId, pointsSource);
      return;
    }

    // Check if layers already exist
    const polyLayerExists = map.current.getLayer(polygonLayerId);
    const pointLayerExists = map.current.getLayer(pointLayerId);

    // If both layers exist, just return - no need to recreate
    if (polyLayerExists && pointLayerExists) {
      return;
    }

    // Create URLs for tile sources
    let polygonsTiles = `${apiPrefix}/geolocations_tiles/${polygonsLayer}/{z}/{x}/{y}.pbf?user_group_guid=${reduxCurrentlySelectedGroup.group_guid}`;
    let pointsTiles = `${apiPrefix}/geolocations_tiles/${pointsLayer}/{z}/{x}/{y}.pbf?user_group_guid=${reduxCurrentlySelectedGroup.group_guid}`;

    if (reduxKey === 'work_assignments') {
      polygonsTiles = `${apiPrefix}/geolocations_tiles/${polygonsLayer}/{z}/{x}/{y}.pbf?user_incident_id=${reduxCurrentIncident?.id}`;
      pointsTiles = `${apiPrefix}/geolocations_tiles/${pointsLayer}/{z}/{x}/{y}.pbf?user_incident_id=${reduxCurrentIncident?.id}`;
    }

    // Only add source if it doesn't exist
    if (!map.current.getSource(polygonsSource)) {
      map.current.addSource(polygonsSource, {
        type: 'vector',
        tiles: [polygonsTiles],
      });
    }

    if (!polyLayerExists) {
      addPolyLayer(map, type, polygonsSource, polygonsLayer, setLayerClicked);
    }

    // Only add source if it doesn't exist
    if (!map.current.getSource(pointsSource)) {
      map.current.addSource(pointsSource, {
        type: 'vector',
        tiles: [pointsTiles],
      });
    }

    if (!pointLayerExists) {
      const pointLayer = createLayerConfig(
        type,
        'points',
        samplePointLayer,
        pointsSource,
        pointsLayer
      );
      delete pointLayer.paint['fill-opacity'];
      map.current.addLayer(pointLayer);

      // Add event listeners for points
      map.current.on('click', pointLayer.id, (e) =>
        setLayerClicked({
          layer: pointLayer,
          e: e,
          features: e.features,
        })
      );
      map.current.on('touchend', pointLayer.id, (e) =>
        setLayerClicked({
          layer: pointLayer,
          e: e,
          features: e.features,
        })
      );
      map.current.on('mouseenter', pointLayer.id, () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });
      map.current.on('mouseleave', pointLayer.id, () => {
        map.current.getCanvas().style.cursor = 'grab';
      });
    }
  };

  // Add or remove layers and sources for each type
  addLayersAndSources('aors', showAORsOnMap, 'aors', reduxCurrentIncident);
  addLayersAndSources(
    'resource_sites',
    showResourceSitesOnMap,
    'resource_sites',
    reduxCurrentIncident
  );
  addLayersAndSources(
    'resource_sets',
    showResourceSetsOnMap,
    'resource_sets',
    reduxCurrentIncident
  );
  addLayersAndSources(
    'work_assignments',
    showWorkAssignmentsOnMap,
    'work_assignments',
    reduxCurrentIncident
  );
}
