import React, { useEffect, useState } from 'react';
import { Accordion, Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import generateUUID from 'utils/sharedUtils/generateUUID';
import moment from 'moment';

import StylishDateTimePicker from 'components/DesignSystems/form/StylishDateTimePicker';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { useForm, Controller } from 'react-hook-form';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import { meeting_template } from 'constants/meeting';
import { AttendeesModal } from './AttendeesModal';
import { fetchAllDistributionList } from 'actions/notificationActions';
import { fetchRostersForGroups } from 'actions/roleActions';
import { StylishNewChip } from 'components/DesignSystems/New/StylishNewChip';
import { upsertMeeting } from 'actions/MeetingsActions';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import GeoLocationComponent from 'components/GeoLocationComponent/GeoLocationComponent';
import { startLoading } from 'reducers/loading/loading.action';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export const MeetingModal = ({ show, onClose, updateMeetingData }) => {
  const mapboxToken = window.env.MAPBOX_ACCESS_TOKEN;
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();

  const [isCustomMeeting, setIsCustomMeeting] = useState(false);
  const [openAttendeesModal, setAttendeesModal] = useState(false);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [disabledGuids, setDisabledGuids] = useState([]);
  const [incidentId, setIncidentId] = useState(null);

  const reduxDispatch = useDispatch();

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });
  const { user_guid } = useSelector((state) => {
    return state.app.user;
  });
  const reduxRostersForGroups = useSelector((state) => {
    return state.app.rostersForGroups;
  });
  const isFetchRostersForGroupsLoaded = useSelector((state) => {
    return state.app.isFetchRostersForGroupsLoaded;
  });
  const distributionListItems = useSelector((state) => {
    return state.app.distributionListItems;
  });
  const reduxCurrentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });

  useEffect(() => {
    if (
      typeof user_guid !== 'undefined' &&
      !reduxRostersForGroups &&
      isFetchRostersForGroupsLoaded &&
      reduxCurrentlySelectedGroup
    ) {
      reduxDispatch(
        fetchRostersForGroups([reduxCurrentlySelectedGroup.group_guid])
      );
    }

    if (reduxCurrentlySelectedGroup) {
      reduxDispatch(
        fetchAllDistributionList(reduxCurrentlySelectedGroup.group_guid)
      );
    }
  }, [user_guid, reduxCurrentlySelectedGroup]);

  useEffect(() => {
    if (reduxCurrentIncident?.id) {
      setIncidentId(reduxCurrentIncident?.id);
    }
  }, [reduxCurrentIncident]);

  const handleDisableGuid = (guid) => {
    if (!!disabledGuids.find((t) => t === guid)) {
      setDisabledGuids(disabledGuids.filter((t) => t !== guid));
    } else {
      setDisabledGuids([...disabledGuids, guid]);
    }
  };
  useEffect(() => {
    if (updateMeetingData && updateMeetingData?.extendedProps) {
      const isisCustom = meeting_template.find(
        (meeting) =>
          meeting.value === updateMeetingData?.extendedProps?.name.trim()
      );
      setIsCustomMeeting(isisCustom ? false : true);
      setValue(
        'name',
        !isisCustom
          ? updateMeetingData?.extendedProps?.name.trim()
          : {
              label: updateMeetingData?.extendedProps?.name.trim(),
              value: updateMeetingData?.extendedProps?.name.trim(),
            }
      );
      setValue(
        'meeting_date',
        moment(updateMeetingData?.extendedProps?.meeting_date).format(
          'YYYY-MM-DDTHH:mm'
        )
      );
      setValue('purpose', updateMeetingData?.extendedProps?.purpose);
      setValue('location', updateMeetingData?.extendedProps?.geolocation);
      setSelectedTeams(updateMeetingData?.extendedProps?.attendees);
    }
  }, [updateMeetingData]);

  const handleDistributionListDeleteClick = (teamId) => {
    setSelectedTeams(selectedTeams.filter((st) => st !== teamId));
  };

  const createUpdateMeeting = (submitData) => {
    const current_location_geom = !!submitData.location.geojson
      ? submitData.location.geojson.data.features[0].geometry
      : null;
    const meetingData = {
      ...submitData,
      name:
        typeof submitData.name === 'string'
          ? submitData.name
          : submitData.name.value,
      attendees: selectedTeams,
      id: updateMeetingData?._def?.publicId || generateUUID(),
      incident_id: incidentId,
      geolocation: submitData.location,
      location: null,
      current_location_geom: current_location_geom,
    };
    reduxDispatch(startLoading());
    reduxDispatch(upsertMeeting(meetingData));
    onClose();
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Meetings</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(createUpdateMeeting)}>
          <Modal.Body>
            {!isCustomMeeting ? (
              <div className="mb-3">
                <label className="form-label">Meeting Schedule Template</label>
                <Controller
                  control={control}
                  name="name"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <StylishNewSelect
                      options={meeting_template}
                      value={value}
                      onChange={onChange}
                      placeholder={'Select a Meeting'}
                    />
                  )}
                />
                {errors.name?.type === 'required' && (
                  <span className="form-text form-error mb-3">
                    This field is required
                  </span>
                )}
              </div>
            ) : (
              <div className="mb-3">
                <label className="form-label">Meeting Name</label>
                <Controller
                  control={control}
                  name="name"
                  defaultValue={''}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <StylishNewInput
                      value={value}
                      onChange={onChange}
                      placeholder={'Meeting Name'}
                    />
                  )}
                />
                {errors.name?.type === 'required' && (
                  <span className="form-text form-error mb-3">
                    This field is required
                  </span>
                )}
              </div>
            )}

            <div className="mb-3">
              <StylishNewButton
                className="button--secondary"
                type="button"
                onClick={() => {
                  setIsCustomMeeting(!isCustomMeeting);
                  setValue('name', '');
                  clearErrors('name');
                }}
              >
                {!isCustomMeeting
                  ? 'Create Custom Meeting'
                  : 'Choose Meeting Template'}
              </StylishNewButton>
            </div>

            <div className="mb-3">
              <Controller
                control={control}
                name="meeting_date"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishDateTimePicker
                    value={value}
                    onChange={onChange}
                    type={'datetime-local'}
                    minDate={moment()}
                    dateLabel={'Meeting Date'}
                    timeLabel={'Meeting Time'}
                  />
                )}
              />
              {errors.meeting_date?.type === 'required' && (
                <span className="form-text form-error mb-3">
                  This field is required
                </span>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">Purpose</label>
              <Controller
                control={control}
                name="purpose"
                render={({ field: { onChange, value } }) => (
                  <StylishNewTextArea
                    value={value}
                    onChange={onChange}
                    maxLength={1000}
                    placeholder={'Purpose'}
                  />
                )}
              />
            </div>

            <div className="mb-3">
              <label className="form-label d-block">Attendees</label>
              <StylishNewButton
                className="button--secondary"
                type="button"
                onClick={() => setAttendeesModal(true)}
              >
                Add Attendees
              </StylishNewButton>
            </div>

            {distributionListItems.length ? (
              <Accordion defaultActiveKey="0" flush className="mb-4">
                {distributionListItems
                  .filter((dli) => selectedTeams.includes(dli.id))
                  .map((item) => {
                    return (
                      <Accordion.Item key={item.id} eventKey={item.id}>
                        <Accordion.Header className="d-flex align-items-center">
                          {item.name}
                          <StylishNewButton
                            customButton
                            className="button--icon ms-auto"
                            type="button"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleDistributionListDeleteClick(item.id);
                            }}
                          >
                            <SharedIcon iconName="delete" />
                          </StylishNewButton>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="form-chip-group">
                            {item.members
                              .filter((member) => {
                                return (
                                  !!reduxRostersForGroups &&
                                  !!reduxRostersForGroups.length &&
                                  reduxRostersForGroups.some(
                                    (person) => person.user_guid === member
                                  )
                                );
                              })
                              .map((member) => {
                                const user = reduxRostersForGroups.find(
                                  (person) => person.user_guid === member
                                );
                                const name =
                                  user.user_name || user.email_address;
                                return (
                                  <>
                                    <StylishNewChip
                                      key={`key-${member}`}
                                      label={name}
                                      disabled={
                                        !!disabledGuids.find(
                                          (guid) => member === guid
                                        )
                                      }
                                      // handleDisable={() =>
                                      //   handleDisableGuid(member)
                                      // }
                                    />
                                  </>
                                );
                              })}
                            {!!item.nonMembers &&
                              item.nonMembers.map((member) => {
                                return (
                                  <StylishNewChip
                                    key={`key-${member.id}`}
                                    label={member.content}
                                    // handleDisable={() =>
                                    //   handleDisableGuid(member.id)
                                    // }
                                    disabled={
                                      !!disabledGuids.find(
                                        (guid) => guid === member.id
                                      )
                                    }
                                  />
                                );
                              })}
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
              </Accordion>
            ) : null}

            <div className="m-0">
              <label className="form-label">Location</label>
              <Controller
                control={control}
                name="location"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <GeoLocationComponent
                    location={value}
                    setLocation={onChange}
                    mapboxToken={mapboxToken}
                  />
                )}
              />
              {errors.location?.type === 'required' && (
                <span className="form-text form-error mb-3">
                  This field is required
                </span>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <StylishNewButton className="button--primary" type="submit">
                Save
              </StylishNewButton>
              <StylishNewButton
                className="button--secondary button--reverse"
                onClick={onClose}
              >
                Cancel
              </StylishNewButton>
            </div>
          </Modal.Footer>
        </form>
      </Modal>

      {openAttendeesModal && (
        <AttendeesModal
          show={openAttendeesModal}
          onClose={() => setAttendeesModal(false)}
          teamsSelected={selectedTeams}
          onChangeTeamSelection={(data) => setSelectedTeams(data)}
        />
      )}
    </>
  );
};
