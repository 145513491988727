import React from 'react';

export function ParentRelationships({
  parents,
  label,
}: {
  label: string;
  parents: undefined | { name: string }[];
}) {
  return (
    <div className="d-flex align-items-center justify-content-between mb-2">
      <div className="txt--semibold">
        {label}: {parents?.length ?? 0}
      </div>
      <div>
        {parents &&
          parents.map((entity, index) => (
            <span key={index} className="badge badge--sm badge--neutral me-2">
              <span>{entity.name}</span>
            </span>
          ))}
      </div>
    </div>
  );
}
