import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as turf from '@turf/turf';

import { StylishSwitcher } from 'components/DesignSystems/New/StylishSwitcher';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog.js';
import GeolocationDetails from 'components/MapComponent/mapComponents/GeolocationDetails';

import IconClose from '../../../assets/images/icon__times.svg';
import IconArrowRight from '../../../assets/images/icon__arrow--right.svg';
import IconArrowLeft from '../../../assets/images/icon__arrow--left.svg';

import { endLoading, startLoading } from 'reducers/loading/loading.action.js';
import {
  deleteCoreComponent,
  fetchCoreComponents,
} from 'actions/ai2fpActions.js';

import CollapsibleResourceSitesList from 'components/IAP/CollapsibleResourceSitesList';
import CollapsibleResourceSetsList from 'components/IAP/CollapsibleResourceSetsList';
import CollapsibleWorkAssignmentsList from 'components/IAP/CollapsibleWorkAssignmentsList';
import CollapsibleGeolocationsList from 'components/MapComponent/mapComponents/CollapsibleGeolocationsList';

import { useResourceSites } from 'components/IAP/hooks/resourceSiteHooks';
import { useSubscriptionLevel } from '../../SubscriptionManagement/useSubscriptionLevel';
import AorCreatePage from '../AorCreatePage';
import { drawToggle } from '../utils/drawToggle';
import { addPolyLayer } from '../mapEffects/geolocationsEffect';
import { useFeatureFlag } from '../../../utils/featureFlags';
import { transportationAirportsEffect, transportationSeaportsEffect } from '../mapEffects/transportationEffect';
import { useAirportsData, useSeaportsData } from '../mapHooks/useTransportation';

const flyToGeolocation = (map, item) => {
  if (item?.geolocation?.geojson?.data?.features?.length) {
    var box = turf.bbox(item.geolocation.geojson.data.features[0].geometry);
    map.current.fitBounds(box, { padding: 50, maxZoom: 10 });
  }
};

const GeolocationSwitcher = ({ label, checked, toggleSwitch, sidebarSecondaryHandler }) => (
  <div className="d-flex align-items-center bg-gray-900--light-5 p-3 pe-0 rounded mb-3">
    <span className="text-truncate me-3">{label}</span>
    <StylishSwitcher
      className="ms-auto"
      checked={checked}
      onChange={toggleSwitch}
    />
    {
      sidebarSecondaryHandler &&
      <span
        className="px-3 cursor-pointer"
        onClick={sidebarSecondaryHandler}
      >
        <img src={IconArrowRight} alt="" className="img-h-16" />
      </span>
    }
  </div>
)

const MapGeolocations = ({
  map,
  mapHasLoaded,
  apiPrefix,
  selectedGeolocation,
  setLayerClicked,
  onClose,
  layerState,
  location,
  setLocation,
  drawRef,
}) => {
  const { lockIncident } = useSubscriptionLevel();
  const dispatch = useDispatch();
  const { data: resourceSites } = useResourceSites();
  const { data: airportNodes } = useAirportsData('', 50, 1)
  const { data: seaportNodes } = useSeaportsData('', 50, 1)

  const reduxCurrentlySelectedGroup = useSelector(
    (state) => state.app.currentlySelectedGroup
  );
  const reduxCoreComponents = useSelector((state) => state.app.coreComponents);
  const reduxCurrentIncident = useSelector(
    (state) => state.app.currentIncident
  );
  const neoFeatureFlag = useFeatureFlag('NEO');

  const [sidebarSecondaryActiveItem, setSidebarSecondaryActiveItem] = useState(
    null
  );
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [deleteGeolocationType, setDeleteGeolocationType] = useState(null);
  const [deleteGeolocationData, setDeleteGeolocationData] = useState(null);
  const [AORSelected, setAORSelected] = useState();
  const [aorCreationDialogActive, setAORCreationDialogActive] = useState(false);

  const handleClickClose = () => {
    onClose();
    setAORCreationDialogActive(false);
    drawRef.current.deleteAll();
    setLocation(null);
    drawRef.current.changeMode('simple_select');
    map.current.getCanvas().style.cursor = 'grab';
  };

  const sidebarSecondaryHandler = (key) => {
    setSidebarSecondaryActiveItem(key);
  };

  if (drawRef.current.getMode() === 'simple_select') {
    map.current.getCanvas().style.cursor = 'grab';
  } else if (drawRef.current.getMode() === 'draw_polygon') {
    map.current.getCanvas().style.cursor = 'crosshair';
  }

  const AORClicked = (type, aor) => {
    setAORSelected(aor);
    if (type === 'edit') setAORCreationDialogActive(true);
    if (type === 'delete') {
      setDeleteGeolocationType('AORs');
      setDeleteGeolocationData(aor);
      setShowConfirmDialog(true);
    }
  };

  const deleteAORClicked = async () => {
    dispatch(startLoading());
    await dispatch(deleteCoreComponent(deleteGeolocationData));

    map.current.removeLayer('aors_polygons_layer');
    setTimeout(() => {
      addPolyLayer(
        map,
        'aors',
        'geolocation_tiles_aors_polygons_source',
        'public.geolocation_tiles_aors_polygons',
        setLayerClicked
      );
    }, 25);

    setShowConfirmDialog(false);
    setDeleteGeolocationType(null);
    setDeleteGeolocationData(null);
  };

  useEffect(() => {
    if (selectedGeolocation?.type === 'AOR') {
      setAORSelected(selectedGeolocation?.geolocation);
    }
  }, [selectedGeolocation]);

  useEffect(() => {
    if (
      reduxCurrentlySelectedGroup?.group_guid &&
      map &&
      reduxCurrentIncident &&
      mapHasLoaded
    ) {
      if (!reduxCoreComponents) {
        dispatch(fetchCoreComponents(reduxCurrentlySelectedGroup.group_guid));
      }
    }
  }, [reduxCurrentlySelectedGroup, map, mapHasLoaded]);

  useEffect(() => {
    dispatch(endLoading());
  }, [reduxCoreComponents]);

  useEffect(() => {
    transportationAirportsEffect(
      map,
      apiPrefix,
      layerState.showAirportsOnMap,
      setLayerClicked,
      airportNodes
    )
  }, [airportNodes, layerState.showAirportsOnMap]);

  useEffect(() => {
    transportationSeaportsEffect(
      map,
      apiPrefix,
      layerState.showSeaportsOnMap,
      setLayerClicked,
      seaportNodes
    )
  }, [seaportNodes, layerState.showSeaportsOnMap]);

  function icsFlyToClicked(sidebarSecondaryActiveItem, item) {
    if (item?.resource_set_id && item?.resource_site_id && !item?.geolocation) {
      const site = resourceSites.find((rs) => rs.id === item.resource_site_id);
      if (site) {
        flyToGeolocation(map, site);
      }
    } else {
      flyToGeolocation(map, item);
    }
  }

  const [showGeolocationDetails, setShowGeolocationDetails] = useState(false);
  const [selectedAORForDetails, setSelectedAORForDetails] = useState(null);

  const handleGeolocationAction = (action, aor) => {
    switch (action) {
      case 'edit':
        flyToGeolocation(map, aor);
        AORClicked('edit', aor);
        drawToggle(true);
        break;
      case 'delete':
        AORClicked('delete', aor);
        break;
      case 'view':
        flyToGeolocation(map, aor);
        break;
      case 'details':
        setSelectedAORForDetails(aor);
        setShowGeolocationDetails(true);
        break;
      default:
        break;
    }
  };

  return (
    <div className="sidebar-content-wide">
      <div className="sidebar-title">
        {sidebarSecondaryActiveItem && (
          <StylishNewButton
            customButton
            className={'button--icon'}
            onClick={() => {
              setSidebarSecondaryActiveItem(null);
              setAORCreationDialogActive(false);
              drawToggle(false);
              setLocation(null);
              drawRef.current.changeMode('simple_select');
              drawRef.current.deleteAll();
            }}
          >
            <img src={IconArrowLeft} alt="" />
          </StylishNewButton>
        )}
        <h4 className="m-0">Geolocations</h4>
        <StylishNewButton
          customButton
          className={'button--icon'}
          onClick={() => handleClickClose()}
        >
          <img src={IconClose} alt="" />
        </StylishNewButton>
      </div>
      <div className="sidebar-inner">
        {!sidebarSecondaryActiveItem ? (
          <>
            <GeolocationSwitcher
              label="AORs"
              checked={layerState.showAORsOnMap}
              toggleSwitch={() =>
                layerState.setShowAORsOnMap(!layerState.showAORsOnMap)
              }
              sidebarSecondaryHandler={() =>
                sidebarSecondaryHandler('Geolocations AORs')
              }
            />
            {!lockIncident ? (
              <>
                <GeolocationSwitcher
                  label="Resource Sites"
                  checked={layerState.showResourceSitesOnMap}
                  toggleSwitch={() =>
                    layerState.setShowResourceSitesOnMap(
                      !layerState.showResourceSitesOnMap
                    )
                  }
                  sidebarSecondaryHandler={() =>
                    sidebarSecondaryHandler('Geolocations Resource Sites')
                  }
                />

                <GeolocationSwitcher
                  label="Resource Sets"
                  checked={layerState.showResourceSetsOnMap}
                  toggleSwitch={() =>
                    layerState.setShowResourceSetsOnMap(
                      !layerState.showResourceSetsOnMap
                    )
                  }
                  sidebarSecondaryHandler={() =>
                    sidebarSecondaryHandler('Geolocations Resource Sets')
                  }
                />

                <GeolocationSwitcher
                  label="Work Assignments"
                  checked={layerState.showWorkAssignmentsOnMap}
                  toggleSwitch={() =>
                    layerState.setShowWorkAssignmentsOnMap(
                      !layerState.showWorkAssignmentsOnMap
                    )
                  }
                  sidebarSecondaryHandler={() =>
                    sidebarSecondaryHandler('Geolocations Work Assignments')
                  }
                />

                {
                  neoFeatureFlag && (
                    <>
                      <GeolocationSwitcher
                        label="Airports"
                        checked={layerState.showAirportsOnMap}
                        toggleSwitch={() => layerState.setShowAirportsOnMap(!layerState.showAirportsOnMap)}
                      />
                      <GeolocationSwitcher
                        label="Seaports"
                        checked={layerState.showSeaportsOnMap}
                        toggleSwitch={() => layerState.setShowSeaportsOnMap(!layerState.showSeaportsOnMap)}
                      />
                    </>
                  )
                }

              </>
            ) : null}
          </>
        ) : (
          <>
            {sidebarSecondaryActiveItem === 'Geolocations AORs' &&
              (aorCreationDialogActive ? (
                <AorCreatePage
                  onClose={() => {
                    setAORCreationDialogActive(false);
                    drawToggle(false);
                    drawRef.current.changeMode('simple_select');
                    drawRef.current.deleteAll();
                    setLocation(null);
                  }}
                  ccType="Areas of Responsibility"
                  existingCC={AORSelected}
                  location={location}
                  setLocation={setLocation}
                  drawRef={drawRef}
                  map={map}
                  setLayerClicked={setLayerClicked}
                />
              ) : showGeolocationDetails ? (
                <GeolocationDetails
                  aor={selectedAORForDetails}
                  onBack={() => {
                    setShowGeolocationDetails(false);
                    setSelectedAORForDetails(null);
                  }}
                />
              ) : (
                <>
                  {sidebarSecondaryActiveItem === 'Geolocations AORs' && (
                    <CollapsibleGeolocationsList
                      onMap={true}
                      flyTo={(aor) => handleGeolocationAction('view', aor)}
                      onEdit={(aor) => handleGeolocationAction('edit', aor)}
                      onDelete={(aor) => handleGeolocationAction('delete', aor)}
                      onAdd={() => {
                        setAORSelected();
                        setAORCreationDialogActive(true);
                        drawRef.current.changeMode('draw_polygon');
                        drawToggle(true);
                      }}
                    />
                  )}
                </>
              ))}

            {sidebarSecondaryActiveItem === 'Geolocations Resource Sites' && (
              <CollapsibleResourceSitesList
                onMap={true}
                flyTo={(e) => icsFlyToClicked(sidebarSecondaryActiveItem, e)}
              />
            )}

            {sidebarSecondaryActiveItem === 'Geolocations Resource Sets' && (
              <CollapsibleResourceSetsList
                onMap={true}
                flyTo={(e) => icsFlyToClicked(sidebarSecondaryActiveItem, e)}
              />
            )}

            {sidebarSecondaryActiveItem === 'Geolocations Work Assignments' && (
              <CollapsibleWorkAssignmentsList
                onMap={true}
                flyTo={(e) => icsFlyToClicked(sidebarSecondaryActiveItem, e)}
              />
            )}
          </>
        )}
      </div>

      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={`Delete ${deleteGeolocationType}`}
          dialogContent="Are you sure you want to delete this? This action cannot be reversed."
          onClose={() => {
            setShowConfirmDialog(false);
            setDeleteGeolocationType(null);
            setDeleteGeolocationData(null);
          }}
          onConfirm={deleteAORClicked}
        />
      )}
    </div>
  );
};

export default MapGeolocations;
