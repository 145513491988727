import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  sendNotificationByGroup,
  upsertNotificationByGroup,
  useNotificationCount,
} from 'actions/notificationActions';
import generateUUID from 'utils/sharedUtils/generateUUID';
import SelectTemplateDialog from './SelectTemplateDialog';
import AddDistributionListDialog from './AddDistributionListDialog';
import AddNewRecipientsDialog from './AddNewRecipientsDialog';
import ManageDistributionListsDialog from './ManageDistributionListsDialog';
import NotificationRecipientsScreen from './NotificationRecipientsScreen';
import NotificationMessageScreen from './NotificationMessageScreen';
import NotificationFooterScreen from './NotificationFooterScreen';
import { StylishNewCheckbox } from 'components/DesignSystems/New/StylishNewCheckbox';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
import moment from 'moment';
import { UnavailableUserNotificationDialog } from './UnavailableUserNotificationDialog';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';
import { Alert } from 'react-bootstrap';
import { setSelectedTemplate } from '../../slices/notificationsSlice';
import PageTitle from '../headers/PageTitle';
import { useAppSelector } from '../../slices/commonSelectors';
import { allRecipientTypesOptions } from './recipientTypes';
import generateNotifictionObject from './generateNotificationEntity';
import getNotificationNumbers from './getNotificationNumbers';
import UpgradeSubscriptionModal from '../sidebarNav/UpgradeSubscriptionModal';
import { useSubscriptionLevel } from 'components/SubscriptionManagement/useSubscriptionLevel';

function NotificationComposeScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectTemplateDialogOpen, setSelectTemplateDialogOpen] =
    useState(false);
  const selectedTemplate = useAppSelector(
    (s) => s.notifications.selectedTemplate
  );
  const [recipientTypes, setRecipientTypes] = useState(
    allRecipientTypesOptions
  );
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [SMSMessage, setSMSMessage] = useState('');
  const [TTSMessage, setTTSMessage] = useState('');
  const emailMessageMaxLength = 1000;
  const smsMessageMaxLength = 160;
  const ttsMessageMaxLength = 3000;
  const [addDistributionListDialogOpen, setAddDistributionListDialogOpen] =
    useState(false);
  const [addNewRecipientsDialogOpen, setAddNewRecipientsDialogOpen] =
    useState(false);
  const [fetchedUserProfile, setFetchedUserProfile] = useState(false);
  const [
    showUnavailableUserNotificationDailog,
    setShowUnavailableUserNotificationDailog,
  ] = useState(false);
  const [unavailableUsersData, setUnavailableUsersData] = useState(null);
  const [disabledGuids, setDisabledGuids] = useState([]);
  const [groupMembersToNotify, setGroupMembersToNotify] = useState([]);
  const [nonMembers, setNonMembers] = useState([]);
  const [distributionLists, setDistributionLists] = useState([]);
  const [
    manageDistributionListsDialogOpen,
    setManageDistributionListsDialogOpen,
  ] = useState(false);
  const distributionListItems = useSelector((state) => {
    return state.app.distributionListItems;
  });
  const [allNotifications, setAllNotifications] = useState(null);
  useEffect(() => {
    setDistributionLists(distributionListItems);
  }, [distributionListItems]);
  const [selectedDistributionLists, setSelectedDistributionLists] = useState(
    []
  );
  const [saveAsTemplate, setSaveAsTemplate] = useState(false);
  const [templateTitle, setTemplateTitle] = useState('');
  const [templateTitleError, setTemplateTitleError] = useState(false);
  const [phoneNumberCount, setPhoneNumberCount] = useState(0);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showConfirmDialogData, setShowConfirmDialogData] = useState(null);

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });
  const isSendNotificationLoaded = useSelector((state) => {
    return state.app.isSendNotificationLoaded;
  });
  const isUpsertNotificationLoaded = useSelector((state) => {
    return state.app.isUpsertNotificationLoaded;
  });
  const reduxNotifications = useSelector((state) => {
    return state.app.notifications;
  });
  const isFetchRostersForGroupsLoaded = useSelector((state) => {
    return state.app.isFetchRostersForGroupsLoaded;
  });
  const reduxFocusUser = useSelector((state) => {
    return state.app.focusUser;
  });
  const reduxNotificationsPageData = useSelector((state) => {
    return state.app.notificationPageData;
  });
  const sentMsgCount = useNotificationCount(
    reduxCurrentlySelectedGroup.group_guid
  );
  const { notificationLimit } = useSubscriptionLevel();

  useEffect(() => {
    if (!!reduxNotifications && !!reduxNotifications.length) {
      setAllNotifications(
        reduxNotifications.map((i) => {
          return { ...i, id: generateUUID() };
        })
      );
    } else {
      setAllNotifications([]);
    }
  }, [reduxNotifications]);

  useEffect(() => {
    if (selectedTemplate) {
      setNotificationPageData(selectedTemplate);
    } else {
      setRecipientTypes(allRecipientTypesOptions);
      setSubject('');
      setMessage('');
      setSMSMessage('');
      setTTSMessage('');
      setSaveAsTemplate(false);
      setTemplateTitle('');
      setTemplateTitleError(false);
      setGroupMembersToNotify([]);
      setSelectedDistributionLists([]);
      setNonMembers([]);
    }
  }, [selectedTemplate]);

  useEffect(() => {
    if (reduxNotificationsPageData) {
      setNotificationPageData(reduxNotificationsPageData);
    }
  }, [reduxNotificationsPageData]);

  const setNotificationPageData = (pageData) => {
    const recipientSettings = pageData.settings.recipientSettings;
    setRecipientTypes(recipientSettings.recipientTypes || []);
    setSubject(
      pageData.subject || recipientSettings.emailRecipientSettings.emailSubject
    );
    const nonMembersOnTemplates = [
      ...backwardsCompatibleRecipientsFormat(
        recipientSettings.emailRecipientSettings.emailRecipients
      ),
      ...backwardsCompatibleRecipientsFormat(
        recipientSettings.SMSRecipientSettings.SMSRecipients
      ),
      ...backwardsCompatibleRecipientsFormat(
        recipientSettings.TTSRecipientSettings &&
          recipientSettings.TTSRecipientSettings.TTSCellRecipients
      ),
      ...backwardsCompatibleRecipientsFormat(
        recipientSettings.TTSRecipientSettings &&
          recipientSettings.TTSRecipientSettings.TTSHomeRecipients
      ),
    ];
    setNonMembers(nonMembersOnTemplates);
    setTemplateTitle(pageData.template_title);
    setMessage(
      pageData.message || recipientSettings.emailRecipientSettings.emailMessage
    );
    setSMSMessage(recipientSettings.SMSRecipientSettings.SMSMessage || '');
    setTTSMessage(
      (pageData.settings.contentSettings &&
        pageData.settings.contentSettings.TTSMessage) ||
        (recipientSettings.TTSMessageRecipientSettings &&
          recipientSettings.TTSMessageRecipientSettings.TTSMessage) ||
        ''
    );
    setGroupMembersToNotify(recipientSettings.groupMembersToNotify || []);

    if (recipientSettings.distributionLists) {
      setSelectedDistributionLists(recipientSettings.distributionLists);
    }
  };

  const reduxRostersForGroups = useSelector((state) => {
    const rosters = state.app.rostersForGroups || [];
    return rosters
      .filter(
        (member) => member.group_guid === reduxCurrentlySelectedGroup.group_guid
      )
      .map((member) => ({
        ...member,
        role_string: member.role_assignments
          .map((item) => item.name)
          .join(', '),
      }));
  });

  // Note: We're in the midst of refactoring the shape of this object.
  // So there will need to be some redundancy while we sort out the back-end
  // Consult  https://github.com/DisasterTechInc/COMS/blob/develop/src/routes/Notifications/NotificationsConstants.js#L3
  // to see the intended shape for this object when resolving redunancy.

  function createNotificationObject() {
    if (
      recipientTypes.some((r) =>
        ['SMS', 'Text-To-Speech (cell)', 'Text-To-Speech (home)'].includes(r)
      )
    ) {
      const phoneNumCount = getNotificationNumbers({
        disabledGuids,
        nonMembers,
        groupMembersToNotify,
        reduxRostersForGroups,
        selectedDistributionLists,
        distributionLists,
      });
      setPhoneNumberCount(phoneNumCount);
    }

    return generateNotifictionObject(
      subject,
      message,
      reduxCurrentlySelectedGroup.group_guid,
      recipientTypes,
      nonMembers.filter((ele) => ele.type === 'emailRecipients'),
      nonMembers.filter((ele) => ele.type === 'SMSRecipients'),
      SMSMessage,
      nonMembers.filter((ele) => ele.type === 'TTSHomeRecipients'),
      nonMembers.filter((ele) => ele.type === 'TTSCellRecipients'),
      TTSMessage,
      groupMembersToNotify,
      selectedDistributionLists,
      disabledGuids,
      selectedTemplate &&
        saveAsTemplate &&
        templateTitle === selectedTemplate.template_title &&
        selectedTemplate.notification_id,
      saveAsTemplate ? templateTitle : undefined
    );
  }

  function backwardsCompatibleRecipientsFormat(recipients) {
    if (!recipients || (!!recipients && !recipients.length)) {
      return [];
    } else {
      if (recipients[0].id) {
        return recipients;
      } else {
        return recipients.map((r) => {
          return { id: generateUUID, content: r };
        });
      }
    }
  }

  const handleFormSubmit = () => {
    const notification = createNotificationObject();
    const fData = new Set([
      ...notification.settings.recipientSettings.distributionLists.flatMap(
        (d) => distributionListItems.find((dL) => dL.id === d)?.members || []
      ),
      ...notification.settings.recipientSettings.groupMembersToNotify,
    ]);
    const fAvail = Array.from(fData).map((fd) => {
      const uAvail = reduxRostersForGroups.find((rg) => rg.user_guid === fd);
      return {
        user_guid: uAvail?.user_guid,
        user_name: uAvail?.user_name,
        email_address: uAvail?.email_address,
        unavailability: uAvail?.unavailability,
      };
    });

    const unavailableUsers = fAvail.filter((fa) => {
      const isUnavailable = fa.unavailability.some(
        (fau) =>
          fau.start_time <= moment().toISOString() &&
          fau.end_time >= moment().toISOString()
      );
      return isUnavailable;
    });

    if (unavailableUsers.length) {
      setUnavailableUsersData(unavailableUsers);
      setShowUnavailableUserNotificationDailog(true);
    }
    if (saveAsTemplate) {
      if (templateTitle === '' || !templateTitle) {
        setTemplateTitleError(true);
        return;
      }
      const currentTemplate = reduxNotifications.findIndex(
        (ele) => ele.notification_id === notification.notification_id
      );
      if (currentTemplate === -1) {
        const sameNameTemplate = reduxNotifications.findIndex(
          (ele) => ele.template_title === notification.template_title
        );
        if (sameNameTemplate !== -1) {
          const errorMessage =
            `Duplicate Template Name\n\n` +
            'Failed Create Notification Template';
          console.warn(errorMessage);
          toast.error(errorMessage, toastConfig);
          return;
        }
      }
    }

    if (notification.template_title) {
      dispatch(setSelectedTemplate(notification));
    }
    setShowConfirmDialogData(notification);
    setShowConfirmDialog(true);
  };

  const onConfirmedSendNotification = () => {
    dispatch(upsertNotificationByGroup(showConfirmDialogData));
    dispatch(sendNotificationByGroup(showConfirmDialogData));
    setDisabledGuids([]);
    setShowConfirmDialog(false);
    setShowConfirmDialogData(null);
  };

  const handleTemplateSave = () => {
    if (
      groupMembersToNotify.length === 0 &&
      nonMembers.length === 0 &&
      selectedDistributionLists.length === 0
    ) {
      toast.error('Please select recipient to save template.', toastConfig);
      return;
    }
    if (templateTitle === '' || !templateTitle) {
      setTemplateTitleError(true);
      return;
    }
    const notification = createNotificationObject();
    const currentTemplate = reduxNotifications.findIndex(
      (ele) => ele.notification_id === notification.notification_id
    );
    if (currentTemplate === -1) {
      const sameNameTemplate = reduxNotifications.findIndex(
        (ele) => ele.template_title === notification.template_title
      );
      if (sameNameTemplate !== -1) {
        const errorMessage =
          `Duplicate Template Name.\n\n` +
          'Failed Create Notification Template';
        toast.error(errorMessage, toastConfig);
        console.warn(errorMessage);
        return;
      }
    }

    dispatch(setSelectedTemplate(notification));
    dispatch(upsertNotificationByGroup(notification));
  };

  function recipientTypeClicked(type) {
    if (recipientTypes.find((t) => t === type)) {
      setRecipientTypes(recipientTypes.filter((t) => t !== type));
    } else {
      setRecipientTypes([...recipientTypes, type]);
    }
  }

  useEffect(() => {
    if (
      !!reduxFocusUser &&
      !!reduxFocusUser.user_guid &&
      !!fetchedUserProfile
    ) {
      setFetchedUserProfile(false);
      navigate(`/profile/${reduxFocusUser.user_guid}`);
    }
  }, [reduxFocusUser]);

  const typeKey = {
    Email: 'emailRecipients',
    SMS: 'SMSRecipients',
    'Text-To-Speech (home)': 'TTSHomeRecipients',
    'Text-To-Speech (cell)': 'TTSCellRecipients',
  };

  useEffect(() => {
    setNonMembers(
      nonMembers.filter(
        (item) => !!recipientTypes.find((type) => typeKey[type] === item.type)
      )
    );
  }, [recipientTypes]);

  if (!reduxNotifications) {
    return <div>Loading Notifications...</div>;
  }

  if (!reduxNotifications) {
    return <div>Loading Notifications...</div>;
  }

  return (
    <>
      <PageTitle title="Notifications">
        <div className="button-group d-block d-md-flex">
          <StylishNewButton
            className={'button--secondary w-100 w-md-auto'}
            onClick={() => setSelectTemplateDialogOpen(true)}
          >
            Select Template
          </StylishNewButton>
          <StylishNewButton
            className={
              'button--secondary w-100 w-md-auto ms-0 ms-md-3 mt-2 mt-md-0 text-nowrap'
            }
            onClick={() => setManageDistributionListsDialogOpen(true)}
          >
            Manage Relevant Team(s)
          </StylishNewButton>
        </div>
      </PageTitle>
      {selectedTemplate && (
        <div className="form-block d-flex align-items-center">
          <h4 className="m-0">{`Current template: ${selectedTemplate.template_title} `}</h4>
          <StylishNewButton
            className={'button--primary ms-auto'}
            onClick={() => dispatch(setSelectedTemplate())}
          >
            Clear selection
          </StylishNewButton>
        </div>
      )}
      <Alert variant="info" className="mb-4 d-flex align-items-center">
        <SharedIcon iconName="info" classes="me-2" />
        Notifications require Recipients, Subject, and Message to be set. You
        will only be able to save a notification template if a role is selected
        or a recipient is added
      </Alert>
      {sentMsgCount.isSuccess &&
      recipientTypes.some((r) =>
        ['SMS', 'Text-To-Speech (cell)', 'Text-To-Speech (home)'].includes(r)
      ) &&
      sentMsgCount.data >= notificationLimit ? (
        <Alert
          variant="danger"
          className="mb-4 d-flex align-items-center alert-hover"
          onClick={() => setShowUpgradeModal(true)}
        >
          <SharedIcon iconName="warning" classes="me-2" />
          Your Organization has exceeded the limit of {notificationLimit}{' '}
          SMS/TTS messages in the past 30 days. Click here to unlock the full
          potential of PRATUS and enable Mass Notifications
        </Alert>
      ) : (
        <Alert variant="info" className="mb-4 d-flex align-items-center">
          <SharedIcon iconName="info" classes="me-2" />
          Your Organization has sent {sentMsgCount.data} SMS/TTS message(s) in
          the past 30 days
        </Alert>
      )}
      <div className="form-block mb-4">
        <h4 className="mb-4">
          Select Type
          <span aria-label="Required field" className="required">
            *
          </span>
        </h4>
        {allRecipientTypesOptions.map((type, idx) => (
          <div
            key={`key-${idx}`}
            className={`${
              idx !== allRecipientTypesOptions.length - 1 ? 'mb-2' : ''
            }`}
          >
            <StylishNewCheckbox
              className={'d-inline-flex'}
              key={`recipient-type-checkbox-${type}`}
              checked={!!recipientTypes.find((t) => t === type)}
              onClick={() => recipientTypeClicked(type)}
              label={type}
            />
          </div>
        ))}
        <p className="fst-italic weight-500 mt-4 mb-0">
          Note: Notifications with custom messages and recipients can be
          defined.
        </p>
      </div>
      <NotificationRecipientsScreen
        isFetchRostersForGroupsLoaded={isFetchRostersForGroupsLoaded}
        groupMembersToNotify={groupMembersToNotify}
        nonMembers={nonMembers}
        disabledGuids={disabledGuids}
        setDisabledGuids={setDisabledGuids}
        selectedDistributionLists={selectedDistributionLists}
        distributionLists={distributionLists}
        setAddDistributionListDialogOpen={setAddDistributionListDialogOpen}
        setAddNewRecipientsDialogOpen={setAddNewRecipientsDialogOpen}
        setNonMembers={setNonMembers}
        setGroupMembersToNotify={setGroupMembersToNotify}
        setSelectedDistributionLists={setSelectedDistributionLists}
      />
      <NotificationMessageScreen
        recipientTypes={recipientTypes}
        subject={subject}
        setSubject={setSubject}
        message={message}
        setMessage={setMessage}
        emailMessageMaxLength={emailMessageMaxLength}
        SMSMessage={SMSMessage}
        smsMessageMaxLength={smsMessageMaxLength}
        setSMSMessage={setSMSMessage}
        ttsMessageMaxLength={ttsMessageMaxLength}
        TTSMessage={TTSMessage}
        setTTSMessage={setTTSMessage}
        saveAsTemplate={saveAsTemplate}
        setSaveAsTemplate={setSaveAsTemplate}
        templateTitle={templateTitle}
        setTemplateTitle={setTemplateTitle}
        templateTitleError={templateTitleError}
        setTemplateTitleError={setTemplateTitleError}
        handleTemplateSave={handleTemplateSave}
      />
      <NotificationFooterScreen
        recipientTypes={recipientTypes}
        isUpsertNotificationLoaded={isUpsertNotificationLoaded}
        isSendNotificationLoaded={isSendNotificationLoaded}
        handleFormSubmit={handleFormSubmit}
        sentMsgCount={sentMsgCount}
        textMessageLimit={notificationLimit}
        nonMembers={nonMembers}
        groupMembersToNotify={groupMembersToNotify}
        selectedDistributionLists={selectedDistributionLists}
        saveAsTemplate={saveAsTemplate}
      />
      {selectTemplateDialogOpen && (
        <SelectTemplateDialog
          show={selectTemplateDialogOpen}
          selectedNotification={selectedTemplate}
          availableNotifications={allNotifications}
          setSaveAsTemplate={setSaveAsTemplate}
          onClose={() => setSelectTemplateDialogOpen(false)}
        />
      )}
      {addDistributionListDialogOpen && (
        <AddDistributionListDialog
          show={addDistributionListDialogOpen}
          distributionLists={distributionLists}
          setDistributionLists={setDistributionLists}
          selectedDistributionLists={selectedDistributionLists}
          setSelectedDistributionLists={setSelectedDistributionLists}
          roster={reduxRostersForGroups}
          onClose={() => setAddDistributionListDialogOpen(false)}
          recipientTypes={recipientTypes}
        />
      )}
      {addNewRecipientsDialogOpen && (
        <AddNewRecipientsDialog
          show={addNewRecipientsDialogOpen}
          groupMembersToNotify={groupMembersToNotify}
          setGroupMembersToNotify={setGroupMembersToNotify}
          distributionLists={distributionLists}
          setDistributionLists={setDistributionLists}
          setSelectedDistributionLists={setSelectedDistributionLists}
          roster={reduxRostersForGroups}
          onClose={() => setAddNewRecipientsDialogOpen(false)}
          recipientTypes={recipientTypes}
          nonMembers={nonMembers}
          setNonMembers={setNonMembers}
        />
      )}
      {manageDistributionListsDialogOpen && (
        <ManageDistributionListsDialog
          show={manageDistributionListsDialogOpen}
          distributionLists={distributionLists}
          setDistributionLists={setDistributionLists}
          selectedDistributionLists={selectedDistributionLists}
          setSelectedDistributionLists={setSelectedDistributionLists}
          roster={reduxRostersForGroups}
          recipientTypes={recipientTypes}
          onClose={() => setManageDistributionListsDialogOpen(false)}
        />
      )}
      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Send Notification'}
          dialogContent={
            recipientTypes.some((r) =>
              [
                'SMS',
                'Text-To-Speech (cell)',
                'Text-To-Speech (home)',
              ].includes(r)
            ) && sentMsgCount.data + phoneNumberCount > notificationLimit ? (
              <>
                Warning: This will go over the limit of {notificationLimit}{' '}
                notifications.{' '}
                <a
                  href="https://www.disastertech.com/contact-us"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'underline' }}
                >
                  Click here to upgrade your subscription
                </a>{' '}
                or reduce the number of recipients
              </>
            ) : (
              <>
                Are you sure you want to send this Notification?
                {recipientTypes.some((r) =>
                  [
                    'SMS',
                    'Text-To-Speech (cell)',
                    'Text-To-Speech (home)',
                  ].includes(r)
                ) &&
                  ' You will be sending ' +
                    phoneNumberCount +
                    ' SMS/TTS message(s).'}
              </>
            )
          }
          disabled={
            recipientTypes.some((r) =>
              [
                'SMS',
                'Text-To-Speech (cell)',
                'Text-To-Speech (home)',
              ].includes(r)
            ) && sentMsgCount.data + phoneNumberCount > notificationLimit
          }
          dialogButtonText={'Send'}
          onClose={() => {
            setShowConfirmDialog(false);
          }}
          onConfirm={onConfirmedSendNotification}
        />
      )}

      {showUnavailableUserNotificationDailog && (
        <UnavailableUserNotificationDialog
          show={showUnavailableUserNotificationDailog}
          unavailableUsers={unavailableUsersData}
          onClose={() => {
            setShowUnavailableUserNotificationDailog(false);
            setUnavailableUsersData(null);
          }}
        />
      )}
      {showUpgradeModal && (
        <UpgradeSubscriptionModal onClose={() => setShowUpgradeModal(false)} />
      )}
    </>
  );
}

export default NotificationComposeScreen;
