import React from 'react';
import { SeeristEvent } from './SeeristEvent';
import {
  useRefreshSeerist,
  useSeeristEvents,
} from '../mapHooks/useSeeristEvents';
import { StylishNewButton } from '../../DesignSystems/New/StylishNewButton';
import { MdRefresh } from 'react-icons/md';
import { AreaOfResponsibility } from '../../../types/aor';
import ReactPaginate from 'react-paginate';
import { chunk } from 'lodash';

type SeeristEventsListProps = {
  aor: AreaOfResponsibility;
};

const SeeristEventsList: React.FC<SeeristEventsListProps> = ({ aor }) => {
  const {
    data: events,
    isLoading: isLoadingEvents,
    isFetching,
  } = useSeeristEvents(aor.id);
  const { mutate: refreshSeerist, isPending } = useRefreshSeerist();

  const handleRefresh = () => {
    refreshSeerist(aor.id);
  };

  const [page, setPage] = React.useState(0);
  const pagedEvents = chunk(events, 20);

  // Show loading spinner for both initial load and subsequent fetches
  const isLoading = isLoadingEvents || isPending || isFetching;

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="d-flex align-items-center">
          <h5 className="m-0">Seerist Events</h5>
          <div className="ms-2 d-flex align-items-center">
            {isLoading ? (
              <div
                className="spinner-border spinner-border-sm text-primary"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <StylishNewButton
                className="button button--link"
                onClick={handleRefresh}
                disabled={isLoading}
              >
                <MdRefresh />
              </StylishNewButton>
            )}
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end">
          <ReactPaginate
            className="pagination pagination-sm"
            pageCount={pagedEvents.length}
            marginPagesDisplayed={3}
            onPageChange={({ selected }) => setPage(selected)}
          />
        </div>
      </div>
      {isLoadingEvents && !events ? (
        <div className="text-center p-4">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div>
          {events?.length ? (
            pagedEvents[page].map((event) => (
              <SeeristEvent key={event.id} event={event} />
            ))
          ) : (
            <p className="text-center text-secondary mt-4">
              No events found for this area.
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default SeeristEventsList;
