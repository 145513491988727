import { Modal } from 'react-bootstrap';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { FormProvider, useForm } from 'react-hook-form';
import FormTextAreaField from 'components/IAP/formFields/FormTextAreaField';
import { useSendAIContentReport } from './useAIContentReporting';

type AIContentReportDialogProps = {
  reportedContent: any;
  onClose: () => void;
}
const AIContentReportDialog = ({
  reportedContent,
  onClose,
}: AIContentReportDialogProps) => {

  const sendAIContentReport = useSendAIContentReport();

  const handleConfirmReport = (data: { description: string }) => {

    sendAIContentReport.mutate({section: reportedContent, description: data.description});

    onClose();
  };

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      description: '',
    }
  })

  const { formState: { errors }} = methods;

  return (
    <Modal
      show={!!reportedContent}
      onHide={onClose}
      centered
      backdrop={true}
      keyboard={false}
    >

      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(handleConfirmReport)}
        >
          <Modal.Header closeButton closeVariant="white">
            <Modal.Title>Report AI Generated Content</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <FormTextAreaField
                title="What went wrong?"
                fieldName="description"
                rules={{ required: { value: true, message: 'Description is required' } }}
              />
              <p>
                {"We'll also share the content you're providing feedback on to help improve future responses."}
              </p>
          </Modal.Body>
          <Modal.Footer>
            <StylishNewButton
              className="button--secondary"
              onClick={onClose}
              >
              Cancel
            </StylishNewButton>
            <StylishNewButton
              className="button--tertiary"
              type="submit"
              >
              Report
            </StylishNewButton>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default AIContentReportDialog;