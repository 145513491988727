import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { SharedIcon } from '../SharedIcon/SharedIcon';
import EntityList from './EntityList';
import { useToggle } from 'react-use';
import { Card } from 'react-bootstrap';
import { useState,useEffect } from 'react';
import classNames from 'classnames';
import {
  ResourceSet,
  useResourceSets,
} from './hooks/resourceSetHooks';
import ResourceSetAddDrawer from './ResourceSetAddDrawer';
import ResourceSetEditDrawer from './ResourceSetEditDrawer';
import ResourceSetUsageDrawer from './ResourceSetUsageDrawer';
import ResourceSetSplitDrawer from './ResourceSetSplitDrawer';
import ResourceSetJoinDrawer from './ResourceSetJoinDrawer';

import {
  MdLocationOn,
  MdEdit,
  MdDataThresholding,
} from 'react-icons/md';

interface CollapsibleResourceSetsListProps {
  onMap?: boolean;
  flyTo?: any;
  resource_site_id?: string;
}

const CollapsibleResourceSetsList: React.FC<CollapsibleResourceSetsListProps> = ({ onMap = false, flyTo, resource_site_id }) => {
  const [expanded, toggleExpanded] = useToggle(onMap);
  const [editOpen, toggleEditDrawer] = useToggle(false);
  const [addOpen, toggleAddDrawer] = useToggle(false);
  const [splitOpen, toggleSplitDrawer] = useToggle(false);
  const [joinOpen, toggleJoinDrawer] = useToggle(false);
  const [usageOpen, toggleUsageDrawer] = useToggle(false)
  const [
    selectedEntity,
    setSelectedEntity,
  ] = useState<ResourceSet | null>(null);

  const [
    usageEntity,
    setUsageEntity,
  ] = useState<ResourceSet | null>(null);

  const { data: resourceSets } = useResourceSets();

  const selectElement = (element: ResourceSet) => {
    setSelectedEntity(element);
    toggleEditDrawer();
  };

  const selectSplit = (element: ResourceSet) => {
    setSelectedEntity(element);
    toggleSplitDrawer();
  };

  const selectJoin = (element: ResourceSet) => {
    setSelectedEntity(element);
    toggleJoinDrawer();
  };

  const selectUsage = (element: ResourceSet) => {
    setUsageEntity(element);
    toggleUsageDrawer();
  };

  const finalResourceSets: ResourceSet[] = (!resource_site_id && (resourceSets ?? [])) || (resourceSets ?? []).filter((rs: ResourceSet) => rs.resource_site_id === resource_site_id);

  return (
    <>
      <EntityList
        entities={finalResourceSets}
        expanded={expanded}
        toggleExpanded={toggleExpanded}
        header={
          <div className="d-flex align-items-center justify-content-between flex-grow-1">
            <span>Resource Sets</span>
            <StylishNewButton
              className={classNames('button', {
                'button--primary': expanded,
                'button--secondary': !expanded,
              })}
              onClick={toggleAddDrawer}
            >
              <SharedIcon iconName="add" classes="me-2" />
              Add
            </StylishNewButton>
          </div>
        }
        renderEntity={(element) => (
          <Card
            key={element.id}
            className="bg-neutral-800 my-1 rounded-1 flex-row align-items-center justify-content-between"
            style={{
              padding: '0.625rem 1rem 0.625rem 1rem',
            }}
          >
            <span>{element.name} | ({element.kind}) | {element.quantity} | {element.status}</span>
            <span style={{float:"right"}}>
            {!!onMap && (
              <StylishNewButton
                className="button button--link"
                onClick={() => flyTo(element)}
              >
                <MdLocationOn />
              </StylishNewButton>
            )}

            <StylishNewButton
                className="button button--link"
                onClick={() => selectUsage(element)}
              >
                <MdDataThresholding />
            </StylishNewButton>

            <StylishNewButton
                className="button button--link"
              onClick={() => selectSplit(element)}
            >
              <SharedIcon iconName="call_split" classes="me-2" />
            </StylishNewButton>

            <StylishNewButton
              className="button button--link"
              onClick={() => selectJoin(element)}
            >
              <SharedIcon iconName="merge_type" classes="me-2" />
            </StylishNewButton>

            <StylishNewButton
              className="button button--link"
              onClick={() => selectElement(element)}
            >
              <MdEdit />
            </StylishNewButton>
            </span>
          </Card>
        )}
      ></EntityList>
      {addOpen ? <ResourceSetAddDrawer toggle={toggleAddDrawer} /> : null}
      {editOpen && selectedEntity ? (
        <ResourceSetEditDrawer
          toggle={toggleEditDrawer}
          selectedEntity={selectedEntity}
        />
      ) : null}
      {splitOpen && selectedEntity ? (
        <ResourceSetSplitDrawer
          toggle={toggleSplitDrawer}
          selectedEntity={selectedEntity}
        />
      ) : null}
      {joinOpen && selectedEntity ? (
        <ResourceSetJoinDrawer
          toggle={toggleJoinDrawer}
          selectedEntity={selectedEntity}
        />
      ) : null}
      {usageOpen && usageEntity ? (
        <ResourceSetUsageDrawer 
          toggle={toggleUsageDrawer} 
          selectedEntity={usageEntity}
        />
      ) : null}
    </>
  );
};

export default CollapsibleResourceSetsList;
