import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';

type NotificationFooterScreenProps = {
  recipientTypes: string[];
  isUpsertNotificationLoaded: boolean;
  isSendNotificationLoaded: boolean;
  handleFormSubmit: () => void;
  sentMsgCount: { data: number };
  textMessageLimit: number;
  nonMembers: NonMember[];
  groupMembersToNotify: UUID[];
  selectedDistributionLists: UUID[];
  saveAsTemplate: boolean;
};

export const NotificationFooterScreen: React.FC<
  NotificationFooterScreenProps
> = ({
  recipientTypes,
  isUpsertNotificationLoaded,
  isSendNotificationLoaded,
  handleFormSubmit,
  sentMsgCount,
  textMessageLimit,
  nonMembers,
  groupMembersToNotify,
  selectedDistributionLists,
  saveAsTemplate,
}) => {
  const reduxValidateRBACPermissionForActionResult = useSelector(
    (state: any) => {
      return state.app.validateRBACPermissionForActionResult;
    }
  );
  const reduxSendNotificationResult = useSelector((state: any) => {
    return state.app.sendNotificationResult;
  });
  const reduxUpsertedNotification = useSelector((state: any) => {
    return state.app.upsertedNotification;
  });

  return (
    <>
      {!reduxValidateRBACPermissionForActionResult[
        'Manage Notification Templates'
      ] && (
        <span className="form-info d-flex mt-5 weight-500">
          You do not have permission to Manage Notification Templates.
          Permissions are managed on the &nbsp;
          <Link to="/groups">groups</Link>
          &nbsp; page
        </span>
      )}
      {!reduxValidateRBACPermissionForActionResult['Send Notification'] && (
        <span className="form-info d-flex mt-5 weight-500">
          You do not have permission to Send Notifications. Permissions are
          managed on the &nbsp;
          <Link to="/groups">groups</Link>
          &nbsp; page
        </span>
      )}
      {!recipientTypes.length && (
        <span className="form-info d-flex mt-5 weight-500">
          At least one recipient type is required for a Notification
        </span>
      )}
      <hr className="dashed my-4" />
      <div className="d-flex align-items-center mb-5">
        {!isUpsertNotificationLoaded && (
          <span className="form-text d-flex align-items-center weight-500 me-3">
            <i className="fa fa-spinner fa-pulse fa-2x me-2"></i> Saving
            Notification...
          </span>
        )}
        {!isSendNotificationLoaded && (
          <span className="form-text d-flex align-items-center weight-500 me-3">
            <i className="fa fa-spinner fa-pulse fa-2x me-2"></i> Sending
            Notification...
          </span>
        )}
        {!!isSendNotificationLoaded && reduxSendNotificationResult && (
          <span className="form-text d-flex align-items-center weight-500 me-3">
            <i className="fa fa-check-circle fa-2x me-2" aria-hidden="true"></i>{' '}
            Notification Sent!
          </span>
        )}
        {!!isUpsertNotificationLoaded &&
          reduxUpsertedNotification &&
          !!saveAsTemplate && (
            <span className="form-text d-flex align-items-center weight-500 me-3">
              <i
                className="fa fa-check-circle fa-2x me-2"
                aria-hidden="true"
              ></i>{' '}
              Notification Template Saved!
            </span>
          )}
        <StylishNewButton
          onClick={handleFormSubmit}
          className="button--primary ms-auto"
          disabled={
            (recipientTypes.some((r) =>
              [
                'SMS',
                'Text-To-Speech (cell)',
                'Text-To-Speech (home)',
              ].includes(r)
            ) &&
              sentMsgCount.data >= textMessageLimit) ||
            !recipientTypes.length ||
            !reduxValidateRBACPermissionForActionResult['Send Notification'] ||
            !(
              nonMembers.length ||
              groupMembersToNotify.length ||
              selectedDistributionLists.length
            )
          }
        >
          Send
        </StylishNewButton>
      </div>
    </>
  );
};

export default NotificationFooterScreen;
