import React from 'react';
import { Modal } from 'react-bootstrap';
import { StylishNewButton } from './StylishNewButton';
import { sanitizeHtml } from '../../../utils/sanitizeHtml';

export const StylishConfirmDialog = ({
  show,
  dialogTitle,
  dialogContent,
  dialogContentType = 'text',
  dialogNote,
  dialogButtonText,
  dialogCloseText = 'Cancel',
  disabled = false,
  onClose,
  onConfirm,
}) => {
  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>{dialogTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {dialogContentType === 'text' ? (
            <p className="m-0">{dialogContent}</p>
          ) : dialogContentType === 'html' ? (
            <p
              className="m-0"
              dangerouslySetInnerHTML={{ __html: sanitizeHtml(dialogContent) }}
            ></p>
          ) : null}

          {dialogNote ? (
            <>
              <span className="form-text form-error multiline mt-4">
                {dialogNote}
              </span>
            </>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button--secondary button--reverse"
              onClick={onClose}
            >
              {dialogCloseText}
            </StylishNewButton>
            <StylishNewButton
              className="button--primary"
              onClick={onConfirm}
              disabled={disabled}
            >
              {dialogButtonText ? dialogButtonText : 'Delete'}
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
