import Collapse from '@mui/material/Collapse';
import { useCss, useToggle } from 'react-use';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
import ExpandDownArrow from '../DesignSystems/buttons/ExpandDownArrow';
import ExpandRightArrow from '../DesignSystems/buttons/ExpandRightArrow';

type EntityListProps<T extends any[] = any[]> = {
  entities: T | undefined;
  header: JSX.Element;
  renderEntity: (entity: T[number]) => JSX.Element;
  expanded: boolean;
  toggleExpanded: () => void;
};

const EntityList = <T extends any[]>({
  entities,
  header,
  renderEntity,
  expanded,
  toggleExpanded,
}: EntityListProps<T>) => {

  const [viewArchived, setViewArchived] = useToggle(false);
  
  const filteredEntities = entities?.filter(fe => fe.archived === viewArchived);
  
  const listStyle = useCss({
    borderLeft: '1px solid var(--neutral-700)',
    paddingLeft: '1.375rem',
    marginLeft: '0.625rem',
  });

  return (
    <div className="w-100">
      <div className="w-100 d-flex align-items-center mb-2">
        {filteredEntities?.length ? (
          <div>
            {expanded ? (
              <ExpandDownArrow onClick={toggleExpanded} />
            ) : (
              <ExpandRightArrow onClick={toggleExpanded} />
            )}
          </div>
        ) : (
          <div className="ms-2 me-3"></div>
        )}
        <div
          className="me-2"
          onClick={setViewArchived}
          style={{ cursor: 'pointer' }}
          title={viewArchived ? 'Viewing Inactive' : 'Viewing Active'}
        >
          {viewArchived ? <IoMdEyeOff /> : <IoMdEye />}
        </div>
        {header}
      </div>
      <Collapse in={expanded}>
        <div className={listStyle}>
          {filteredEntities?.map((entity) => renderEntity(entity))}
        </div>
      </Collapse>
    </div>
  );
};

export default EntityList;