import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import NewICSResourceDialog from 'components/ICS/NewICSResourceDialog';
import { useSelector, useDispatch } from 'react-redux';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import ICSIncidentResource from './ICSIncidentResource';
import {
  createIcsIncidentResource,
  updateIcsIncidentResource,
} from 'slices/ics_incidentResourcesSlice';

// This whole file is largely unused. The following function was undefined. Creating stub so we can enable linting
// without worrying about deleting more unused stuff.
const updateIcsResource = (resource_id, payload) => {};

export default function ICSResourcesDialog({
  show,
  onClose,
  ics_incident_resources,
  ics_resource_sets,
  incident_id = null,
  ics_incident_tactics
}) {
  const reduxDispatch = useDispatch();
  const [isNewResourceDialogOpen, setIsNewResourceDialogOpen] = useState(false);
  const [modalType, setModalType] = useState('New');
  const [editResourceData, setEditResourceData] = useState();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [archiveConfirmationData, setArchiveConfirmationData] = useState(null);
  const reduxCurrentlySelectedGroup = useSelector((state) => state.app.currentlySelectedGroup);
  const reduxCurrentIncident = useSelector((state) => state.app.currentIncident);
  const [dialogResources, setDialogResources] = useState([...ics_incident_resources]);

  useEffect(() => {
    setDialogResources(ics_incident_resources);
  }, [ics_incident_resources]);

  const calculateHaveNeed = () => {
    const haveNeedMap = {};

    ics_incident_resources.forEach(incidentResource => {
      const haveQuantity = ics_resource_sets
        .filter(resourceSet => resourceSet.kind === incidentResource.kind)
        .reduce((acc, resourceSet) => acc + resourceSet.quantity, 0);

      const needQuantity = Math.max(incidentResource.required_quantity - haveQuantity, 0);

      haveNeedMap[incidentResource.incident_resource_id] = {
        have: haveQuantity,
        need: needQuantity
      };
    });

    return haveNeedMap;
  };

  const haveNeedMap = calculateHaveNeed();

  const allColumns = [
    {
      dataField: 'kind',
      text: 'Kind',
      sort: true,
      attrs: { title: 'Kind' },
    },
    {
      dataField: 'required_quantity',
      text: 'Req #',
      sort: true,
      attrs: { title: 'Req #' },
    },
    {
      dataField: 'have',
      text: 'Have #',
      sort: true,
      attrs: { title: 'Have #' },
    },
    {
      dataField: 'need',
      text: 'Need #',
      sort: true,
      attrs: { title: 'Need #' },
    },
  ];

  const onEdit = (row) => {
    setModalType('Edit');
    setIsNewResourceDialogOpen(true);
    setEditResourceData({
      ...row,
      versions: ics_incident_resources.find((ir) => ir.resource_id === row.id)?.versions,
    });
  };

  const onArchive = (row) => {
    setShowConfirmDialog(true);
    setArchiveConfirmationData(row);
  };

  const onConfirmedArchiveActionData = () => {
    const payload = {
      ...archiveConfirmationData,
      incident_id,
      archived: !archiveConfirmationData.archived,
    };

    if (!!incident_id) {
      reduxDispatch(
        updateIcsIncidentResource(
          incident_id,
          archiveConfirmationData.id,
          payload
        )
      );
    } else {
      reduxDispatch(updateIcsResource(archiveConfirmationData.id, payload));
    }

    setShowConfirmDialog(false);
    setArchiveConfirmationData(null);
  };

  const onNewResource = () => {
    setModalType('New');
    setIsNewResourceDialogOpen(true);
  };

  const tableRows = ics_incident_resources.map(incidentResource => ({
    ...incidentResource,
    have: haveNeedMap[incidentResource.incident_resource_id]?.have || 0,
    need: haveNeedMap[incidentResource.incident_resource_id]?.need || 0
  }));

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white" className="pb-0">
        <Modal.Title>Incident Resource Requirements</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <p className="weight-500 mb-4">
          {reduxCurrentIncident?.name}
        </p>
        <StylishNewButton
          type="button"
          className="button--primary mb-4"
          onClick={onNewResource}
        >
          Add Resource Requirement
        </StylishNewButton>
        <div>
          <label>
            {!!incident_id && (
              <>
                Select to toggle resource requirements as active or inactive.
              </>
            )}
          </label>
        </div>
        <StylishNewTable
          keyField={'id'}
          rows={tableRows}
          columns={allColumns}
          expandRow={{
            parentClassName: 'parent-expand-tr',
            className: 'child-expand-tr',
            renderer: (row) => {
              return (
                <>
                  <ICSIncidentResource
                    row={row}
                    ics_incident_resources={ics_incident_resources}
                  />
                  <div className="button-group">
                    <StylishNewButton
                      className="button--primary"
                      onClick={() => onEdit(row)}
                    >
                      Edit
                    </StylishNewButton>
                    <StylishNewButton
                      className="button--tertiary button--reverse"
                      onClick={() => onArchive(row)}
                    >
                      {row.archived ? `Unarchive` : `Archive`}
                    </StylishNewButton>
                  </div>
                </>
              );
            },
            showExpandColumn: true,
            expandByColumnOnly: true,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
              if (isAnyExpands) {
                return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
              }
              return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
            },
            expandColumnRenderer: ({ expanded }) => {
              if (expanded) {
                return (
                  <>
                    <div className="d-flex align-items-center justify-content-md-center">
                      <span className="me-2">Details</span>
                      <i className="fa fa-chevron-down" aria-hidden="true"></i>
                    </div>
                  </>
                );
              }
              return (
                <>
                  <div className="d-flex align-items-center justify-content-md-center">
                    <span className="me-2">Details</span>
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </div>
                </>
              );
            },
          }}

        />
      </Modal.Body>
      <Modal.Footer>
        <div className="button-group">
          <StylishNewButton
            className="button--secondary button--reverse"
            type="button"
            onClick={onClose}
          >
            Close
          </StylishNewButton>
        </div>
      </Modal.Footer>
      {isNewResourceDialogOpen && (
        <NewICSResourceDialog
          show={isNewResourceDialogOpen}
          setEditResourceData={setEditResourceData}
          setModalType={setModalType}
          editResourceData={editResourceData}
          modalType={modalType}
          onClose={() => setIsNewResourceDialogOpen(false)}
          incident_id={incident_id}
          ics_incident_tactics={ics_incident_tactics}
        />
      )}
      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Archive Confirmation'}
          dialogContent={'Are you sure you want to archive this Resource?'}
          dialogButtonText={'Archive'}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={onConfirmedArchiveActionData}
        />
      )}
    </Modal>
  );
}


/*
            selectRow={{
            mode: 'checkbox',
            clickToSelect: true,
            hideSelectAll: true,
            selected: ics_incident_resources.filter(r => !r.archived).map(r => r.id),
            selectionHeaderRenderer: ({ mode, ...rest }) => (
              <div className="form-checkbox align-items-center">
                <input type={mode} {...rest} readOnly />
                <span className="icon"></span>
              </div>
            ),
            selectionRenderer: ({ mode, ...rest }) => (
              <div className="form-checkbox align-items-center">
                <input type={mode} {...rest} readOnly />
                <span className="icon"></span>
              </div>
            ),
            onSelect: (row, isSelect, rowIndex) => {
              reduxDispatch(
                updateIcsIncidentResource(incident_id, row.incident_resource_id, {
                  ...row,
                  archived: !row.archived,
                })
              );
            },
            onSelectAll: (isSelect, rows, e) => {
              isSelect ? setDialogResources(rows) : setDialogResources([]);
            },
          }}
*/