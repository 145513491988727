import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from 'constants/apiConfig';
import { selectGroup, useAppSelector } from 'slices/commonSelectors';

const analysisListKeys = {
  base: 'capacityAnalysisList',
  list: (group_guid:UUID) => [analysisListKeys.base, group_guid],
  prompt: (id:UUID) => [analysisListKeys.base, id],
};

export const useCapacity = (result: any) => {
  const queryClient = useQueryClient();
  const { group_guid } = useAppSelector(selectGroup);
  return useMutation({
    mutationFn: async () => {
      const response = await axios.post(
        config.apiGateway.checkTransportationCapacity,
        { capacityInput: result, group_guid }
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: analysisListKeys.list(group_guid) });
    },
  });
};

export const useCapacityAnalysisList = (group_id: UUID) => {
  return useQuery({
    queryKey: analysisListKeys.list(group_id),
    queryFn: async () => {
      const { data } = await axios.get(
        config.apiGateway.getCapacityAnalysisList,
        {
          params: { group_id, limit: 10, page: 1 },
        }
      );
      return data;
    },
    enabled: !!group_id, // Only run the query if group_guid is defined
  });
};

export const useCapacityAnalysis = (id: UUID, group_guid: UUID) => {
  return useQuery({
    queryKey: ['capacityAnalysis', group_guid, id],
    queryFn: async () => {
      const { data } = await axios.get(config.apiGateway.getCapacityAnalysis, {
        params: { id, group_guid },
      });
      return data;
    },
    enabled: (!!group_guid && !!id), // Only run the query if group_guid and id are defined
  });
};
