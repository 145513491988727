import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import generateUUID from 'utils/sharedUtils/generateUUID';
import { upsertCoreComponent } from 'actions/ai2fpActions';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { fetchNestedCategories } from 'actions/locationAction';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { DebounceInput } from 'react-debounce-input';
import { createLocationFromGeojson } from 'components/MapComponent/utils/createLocationFromGeojson';
import { addPolyLayer } from './mapEffects/geolocationsEffect';

export const samplePointSource = {
  id: '',
  type: 'geojson',
  data: {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [],
        },
        generateId: true,
      },
    ],
  },
};

export default function AorCreatePage({
  map,
  onClose,
  ccType,
  existingCC = null,
  location = null,
  setLocation,
  drawRef,
  setLayerClicked,
}) {
  const dispatch = useDispatch();

  const [isLoaded, setIsLoaded] = useState(true);

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const isFetchCoreComponentsLoaded = useSelector((state) => {
    return state.app.isFetchCoreComponentsLoaded;
  });

  const isUpsertCoreComponentLoaded = useSelector((state) => {
    return state.app.isUpsertCoreComponentLoaded;
  });

  const [selectedLat, setSelectedLat] = useState(null);
  const [selectedLng, setSelectedLng] = useState(null);
  const [nameEditable, setNameEditable] = useState(false);
  const [addressEditable, setAddressEditable] = useState(false);
  const [editedName, setEditedName] = useState('');
  const [editedAddress, setEditedAddress] = useState('');

  const [name, setName] = useState(
    (!!existingCC && !!existingCC.name && existingCC.name) || ''
  );
  const [description, setDescription] = useState(
    (!!existingCC && !!existingCC.description && existingCC.description) || ''
  );

  const readOnly = false;
  const mapboxTokenActual =
    window.env.MAPBOX_ACCESS_TOKEN || process.env.MAPBOX_ACCESS_TOKEN;

  const onChangeLatLang = (value, type) => {
    const geojson = { ...samplePointSource };
    if (type === 'lat') {
      if (value > 90 || value < -90) {
        toast.error('Latitude should be between -90 and 90');
        return;
      }
      setSelectedLat(value);
      if (
        existingCC?.location?.centroid.geometry.coordinates[0] ||
        selectedLng
      ) {
        const val =
          existingCC?.location?.centroid.geometry.coordinates[0] || selectedLng;
        geojson.data.features[0].geometry.coordinates = [val, value];
        createLocationFromGeojson(
          geojson.data,
          mapboxTokenActual,
          setLocation,
          setSelectedLat,
          setSelectedLng
        );
      }
    } else if (type === 'lng') {
      if (value > 180 || value < -180) {
        toast.error('Longitude should be between -180 and 180');
        return;
      }
      setSelectedLng(value);
      if (
        existingCC?.location?.centroid.geometry.coordinates[1] ||
        selectedLat
      ) {
        const val =
          existingCC?.location?.centroid.geometry.coordinates[1] || selectedLat;
        geojson.data.features[0].geometry.coordinates = [value, val];
        createLocationFromGeojson(
          geojson.data,
          mapboxTokenActual,
          setLocation,
          setSelectedLat,
          setSelectedLng
        );
      }
    }
  };
  useEffect(() => {
    if (
      !isLoaded &&
      isUpsertCoreComponentLoaded &&
      isFetchCoreComponentsLoaded
    ) {
      onClose();
    }
  }, [
    isUpsertCoreComponentLoaded,
    isFetchCoreComponentsLoaded,
    isLoaded,
    onClose,
  ]);

  function createCC() {
    const current_location_geom =
      !!location &&
      !!location.geojson &&
      location.geojson.data.features[0].geometry;
    const cc = {
      id: (!!existingCC && existingCC.id) || generateUUID(),
      group_guid: reduxCurrentlySelectedGroup.group_guid,
      name: name,
      description: description,
      location: location,
      current_location_geom: current_location_geom,
      cc_type: ccType,
      category_id: selectedCategory?.value,
    };
    return cc;
  }

  const handleCCCreateClick = () => {
    setIsLoaded(false);
    dispatch(upsertCoreComponent(createCC()));
    drawRef.current.deleteAll();
    setLocation(null);
    drawRef.current.changeMode('simple_select');

    map.current.removeLayer('aors_polygons_layer');
    setTimeout(() => {
      addPolyLayer(
        map,
        'aors',
        'geolocation_tiles_aors_polygons_source',
        'public.geolocation_tiles_aors_polygons',
        setLayerClicked
      );
    }, 50);
  };

  function saveAddress() {
    setLocation({ ...location, address: editedAddress });
    setAddressEditable(false);
    setEditedAddress('');
  }
  function cancelAddress() {
    setAddressEditable(false);
    setEditedAddress('');
  }
  const currName = (!!existingCC && existingCC.name) || null;

  function saveName() {
    setLocation({ ...location, name: editedName });
    setNameEditable(false);
    setEditedName('');
  }
  function cancelName() {
    setNameEditable(false);
    setEditedName('');
  }
  const nestedCategories = useSelector((state) => {
    return state.app.nestedCategories;
  });

  const [locationTypes, setLocationTypes] = useState([]);

  useEffect(() => {
    dispatch(fetchNestedCategories());
  }, []);

  useEffect(() => {
    if (nestedCategories) {
      const formattedTypes = nestedCategories.reduce((acc, category) => {
        const type = {
          label: category.category_name,
          value: category.id,
        };

        return [...acc, { ...type }];
      }, []);

      setLocationTypes(formattedTypes);

      if (existingCC && existingCC.category_id) {
        const foundCategory = nestedCategories.find(
          (item) => item.id === existingCC.category_id
        );
        setSelectedCategory({
          label: foundCategory?.category_name,
          value: foundCategory?.id,
        });
      }
    }
  }, [nestedCategories]);

  const [selectedCategory, setSelectedCategory] = useState(
    existingCC && { value: existingCC.category_id }
  );

  return (
    <div>
      <h4>{(!!existingCC && <>Edit {existingCC.name}</>) || <>New AOR</>}</h4>
      <div>
        {!!locationTypes.length && !!locationTypes[0].label && (
          <>
            <div className="mb-3">
              <label className="form-label">{`Select AOR Category`}</label>
              <StylishNewSelect
                options={locationTypes}
                onChange={(e) => setSelectedCategory(e)}
                value={selectedCategory}
                isClearable={false}
                isSearchable={false}
                isMulti={false}
                isDisabled={false}
              />
            </div>
            <hr className="dashed my-4" />
          </>
        )}
        <div className="mb-3">
          <label className="form-label">Name</label>
          <StylishNewInput
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Description</label>
          <StylishNewTextArea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        <div className="m-0">
          <label className="form-label">Location</label>
          <div>
            {!existingCC && !location ? (
              <div>Select a location on the map</div>
            ) : (
              <div className="m-2">
                <div className=" d-flex">
                  <span className="GeoLocationComponent-Details-Title">
                    Name:{' '}
                  </span>
                  {nameEditable ? (
                    <>
                      <StylishNewInput
                        onChange={(e) => setEditedName(e.target.value)}
                        value={editedName || ''}
                        placeholder="Enter name"
                      />
                      <div className="m-1">
                        <StylishNewButton
                          className="button--primary"
                          onClick={() => saveName()}
                          type="button"
                        >
                          Accept
                        </StylishNewButton>
                      </div>
                      <div className="m-1">
                        <StylishNewButton
                          className="button--secondary button--reverse"
                          onClick={() => cancelName()}
                          type="button"
                        >
                          Cancel
                        </StylishNewButton>
                      </div>
                    </>
                  ) : (
                    <span
                      onClick={() => !readOnly && setNameEditable(true)}
                      className={classNames(
                        'GeoLocationComponent-Details-Text',
                        {
                          'GeoLocationComponent-Details-Text-Editable': !readOnly,
                        }
                      )}
                    >
                      {location?.name || existingCC?.location?.name}
                    </span>
                  )}
                </div>
                <div className="d-flex">
                  <span className="GeoLocationComponent-Details-Title">
                    Address:{' '}
                  </span>
                  {(!!addressEditable && (
                    <>
                      <StylishNewInput
                        onChange={(e) => setEditedAddress(e.target.value)}
                        value={editedAddress || ''}
                        placeholder="Enter address"
                      />
                      <div className="m-1">
                        <StylishNewButton
                          className="button--primary"
                          type="button"
                          onClick={() => saveAddress()}
                        >
                          Accept
                        </StylishNewButton>
                      </div>
                      <div className="m-1">
                        <StylishNewButton
                          className="button--secondary button--reverse"
                          type="button"
                          onClick={() => cancelAddress()}
                        >
                          Cancel
                        </StylishNewButton>
                      </div>
                    </>
                  )) || (
                    <>
                      {(
                        <span
                          onClick={() => !readOnly && setAddressEditable(true)}
                          className={classNames(
                            'GeoLocationComponent-Details-Text',
                            {
                              'GeoLocationComponent-Details-Text-Editable': !readOnly,
                            }
                          )}
                        >
                          {location?.address || existingCC?.location?.address}
                        </span>
                      ) || (
                        <span
                          onClick={() => !readOnly && setAddressEditable(true)}
                          className={classNames(
                            'GeoLocationComponent-Details-Text',
                            {
                              'GeoLocationComponent-Details-Text-Editable': !readOnly,
                            }
                          )}
                        >
                          {location?.address || existingCC?.location?.address}
                        </span>
                      )}
                    </>
                  )}
                </div>
                <div>
                  <span className="GeoLocationComponent-Details-Title">
                    Lng/Lat:{' '}
                  </span>
                  <span className="GeoLocationComponent-Details-Text">
                    {JSON.stringify(
                      location?.centroid?.geometry?.coordinates[0]
                    ) ||
                      JSON.stringify(
                        existingCC?.location?.centroid?.geometry?.coordinates
                      )}
                  </span>
                </div>
              </div>
            )}
            <div
              className="mt-3 GeoLocationComponent-Map-Active-Wrap"
              style={{
                display: 'block',
              }}
            >
              <div className="row mb-3">
                <div className="col-md-6">
                  <label className="form-label">Lat</label>
                  <DebounceInput
                    name="lat"
                    type="number"
                    placeholder="Lat"
                    className="form-control"
                    minLength={1}
                    debounceTimeout={300}
                    value={
                      existingCC?.location?.centroid?.geometry
                        ?.coordinates[1] || selectedLat
                    }
                    onChange={(e) => onChangeLatLang(e.target.value, 'lat')}
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">Lng</label>

                  <DebounceInput
                    name="lng"
                    type="number"
                    placeholder="Lng"
                    className="form-control"
                    minLength={1}
                    debounceTimeout={300}
                    value={
                      existingCC?.location?.centroid?.geometry
                        ?.coordinates[0] || selectedLng
                    }
                    onChange={(e) => onChangeLatLang(e.target.value, 'lng')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="button-group">
          <StylishNewButton
            type="button"
            className="button--secondary button--reverse"
            onClick={onClose}
          >
            Cancel
          </StylishNewButton>
          <StylishNewButton
            className="button--primary"
            disabled={!isLoaded || !name || nameEditable || addressEditable}
            onClick={() => handleCCCreateClick()}
          >
            Save {currName}
          </StylishNewButton>
        </div>
      </div>
      {!isLoaded && <div>Loading...</div>}
    </div>
  );
}
