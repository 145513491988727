import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import NewRoleDialog from './NewRoleDialog';
import EditRoleDialog from './EditRoleDialog';
import { deleteRole, fetchAllRolesInGroup } from 'actions/roleActions';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';

export default function GroupRoles() {
  const reduxDispatch = useDispatch();

  const [roleSelectedForEditing, setRoleSelectedForEditing] = useState();
  const [isNewRoleDialogOpen, setIsNewRoleDialogOpen] = useState(false);
  const [isEditRoleDialogOpen, setIsEditRoleDialogOpen] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [deleteRoleData, setDeleteRoleData] = useState();

  const reduxAllRolesInGroup = useSelector((state) => {
    return state.app.allRolesInGroup;
  });

  const reduxValidateRBACPermissionForActionResult = useSelector((state) => {
    return state.app.validateRBACPermissionForActionResult;
  });

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  useEffect(() => {
    reduxDispatch(endLoading());
  }, [reduxAllRolesInGroup]);

  useEffect(() => {
    reduxDispatch(fetchAllRolesInGroup(reduxCurrentlySelectedGroup.group_guid));
  }, [reduxCurrentlySelectedGroup]);

  const defaultRoleNames = ['Group Admin', 'Community Member'];

  const allColumn = [
    {
      dataField: 'name',
      text: 'Organization Role',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Organization Role' },
    },
  ];

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <div className="row">
            <div className="col-md-12 mb-3">
              <h6 className="mb-1">Role Name:</h6>
              <span className="d-block">{row.name}</span>
            </div>
            <div className="col-md-12">
              <h6 className="mb-1">Description:</h6>
              <span className="d-block">{row.description}</span>
            </div>
          </div>
          <div className="button-group mt-4">
            {reduxValidateRBACPermissionForActionResult['Edit Role'] &&
              row.name !== 'Group Admin' && (
                <StylishNewButton
                  className="button--primary"
                  onClick={() => editRoleButtonClicked(row)}
                >
                  Edit Role
                </StylishNewButton>
              )}
            {!reduxValidateRBACPermissionForActionResult['Edit Role'] && (
              <StylishNewButton
                disabled
                tooltipOnDisable="You do not have this permission."
                className="button--primary"
              >
                Edit Role
              </StylishNewButton>
            )}
            {reduxValidateRBACPermissionForActionResult['Delete Role'] &&
              !defaultRoleNames.find((r) => r === row.name) &&
              row.name !== 'Group Admin' && (
                <StylishNewButton
                  className="button--tertiary button--reverse"
                  onClick={() => deleteRoleButtonClicked(row)}
                >
                  Delete Role
                </StylishNewButton>
              )}
            {(!reduxValidateRBACPermissionForActionResult['Delete Role'] &&
              !defaultRoleNames.find((r) => r === row.name) && (
                <StylishNewButton
                  disabled
                  className="button--tertiary button--reverse"
                >
                  Delete Role
                </StylishNewButton>
              )) ||
              (!!defaultRoleNames.find((r) => r === row.name) && (
                <StylishNewButton
                  disabled
                  className="button--tertiary button--reverse"
                >
                  Delete Role
                </StylishNewButton>
              ))}
          </div>
          {!reduxValidateRBACPermissionForActionResult['Edit Role'] && (
            <span className="form-text form-error d-flex mt-3">
              You do not have permission to edit role.
            </span>
          )}
          {(!reduxValidateRBACPermissionForActionResult['Delete Role'] &&
            !defaultRoleNames.find((r) => r === row.name) && (
              <span className="form-text form-error d-flex mt-3">
                You do not have permission to delete role.
              </span>
            )) ||
            (!!defaultRoleNames.find((r) => r === row.name) && (
              <span className="form-text form-error d-flex mt-3">
                Default roles cannot be deleted
              </span>
            ))}
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  function editRoleButtonClicked(role) {
    setRoleSelectedForEditing(role);
    setIsEditRoleDialogOpen(true);
  }

  function deleteRoleButtonClicked(row) {
    setDeleteRoleData(row);
    setShowConfirmDialog(true);
  }

  const deleteRoleConfirmed = () => {
    reduxDispatch(startLoading());
    reduxDispatch(deleteRole(deleteRoleData));
    setShowConfirmDialog(false);
  };

  if (
    typeof reduxAllRolesInGroup === 'undefined' ||
    typeof reduxValidateRBACPermissionForActionResult === 'undefined' ||
    typeof reduxValidateRBACPermissionForActionResult['Create Role'] ===
      'undefined' ||
    typeof reduxValidateRBACPermissionForActionResult['Edit Role'] ===
      'undefined' ||
    typeof reduxValidateRBACPermissionForActionResult['Delete Role'] ===
      'undefined'
  ) {
    return <div>Loading Organization Roles...</div>;
  }

  return (
    <>
      <div className="d-md-flex align-items-center mb-3">
        <h4 className="m-0">Organization Roles</h4>
        {reduxValidateRBACPermissionForActionResult['Create Role'] ? (
          <>
            <StylishNewButton
              className="button--primary ms-auto mt-2 mt-md-0"
              onClick={() => setIsNewRoleDialogOpen(true)}
            >
              New Role
            </StylishNewButton>
          </>
        ) : (
          <>
            <div className="ms-auto text-end">
              <StylishNewButton
                className="button--primary mt-2 mt-md-0"
                disabled
              >
                New Role
              </StylishNewButton>
              <span className="form-text form-error">
                {"You don't have permission."}
              </span>
            </div>
          </>
        )}
      </div>
      <div className="form-block">
        <StylishNewTable
          keyField={'id'}
          columns={allColumn}
          rows={reduxAllRolesInGroup}
          expandRow={expandRow}
        />

        {isNewRoleDialogOpen && (
          <NewRoleDialog
            show={isNewRoleDialogOpen}
            onClose={() => setIsNewRoleDialogOpen(false)}
            group_guid={reduxCurrentlySelectedGroup.group_guid}
          ></NewRoleDialog>
        )}

        {isEditRoleDialogOpen && (
          <EditRoleDialog
            show={isEditRoleDialogOpen}
            onClose={() => setIsEditRoleDialogOpen(false)}
            existingRole={roleSelectedForEditing}
            group_guid={reduxCurrentlySelectedGroup.group_guid}
          ></EditRoleDialog>
        )}

        {showConfirmDialog && (
          <StylishConfirmDialog
            show={showConfirmDialog}
            dialogTitle={'Delete Confirmation'}
            dialogContent={`Delete the role '${deleteRoleData.name}'?  This action cannot be undone. All members with this role will lose role privileges.`}
            onClose={() => setShowConfirmDialog(false)}
            onConfirm={deleteRoleConfirmed}
          />
        )}
      </div>
    </>
  );
}
