import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import NewDistributionListDialog from './NewDistributionListDialog';
import EditDistributionListDialog from './EditDistributionListDialog';
import { useDispatch } from 'react-redux';
import {
  editDistributionList,
  createDistributionList,
} from 'actions/notificationActions';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishNewChip } from 'components/DesignSystems/New/StylishNewChip';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
export default function AddDistributionListDialog({
  show,
  distributionLists = [],
  selectedDistributionLists = [],
  setSelectedDistributionLists,
  roster,
  onClose,
  recipientTypes,
}) {
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();

  const [selectedLists, setSelectedLists] = useState(
    distributionLists.filter((list) =>
      selectedDistributionLists.includes(list.id)
    )
  );

  const [
    newDistributionListDialogOpen,
    setNewDistributionListDialogOpen,
  ] = useState(false);

  const [
    editDistributionListDialogOpen,
    setEditDistributionListDialogOpen,
  ] = useState();

  const allColumns = [
    {
      dataField: 'name',
      text: 'Relevant Team',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Relevant Team' },
    },
  ];

  useEffect(() => {
    setSelectedLists(
      distributionLists.filter((list) =>
        selectedDistributionLists.includes(list.id)
      )
    );
  }, [distributionLists, selectedDistributionLists]);

  const handleRecipientNameClick = (recipient) => {
    navigate(`/profile/${recipient}`);
  };

  const handleNewDistributionList = (list) => {
    if (list && list.id && list.name) {
      reduxDispatch(createDistributionList(list));
    }

    setNewDistributionListDialogOpen(false);
  };

  const handleEditDistributionList = (list) => {
    if (list && list.id && list.name) {
      reduxDispatch(editDistributionList(list));
    }

    setEditDistributionListDialogOpen(false);
  };

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <div className="form-chip-group">
            {row.members
              .filter((member) =>
                roster.some((person) => person.user_guid === member)
              )
              .map((member) => {
                const user = roster.find(
                  (person) => person.user_guid === member
                );
                const name = user.user_name || user.email_address;
                return (
                  <StylishNewChip
                    key={`key-${member}`}
                    label={name}
                    handleClick={() => {
                      handleRecipientNameClick(member);
                    }}
                  />
                );
              })}
            {!!row.nonMembers &&
              row.nonMembers.map((member) => {
                return (
                  <StylishNewChip
                    key={`key-${member.id}`}
                    label={member.content}
                  />
                );
              })}
          </div>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    hideSelectAll: false,
    selected: selectedDistributionLists,
    selectionHeaderRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} />
        <span className="icon"></span>
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} />
        <span className="icon"></span>
      </div>
    ),
    onSelect: (row) => {
      let selection = [...selectedDistributionLists];
      if (selection.includes(row.id)) {
        selection = selection.filter((i) => i !== row.id);
      } else {
        selection.push(row.id);
      }
      setSelectedDistributionLists(selection);
    },
    onSelectAll: (isSelect, rows) => {
      let selection = [...selectedDistributionLists];
      rows.forEach((row) => {
        if (selection.includes(row.id)) {
          selection = selection.filter((i) => i !== row.id);
        } else {
          selection.push(row.id);
        }
      });
      setSelectedDistributionLists(selection);
    },
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Select Relevant Team(s)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Select Team</h5>
          <StylishNewTable
            keyField={'id'}
            columns={allColumns}
            rows={distributionLists}
            hasPagination={true}
            paginationPerPageItem="10"
            expandRow={expandRow}
            selectRow={selectRow}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton className="button--primary" onClick={onClose}>
              Save List Selection
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </Modal>

      {newDistributionListDialogOpen && (
        <NewDistributionListDialog
          roster={roster}
          onClose={(list) => handleNewDistributionList(list)}
          recipientTypes={recipientTypes}
          distributionLists={distributionLists}
        />
      )}

      {editDistributionListDialogOpen && (
        <EditDistributionListDialog
          roster={roster}
          onClose={(list) => handleEditDistributionList(list)}
          recipientTypes={[]}
          selectedDistributionList={editDistributionListDialogOpen}
          distributionLists={distributionLists}
        />
      )}
    </>
  );
}
