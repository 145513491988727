import React from 'react';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';
import { useFeatureFlag } from '../../../utils/featureFlags';
import SeeristEventsList from './SeeristEventsList';

const GeolocationDetails = ({ aor, onBack }) => {
  const neoFlag = useFeatureFlag('NEO');

  return (
    <div>
      <div className="d-flex align-items-center mb-4">
        <StylishNewButton className="button button--link me-3" onClick={onBack}>
          <SharedIcon iconName="arrow_back" />
        </StylishNewButton>
        <h5 className="m-0">{aor.name} Details</h5>
      </div>

      <div className="bg-neutral-800 p-3 rounded mb-4">
        <div className="mb-3">
          <label className="text-muted mb-1">Description</label>
          <div>{aor.description || 'No description provided'}</div>
        </div>
        <div className="mb-3">
          <label className="text-muted mb-1">Location</label>
          <div>{aor.location?.name || 'No location name'}</div>
          <div className="text-muted small">
            {aor.location?.address || 'No address provided'}
          </div>
        </div>
      </div>

      {neoFlag ? <SeeristEventsList aor={aor} /> : null}
    </div>
  );
};

export default GeolocationDetails;
