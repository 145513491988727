import React from 'react';
import { useSelector } from 'react-redux';
import { StylishNewCheckbox } from 'components/DesignSystems/New/StylishNewCheckbox';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';

type NotificationMessageScreenProps = {
  recipientTypes: string[];
  subject: string;
  setSubject: (x: string) => void;
  message: string;
  setMessage: (x: string) => void;
  handleMessageInput: (x: string) => void;
  emailMessageMaxLength: number;
  SMSMessage: string;
  smsMessageMaxLength: number;
  setSMSMessage: (x: string) => void;
  ttsMessageMaxLength: number;
  TTSMessage: string;
  setTTSMessage: (x: string) => void;
  saveAsTemplate: boolean;
  setSaveAsTemplate: (x: boolean) => void;
  templateTitle: string;
  setTemplateTitle: (x: string) => void;
  templateTitleError: boolean;
  setTemplateTitleError: (x: boolean) => void;
  handleTemplateSave: () => void;
  renderTTSMessage: boolean;
};
export const NotificationMessageScreen: React.FC<
  NotificationMessageScreenProps
> = ({
  recipientTypes,
  subject,
  setSubject,
  message,
  setMessage,
  emailMessageMaxLength,
  SMSMessage,
  smsMessageMaxLength,
  setSMSMessage,
  ttsMessageMaxLength,
  TTSMessage,
  setTTSMessage,
  saveAsTemplate,
  setSaveAsTemplate,
  templateTitle,
  setTemplateTitle,
  templateTitleError,
  setTemplateTitleError,
  handleTemplateSave,
}) => {
  const reduxValidateRBACPermissionForActionResult = useSelector(
    (state: any) => {
      return state.app.validateRBACPermissionForActionResult;
    }
  );

  return (
    <div className="form-block mb-4">
      {!!recipientTypes.length && (
        <>
          <h4 className="mb-4">Message</h4>
          {recipientTypes.find((t) => t === 'Email') && (
            <>
              <div className="mb-4">
                <label className="form-label">Email Subject</label>
                <StylishNewInput
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="form-label">Email Message</label>
                <StylishNewTextArea
                  value={message}
                  onChange={(e) => {
                    if (e.target.value.length <= emailMessageMaxLength)
                      setMessage(e.target.value);
                  }}
                />
                <span className="form-info d-flex mt-2 weight-500">
                  Character Count: {message?.length || 0} - Characters
                  Remaining: {emailMessageMaxLength - message?.length || 0}
                </span>
                {message?.length > emailMessageMaxLength && (
                  <span className="form-text form-error">
                    You cannot send more than {emailMessageMaxLength} characters
                    via Email
                  </span>
                )}
              </div>
            </>
          )}
          {!!recipientTypes.find((t) => t === 'SMS') && (
            <div className="mb-4">
              <label className="form-label">SMS Message</label>
              <StylishNewTextArea
                value={SMSMessage}
                onChange={(e) => setSMSMessage(e.target.value)}
              />
              <span className="form-info d-flex mt-2 weight-500">
                Character count: {SMSMessage.length || 0} - Characters
                Remaining: {smsMessageMaxLength - SMSMessage.length || 0}
              </span>
              {SMSMessage.length > smsMessageMaxLength && (
                <span className="form-text form-error">
                  You cannot send more than {smsMessageMaxLength} characters via
                  SMS
                </span>
              )}
            </div>
          )}
          {recipientTypes.some(
            (t) =>
              t === 'Text-To-Speech (cell)' || t === 'Text-To-Speech (home)'
          ) && (
            <div className="mb-4">
              <label className="form-label">Text-To-Speech Message</label>
              <StylishNewTextArea
                value={TTSMessage}
                onChange={(e) => setTTSMessage(e.target.value)}
              />
              <span className="form-info d-flex mt-2 weight-500">
                Character count: {TTSMessage.length || 0} - Characters
                Remaining: {ttsMessageMaxLength - TTSMessage.length || 0}
              </span>
              {TTSMessage.length > ttsMessageMaxLength && (
                <span className="form-text form-error">
                  You cannot send more than {ttsMessageMaxLength} characters via
                  Text-To-Speech
                </span>
              )}
            </div>
          )}
          <hr className="dashed" />
        </>
      )}

      <StylishNewCheckbox
        checked={saveAsTemplate}
        onClick={() => {
          setSaveAsTemplate(!saveAsTemplate);
        }}
        label="Save as Template"
        disabled={
          !reduxValidateRBACPermissionForActionResult[
            'Manage Notification Templates'
          ]
        }
      />
      {saveAsTemplate && (
        <div className="mt-4">
          <label className="form-label">Template Title</label>
          <StylishNewInput
            value={templateTitle}
            onChange={(e) => {
              setTemplateTitle(e.target.value);
              setTemplateTitleError(false);
            }}
          />
          {templateTitleError ? (
            <span className="form-text form-error">
              This is a required field
            </span>
          ) : null}
          <StylishNewButton
            onClick={handleTemplateSave}
            className="button--primary mt-3"
            disabled={
              !reduxValidateRBACPermissionForActionResult[
                'Manage Notification Templates'
              ]
            }
          >
            Save
          </StylishNewButton>
        </div>
      )}
    </div>
  );
};

export default NotificationMessageScreen;
