import { useDispatch } from 'react-redux';
import {
  useAppSelector,
  selectAllExerciseWorkspaces,
  selectExerciseWorkspace,
} from 'slices/commonSelectors';
import {
  persistUserCurrentExerciseWorkSpace,
  changeSelectedWorkSpace,
  changeSelectedWorkSpaceStatus,
} from 'PREPAREsrc/store/workspaces/workspaces.action';

export const useSelectedExerciseWorkspace = () => {
  const dispatch = useDispatch();

  const exercises: ExerciseWorkspace[] = useAppSelector(selectAllExerciseWorkspaces);

  // initial selection
  const selectedExercise: ExerciseWorkspace | undefined = useAppSelector(selectExerciseWorkspace)

  const handleSelectId = (proposedId: string | undefined) => {
    // has to be new
    if (selectedExercise?.id === proposedId) {
      return;
    }

    // has to exist
    const proposedExercise = exercises && exercises.find((exercise) => exercise.id === proposedId);

    if (!proposedExercise) {
      return;
    }
    
    dispatch(persistUserCurrentExerciseWorkSpace(proposedId));
    dispatch(changeSelectedWorkSpace(proposedId));
    dispatch(changeSelectedWorkSpaceStatus(proposedExercise.status));
  };

  return {
    exercises,
    selectedExercise,
    handleSelectExerciseId: handleSelectId,
  };
}