import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StylishNewToggleButtonGroup } from 'components/DesignSystems/New/StylishNewToggleButtonGroup';
import ManageSiteChecklist from './ManageSiteChecklist';
import ManageTeamChecklist from './ManageTeamChecklist';
import CreateChecklist from './CreateChecklist';
import { fetchAllDistributionList } from 'actions/notificationActions';
import { getAllLocationAsset } from 'actions/locationAction';
import { Modal } from 'react-bootstrap';

const toggleData = [
  { label: 'Site Checklist', value: 'Site Checklist' },
  { label: 'Team Checklist', value: 'Team Checklist' },
];
export default function ManageChecklistModal({ show, onClose }) {
  const reduxDispatch = useDispatch();

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  useEffect(() => {
    if (!!reduxCurrentlySelectedGroup) {
      reduxDispatch(
        fetchAllDistributionList(reduxCurrentlySelectedGroup.group_guid)
      );
      reduxDispatch(getAllLocationAsset('All'));
    }
  }, [reduxCurrentlySelectedGroup.group_guid]);

  const [selectedToggleButton, setSelectedToggleButton] =
    useState('Site Checklist');
  const [isCreateChecklist, setIsCreateChecklist] = useState(false);
  const [selectedChecklist, setSelectedChecklist] = useState();
  const onChangeToggleButtonGroup = (e) => {
    setSelectedToggleButton(e);
    onChecklistChangeTab('View');
  };
  const onChecklistChangeTab = (e, selectedChecklist) => {
    if (e === 'Edit Checklist') {
      setSelectedChecklist(selectedChecklist);
      setIsCreateChecklist(true);
    } else if (e === 'Create Checklist') {
      setSelectedChecklist(undefined);
      setIsCreateChecklist(true);
    } else {
      setSelectedChecklist(undefined);
      setIsCreateChecklist(false);
    }
  };
  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Manage Checklist</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="d-flex align-items-center mb-4">
          {!isCreateChecklist && (
            <>
              <div className="button-group">
                <StylishNewButton
                  className="button--primary w-100 w-md-auto"
                  onClick={() =>
                    isCreateChecklist
                      ? onChecklistChangeTab('View')
                      : onChecklistChangeTab('Create Checklist')
                  }
                >
                  {isCreateChecklist ? 'Manage' : 'Create'}{' '}
                  {selectedToggleButton}
                </StylishNewButton>
              </div>
              <StylishNewToggleButtonGroup
                className={'ms-auto'}
                data={toggleData}
                onChange={(e) => onChangeToggleButtonGroup(e)}
                selected={selectedToggleButton}
              />
            </>
          )}
        </div>
        {isCreateChecklist ? (
          <CreateChecklist
            setIsCreateChecklist={setIsCreateChecklist}
            onChangeTab={(selectedChecklist, tab) =>
              onChecklistChangeTab(selectedChecklist, tab)
            }
            selectedChecklist={selectedChecklist}
            type={selectedToggleButton}
          />
        ) : selectedToggleButton === 'Site Checklist' ? (
          <ManageSiteChecklist
            type={selectedToggleButton}
            onChangeTab={onChecklistChangeTab}
          />
        ) : selectedToggleButton === 'Team Checklist' ? (
          <ManageTeamChecklist
            type={selectedToggleButton}
            onChangeTab={onChecklistChangeTab}
          />
        ) : null}
      </Modal.Body>
    </Modal>
  );
}
