import React from 'react';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import moment from 'moment';

export const ResultsPage: React.FC<any> = ({ setActiveStep, result }) => {
  return (
    <>
      <AnalysisView result={result} />
      <StylishNewButton
        className="button--sml button--secondary"
        onClick={() => {
          setActiveStep((s: number) => s - 1);
        }}
      >
        Previous
      </StylishNewButton>
      <StylishNewButton
        className="button--sml button--primary float-end"
        onClick={() => setActiveStep(1)}
      >
        Start Over
      </StylishNewButton>
    </>
  );
};

export const AnalysisView: React.FC<any> = ({ result }) => {
  const totalCapacity = Number(result?.total_capacity)?.toLocaleString("en-US")
  return (
    <>
      {result && <div>Total Capacity: {totalCapacity} passengers</div>}
      <hr />
      <div className="list-border">
        {result && result?.per_leg_capacity_output ? (
          result?.per_leg_capacity_output?.map((x: any) => {
            return (
              <>
                <div key={x.id} className="list-item">
                  <span>ID: {x.id}</span>
                  <span>Type: {x.type}</span>
                  <span>Origin: {x.origin_code}</span>
                  <span>Destination: {x.destination_code}</span>
                  <span>Capacity: {x?.per_leg_capacity?.toLocaleString("en-US") || "Unknown"}</span>
                </div>
                <br />
                Trips:{' '}
                {x.trips.map((trip: any, idx: number) => {
                  const passengerCapacity = Number(trip?.passenger_capacity)?.toLocaleString("en-US")
                  return (
                    <>
                      <div key={idx} className="list-item">
                        <span>Trip Code: {trip.trip_code}</span>
                        <span>
                          Passenger Capacity: {passengerCapacity}
                        </span>
                      </div>
                      <span>
                        Departure:{' '}
                        {moment(trip.departure).format('DD MMM YY - HH:mm A')}
                      </span>
                    </>
                  );
                })}
                <hr />
              </>
            );
          })
        ) : (
          <div>No Flights Available</div>
        )}
      </div>
    </>
  );
};
