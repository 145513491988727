import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from 'constants/apiConfig';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
import { useSelector } from 'react-redux';
import { selectGroupGuid } from 'slices/commonSelectors';
import { useFeatureFlag } from '../../../utils/featureFlags';

export const keys = {
  seeristEvents: 'seeristEvents',
};

export type SeeristArticle = {
  id: string;
  image_url: string;
  title: string;
  source_url: string;
  published_date: string;
  summary: string;
};

export type SeeristEvent = {
  id: string;
  title: string;
  image_url: string;
  source_url: string;
  event_date: string;
  severity: string;
  summary: string;
  articles: SeeristArticle[];
};

// Fetch Seerist events for an AOR
export const useSeeristEvents = (aorId: string) => {
  const group_guid = useSelector(selectGroupGuid);
  const neoFlag = useFeatureFlag('NEO');

  return useQuery({
    queryKey: [keys.seeristEvents, group_guid, aorId],
    queryFn: async () => {
      if (!aorId) return null;
      const response = await axios.get<SeeristEvent[]>(
        `${config.apiGateway.seeristEvents}/${aorId}`,
        {
          params: { group_guid },
        }
      );
      return response.data;
    },
    enabled: !!aorId && !!group_guid && neoFlag,
  });
};

// Refresh Seerist data for an AOR
export const useRefreshSeerist = () => {
  const queryClient = useQueryClient();
  const group_guid = useSelector(selectGroupGuid);

  return useMutation({
    mutationFn: async (aorId: string) => {
      const response = await axios.post(
        `${config.apiGateway.seeristEvents}/${aorId}/refresh`,
        { group_guid }
      );
      return response.data;
    },
    onSuccess: (data, aorId) => {
      queryClient.invalidateQueries({
        queryKey: [keys.seeristEvents],
      });
      toast.success('Seerist data refreshed successfully.', toastConfig);
    },
    onError: (error) => {
      console.error('Error refreshing Seerist data:', error);
      toast.error('Failed to refresh Seerist data.', toastConfig);
    },
  });
};
