// Import necessary components
import {
  Document,
  Packer,
  Paragraph,
  TextRun,
  ExternalHyperlink,
  HeadingLevel,
  Table,
  TableRow,
  TableCell,
  WidthType,
  VerticalAlign,
} from 'docx';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import MarkdownIt from 'markdown-it';
import { useSharepointDocxUpload } from '../hooks/useSharepointFiles';

// Initialize MarkdownIt with necessary options
const md = new MarkdownIt({
  linkify: true,
  typographer: true,
  breaks: false, // Treat single newlines as spaces
});

// Function to normalize data for export (used for Clipboard destination)
function normalizeForExport(data) {
  if (typeof data === 'string') return data;

  const formattedMessages = data
    .map((message) => {
      let messageRole = message.role.toUpperCase();
      if (messageRole === 'ASSISTANT') {
        messageRole = 'DisasterGPT';
      }
      const timestamp = message.timestamp
        ? dayjs(message.timestamp).format('YYYY-MM-DD HH:mm')
        : '';
      return `${messageRole} [${timestamp}]:\n${message.content.replace(
        /【.*?】/g,
        ''
      )}\n\n`;
    })
    .join('');
  return formattedMessages;
}

// Helper function to map heading levels
function getHeadingLevel(level) {
  switch (level) {
    case 1:
      return HeadingLevel.HEADING_1;
    case 2:
      return HeadingLevel.HEADING_2;
    case 3:
      return HeadingLevel.HEADING_3;
    case 4:
      return HeadingLevel.HEADING_4;
    case 5:
      return HeadingLevel.HEADING_5;
    case 6:
      return HeadingLevel.HEADING_6;
    default:
      return HeadingLevel.HEADING_1;
  }
}

// Helper function to parse inline tokens with proper style handling
function parseInlineTokens(inlineTokens) {
  const runs = [];
  const styleStack = [];

  inlineTokens.forEach((token) => {
    switch (token.type) {
      case 'strong_open':
        styleStack.push({ bold: true });
        break;

      case 'strong_close':
        styleStack.pop();
        break;

      case 'em_open':
        styleStack.push({ italics: true });
        break;

      case 'em_close':
        styleStack.pop();
        break;

      case 'link_open':
        {
          const href = token.attrs.find((attr) => attr[0] === 'href')[1];
          styleStack.push({ link: href });
        }
        break;

      case 'link_close':
        styleStack.pop();
        break;

      case 'code_inline':
        runs.push(
          new TextRun({
            text: token.content,
            font: 'Courier New',
            shading: { fill: 'EEEEEE' },
          })
        );
        break;

      case 'text':
        {
          // Replace any remaining \n with spaces
          const cleanedText = token.content
            .replace(/\n/g, ' ')
            .replace(/【.*?】/g, '');

          // Accumulate current styles
          const currentStyle = styleStack.reduce((acc, style) => {
            return { ...acc, ...style };
          }, {});

          // Handle links separately as ExternalHyperlink requires different handling
          if (currentStyle.link) {
            runs.push(
              new ExternalHyperlink({
                children: [
                  new TextRun({ text: cleanedText, style: 'Hyperlink' }),
                ],
                link: currentStyle.link,
              })
            );
          } else {
            runs.push(new TextRun({ ...currentStyle, text: cleanedText }));
          }
        }
        break;

      // Handle more inline types as needed

      default:
        break;
    }
  });

  return runs;
}

// Helper function to parse tables
function parseTable(tokens, startIndex) {
  const rows = [];
  let i = startIndex + 1; // Skip 'table_open'

  while (i < tokens.length && tokens[i].type !== 'table_close') {
    const token = tokens[i];

    if (token.type === 'thead_open' || token.type === 'tbody_open') {
      i++; // Skip 'thead_open' or 'tbody_open'
    } else if (token.type === 'tr_open') {
      const cells = [];
      i++; // Move past 'tr_open'

      while (i < tokens.length && tokens[i].type !== 'tr_close') {
        const cellToken = tokens[i];

        if (cellToken.type === 'th_open' || cellToken.type === 'td_open') {
          const isHeader = cellToken.type === 'th_open';
          i++; // Move past 'th_open' or 'td_open'

          const inlineToken = tokens[i];
          let cellChildren = [];
          if (inlineToken && inlineToken.type === 'inline') {
            cellChildren = parseInlineTokens(inlineToken.children);
            i++; // Move past 'inline'
          }

          // Skip 'th_close' or 'td_close'
          i++;

          cells.push({
            children: cellChildren,
            isHeader: isHeader,
          });
        } else {
          i++;
        }
      }

      rows.push(cells);
      i++; // Move past 'tr_close'
    } else if (token.type === 'thead_close' || token.type === 'tbody_close') {
      i++; // Skip 'thead_close' or 'tbody_close'
    } else {
      i++;
    }
  }

  // Build the DOCX Table
  const tableRows = rows.map((cells) => {
    return new TableRow({
      children: cells.map((cell) => {
        return new TableCell({
          children: [
            new Paragraph({
              children: cell.children,
              heading: cell.isHeader ? HeadingLevel.HEADING_3 : undefined,
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          shading: cell.isHeader
            ? { fill: 'E2E2E2' } // Light gray background for header cells
            : undefined,
          borders: {
            top: { style: 'single', size: 1, color: '000000' },
            bottom: { style: 'single', size: 1, color: '000000' },
            left: { style: 'single', size: 1, color: '000000' },
            right: { style: 'single', size: 1, color: '000000' },
          },
          margins: {
            top: 100,
            bottom: 100,
            left: 100,
            right: 100,
          },
        });
      }),
    });
  });

  const table = new Table({
    rows: tableRows,
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
  });

  return { table, newIndex: i + 1 };
}

// Helper function to convert Markdown tokens to Docx Paragraphs
function convertMarkdownTokensToDocx(tokens, context) {
  const paragraphs = [];
  const listStack = [];
  const numberingMap = {}; // Map of numbering references
  const totalTokens = tokens.length;

  let i = 0;
  while (i < totalTokens) {
    const token = tokens[i];

    switch (token.type) {
      case 'paragraph_open':
        {
          const inlineToken = tokens[i + 1];
          if (inlineToken && inlineToken.type === 'inline') {
            const paragraph = new Paragraph({
              children: parseInlineTokens(inlineToken.children),
            });
            paragraphs.push(paragraph);
            i += 3; // Skip 'paragraph_open', 'inline', 'paragraph_close'
          } else {
            i++;
          }
        }
        break;

      case 'heading_open':
        {
          const level = parseInt(token.tag.replace('h', ''), 10);
          const inlineToken = tokens[i + 1];
          if (inlineToken && inlineToken.type === 'inline') {
            const paragraph = new Paragraph({
              children: parseInlineTokens(inlineToken.children),
              heading: getHeadingLevel(level),
            });
            paragraphs.push(paragraph);
            i += 3; // Skip 'heading_open', 'inline', 'heading_close'
          } else {
            i++;
          }
        }
        break;

      case 'bullet_list_open':
      case 'ordered_list_open':
        {
          listStack.push(token.type);
          i++; // Move to the next token
        }
        break;

      case 'bullet_list_close':
      case 'ordered_list_close':
        {
          listStack.pop();
          i++; // Move to the next token
        }
        break;

      case 'list_item_open':
        {
          const listTypes = listStack.slice(); // Copy of listStack
          const currentLevel = listTypes.length - 1;
          const numberingKey = listTypes.join('|');

          let numberingReference = numberingMap[numberingKey];

          if (!numberingReference) {
            numberingReference = `list-${context.numberingIdCounter++}`;

            const levels = [];

            for (let level = 0; level <= currentLevel; level++) {
              const listTypeAtLevel = listTypes[level];

              const isBulletList = listTypeAtLevel === 'bullet_list_open';

              levels.push({
                level: level,
                format: isBulletList ? 'bullet' : 'decimal',
                text: isBulletList
                  ? level % 2 === 0
                    ? '•'
                    : '◦'
                  : `%${level + 1}.`,
                alignment: 'left',
                style: {
                  paragraph: {
                    indent: { left: 720 * (level + 1), hanging: 360 },
                  },
                },
              });
            }

            const numberingConfig = {
              reference: numberingReference,
              levels: levels,
            };

            context.numberingConfigs.push(numberingConfig);
            numberingMap[numberingKey] = numberingReference;
          }

          i++; // Move to the content inside the list item

          while (i < totalTokens && tokens[i].type !== 'list_item_close') {
            const innerToken = tokens[i];

            if (innerToken.type === 'paragraph_open') {
              const inlineToken = tokens[i + 1];
              if (inlineToken && inlineToken.type === 'inline') {
                const paragraph = new Paragraph({
                  children: parseInlineTokens(inlineToken.children),
                  numbering: {
                    reference: numberingReference,
                    level: currentLevel,
                  },
                });
                paragraphs.push(paragraph);
                i += 3; // Skip 'paragraph_open', 'inline', 'paragraph_close'
              } else {
                i++;
              }
            } else if (
              innerToken.type === 'bullet_list_open' ||
              innerToken.type === 'ordered_list_open'
            ) {
              // Recursively process nested lists
              const nestedTokens = [];
              let nestedLevel = 0;

              // Collect tokens for the nested list
              do {
                if (
                  tokens[i].type === 'bullet_list_open' ||
                  tokens[i].type === 'ordered_list_open'
                ) {
                  nestedLevel++;
                } else if (
                  tokens[i].type === 'bullet_list_close' ||
                  tokens[i].type === 'ordered_list_close'
                ) {
                  nestedLevel--;
                }
                nestedTokens.push(tokens[i]);
                i++;
              } while (i < totalTokens && nestedLevel > 0);

              const nestedParagraphs = convertMarkdownTokensToDocx(
                nestedTokens,
                context
              );
              paragraphs.push(...nestedParagraphs);
            } else {
              i++;
            }
          }

          i++; // Skip 'list_item_close'
        }
        break;

      case 'blockquote_open':
        {
          const contentTokens = [];
          let nestedLevel = 1;
          i++; // Move past 'blockquote_open'

          while (i < totalTokens && nestedLevel > 0) {
            if (tokens[i].type === 'blockquote_open') nestedLevel++;
            if (tokens[i].type === 'blockquote_close') nestedLevel--;
            if (nestedLevel > 0) contentTokens.push(tokens[i]);
            i++;
          }

          const quoteParagraphs = convertMarkdownTokensToDocx(
            contentTokens,
            context
          );
          // Apply quote style to all paragraphs
          quoteParagraphs.forEach((paragraph) => {
            paragraph.style = 'Quote';
            paragraph.indent = { left: 720 };
          });
          paragraphs.push(...quoteParagraphs);
        }
        break;

      case 'code_block':
      case 'fence':
        {
          const paragraph = new Paragraph({
            children: [
              new TextRun({
                text: token.content,
                font: 'Courier New',
                size: 24,
              }),
            ],
            shading: {
              fill: 'EEEEEE',
            },
            spacing: {
              before: 200,
              after: 200,
            },
          });
          paragraphs.push(paragraph);
          i++; // Move to the next token
        }
        break;

      case 'table_open':
        {
          const { table, newIndex } = parseTable(tokens, i);
          paragraphs.push(table);
          i = newIndex;
        }
        break;

      // Add more cases as needed for images, etc.

      default:
        i++; // Move to the next token
        break;
    }
  }

  return paragraphs;
}

// Function to convert Structured Data to DOCX Document
export function structuredDataToDocx(data) {
  const allParagraphs = [];
  const context = {
    numberingConfigs: [],
    numberingIdCounter: 1, // Start numbering ID counter
  };

  data.forEach((item, index) => {
    // Add role and timestamp as a separate paragraph

    let messageRole = item.role.toUpperCase();
    if (messageRole === 'ASSISTANT') {
      messageRole = 'DisasterGPT';
    }

    const timestamp = item.timestamp
      ? dayjs(item.timestamp).format('YYYY-MM-DD HH:mm')
      : '';

    // Role and Timestamp Paragraph
    allParagraphs.push(
      new Paragraph({
        children: [
          new TextRun({ text: messageRole, bold: true }),
          new TextRun({
            text: timestamp ? ` [${timestamp}]` : ``,
            italics: true,
          }),
        ],
        spacing: { after: 200 },
      })
    );

    // Check if content exists
    if (item.content) {
      // Unescape newline characters and split the content into separate paragraphs at double newline
      const processedContent = item.content
        .replace(/\\n/g, '\n')
        .replace(/【.*?】/g, '');
      const splitParagraphs = processedContent.split(/\n\s*\n/);

      splitParagraphs.forEach((paragraphText) => {
        // Parse each paragraph's markdown
        const tokens = md.parse(paragraphText, {});
        // Pass the context to the function
        const contentParagraphs = convertMarkdownTokensToDocx(tokens, context);
        allParagraphs.push(...contentParagraphs);
        allParagraphs.push(
          new Paragraph({ text: '', spacing: { after: 200 } })
        );
      });
    } else {
      console.warn(`Item at index ${index} has no content.`);
    }
  });

  // Initialize the Document with numbering and styles and a single section containing all children
  const doc = new Document({
    numbering: {
      config: context.numberingConfigs,
    },
    styles: {
      paragraphStyles: [
        {
          id: 'Quote',
          name: 'Quote',
          basedOn: 'Normal',
          next: 'Normal',
          quickFormat: true,
          run: {
            italics: true,
          },
          paragraph: {
            indent: { left: 720 },
          },
        },
      ],
    },
    sections: [
      {
        children: allParagraphs,
      },
    ],
  });

  return doc;
}

// Export function with timestamp in filename
export function docxExport(data, destination, filename) {
  let parsedData = data;

  if (destination === 'Sharepoint') {
    // Build doc, return promise that resolves to the blob
    try {
      const doc = structuredDataToDocx(data);
      
      // Same timestamp logic as "Download"
      const now = dayjs();
      let timestamp = now.format('YYYY-MM-DD hh:mm A');
      timestamp = timestamp.replace(/:/g, '-');
      const filenameWithTimestamp = `${filename}_${timestamp}.docx`;

      // Return a promise that resolves to { blob, filenameWithTimestamp }
      return Packer.toBlob(doc).then((blob) => {
        return { blob, filenameWithTimestamp };
      });
    } catch (err) {
      toast.error(`Error preparing docx for SharePoint`);
      console.error('SharePoint docxExport error:', err);
      // Return a rejected promise
      return Promise.reject(err);
    }
  }

  if (destination === 'Download' && typeof data === 'string') {
    // Only parse JSON if destination is 'Download' and data is a string
    try {
      parsedData = JSON.parse(data);
    } catch (error) {
      toast.error(`Invalid data format. Unable to parse JSON.`);
      console.error('Error parsing JSON data:', error);
      return;
    }
  }

  if (destination === 'Clipboard') {
    // Step 1: Normalize data into a Markdown string
    const markdownString = normalizeForExport(parsedData);
    // Copy Markdown string to clipboard
    navigator.clipboard
      .writeText(markdownString)
      .then(() => {
        toast.success(`Copied ${filename} to clipboard`);
      })
      .catch((err) => {
        toast.error(`Error copying ${filename} to clipboard`);
        console.error('Error copying to clipboard:', err);
      });
  } else if (destination === 'Download') {
    try {
      // Step 2: Convert Structured Data to DOCX Document
      const doc = structuredDataToDocx(parsedData);

      // Step 3: Generate the DOCX file with timestamp in filename
      // Get current datetime
      const now = dayjs();

      // Format datetime as 'yyyy-mm-dd hh:mm am/pm'
      let timestamp = now.format('YYYY-MM-DD hh:mm A'); // e.g., '2024-12-05 10:30 AM'

      // Replace ':' with '-' to make it filename-safe
      timestamp = timestamp.replace(/:/g, '-');

      // Create the new filename with timestamp
      const filenameWithTimestamp = `${filename}_${timestamp}.docx`;

      // Generate and save the DOCX file
      Packer.toBlob(doc)
        .then((blob) => {
          saveAs(blob, filenameWithTimestamp);
          toast.success(`Downloaded ${filenameWithTimestamp}`);
        })
        .catch((err) => {
          toast.error(`Error exporting ${filenameWithTimestamp}`);
          console.error('Error exporting to docx:', err);
        });
    } catch (error) {
      toast.error(`Error processing ${filename}.docx`);
      console.error('Error processing docxExport:', error);
    }
  } else {
    // Handle other destinations if necessary
    toast.error(`Unsupported export destination: ${destination}`);
    console.error(`Unsupported export destination: ${destination}`);
  }
}

// Example usage
// docxExport(exampleData, 'Download', 'Advisory_Report');
