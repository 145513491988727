import React, { useState, useEffect } from 'react';
import { Accordion, Offcanvas, Row } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import DrawerWrapper, { DrawerFooter } from 'components/IAP/DrawerWrapper';
import FormTextField from 'components/IAP/formFields/FormTextField';
import FormTextAreaField from 'components/IAP/formFields/FormTextAreaField';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { useCreatePrompt, useEditPrompt } from './hooks/usePromptLibraryHooks';
import { selectGroupGuid } from 'slices/commonSelectors';
import { useSelector } from 'react-redux';

// Import SOPs, DataSources, and SharePointFiles components
import SOPs from 'components/DisasterGPT/SOPs';
import DataSources from 'components/DisasterGPT/DataSources';
import SharePointFiles from 'components/DisasterGPT/SharepointFiles'; // New component for SharePoint

const PromptDrawer = ({ toggle, selectedEntity }) => {
  const form = useForm({
    defaultValues: selectedEntity || {
      title: '',
      description: '',
      settings: {},
    },
    mode: 'onChange',
  });

  const { handleSubmit } = form;
  const group_guid = useSelector(selectGroupGuid);

  // State for document, data source, and SharePoint file selections
  const [docSelections, setDocSelections] = useState([]);
  const [dataSourceSelections, setDataSourceSelections] = useState([]);
  const [sharepointSelections, setSharepointSelections] = useState([]); // New state for SharePoint selections

  // Load existing selections when editing
  useEffect(() => {
    if (selectedEntity && selectedEntity.settings) {
      if (selectedEntity.settings.documents) {
        setDocSelections(selectedEntity.settings.documents);
      }
      if (selectedEntity.settings.datasources) {
        setDataSourceSelections(selectedEntity.settings.datasources);
      }
      if (selectedEntity.settings.sharepoint) {
        // Load SharePoint selections if they exist
        setSharepointSelections(selectedEntity.settings.sharepoint);
      }
    }
  }, [selectedEntity]);

  const mutateEditPrompt = useEditPrompt();
  const mutateCreatePrompt = useCreatePrompt();

  // Choose the appropriate mutation based on whether we're editing or creating
  const mutation = selectedEntity ? mutateEditPrompt : mutateCreatePrompt;

  const handleFormSubmit = async (formData) => {
    const payload = {
      ...formData,
      settings: {
        ...(formData.settings || {}),
        documents: docSelections,
        datasources: dataSourceSelections,
        sharepoint: sharepointSelections, // Include SharePoint selections in the payload
      },
      group_guid,
      id: selectedEntity?.id, // Include ID if editing
    };

    await mutation.mutateAsync(payload);
    toggle(); // Close the drawer after submission
  };

  // Implement the toggleActive function
  const toggleActive = async () => {
    if (!selectedEntity) return;

    const newArchivedValue = !selectedEntity.archived;
    const payload = {
      ...selectedEntity,
      archived: newArchivedValue,
      group_guid,
    };

    await mutation.mutateAsync(payload);
    toggle(); // Close the drawer after updating
  };

  return (
    <DrawerWrapper
      toggle={toggle}
      title={selectedEntity ? 'Edit Prompt' : 'Add Prompt'}
    >
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          className="d-flex flex-column justify-content-between flex-grow-1"
        >
          <Offcanvas.Body>
            <Row>
              <FormTextField
                title="Title"
                fieldName="title"
                rules={{
                  required: { value: true, message: 'This field is required' },
                  maxLength: {
                    value: 255,
                    message: 'The maximum length is 255 characters',
                  },
                }}
                variant="half"
              />
            </Row>
            <Row>
              <FormTextAreaField
                title="Description"
                fieldName="description"
                variant="full"
              />
            </Row>

            {/* Include SOPs (Documents) component */}
            <Accordion className="custom-accordion">
              <Accordion.Item>
                <SOPs
                  selections={docSelections}
                  setSelections={setDocSelections}
                />
              </Accordion.Item>
              <Accordion.Item eventKey="0" className="mt-0">
                <DataSources
                  selections={dataSourceSelections}
                  setSelections={setDataSourceSelections}
                />
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="mt-0">
                <SharePointFiles
                  selections={sharepointSelections} // Pass the state for SharePoint selections
                  setSelections={setSharepointSelections} // Function to update SharePoint selections
                />
              </Accordion.Item>
            </Accordion>
          </Offcanvas.Body>
          <DrawerFooter>
            <div className="button-group">
              {/*              {selectedEntity && (
                <StylishNewButton
                  className="button--tertiary button--reverse"
                  type="button"
                  onClick={toggleActive}
                >
                  {selectedEntity.archived ? 'Activate' : 'Deactivate'}
                </StylishNewButton>
              )}*/}
              <StylishNewButton
                className="button--secondary"
                type="button"
                onClick={toggle}
              >
                Close
              </StylishNewButton>
              <StylishNewButton
                className="button--primary"
                type="submit"
                disabled={mutation.isLoading}
              >
                Save
              </StylishNewButton>
            </div>
          </DrawerFooter>
        </form>
      </FormProvider>
    </DrawerWrapper>
  );
};

export default PromptDrawer;
