import config from 'constants/apiConfig';
import axios from 'axios';
import { FETCH_MEETINGS, UPSERT_FORM_RECORD_ERROR } from 'constants/form-action-types';
import { endLoading } from 'reducers/loading/loading.action';
import { toast } from 'react-toastify';
import { noAutoClose } from 'assets/data/config';

function parseError(api, error) {
  const errorMessage =
    `${api}` +
    'There was an error saving your Meeting.  No associated Workflows have modified. Please try again.' +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: UPSERT_FORM_RECORD_ERROR,
  };
}

export const upsertMeeting = (meetingData) => (dispatch, getState) =>
  axios(config.apiGateway.upsertMeeting, {
    method: 'POST',
    data: JSON.stringify({
      group_guid: getState().app.currentlySelectedGroup.group_guid,
      ...meetingData,
    }),
  })
    .then(() => {
      dispatch(fetchMeetings(meetingData));
      // toast.success('Upsert Meeting successful', {
      //   autoClose: 2000,
      // });
    })
    .catch((error) => {
      dispatch(parseError(config.apiGateway.upsertMeeting, error));
    })
    .finally(() => {
      dispatch(endLoading());
    });

export const fetchMeetings = (meetingData) => (dispatch, getState) =>
  axios(config.apiGateway.fetchMeetings, {
    method: 'POST',
    data: JSON.stringify({
      group_guid: getState().app.currentlySelectedGroup.group_guid,
      ...meetingData,
    }),
  })
    .then((response) => {
      dispatch({ type: FETCH_MEETINGS, payload: response.data });

      dispatch(endLoading());
    })
    .catch((error) => {
      dispatch(parseError(config.apiGateway.fetchMeetings, error));
    })
    .finally(() => {
      dispatch(endLoading());
    });
