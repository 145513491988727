import {
  selectGroup,
  selectUser,
  useAppSelector,
} from '../../slices/commonSelectors';
import { useFeatureFlag } from '../../utils/featureFlags';

export enum SUBSCRIPTION {
  TRIAL = 'Trial',
  PAID = 'Paid',
  INCIDENT = 'Incident',
  EXERCISE = 'Exercise',
  EXPIRED = 'Expired', // Freemium
}

export const statusOptions = [
  'Trial',
  'Paid',
  'Paid (Incident only)',
  'Paid (Exercise only)',
  'Expired',
];

export const useSubscriptionLevel = (user0?: User) => {
  const bypassSubscription = !useFeatureFlag('SUBSCRIPTION_MANAGEMENT');

  const actualUser = useAppSelector(selectUser);
  const user = user0 ?? actualUser;

  const currentGroup = useAppSelector(selectGroup);

  const groupSubscription = user.subscriptions?.find(
    (s) => s.group_guid === currentGroup.group_guid
  );

  const subscriptionLevel = getSubscriptionLevel(groupSubscription?.status);

  const notificationLimit =
    groupSubscription?.details?.notifications_limit || 150;

  const paid = subscriptionLevel === SUBSCRIPTION.PAID;
  const lockIncident = ![SUBSCRIPTION.PAID, SUBSCRIPTION.INCIDENT].includes(
    subscriptionLevel
  );
  const lockExercise = ![SUBSCRIPTION.PAID, SUBSCRIPTION.EXERCISE].includes(
    subscriptionLevel
  );
  const freemium = [SUBSCRIPTION.TRIAL, SUBSCRIPTION.EXPIRED].includes(
    subscriptionLevel
  );

  const lockNotification = true;

  if (bypassSubscription) {
    return {
      paid: true,
      lockIncident: false,
      lockExercise: false,
      freemium: false,
      lockNotification: false,
      subscriptionLevel: SUBSCRIPTION.PAID,
      notificationLimit: notificationLimit,
    };
  }

  return {
    paid,
    lockIncident,
    lockExercise,
    freemium,
    lockNotification,
    subscriptionLevel,
    notificationLimit: notificationLimit,
  };
};

function getSubscriptionLevel(current_status?: string) {
  switch (current_status) {
    case 'Trial':
      return SUBSCRIPTION.TRIAL;
    case 'Paid':
      return SUBSCRIPTION.PAID;
    case 'Paid (Incident only)':
      return SUBSCRIPTION.INCIDENT;
    case 'Paid (Exercise only)':
      return SUBSCRIPTION.EXERCISE;
    case 'Expired':
    default:
      return SUBSCRIPTION.EXPIRED;
  }
}

export function describeSubscriptionLevel(subscriptionLevel: SUBSCRIPTION) {
  switch (subscriptionLevel) {
    case SUBSCRIPTION.PAID:
      return 'Full Access';
    case SUBSCRIPTION.INCIDENT:
      return 'Incident Only';
    case SUBSCRIPTION.EXERCISE:
      return 'Exercise Only';
    case SUBSCRIPTION.TRIAL:
    case SUBSCRIPTION.EXPIRED:
      return 'Map Only';
  }
}
