import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import StylishCheckbox from 'components/DesignSystems/form/StylishCheckbox';

import { setCloneDefaults, fetchCloneDefaults } from 'actions/diceActions';

import './CloneDefaults.css';

export default function CloneDefaults() {
  const reduxDispatch = useDispatch();

  const reduxCurrentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const reduxCloneDefaults = useSelector((state) => {
    return state.app.cloneDefaults;
  });

  const isFetchCloneDefaultsLoaded = useSelector((state) => {
    return state.app.isFetchCloneDefaultsLoaded;
  });

  const isSetCloneDefaultsLoaded = useSelector((state) => {
    return state.app.isSetCloneDefaultsLoaded;
  });

  const user = useSelector((state) => {
    return state.app.user;
  });

  useEffect(() => {
    if (
      !!reduxCurrentIncident &&
      !!reduxCurrentIncident.id &&
      !!reduxCurrentlySelectedGroup &&
      !!reduxCurrentlySelectedGroup.group_guid &&
      !!isFetchCloneDefaultsLoaded
    ) {
      reduxDispatch(fetchCloneDefaults());
    }
  }, [reduxCurrentIncident, reduxCurrentlySelectedGroup]);

  if (
    !!process.env.REACT_APP_NEW_USER_DEMO_SETTER_DOMAIN &&
    user.email_address.split(process.env.REACT_APP_NEW_USER_DEMO_SETTER_DOMAIN)
      .length > 1 &&
    (!reduxCurrentIncident ||
      !reduxCurrentlySelectedGroup ||
      !reduxCloneDefaults)
  ) {
    return <div>Loading clone defaults...</div>;
  }

  return (
    <div className="CloneDefaults">
      {!!process.env.REACT_APP_NEW_USER_DEMO_SETTER_DOMAIN &&
        user.email_address.split(
          process.env.REACT_APP_NEW_USER_DEMO_SETTER_DOMAIN
        ).length > 1 && (
          <div>
            <StylishCheckbox
              key={`CloneDefaults-checkbox`}
              checked={
                reduxCloneDefaults.group_guid ===
                  reduxCurrentlySelectedGroup.group_guid &&
                reduxCloneDefaults.incident_id === reduxCurrentIncident.id
              }
              disabled={
                reduxCloneDefaults.group_guid ===
                  reduxCurrentlySelectedGroup.group_guid &&
                reduxCloneDefaults.incident_id === reduxCurrentIncident.id
              }
              onClick={() =>
                reduxDispatch(
                  setCloneDefaults(
                    reduxCurrentlySelectedGroup.group_guid,
                    reduxCurrentIncident.id
                  )
                )
              }
              label={
                'The current Group and Incident are the default for new users.'
              }
              className="CloneDefaults-checkbox"
            />
            {!isSetCloneDefaultsLoaded && <small>Updating defaults...</small>}
          </div>
        )}
    </div>
  );
}
