import React from 'react';
import { useSelector } from 'react-redux';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishNewChip } from 'components/DesignSystems/New/StylishNewChip';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';
import { Accordion } from 'react-bootstrap';

type NotificationRecipientsScreenProps = {
  isFetchRostersForGroupsLoaded: boolean;
  groupMembersToNotify: UUID[];
  nonMembers: NonMember[];
  disabledGuids: UUID[];
  setDisabledGuids: (x: UUID[]) => void;
  selectedDistributionLists: UUID[];
  distributionLists: Team[];
  setAddDistributionListDialogOpen: (x: boolean) => void;
  setAddNewRecipientsDialogOpen: (x: boolean) => void;
  setNonMembers: (x: NonMember[]) => void;
  setGroupMembersToNotify: (x: UUID[]) => void;
  setSelectedDistributionLists: (x: UUID[]) => void;
};

export const NotificationRecipientsScreen: React.FC<
  NotificationRecipientsScreenProps
> = ({
  isFetchRostersForGroupsLoaded,
  groupMembersToNotify,
  nonMembers,
  disabledGuids,
  setDisabledGuids,
  selectedDistributionLists,
  distributionLists,
  setAddDistributionListDialogOpen,
  setAddNewRecipientsDialogOpen,
  setNonMembers,
  setGroupMembersToNotify,
  setSelectedDistributionLists,
}) => {
  const reduxCurrentlySelectedGroup = useSelector((state: any) => {
    return state.app.currentlySelectedGroup;
  });
  const reduxRostersForGroups: RosterItem[] = useSelector((state: any) => {
    const rosters = state.app.rostersForGroups || [];
    return rosters
      .filter(
        (member: RosterItem) =>
          member.group_guid === reduxCurrentlySelectedGroup.group_guid
      )
      .map((member: RosterItem) => ({
        ...member,
        role_string: member.role_assignments
          .map((item) => item.name)
          .join(', '),
      }));
  });

  const handleDisableGuid = (guid: UUID) => {
    if (disabledGuids.find((t) => t === guid)) {
      setDisabledGuids(disabledGuids.filter((t) => t !== guid));
    } else {
      setDisabledGuids([...disabledGuids, guid]);
    }
  };

  const handleRecipientDeleteClick = (recipient: NonMember | UUID) => {
    if (
      typeof recipient !== 'string' &&
      nonMembers.find((m) => m.id === recipient.id)
    ) {
      setNonMembers(nonMembers.filter((m) => m.id !== recipient.id));
    } else {
      setGroupMembersToNotify(
        groupMembersToNotify.filter((member) => member !== recipient)
      );
    }
  };

  const handleDistributionListDeleteClick = (listId: UUID) => {
    setSelectedDistributionLists(
      selectedDistributionLists.filter((list) => list !== listId)
    );
  };

  return (
    <div className="form-block mb-4">
      <h4 className="mb-4">
        Recipients
        <span aria-label="Required field" className="required">
          *
        </span>
      </h4>
      {!!isFetchRostersForGroupsLoaded && !!reduxRostersForGroups.length && (
        <>
          {!!groupMembersToNotify && !!groupMembersToNotify.length ? (
            <h5 className="mb-2">Selected Recipients</h5>
          ) : null}
          {!!groupMembersToNotify && !!groupMembersToNotify.length && (
            <>
              <div className="form-chip-group mb-4">
                {groupMembersToNotify
                  .filter((member) =>
                    reduxRostersForGroups.find(
                      (person) => person.user_guid === member
                    )
                  )
                  .map((member) => {
                    const user = reduxRostersForGroups.find(
                      (person) => person.user_guid === member
                    );
                    const name = user?.user_name || user?.email_address;
                    return (
                      <>
                        <StylishNewChip
                          key={`key-${member}`}
                          label={name || ''}
                          handleDelete={() => {
                            handleRecipientDeleteClick(member);
                          }}
                          handleDisable={() => handleDisableGuid(member)}
                          disabled={
                            !!disabledGuids.find((guid) => guid === member)
                          }
                        />
                      </>
                    );
                  })}
              </div>
            </>
          )}
          {!!nonMembers && !!nonMembers.length && (
            <>
              <div className="form-chip-group mb-4">
                {nonMembers.map((member) => {
                  return (
                    <StylishNewChip
                      key={`key-${member.id}`}
                      label={member.content}
                      handleDelete={() => {
                        handleRecipientDeleteClick(member);
                      }}
                      handleDisable={() => handleDisableGuid(member.id)}
                      disabled={
                        !!disabledGuids.find((guid) => guid === member.id)
                      }
                    />
                  );
                })}
              </div>
            </>
          )}
        </>
      )}
      {selectedDistributionLists.length ? (
        <h5 className="mb-2">Selected Team(s)</h5>
      ) : null}
      {selectedDistributionLists.length ? (
        <Accordion defaultActiveKey="0" flush className="mb-4">
          {selectedDistributionLists.map((listId) => {
            const item = distributionLists.find((list) => list.id === listId);
            if (!item) {
              return null;
            }
            return (
              <Accordion.Item key={item.id} eventKey={item.id}>
                <Accordion.Header className="d-flex align-items-center">
                  {item.name}
                  <StylishNewButton
                    customButton
                    className="button--icon ms-auto"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleDistributionListDeleteClick(item.id);
                    }}
                  >
                    <SharedIcon iconName="delete" />
                  </StylishNewButton>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="form-chip-group">
                    {item.members
                      .filter((member) => {
                        return (
                          !!reduxRostersForGroups &&
                          !!reduxRostersForGroups.length &&
                          reduxRostersForGroups.some(
                            (person) => person.user_guid === member
                          )
                        );
                      })
                      .map((member) => {
                        const user = reduxRostersForGroups.find(
                          (person) => person.user_guid === member
                        );
                        const name = user?.user_name || user?.email_address;
                        return (
                          <>
                            <StylishNewChip
                              key={`key-${member}`}
                              label={name || ''}
                              disabled={
                                !!disabledGuids.find((guid) => member === guid)
                              }
                              handleDisable={() => handleDisableGuid(member)}
                            />
                          </>
                        );
                      })}
                    {!!item.nonMembers &&
                      item.nonMembers.map((member) => {
                        return (
                          <StylishNewChip
                            key={`key-${member.id}`}
                            label={member.content}
                            handleDisable={() => handleDisableGuid(member.id)}
                            disabled={
                              !!disabledGuids.find((guid) => guid === member.id)
                            }
                          />
                        );
                      })}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      ) : null}

      <div className="button-group d-block d-md-flex">
        <StylishNewButton
          className={'button--secondary w-100 w-md-auto'}
          onClick={() => setAddDistributionListDialogOpen(true)}
        >
          Select Relevant Team(s)
        </StylishNewButton>
        <StylishNewButton
          className={
            'button--secondary w-100 w-md-auto ms-0 ms-md-3 mt-2 mt-md-0'
          }
          onClick={() => setAddNewRecipientsDialogOpen(true)}
        >
          Add New Recipients
        </StylishNewButton>
      </div>
    </div>
  );
};

export default NotificationRecipientsScreen;
