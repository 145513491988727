import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { SeeristEvent as TSeeristEvent } from '../mapHooks/useSeeristEvents';
import { StylishNewButton } from '../../DesignSystems/New/StylishNewButton';
import { SharedIcon } from '../../SharedIcon/SharedIcon';

type SeerisEventProps = {
  event: TSeeristEvent;
};

export const SeeristEvent: React.FC<SeerisEventProps> = ({ event }) => {
  const [preview, setPreview] = useState(true);
  const [expanded, setExpanded] = useState(false);

  return preview ? (
    <Card className="border-0 shadow-sm bg-dark text-light mb-1">
      <Card.Body className="p-2 px-3">
        <StylishNewButton
          className="button--link text-link"
          onClick={() => setPreview(false)}
        >
          {event.title}
        </StylishNewButton>
      </Card.Body>
    </Card>
  ) : (
    <Card className="mb-1 border-0 shadow-sm bg-dark text-light">
      <Card.Body>
        <div className="d-flex align-items-start">
          {event.image_url && (
            <div className="flex-shrink-0 me-3">
              <img
                src={event.image_url}
                alt={event.title}
                className="rounded"
                style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                onError={(e) =>
                  ((e.target as HTMLImageElement).style.display = 'none')
                }
              />
            </div>
          )}

          <div className="flex-grow-1">
            <div className="d-flex justify-content-end">
              <StylishNewButton
                className="button--icon sml"
                onClick={() => setPreview(true)}
              >
                <SharedIcon iconName={'close'} size={'sm'} />
              </StylishNewButton>
            </div>
            <h5 className="mb-1">
              <a
                href={event.source_url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-light text-decoration-none fw-bold"
              >
                {event.title}
              </a>
            </h5>
            <small className="text-secondary">
              {new Date(event.event_date).toLocaleString()}
            </small>

            <div className="mt-2">
              <span className="text-secondary">Severity: </span>
              <span className="badge bg-danger">{event.severity}</span>
            </div>
            <p className="mt-3">{event.summary}</p>
          </div>
        </div>

        {event.articles.length > 0 && (
          <Button
            variant="outline-light"
            size="sm"
            className="mt-3"
            onClick={() => setExpanded(!expanded)}
          >
            {expanded
              ? 'Hide Articles'
              : `Show Articles (${event.articles.length})`}
          </Button>
        )}
      </Card.Body>

      {expanded && event.articles.length > 0 && (
        <Card.Footer className="bg-secondary bg-opacity-25">
          <h6 className="mb-3">Related Articles</h6>
          {event.articles.map((article: any) => (
            <div key={article.id} className="d-flex align-items-start mb-3">
              {article.image_url && (
                <div className="flex-shrink-0 me-3">
                  <img
                    src={article.image_url}
                    alt={article.title}
                    className="rounded"
                    style={{
                      width: '150px',
                      height: '150px',
                      objectFit: 'cover',
                    }}
                    onError={(e) =>
                      ((e.target as HTMLImageElement).style.display = 'none')
                    }
                  />
                </div>
              )}
              <div className="flex-grow-1">
                <h6 className="mb-1">
                  <a
                    href={article.source_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-light text-decoration-none fw-bold"
                  >
                    {article.title}
                  </a>
                </h6>
                <small className="text-secondary">
                  {new Date(article.published_date).toLocaleString()}
                </small>
                <p className="mt-2">{article.summary}</p>
              </div>
            </div>
          ))}
        </Card.Footer>
      )}
    </Card>
  );
};
