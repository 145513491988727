import { useEffect, useState } from 'react';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { useCapacity } from 'hooks/useCapacity';
import { toast } from 'react-toastify';

interface FinalPageProps {
  setActiveStep: any;
  origin: { label: string; value: UUID }; // AOR for origin
  destination: { label: string; value: UUID }; // AOR for destination
  selOriAirports: string[];
  selDestAirports: string[];
  selOriSeaports: string[];
  selDestSeaports: string[];
  setResult: (result: any) => void;
}

export const FinalPage: React.FC<FinalPageProps> = ({
  setActiveStep,
  origin,
  destination,
  selOriAirports,
  selDestAirports,
  selOriSeaports,
  selDestSeaports,
  setResult,
}) => {

  const capacityInput = {
    origin_aor_id: origin.value,
    destination_aor_id: destination.value,
    origin_airport_codes: selOriAirports,
    destination_airport_codes: selDestAirports,
    origin_seaport_codes: selOriSeaports,
    destination_seaport_codes: selDestSeaports,
  };

  const getCapacity = useCapacity(capacityInput);

  useEffect(() => {
    if (getCapacity.isSuccess) {
      setResult(getCapacity.data);
      setActiveStep((s: number) => s + 1);
    }
    if (getCapacity.isError) {
      toast.error('Error getting capacity analysis');
      console.log(getCapacity.error);
    }
  }, [getCapacity]);

  return (
    <>
      Confirm data:
      <br />
      Origin: {origin.label}
      <br />
      Destination: {destination.label}
      <br />
      Selected Origin Airports: {selOriAirports.join(', ')}
      <br />
      Selected Destination Airports: {selDestAirports.join(', ')}
      <br />
      Selected Origin Seaports: {selOriSeaports.join(', ')}
      <br />
      Selected Destination Seaports: {selDestSeaports.join(', ')}
      <br />
      <br />
      <StylishNewButton
        className="button--sml button--secondary"
        onClick={() => {
          setActiveStep((s: number) => s - 1);
        }}
      >
        Previous
      </StylishNewButton>
      <StylishNewButton
        className="button--sml button--primary float-end"
        onClick={() => getCapacity.mutateAsync()}
        disabled={getCapacity.isPending}
      >
        {getCapacity.isPending 
          ? <i className="fa fa-spinner fa-pulse fs-5 "></i>
          : <span>Analyze</span> }
      </StylishNewButton>
    </>
  );
};
