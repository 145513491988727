import React, { useState, useEffect } from 'react';
import Dialog, {
  Header,
  Body,
  Footer,
} from 'components/DesignSystems/dialog/Dialog';
import { CloseButton } from 'components/DesignSystems/buttons/CloseButton';
import NotificationRecipients from './NotificationRecipients';
import validateEmailAddress from 'utils/validateEmailAddress';
import validatePagerAddress from 'utils/validatePagerAddress';

import './EditDistributionListDialog.css';
import { Modal } from 'react-bootstrap';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import ManageTeamLocation from './ManageTeamLocation';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';

export default function EditDistributionListDialog({
  roster,
  onClose,
  selectedDistributionList: { members, name, nonMembers, id } = {},
  distributionLists,
  show,
  teamLocation,
}) {
  const [selectedMembers, setSelectedMembers] = useState(
    roster.filter((member) => members.includes(member.user_guid))
  );
  const [emailRecipients, setEmailRecipients] = useState([]);
  const [SMSRecipients, setSMSRecipients] = useState([]);
  const [TTSHomeRecipients, setTTSHomeRecipients] = useState([]);
  const [TTSCellRecipients, setTTSCellRecipients] = useState([]);
  const [pagerRecipients, setPagerRecipients] = useState([]);
  const [templateTitle, setTemplateTitle] = useState(name);
  const [selectedTeamLocation, setSelectedTeamLocation] = useState(null);

  const [recipientTypes, setRecipientTypes] = useState([]);

  const typeKey = {
    emailRecipients: 'Email',
    SMSRecipients: 'SMS',
    TTSHomeRecipients: 'Text-To-Speech (home)',
    TTSCellRecipients: 'Text-To-Speech (cell)',
    pagerRecipients: 'Pager',
  };
  useEffect(() => {
    if (teamLocation) {
      setSelectedTeamLocation(teamLocation);
    }
  }, [teamLocation]);

  useEffect(() => {
    if (nonMembers && Array.isArray(nonMembers) && !!nonMembers.length) {
      const {
        types,
        emailRecipients,
        SMSRecipients,
        TTSHomeRecipients,
        TTSCellRecipients,
        pagerRecipients,
      } = nonMembers.reduce(
        (acc, item) => {
          const key = typeKey[item.type];

          if (acc.types.indexOf(key) === -1) {
            acc.types.push(key);
          }

          switch (item.type) {
            case 'emailRecipients':
              acc.emailRecipients.push(item);
              break;
            case 'SMSRecipients':
              acc.SMSRecipients.push(item);
              break;
            case 'TTSHomeRecipients':
              acc.TTSHomeRecipients.push(item);
              break;
            case 'TTSCellRecipients':
              acc.TTSCellRecipients.push(item);
              break;
            case 'pagerRecipients':
              acc.pagerRecipients.push(item);
              break;
            default:
            // do nothing
          }

          return acc;
        },
        {
          types: [],
          emailRecipients: [],
          SMSRecipients: [],
          TTSHomeRecipients: [],
          TTSCellRecipients: [],
          pagerRecipients: [],
        }
      );

      setRecipientTypes(types);
      setEmailRecipients(emailRecipients);
      setSMSRecipients(SMSRecipients);
      setTTSHomeRecipients(TTSHomeRecipients);
      setTTSCellRecipients(TTSCellRecipients);
      setPagerRecipients(pagerRecipients);
    }
  }, [nonMembers]);

  const column = [
    {
      dataField: 'email_address',
      text: 'Email Address',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Member Email' },
    },
    {
      dataField: 'user_name',
      text: 'Member Name',
      sort: true,
      attrs: { title: 'Member Name' },
    },
    {
      dataField: 'role_string',
      text: 'Organization Role',
      sort: true,
      attrs: { title: 'Organization Role' },
    },
  ];

  const getUniqueValidations = (recipients, check) => {
    const recipientValidations = recipients
      .map((recipient) => check(recipient.content))
      .filter((invalid) => invalid.length)
      .reduce((acc, item) => {
        item.forEach((inner) => acc.push(inner));
        return acc;
      }, []);

    return [...new Set(recipientValidations)];
  };

  const onSaveTeamClick = () => {
    const SMSValidations = getUniqueValidations(
      SMSRecipients,
      getSMSValidationsForItem
    );
    const emailValidations = getUniqueValidations(
      emailRecipients,
      getEmailValidationsForItem
    );
    const TTSCellValidations = getUniqueValidations(
      TTSCellRecipients,
      getTTSValidationsForItem
    );
    const TTSHomeValidations = getUniqueValidations(
      TTSHomeRecipients,
      getTTSValidationsForItem
    );
    const pagerValidations = getUniqueValidations(
      pagerRecipients,
      getPagerValidationsForItem
    );

    const aggregateValidations = [
      ...SMSValidations,
      ...emailValidations,
      ...TTSCellValidations,
      ...TTSHomeValidations,
      ...pagerValidations,
    ];

    if (aggregateValidations.length > 0) {
      toast.error(
        <div>
          {aggregateValidations.map((text) => (
            <>
              - {text}
              <br />
            </>
          ))}
        </div>,
        toastConfig
      );
    } else {
      const newList = {
        id,
        name: templateTitle,
        members: selectedMembers.map((member) => member.user_guid),
        nonMembers: [
          ...emailRecipients,
          ...SMSRecipients,
          ...TTSHomeRecipients,
          ...TTSCellRecipients,
          ...pagerRecipients,
        ],
        location_entity_id: selectedTeamLocation?.location_entity_data?.value,
        location_sub_entity_id:
          selectedTeamLocation?.location_sub_entity_data?.value,
        location_enterprise_id:
          selectedTeamLocation?.location_enterprise_data?.value,
        location_asset_id: selectedTeamLocation?.location_asset_data?.value,
        location_id: selectedTeamLocation?.location_id,
      };
      const listwithoutcurrentEle = distributionLists.filter(
        (ele) => ele.id !== newList.id
      );
      const currentList = listwithoutcurrentEle.findIndex(
        (ele) => ele.name === newList.name
      );
      if (currentList !== -1) {
        const errorMessage =
          `Duplicate Relevant Team Name.\n\n` +
          'Failed Create New Relevant Team';
        console.warn(errorMessage);
        toast.error(errorMessage, toastConfig);
        return;
      } else {
        onClose(newList);
      }
    }
  };

  function getEmailValidationsForItem(item) {
    let validation_messages = [];
    if (!item) {
      validation_messages = [...validation_messages, `Email cannot be blank`];
    } else if (!validateEmailAddress(item)) {
      validation_messages = [...validation_messages, `Invalid email address`];
    }
    return validation_messages;
  }

  function getSMSValidationsForItem(item) {
    let validation_messages = [];
    if (!item) {
      validation_messages = [
        ...validation_messages,
        `SMS number cannot be blank`,
      ];
    } else if (
      !item.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/g)
    ) {
      validation_messages = [...validation_messages, `Invalid SMS number`];
    }
    return validation_messages;
  }

  function getTTSValidationsForItem(item) {
    let validation_messages = [];
    if (!item) {
      validation_messages = [
        ...validation_messages,
        `Text-To-Speech number cannot be blank`,
      ];
    } else if (
      !item.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/g)
    ) {
      validation_messages = [
        ...validation_messages,
        `Invalid Text-To-Speech number`,
      ];
    }
    return validation_messages;
  }

  function getPagerValidationsForItem(item) {
    let validation_messages = [];
    if (!item) {
      validation_messages = [
        ...validation_messages,
        `Pager address cannot be blank`,
      ];
    } else if (!validatePagerAddress(item)) {
      validation_messages = [...validation_messages, `Invalid pager address`];
    }
    return validation_messages;
  }

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    hideSelectAll: false,
    selected: selectedMembers.map((e) => e.user_guid),
    selectionHeaderRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} />
        <span className="icon"></span>
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} />
        <span className="icon"></span>
      </div>
    ),
    onSelect: (row, isSelect, rowIndex, e) => {
      let selection = selectedMembers.map((i) => i.user_guid);
      if (selection.includes(row.user_guid)) {
        selection = selection.filter((i) => i !== row.user_guid);
      } else {
        selection.push(row.user_guid);
      }
      setSelectedMembers(
        roster.filter((item) => selection.includes(item.user_guid))
      );
    },
    onSelectAll: (isSelect, rows, e) => {
      if (isSelect) {
        setSelectedMembers([...selectedMembers, ...rows]);
      } else {
        setSelectedMembers([]);
      }
    },
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Edit Relevant Team</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Select Organization Members</h4>
        <StylishNewTable
          keyField={'user_guid'}
          rows={roster}
          columns={column}
          selectRow={selectRow}
          hasPagination={true}
          paginationPerPageItem="10"
          classes="mb-4"
        />
        {!recipientTypes.length ? <hr className="dashed" /> : null}
        {!!recipientTypes.length && (
          <>
            <hr className="dashed" />
            <h4>Additional Recipients</h4>
            <div className="bg-gray-900 rounded p-4">
              <div className="notificationComposeScreen-section">
                {!!recipientTypes.find((t) => t === 'Email') && (
                  <div className="mb-4">
                    <label className="form-label">Email Recipients</label>
                    <NotificationRecipients
                      recipients={emailRecipients}
                      onRecipientsChange={(newRecipients) =>
                        setEmailRecipients(newRecipients)
                      }
                      getValidationMessagesForItem={getEmailValidationsForItem}
                      type="emailRecipients"
                    />
                  </div>
                )}
                <hr className="dashed my-3" />
                {!!recipientTypes.find((t) => t === 'SMS') && (
                  <div className="mb-4">
                    <label className="form-label">SMS Recipients</label>
                    <NotificationRecipients
                      recipients={SMSRecipients}
                      onRecipientsChange={(newRecipients) =>
                        setSMSRecipients(newRecipients)
                      }
                      getValidationMessagesForItem={getSMSValidationsForItem}
                      type="SMSRecipients"
                    />
                  </div>
                )}

                {!!recipientTypes.find(
                  (t) => t === 'Text-To-Speech (cell)'
                ) && (
                  <div className="mb-4">
                    <label className="form-label">
                      Text-To-Speech (cell) Recipients
                    </label>
                    <NotificationRecipients
                      recipients={TTSCellRecipients}
                      onRecipientsChange={(newRecipients) =>
                        setTTSCellRecipients(newRecipients)
                      }
                      getValidationMessagesForItem={getTTSValidationsForItem}
                      type="TTSCellRecipients"
                    />
                  </div>
                )}

                {!!recipientTypes.find(
                  (t) => t === 'Text-To-Speech (home)'
                ) && (
                  <div className="mb-4">
                    <label className="form-label">
                      Text-To-Speech (home) Recipients
                    </label>
                    <NotificationRecipients
                      recipients={TTSHomeRecipients}
                      onRecipientsChange={(newRecipients) =>
                        setTTSHomeRecipients(newRecipients)
                      }
                      getValidationMessagesForItem={getTTSValidationsForItem}
                      type="TTSHomeRecipients"
                    />
                  </div>
                )}

                {!!recipientTypes.find((t) => t === 'Pager') && (
                  <div className="mb-4">
                    <label className="form-label"> Pager Recipients</label>
                    <NotificationRecipients
                      recipients={pagerRecipients}
                      onRecipientsChange={(newRecipients) =>
                        setPagerRecipients(newRecipients)
                      }
                      getValidationMessagesForItem={getPagerValidationsForItem}
                      type="pagerRecipients"
                    />
                  </div>
                )}
              </div>
            </div>
            <hr className="dashed" />
          </>
        )}
        <label className="form-label">Team Title</label>
        <StylishNewInput
          className="NewDistributionListDialog-input mb-4"
          value={templateTitle}
          onChange={(e) => setTemplateTitle(e.target.value)}
        />
        <hr className="dashed my-3" />
        <ManageTeamLocation
          selectedTeamLocation={selectedTeamLocation}
          onChangeTeamLocation={setSelectedTeamLocation}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="button-group flex-column align-items-md-end">
          <StylishNewButton
            className="button--primary"
            onClick={onSaveTeamClick}
            disabled={
              !(
                templateTitle &&
                (selectedMembers.length ||
                  emailRecipients.length ||
                  SMSRecipients.length ||
                  TTSHomeRecipients.length ||
                  TTSCellRecipients.length ||
                  pagerRecipients.length)
              )
            }
          >
            Save Team
          </StylishNewButton>
          {!(
            templateTitle &&
            (selectedMembers.length ||
              emailRecipients.length ||
              SMSRecipients.length ||
              TTSHomeRecipients.length ||
              TTSCellRecipients.length ||
              pagerRecipients.length)
          ) && (
            <span className="form-text form-error">Form Validation Error</span>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
}
