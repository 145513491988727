import { useState } from 'react';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { MdLocationOn } from 'react-icons/md';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';

export const DestinationPage: React.FC<any> = ({
  arr,
  destination,
  setDestination,
  setSelDestAirports,
  setActiveStep,
  origin,
  flyTo,
  flyTo2,
}) => {
  const [search, setSearch] = useState('');

  return (
    <>
      <label className="form-label">Select a Destination AOR</label>
      <StylishNewSearchBar
        value={search}
        onChangeSearchTerm={(e: any) => {
          setSearch(e);
        }}
        placeholder="Search for AOR"
      />
      <div className="list-border">
        {arr
          .filter((x: any) =>
            search !== ''
              ? x.label.toLowerCase().includes(search.toLowerCase())
              : x
          )
          .filter((x: any) => {
            return x.label !== origin.label;
          })
          .map((x: any) => {
            return (
              <div
                key={x.id}
                className="list-item"
                onClick={() => {
                  setDestination({ value: x.value, label: x.label });
                  setSelDestAirports([]);
                }}
              >
                <span className="item-text form-label">
                  <input
                    type="radio"
                    className="neo-radio form-check-input me-3"
                    value={x.label}
                    checked={destination?.label === x?.label}
                    readOnly
                  />
                  {x.label}
                </span>
                <StylishNewButton
                  className="btn button--link float-end"
                  onClick={(e) => {
                    flyTo(x.value);
                    e.stopPropagation();
                  }}
                >
                  <MdLocationOn />
                </StylishNewButton>
              </div>
            );
          })}
      </div>
      <StylishNewButton
        className="button--sml button--secondary"
        onClick={() => {
          setActiveStep((s: number) => s - 1);
        }}
      >
        Previous
      </StylishNewButton>
      <StylishNewButton
        className="button--sml button--primary float-end"
        disabled={!destination}
        onClick={() => {
          setActiveStep((s: number) => s + 1);
          flyTo2(origin.value, destination.value);
        }}
      >
        Next
      </StylishNewButton>
    </>
  );
};
